import { Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import { H2 } from 'components/Typography';
import { useFormik } from 'formik';
import { useState, type FC } from 'react';
import * as Yup from 'yup';
import { statusApi } from '../../../api';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';

// component props interface
interface ModalProps {
    data?: any;
    open: boolean;
    edit?: boolean;
    onClose: () => void;
    fetchData: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddStatusModal: FC<ModalProps> = ({
    open,
    onClose,
    edit,
    data,
    fetchData
}) => {
    const initialValues = {
        name: data?.name || '',
        color: data?.color || '',
    };
    const { t } = useTranslation();
    const [colorValue, setColorValue] = useState('#ffffff');

    const handleColorChange = (newColor: any) => {
        setColorValue(newColor.hex);
    };

    const fieldValidationSchema = Yup.object().shape({
        name: Yup.string().trim().required(
            t('common.forms.field.required', {
                field: t('status.name'),
            }),
        ),
    });

    const { values, errors, handleChange, handleSubmit, touched, resetForm} = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {
            statusApi
                .create({ name: values.name.trim(), color: colorValue })
                .then(() => {
                    resetForm();
                    onClose();
                    fetchData();
                })
        },
    });

    return (
        <StyledAppModal open={open} handleClose={() => {
            onClose();
            resetForm();
        }}>
            <H2 mb={2}>
                {edit ? t('common.forms.button.edit') + ' ' + t('status.label') : t('common.forms.addItemLabel', {
                    item: t('status.label'),
                })}
            </H2>

            <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <AppTextField
                            fullWidth
                            size='small'
                            name='name'
                            placeholder={t('status.name')}
                            value={values.name}
                            onChange={handleChange}
                            error={Boolean(errors.name && touched.name)}
                            helperText={(touched.name && errors.name) as string}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <SketchPicker color={colorValue} onChange={handleColorChange} />
                        </div>
                    </Grid>
                </Grid>

                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        resetForm();
                        onClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal>
    );
};

export default AddStatusModal;
