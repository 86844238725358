import {
  Box,
  Card,
  Grid,
  styled,
  Tab,
  Tabs,
  type Theme,
  useMediaQuery,
  Snackbar,
  Autocomplete,
  TextField,
  MenuItem,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { H4, H6, Small, } from 'components/Typography';
import Icons from 'icons/account';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import TabComponent from 'page-sections/employees';
import React, { type FC, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import AvatarBadge from 'components/avatars/AvatarBadge';
import { CameraAlt, DescriptionOutlined } from '@mui/icons-material';
import { ContractFormData } from '../../page-sections/employees/Contract';
import { BankFormData } from '../../page-sections/employees/BankingInformation';
import { BasicInformationFormData } from '../../page-sections/employees/BasicInformation';
import { useLocation, useNavigate } from 'react-router-dom';
import { additionalInformationsApi, agreementApi, bankingInformationApi, contractsApi, employeesApi, idDocumentsApi, licenceApi, statusApi, tokenApi } from '../../api';
import {
  AdditionalInformationsEntity,
  CreateBankingInformationDto,
  CreateContractDto,
  CreateEmployeeDocumentDto,
  CreateEmployeeDto,
  EmployeeEntity,
  EmployeeToSeasonEntity,
  EmployeeToSeasonEntityStatusTypeEnum,
  GenerateTokenDtoLanguageEnum, NoteEntity,
  StatusEntity,
} from '../../api/generated';
import { CreateAgreementDtoNoId } from 'page-sections/data-table/dataTableV2/AddAgreementModal';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import toast from 'react-hot-toast';
import { convertToWebP } from '../../utils/converterToWebP';
import AppAvatar from 'components/avatars/AppAvatar';
import defaultProfileImage from '../../assets/images/default-profile.png';
import { IDFormData } from '../../page-sections/employees/IDDocuments';
import { HousingFormData } from 'page-sections/employees/Housing';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSeason } from 'contexts/SeasonContext';
import CustomToast from 'components/CustomToast/customToast';
import DeleteIcon from 'icons/DeleteIcon';
import AddStatusModal from 'page-sections/data-table/dataTableV2/AddStatusModal';
import { getFullNameWithIdConditionally, translateStatus } from 'utils/utils';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import SelectLanguageModal from 'page-sections/employees/selectLanguageModal';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { AdditionalFormData } from 'page-sections/employees/AdditionalInformation';

export enum TokenType {
  COMPLETE_DATA_REQUEST = 'COMPLETE_DATA_REQUEST',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

// Set alert graphics
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  marginTop: 55,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const CoverPicWrapper = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  height: 125,
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  margin: 'auto',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'white',
  backgroundColor: theme.palette.background.default,
}));
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  '& .MuiTab-root': {
    minWidth: "100%!important",
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
  },
  '& .MuiTabs-indicator': {
    height: 4,
  },
}));
const TabsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));
type IncludeId<T> = T & { id: number };
interface DocumentDtoWithId extends CreateEmployeeDocumentDto {
  id?: number;
}



interface FormData {
  basicInformation?: IncludeId<BasicInformationFormData>;
  idDocuments?: Array<IncludeId<IDFormData>>;
  bank?: IncludeId<BankFormData>;
  additionalInformations?: IncludeId<AdditionalFormData>;
  contract?: IncludeId<ContractFormData>;
  notes?: IncludeId<NoteEntity>
  housing?: IncludeId<HousingFormData>;
  employeesToSeasons?: EmployeeToSeasonEntity[];
}

type FormDataKeys = keyof FormData;

export interface DepartmentAssignment {
  department: {
    id: number;
    name: string;
  };
  workPosition: {
    name: string;
  };
};

const tabs: Array<{
  key: FormDataKeys;
  name: string;
  Icon: any;
}> = [
    {
      key: 'basicInformation',
      name: 'employees.generalInformation.title',
      Icon: Icons.UserOutlined,
    },
    {
      key: 'idDocuments',
      name: 'employees.id.title',
      Icon: Icons.Link,
    },
    {
      key: 'additionalInformations',
      name: 'employees.additionalInformation.title',
      Icon: ContactEmergencyIcon,
    },
    {
      key: 'bank',
      name: 'employees.bank.title',
      Icon: AccountBalanceIcon,
    },
    {
      key: 'contract',
      name: 'employees.contract.title',
      Icon: Icons.SettingsOutlined,
    }, {
      key: 'notes',
      name: 'employees.notes.title',
      Icon: DescriptionOutlined,
    },
    {
      key: 'housing',
      name: 'employees.housing.title',
      Icon: MapsHomeWorkIcon,
    },
  ];

const NewEmployee: FC = () => {
  // const theme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [data, setData] = useState<FormData | undefined>(undefined);
  // isSubmitting hook because formik's submitting hook cannot be accessed here
  const [isSubmitting, setIsSubmitting] = useState(false);
  // activeTab in the state is used to navigate to one specific tab of the employee informations
  const [activeTab, setActiveTab] = useState(state?.activeTab ? state.activeTab : 0);
  const isFromAllEmployees = state?.isFromAllEmployees as boolean | undefined;
  const [tempAvatarImage, setTempAvatarImage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [employees, setEmployees] = useState<EmployeeEntity[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeEntity[]>([]);
  const { seasonId } = useSeason();
  const [showToast, setShowToast] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<StatusEntity | null>();
  const [statusData, setStatusData] = useState<readonly StatusEntity[]>([
    {
      id: -1,
      name: 'Loading options',
      color: '#00000'
    }
  ]);
  const [openAddStatusModal, setOpenAddStatusModal] = useState(false);
  const [, forceUpdate] = useState({});
  const buttonFontSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? '0.7rem' : '1.1rem';
  const sectionsButtonFontSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? '0.6rem' : '0.7rem';
  const { encryptionKey } = useEncryption();

  const [newData, setNewData] = useState<CreateEmployeeDto>();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const employeeContext = useEmployeeContext();
  const StatusTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: selectedStatus?.color,
      },
    },
  });

  const setNewDataWithPrint = (data: any) => {
    setNewData(data);
  }

  useEffect(() => {
    fetchStatusData();
  }, []);

  const handleStatusChange = (event: any, value: StatusEntity | null) => {
    if (value?.id === 0) {
      setOpenAddStatusModal(true);
      return;
    }
    if (value?.id === -1 && data?.basicInformation) {
      updateStatus(data?.basicInformation.id, null, value.name as EmployeeToSeasonEntityStatusTypeEnum);
      const translatedStatus = translateStatus(value.name as EmployeeToSeasonEntityStatusTypeEnum)
      setSelectedStatus({
        name: translatedStatus.name,
        color: translatedStatus.color,
        id: -1
      })
    }
    else if (data?.basicInformation) {

      setSelectedStatus(value)
      updateStatus(data?.basicInformation.id, value?.id, 'CUSTOM');

    }

    forceUpdate({});
    setAutoCompleteOpen(false);

  };

  const optionsWithButton = [
    { id: 0, name: t('status.addNew'), color: '' },
    // add default statuses
    ...Object.keys(EmployeeToSeasonEntityStatusTypeEnum).filter(statusType => statusType.toUpperCase() !== 'CUSTOM').map(statusType => {
      const translatedStatus = translateStatus(statusType.toUpperCase() as EmployeeToSeasonEntityStatusTypeEnum);
      return { id: -1, name: statusType.toUpperCase(), color: translatedStatus.color };
    }),
    ...statusData
  ];

  const fetchStatusData = () => {
    statusApi.findAll().then(({ data }) => {
      setStatusData([...data]);
    }).catch(error => {
      console.error(error);
    });
  }

  async function updateStatus(employeeId: number, newStatusId: number | undefined | null, statusType: EmployeeToSeasonEntityStatusTypeEnum) {
    await employeesApi.assignStatus({
      data: {
        employeeId,
        statusType,
        seasonId,
        statusId: newStatusId
      }
    })
  }

  const deleteStatus = (id: number) => {
    statusApi.remove(String(id))
      .then(() => {
        // This filter is used to remove the status from the on-screen display without having to make a new call to the backend
        setStatusData((prevStatusData) => prevStatusData.filter((status) => status.id !== id));
        // if the status deleted is the one selected for the employee change the selectedStatus to the default "CANDIDATE"
        if (selectedStatus?.id === id) {
          const translatedStatus = translateStatus('CANDIDATE');
          setSelectedStatus({
            name: translatedStatus.name,
            color: translatedStatus.color,
            id: -1
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const prevTab = () => {
    if (activeTab <= 0) {
      return;
    }
    setActiveTab(activeTab - 1);
  };
  const nextTab = () => {
    if (activeTab >= tabs.length - 1) {
      return;
    }
    setActiveTab(activeTab + 1);
  };

  const submitWrapper = async (submit: () => Promise<any>) => {
    setIsSubmitting(true);
    const value = await submit();
    setIsSubmitting(false);
    return value;
  };

  function shortenData<T extends Record<string, any>>(
    oldData: T,
    newData: T,
  ): Partial<T> | undefined {
    const nonEqualFields = Object.fromEntries(
      Object.entries(newData).filter(([key, val]) => val !== oldData[key]),
    );
    if (Object.keys(nonEqualFields).length === 0) {
      return undefined;
    }
    return nonEqualFields as Partial<T>;
  }

  async function updateEmployee(
    newData: CreateEmployeeDto,
  ): Promise<IncludeId<CreateEmployeeDto>> {
    if (!data?.basicInformation) {
      // if mail field is empty delete it
      if (newData.email === '')
        delete newData.email
      // set default status candidate on new employee
      setSelectedStatus({
        id: -1,
        ...translateStatus(isFromAllEmployees ? 'ACTIVE' : 'CANDIDATE')
      });
      return employeesApi.create(newData, { params: { seasonId } }).then(async ({ data }) => {
        if (isFromAllEmployees)
          await updateStatus(data.id, undefined, 'ACTIVE');
        return data;
      });
    }
    newData.filePath = data.basicInformation.filePath; // Used to avoid updating the avatar image in case the user clicks next without having set any new avatar
    const trimmedData = shortenData(data.basicInformation, newData);
    // if mail field is empty set it to undefined
    if (trimmedData && trimmedData?.email === '')
      trimmedData.email = undefined;
    if (trimmedData) {
      return employeesApi
        .update(String(data.basicInformation.id), trimmedData)
        .then(({ data }) => data);
    } else {
      return data.basicInformation;
    }
  }

  const submitBasicInformation = async (basicInformation: BasicInformationFormData) => {
    return submitWrapper(async () => {
      let entity;

      try {
        entity = await updateEmployee(basicInformation);
        setData({
          ...data,
          basicInformation: entity,
        });
        if (tempAvatarImage && entity.id) {
          await handleFileUpload({ id: entity.id, isCalledBySubmit: true });
        }
        fetchEmployees();
        nextTab();
      } catch (error: any) {
        if (error.response.status === 400) {
          toast.error(t('alerts.employeeExists'));
        }
      }
      return entity;
    });
  };

  const sendDataCompilationRequestoToEmployee = async (
    newData: BasicInformationFormData,
    language: GenerateTokenDtoLanguageEnum,
  ) => {
    /**
     * This employee creation is necessary in order to send an e-mail to an employee that exists and to avoid problems 
     * with sending e-mails to employees that have not yet been created
     */
    const generateTokenDto = {
      employeeEmail: newData ? newData.email : data?.basicInformation?.email,
      type: TokenType.COMPLETE_DATA_REQUEST,
      language,
    };
    // if new employee create in database, otherwise do an update
    if (!data?.basicInformation && newData) {
      try {
        await employeesApi.create(newData, { params: { seasonId } }).then(({ data }) => data);
      }
      catch (error: any) {
        if (error.response.status === 400) {
          toast.error(t('alerts.employeeExists'));
        }
        return;
      }
    }
    else {
      try {
        updateEmployee(newData);
      }
      catch (error) {
        console.log(error)
        toast.error(t('alerts.request.error'), { duration: 4000 });
      }
    }



    await tokenApi.generateToken(generateTokenDto)
      .then(() => {
        toast.success(t('alerts.request.send'), { duration: 4000 });
      }).catch((error) => {
        console.log(error)
        toast.error(t('alerts.request.error'), { duration: 4000 });
      })

    fetchEmployees();
    navigate('/dashboard/employees');
  }

  // placeholders are for compatibility with other submit methods, please set them to undefined
  const submitRoom = async (
    housing: HousingFormData,
  ) => {
    if (housing.id < 0) {
      navigate('/dashboard/employees');
      if (data?.basicInformation) {
        await employeesApi.update(String(data?.basicInformation?.id), {})
        await employeesApi.assignRoom({
          data: {
            employeeId: data.basicInformation.id,
            roomId: null,
            seasonId
          }
        })


      }
      return;
    }

    return submitWrapper(async () => {
      const roomdId = housing.id;
      // basicInformation is always set because the tab is before the bank
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await employeesApi.update(String(data!.basicInformation!.id), {}).then(({ data }) => data);

      await employeesApi.assignRoom({
        data: {
          // basicInformation is always set because the tab is before the bank
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          employeeId: data!.basicInformation!.id,
          roomId: roomdId,
          seasonId
        }
      })

      setData({
        ...data,
      });
      navigate('/dashboard/employees');
    });
  };
  // This function was used to avoid errors related to async / await 
  async function getAvatarBlob() {
    const response = await fetch(tempAvatarImage);
    const blob = await response.blob();
    return blob;
  }

  async function updateBankingInformation(
    newData: CreateBankingInformationDto,
  ) {
    if (!data?.bank) {
      return bankingInformationApi.create(newData).then(({ data }) => data);
    }
    const { id, ...oldData } = data.bank;
    const trimmedData = shortenData(oldData, newData);
    if (trimmedData) {
      return bankingInformationApi
        .update(String(id), trimmedData)
        .then(({ data }) => data);
    } else {
      return data.bank;
    }
  }

  const submitBank = async (bank: BankFormData) => {
    return submitWrapper(async () => {
      const newData: CreateBankingInformationDto = {
        ...bank,
        // basicInformation is always set because the tab is before the bank
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        employeeId: data!.basicInformation!.id,
      };
      const entity = await updateBankingInformation(newData);
      setData({
        ...data,
        bank: entity,
      });
      nextTab();
    });
  };
  const submitNotes = async () => {
    return submitWrapper(async () => {
      nextTab();
    });
  };

  async function updateContract(newData: CreateContractDto) {
    if (!data?.contract) {
      return contractsApi.create(newData).then(({ data }) => data);
    }
    const { id, ...oldData } = data.contract;
    const trimmedData = shortenData(oldData, newData);
    if (trimmedData) {
      trimmedData.seasonId = seasonId;
      return contractsApi
        .update(String(id), trimmedData)
        .then(({ data }) => data);
    } else {
      return data.contract;
    }
  }

  const submitContract = async (contract: ContractFormData,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    switchTab?: boolean
  ) => {

    return submitWrapper(async () => {
      const { file, levelName, ...rest } = contract;
      // deleting id from rest because it causes 500 in backend
      delete rest.id;
      // if it's not part time remove work percentage
      if (!rest.isPartTime)
        rest.workPercentage = 0;
      // Initialize newData with the rest of the properties
      const newData: CreateContractDto = {
        ...rest,
        seasonId,
        // basicInformation is always set because the tab is before the contract
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        employeeId: data!.basicInformation!.id,
      };

      // Conditionally add 'level' to newData if it's part of CreateContractDto
      // and it's not an empty string
      newData.levelName = levelName;

      if (setIsLoading)
        setIsLoading(true);

      const entity = await updateContract(newData);
      const entityWithSeason = { ...entity, seasonId };
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data: fileEntity } = await contractsApi.createFile(
          String(entity.id),
          {
            data: formData,
          },
        );
        entityWithSeason.filePath = fileEntity.filePath;
      }

      setData({
        ...data,
        contract: entityWithSeason,
      });

      if (setIsLoading)
        setIsLoading(false);

      if (switchTab) {
        nextTab()
      }
    });
  };


  const submitAgreements = async (newData: CreateAgreementDtoNoId) => {
    return submitWrapper(async () => {
      await agreementApi
        .create({
          isLongTheWholeContract: newData.isLongTheWholeContract,
          notes: newData.notes,
          freeDays: newData.freeDays,
          downpayment: newData.downpayment,
          net_daily: encryptionKey ? undefined : newData.net_daily,
          startedOn: newData.startedOn,
          endedOn: newData.endedOn ? newData.endedOn : undefined,
          contractId: newData.contractId,
          net_daily_encrypted: encryptionKey ? newData.net_daily_encrypted : undefined
        })
    })
  }

  function showAlert(errorMessage: string) {
    setErrorMessage(errorMessage);
    setOpenSnackbar(true);
  }


  // Upload avatar image
  const avatar = data?.basicInformation?.filePath;
  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const fileSize = file.size / (1024 * 1024); // Dimension in MB
    if (fileSize > 30) {
      showAlert(t('alerts.uploadSizeErrorMessage', {
        dimension: '30',
      })); // Open Snackbar
      event.target.value = ''; // Reset input value
      return;
    }
    // If user is set file can be uploaded directly
    if (data?.basicInformation && data.basicInformation.id) {
      await handleFileUpload({ id: data.basicInformation.id, file });
    }
    // If user is not set only display the image, the actual upload happens at onSubmitBasicInformation{} 
    else {
      setTempAvatarImage(URL.createObjectURL(file));
      setDisplayAvatar(URL.createObjectURL(file));
    }
  };

  const handleFileUpload = async ({ id, file, isCalledBySubmit = false }: { id: number, file?: File, isCalledBySubmit?: boolean }) => {
    const formData = new FormData();
    // File is always defined because if the file is not defined isCalledBySubmit is true and the problematic code is never reached
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const blob = isCalledBySubmit ? await getAvatarBlob() : new Blob([file!], { type: file!.type });
    const webpBlob = await convertToWebP(blob, showAlert).catch((error) => { console.error(error) });
    if (!webpBlob) {
      return;
    }
    formData.append('file', webpBlob, 'avatar.webp');
    await employeesApi.createFile(String(id), {
      data: formData,
    });
    setDisplayAvatar(URL.createObjectURL(webpBlob));
    toast.success(t('alerts.uploadSuccess'), { duration: 5000 });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  async function updateIDDocuments(newDataArray: DocumentDtoWithId[]) {

    // Existing documents that were modified
    const updatedDocuments = newDataArray.filter(newData => newData.id && data?.idDocuments?.some(oldData => oldData.id === newData.id));
    // Document present on AWS/BE but were removed by the user
    const deletedDocuments = data?.idDocuments?.filter(oldData => !newDataArray.some(newData => newData.id === oldData.id));

    // Update existing documents
    const updatePromises = updatedDocuments.map(async newData => {
      const oldData = data?.idDocuments?.find(oldData => oldData.id === newData.id);
      if (oldData) {
        if (typeof newData.id !== 'undefined') {
          const shortenedData = shortenData(oldData, newData as IncludeId<IDFormData>);
          if (shortenedData) {
            // force typeName in update data to upload new possible names
            shortenedData.typeName = oldData.typeName;
            return idDocumentsApi.update(String(newData.id), shortenedData).then(({ data }) => data);
          }
        }
        return [];
      }
    });

    // Delete removed documents
    const deletePromises = (deletedDocuments?.map(async oldData => idDocumentsApi.remove(String(oldData.id)).then(({ data }) => data))) ?? [];

    // Wait for all operations to complete
    await Promise.all([...updatePromises, ...deletePromises]);

    // Fetch and return the updated state from the database

    // basicInformation is always set because the tab is before the documents
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const updatedState = await idDocumentsApi.findAll({ params: { employeeId: data?.basicInformation!.id } });
    return updatedState;
  }

  const submitNewDocument = async (newDocument: IDFormData) => {
    if (data?.basicInformation?.id) {
      const entity = await idDocumentsApi.create({
        employeeId: data?.basicInformation?.id,
        documentName: newDocument.documentName,
        isVisibleForUser: newDocument.isVisibleForUser,
        typeName: newDocument.typeName,
        expiringDate: newDocument.expiringDate
      })
      entity.data.documentFiles = [];
      // add new document to employee data
      data.idDocuments = data.idDocuments?.concat(entity.data);
      return entity.data;
    }

  }


  const submitIDDocuments = async (idDocuments: IDFormData[]) => {
    if (idDocuments.length === 0 && (data?.idDocuments?.length === 0 || !data?.idDocuments)) {
      nextTab();
      return;
    }

    await submitWrapper(async () => {
      const newDataArray = idDocuments.map(iddocument => {
        const { tempKey, ...rest } = iddocument;
        return {
          ...rest,
          // basicInformation is always set because the tab is before the bank
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          employeeId: data!.basicInformation!.id,
        };
      });

      const updatedEntities = await updateIDDocuments(newDataArray);
      setData({
        ...data,
        idDocuments: updatedEntities.data,
      });
      nextTab();
    })
  }


  /* const getProgress = () => {
    return (activeTab / tabs.length) * 100;
  }; */

  const cancel = () => {
    navigate('/dashboard/employees');
    toast.remove();
  };

  const handleToastClose = () => {
    setShowToast(false);
    navigate('/dashboard/employees');
  };

  const fetchAdditionalInformations = async () => {
    const employeeId = data?.basicInformation?.id ?? state?.employeeId;
    if (!employeeId)
      return;
    const additionalInformations = (await additionalInformationsApi.findOneByEmployee(employeeId.toString())).data;
    setData(
      {
        ...data,
        additionalInformations: {
          ...additionalInformations,
          foodRequirments: additionalInformations.foodRequirments as any[],
          shirtSize: additionalInformations.shirtSize as any,
          licences: additionalInformations.licences ?? []
        }
      }
    );
  }

  const setAdditionalInformations = (additionalInformations: AdditionalInformationsEntity) => {
    setData(
      {
        ...data,
        additionalInformations: {
          ...additionalInformations,
          foodRequirments: additionalInformations.foodRequirments as any[],
          shirtSize: additionalInformations.shirtSize as any,
          licences: additionalInformations.licences ?? []
        }
      }
    )
  }

  const submitAdditionalInformations = async (submit: AdditionalFormData) => {
    const employeeId = data?.basicInformation?.id ?? state?.employeeId;
    if (!employeeId)
      return;

    let additionalInformation: AdditionalInformationsEntity;
    // additionalInformations exists
    if (submit?.id) {
      additionalInformation = (await additionalInformationsApi.update(String(submit?.id), {
        customFoodRequirments: submit.customFoodRequirments as any,
        foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
        pantsSize: submit.pantsSize as any,
        shirtSize: submit.shirtSize?.toUpperCase() as any
      })).data;
    }
    else {
      additionalInformation = (await additionalInformationsApi.create({
        employeeId,
        customFoodRequirments: submit.customFoodRequirments as any,
        foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
        pantsSize: submit.pantsSize as any,
        shirtSize: submit.shirtSize?.toUpperCase() as any
      })).data;
    };

    const licenceToAdd = submit.licences?.filter((newLicence) => {
      // if a licence is already present it is not to add
      if ((data?.additionalInformations?.licences ?? []).find(oldLicence => oldLicence.id === newLicence.id))
        return false;
      return true;
    })

    const licenceToRemove = (data?.additionalInformations?.licences ?? []).filter((oldLicence) => {
      // if a licence is present in old licences but not in new it must be removed
      if (submit.licences?.find(newLicence => newLicence.id === oldLicence.id))
        return false;
      return true;
    })

    // licences loops

    for (const licence of licenceToAdd ?? []) {
      await licenceApi.assignLicence(licence.id, additionalInformation.id);
    }

    for (const licence of licenceToRemove ?? []) {
      await licenceApi.removeAssignment(licence.id, additionalInformation.id);
    }

    await fetchAdditionalInformations();
    nextTab();
  }

  const fetchData = async (newEmployeeId?: number) => {

    function removeEmployeeId<T extends { employeeId: number }>(
      entity?: T | null,
    ) {
      if (!entity) {
        return undefined;
      }

      const { employeeId, ...rest } = entity;
      return rest;
    }

    let employeeId;
    // Check if the employee was selected in the previous screen
    if (!state?.employeeId && employeeContext.employeeId === -1) {
      // If employee was neither selected in prev screen nor is in data we don't know which employee we are talking about
      if (!data?.basicInformation?.id) {
        setData({});
        return;
      }
      // If the employee was not saved in the prev screen (new employee), but we are in a new section we can use the basic information
      employeeId = data.basicInformation.id;
    } else if (state?.employeeId) {
      // Otherwise use the employeeId from the prev screen
      employeeId = state?.employeeId;
    } else {
      employeeId = employeeContext.employeeId;
    }
    // Assign the new id in case of employee change directly from the screen
    if (newEmployeeId) {
      employeeId = newEmployeeId;
      employeeContext.setEmployeeId(newEmployeeId);
    }

    try {
      const { data } = await employeesApi.findOne(employeeId.toString(), {
        params: {
          includeBankingInformation: true,
          includeContract: true,
          includeDocuments: true,
          includeRoom: true,
          includeStatus: true,
          seasonId
        },
      });

      const employeeEntity = data;

      if (employeeEntity.employeesToSeasons && employeeEntity.employeesToSeasons.at(0)?.statusType === 'CUSTOM') {
        setSelectedStatus(employeeEntity.employeesToSeasons.at(0)?.status);
      }
      else if (employeeEntity.employeesToSeasons?.at(0)?.statusType) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const translatedStatus = translateStatus(employeeEntity.employeesToSeasons.at(0)!.statusType)
        setSelectedStatus({
          id: -1,
          color: translatedStatus.color,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name: translatedStatus.name
        })
      }
      // Fallback in case Employee is not assigned to any season
      else {
        const translatedStatus = translateStatus("CANDIDATE")
        setSelectedStatus({
          id: -1,
          color: translatedStatus.color,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name: translatedStatus.name
        })

      }

      const {
        bankingInformation: bankingInformationEntity,
        documents: documentsEntity,
        room,
        employeesToSeasons,
        ...employee
      } = employeeEntity;
      const bank = removeEmployeeId(bankingInformationEntity);
      const contract = employeesToSeasons?.find(season => season.seasonId === seasonId)?.contract;


      const additionalInformations = (await additionalInformationsApi.findOneByEmployee(employeeId.toString())).data;

      const contractEntity = contract ? {
        seasonId,
        ...contract,
      } : undefined;
      setData({
        basicInformation: employee,
        bank,
        contract: contractEntity,
        idDocuments: documentsEntity !== null ? documentsEntity : undefined,
        housing: room !== null ? room : undefined,
        employeesToSeasons: employeesToSeasons !== null ? employeesToSeasons : undefined,
        additionalInformations: {
          ...additionalInformations,
          foodRequirments: additionalInformations.foodRequirments as any[],
          shirtSize: additionalInformations.shirtSize as any,
        }
      });
    } catch (error: any) {
      // show toast only if season is selected
      if (error.response?.status === 400 && seasonId) {
        setShowToast(true);
      }
    }
  };


  useEffect(() => {
    const fetchDataAndEmployees = async () => {
      await fetchData();
      await fetchEmployees();
    };

    fetchDataAndEmployees();
  }, [seasonId]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (data && Object.keys(data).length !== 0) {
      setActiveTab(newValue);
    }
  };
  // Diplay avatar image
  const [displayAvatar, setDisplayAvatar] = useState<string>(
    avatar || defaultProfileImage
  );
  // Update avatar image without refresh the page
  useEffect(() => {
    setDisplayAvatar(avatar || defaultProfileImage);
  }, [avatar]);

  const changeEmployee = async (direction: 'next' | 'previous') => {
    if (filteredEmployees && filteredEmployees.length > 0) {
      const employeeIds = filteredEmployees.map((employee) => employee.id);
      // The change function is only available after the employee's instance has been created and/or already existed
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentIndex = employeeIds.indexOf(data!.basicInformation!.id);

      let nextIndex;
      if (direction === 'next') {
        nextIndex = (currentIndex + 1) % employeeIds.length;
      } else if (direction === 'previous') {
        nextIndex = (currentIndex - 1 + employeeIds.length) % employeeIds.length;
      } else {
        return;
      }
      fetchData(employeeIds[nextIndex]);
      // Needed to reload the image of an employee without an avatar after the previous employee has just uploded a new image
      setDisplayAvatar(avatar || defaultProfileImage);
    }
    else {
      setOpenSnackbar(true);
      setErrorMessage(t('alerts.noEmployeesFoundForFilters'));
    }
  };

  const fetchEmployees = async () => {
    const data = (await employeesApi.findAll({
      params: {
        includeIsIncomplete: true,
        seasonId
      },
    })).data;
    setEmployees(data);
    // Save the name of all assigned departments 
    const allDepartments = new Set<string>();
    data.forEach((employee: any) => {
      employee.employeesToDepartments.forEach((assignment: DepartmentAssignment) => {
        allDepartments.add(assignment.department.name);
      });
    });
    const uniqueDepartments = Array.from(allDepartments);
    setDepartments(uniqueDepartments);
    setFilteredEmployees(data);
  }

  const handleFiltersChange = (event: React.SyntheticEvent, value: string[]) => {
    const filteredEmployees = value ? employees.filter((employee: any) =>
      value.some((selectedDepartment) =>
        employee.employeesToDepartments.some(
          (assignment: DepartmentAssignment) => assignment.department.name === selectedDepartment
        )
      )
    )
      : employees;
    setFilteredEmployees(filteredEmployees);
  };

  // Email validation 
  const EmailValidator = (email: string | undefined) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email) {
      const isValidEmail = emailRegex.test(email);
      setIsValidEmail(isValidEmail);
    }
    else {
      setIsValidEmail(false);
    }
  }

  useEffect(() => {
    EmailValidator(newData?.email);
  }, [newData?.email]);


  return (
    <Box pt={2} pb={4}>
      <AddStatusModal
        open={openAddStatusModal}
        onClose={() => {
          setOpenAddStatusModal(false);
        }}
        fetchData={fetchStatusData}
      />
      {showToast && (
        <CustomToast
          message={(t('alerts.employeeNotFoundInSeason'))}
          onClose={handleToastClose}
          duration={3000}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity='error' style={{ borderRadius: '10px' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <Card sx={{ padding: 3, position: 'relative' }}>
            <CoverPicWrapper>
              <img
                width='100%'
                height='100%'
                alt='Team Member'
                src='/static/background/user-cover-pic.png'

                style={{ objectFit: 'cover' }}
              />
            </CoverPicWrapper>

            <ContentWrapper>
              <FlexBox justifyContent='center'>
                <AvatarBadge
                  badgeContent={
                    <label htmlFor='icon-button-file'>
                      <input
                        type='file'
                        accept='image/*'
                        id='icon-button-file'
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                      />

                      <IconButton aria-label='upload picture' component='span'>
                        <CameraAlt
                          sx={{ fontSize: 16, color: 'text.disabled' }}
                        />
                      </IconButton>
                    </label>
                  }
                >
                  <ImageWrapper>
                    <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='Team Member' sizes='large' />
                  </ImageWrapper>
                </AvatarBadge>
              </FlexBox>

              <Box mt={2}>
                {data?.basicInformation

                  ? <>
                    <Divider style={{ marginBottom: "1rem" }}>{t("account.basicInformation.selectEmployee")}</Divider>
                    <Autocomplete
                      id="employee-selector"
                      disableClearable
                      options={filteredEmployees ? [...getFullNameWithIdConditionally(filteredEmployees.sort((a: EmployeeEntity, b: EmployeeEntity) => {
                        if (a.lastName < b.lastName) return -1;
                        if (a.lastName > b.lastName) return 1;
                        if (a.firstName < b.firstName) return -1;
                        if (a.firstName > b.firstName) return 1;
                        return 0;
                      })), { "id": 0, "fullName": t("account.basicInformation.selectEmployee"), "showId": false }] : []}
                      getOptionLabel={(option) => option.fullName}
                      renderInput={(params) => <TextField {...params} label={t('dashboard.employees.expiringContracts.table.employee')} />}
                      onChange={(event, value) => {
                        const selectedEmployeeId = value.id;
                        employeeContext.setEmployeeId(selectedEmployeeId);
                        fetchData(selectedEmployeeId);
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Typography component="span">
                            {option.fullName}
                            {option.showId && (
                              <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.6rem', color: 'gray' }}>
                                {' ID ' + option.id + ''}
                              </Typography>
                            )}
                          </Typography>
                        </li>
                      )}


                      isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                      /**
                       * The defualt value 'Unknown' is used to allow the component to load the data after it has fetched it with the asynchronous call
                      **/
                      value={data.basicInformation ? { id: data.basicInformation.id, fullName: data.basicInformation.firstName + ' ' + data.basicInformation.lastName, showId: false } : { id: 0, fullName: t('report.selectEmployee'), showId: false }}
                    />

                  </> : <H4 fontWeight={600} textAlign='center'>
                    {t('employees.new.title')} </H4>
                }
                {data?.basicInformation ?
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>

                    <IconButton onClick={() => { changeEmployee("previous"); }} size='small'>
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <H6 fontStyle={"italic"} alignContent={"center"}>{t("account.basicInformation.changeEmployee")}</H6>
                    <IconButton onClick={() => { changeEmployee("next"); }} size='small'>
                      <KeyboardArrowRightIcon />
                    </IconButton>  </div> : ""}
                {data?.basicInformation ?
                  <>
                    <Divider style={{ marginTop: "1rem" }}>{t("account.basicInformation.filterEmployees")}</Divider>
                    <Autocomplete
                      multiple
                      id="filters"
                      size="small"
                      options={departments}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={t("account.basicInformation.filterPlaceholder")} />
                      )}
                      onChange={handleFiltersChange}
                      style={{ marginTop: '1rem' }}
                    />
                  </> : ''
                }
              </Box>
              {!downMd && (
                <TabsContainer>

                  <CustomTabs
                    orientation='vertical'
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"

                    allowScrollButtonsMobile
                    sx={{
                      borderRight: 1, borderColor: 'divider', width: "100%",
                      justifyItems: 'center'
                    }}
                  >
                    {tabs.map(({ key, name, Icon }, index) => (
                      <Tab
                        icon={<Icon sx={{ color: 'text.secondary' }} />}
                        label={t(name)}
                        id={`tab-${index}`}
                        key={`tab-${index}`}
                        aria-controls={`tab-${index}`}
                        style={{
                          minWidth: "100%!important"
                        }}
                        sx={{
                          display: "flex",
                          textAlign: 'center',
                          minWidth: "100%",
                          marginLeft: 0
                        }}
                      ></Tab>
                    ))}
                  </CustomTabs>
                </TabsContainer>

              )}
            </ContentWrapper>
            {/*  <FlexBetween
                  marginTop={6}
                  flexWrap='wrap'
                  justifyContent='center'
                >
                <Box
                    minWidth={200}
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        minWidth: '100%',
                        mb: 2,
                      },
                    }}
                  >
                    <Tiny mb={0.5}>{t('employees.profileCompletion')}</Tiny>

                    <FlexBox alignItems='center'>
                      <LinearProgress
                        value={getProgress()}
                        color='success'
                        variant='determinate'
                        sx={{ flexGrow: 1, mr: 1 }}
                      />
                      <Tiny fontWeight={600} color='text.primary'>
                        {Math.floor(getProgress())}%
                      </Tiny>
                    </FlexBox>
                  </Box>
                </FlexBetween> */}     {data?.basicInformation ?
              <>
                <Divider style={{ marginTop: "1rem" }}>{t("account.basicInformation.setStatus")}</Divider>
                <Autocomplete
                  sx={{
                    marginTop: '1rem',
                  }}
                  options={optionsWithButton}
                  size="small"
                  disableClearable
                  getOptionLabel={(option) => option.id === 0 ? t('status.addNew') : option.name}
                  value={selectedStatus ?? { name: '', color: '', id: 0 }}
                  isOptionEqualToValue={(option, value) => {
                    if (value?.id === -1 && value.name === option.name) {
                      return true;
                    }
                    else {
                      return value?.id === option.id;
                    }
                  }}
                  open={autoCompleteOpen}
                  onOpen={() => { setAutoCompleteOpen(true) }}
                  onClose={() => { setAutoCompleteOpen(false) }}
                  onChange={handleStatusChange}
                  renderOption={(option, value) => (
                    <MenuItem onClick={() => { handleStatusChange(option, value) }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{
                          whiteSpace: value.name.includes(' ') ? 'pre-wrap' : 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '80%',
                        }}>
                          {value.id !== 0 && (
                            <Small
                              sx={{
                                backgroundColor: value.color,
                                borderRadius: '50%',
                                width: '0.7rem',
                                height: '0.7rem',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginRight: '0.5rem'
                              }}
                            />
                          )}
                          {value.id === -1 ? translateStatus(value.name as EmployeeToSeasonEntityStatusTypeEnum).name : value.name}
                        </span>
                        {value.id !== 0 && value.id !== -1 && (
                          <DeleteIcon
                            color="error"
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteStatus(value.id);
                            }}
                          />
                        )}
                      </div>
                    </MenuItem>
                  )}
                  renderInput={(params) => <StatusTextField {...params}

                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      readOnly: true,

                    }}
                  />


                  }
                />
                <Grid item mt={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValidEmail || !data?.basicInformation && (!newData?.firstName || !newData.lastName)}
                    onClick={() => {
                      setOpenLanguageModal(true);
                    }}
                  >
                    {t('account.basicInformation.askTheEmployeeToCompleteTheProfile')}
                  </Button>
                </Grid>

              </> : ''}
          </Card>
        </Grid>

        <Grid item md={9} xs={12}>
          <SelectLanguageModal
            values={newData}
            sendDataCompilationRequestCallback={sendDataCompilationRequestoToEmployee}
            onClose={() => {
              setOpenLanguageModal(false)
            }}
            open={openLanguageModal}

          />



          {data &&
            ((activeTab === 0 && (
              <div role='tabpanel' id='tab-0' aria-labelledby='tab-0'>
                <TabComponent.BasicInformation
                  key={data?.basicInformation?.id}
                  data={data.basicInformation}
                  id={data.basicInformation?.id}
                  onSubmit={submitBasicInformation}
                  setNewDataCallback={setNewDataWithPrint}
                  userMode = {false}
                />
              </div>
            )) ||
              (activeTab === 1 && (
                <div role='tabpanel' id='tab-1' aria-labelledby='tab-1'>
                  <TabComponent.IDDocument
                    key={data?.basicInformation?.id}
                    data={data.idDocuments}
                    onSubmit={submitIDDocuments}
                    onNewDocument={submitNewDocument}
                    buttonFontSize={sectionsButtonFontSize}
                    userMode = {false}
                  />
                </div>
              )) ||
              (activeTab === 2 && (
                <div role='tabpanel' id='tab-2' aria-labelledby='tab-2'>
                  <TabComponent.AdditionalInformation
                    key={data?.basicInformation?.id}
                    data={data.additionalInformations}
                    onSubmit={submitAdditionalInformations}
                    id={data.basicInformation?.id}
                    setNewDataCallback={setAdditionalInformations}
                    userMode = {false}
                  />
                </div>
              )

              ) ||
              (activeTab === 3 && (
                <div role='tabpanel' id='tab-3' aria-labelledby='tab-3'>
                  <TabComponent.BankingInformation
                    key={data?.basicInformation?.id}
                    data={data.bank}
                    onSubmit={submitBank}
                    userMode = {false}
                  />
                </div>
              )) ||
              (activeTab === 4 && (
                <div role='tabpanel' id='tab-4' aria-labelledby='tab-4'>
                  <TabComponent.Contract
                    key={data?.basicInformation?.id}
                    data={data.contract}
                    onSubmit={submitContract}
                    onSubmitAgreements={submitAgreements}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    id={data.basicInformation!.id}
                    buttonFontSize={sectionsButtonFontSize}
                    userMode = {false}
                  />
                </div>
              )) || (activeTab === 5 && (
                <div role='tabpanel' id='tab-3' aria-labelledby='tab-3'>
                  <TabComponent.EmployeeNotes
                    onSubmit={submitNotes}
                    employeeId={data.basicInformation?.id ?? 0}
                  />
                </div>
              )) ||
              (activeTab === 6 && (
                <div role='tabpanel' id='tab-4' aria-labelledby='tab-6'>
                  <TabComponent.Housing
                    currentEmployeeId={data.basicInformation?.id ?? 0}
                    key={`${data?.basicInformation?.id}-${seasonId}-${Math.random()}`}
                    data={data.housing}
                    employeeToSeasonData={data.employeesToSeasons ? data.employeesToSeasons[0] : undefined}
                    onSubmit={submitRoom}
                    fetchEmployee={fetchData}
                    userMode = {false}
                  />
                </div>
              )))}
          <Grid item>
            <Box mt={2} display="flex" justifyContent="center">

              <Grid item xs={4} sm={6}>

                <Button
                  variant='outlined'
                  onClick={cancel}
                  style={
                    {
                      fontSize: buttonFontSize
                    }
                  }
                >
                  {t('common.forms.button.cancel')}
                </Button>

              </Grid>

              <Grid item xs={4} sm={4} textAlign={'right'}>
                {activeTab > 0 && (
                  <Button
                    variant='contained'
                    onClick={prevTab}
                    style={
                      {
                        fontSize: buttonFontSize
                      }
                    }
                  >
                    {t('common.forms.button.previous')}
                  </Button>
                )}

              </Grid>

              <Grid item xs={4} sm={2} textAlign={'right'}>
                <Button
                  variant='contained'
                  type='submit'
                  form='tab-form'

                  style={
                    {
                      fontSize: buttonFontSize
                    }
                  }
                  disabled={isSubmitting}
                >
                  {activeTab === tabs.length - 1
                    ? t('common.forms.button.save')
                    : t('common.forms.button.next')}
                </Button>

              </Grid>
            </Box>

          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewEmployee;
