import { FC } from 'react';
import { DatePickerProps, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';  // Importing type for DatePicker
import { styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import itLocale from 'date-fns/locale/it';

const StyledDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
    width: '100%',

    '& .MuiOutlinedInput-input': {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        borderColor: theme.palette.action.disabled,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.action.hover,
    },
    '& .MuiInputLabel-root': {
        fontWeight: 500,
        color: theme.palette.text.disabled,
    },
    '& .MuiInputLabel-root.Mui-focused': { fontWeight: 600 },
    '& .MuiSvgIcon-root': { color: theme.palette.text.disabled },
}));
const CalendarInput: FC<DatePickerProps<Date>> = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
            <StyledDesktopDatePicker {...(props as any)} />
        </LocalizationProvider>
    );
};

export default CalendarInput;