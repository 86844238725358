import { Download, Add } from '@mui/icons-material';
import { Button, Card, IconButton, Menu, MenuItem, styled, useMediaQuery } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { H6 } from 'components/Typography';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchByName } from 'utils/utils';
import emptyTableImage from 'assets/images/empty-table.png';
import { format } from 'date-fns';
import { employeesApi } from '../../api';
import { createReportForAllUsers, createWireTransfersReportForAllUsers } from 'utils/report';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchInput from 'components/input-fields/SearchInput';
import { useNavigate } from 'react-router-dom';
import AllEmployeesDataTable from 'page-sections/data-table/AllEmployeesDataTable';
import { useEncryption } from '../../contexts/EncryptionKeyContext';


// styled components
const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
  [theme.breakpoints.down(500)]: {
    marginTop: 10,
    width: '100%',
    flexDirection: 'column-reverse',
    '& > .MuiBox-root': {
      width: '100%',
      margin: '10px 0',
      alignItems: 'center',
      flexDirection: 'column',
    },
    '& .MuiButton-root': { minWidth: '100%' },
  },
}));

interface ResourceProps {
  isSeasonal: boolean;
  isAll: boolean;
}

const AllEmployeesTable: FC<ResourceProps> = ({ isAll }) => {
  const isMobile = useMediaQuery('(max-width:499px)');
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any[]>([]);
  const [hasFilter, setHasFilter] = useState<string>('');
  const [clearFilter, setClearFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [anchorDownloadReportMenu, setAnchorDownloadReportMenu] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const [, setOpenPaymentModal] = useState(false);
  const [, setOpenAddEventModal] = useState(false);
  const { encryptionKey } = useEncryption();


  const [, setEmployeesData] = useState<any[]>([]);

  const handleAddEmployee = () => {
    navigate('/dashboard/employees/new'); // Adjust the path as needed
  };

  const handleClickDownloadReportMenu = (event: any) => {
    event.stopPropagation();
    setAnchorDownloadReportMenu(document.getElementById('downloadReportButton'));
  };

  const handleCloseDownloadReportMenu = () => {
    setAnchorDownloadReportMenu(null);
  };

  const handleRowSelect = (rowArr: []) => {
    setSelectedRows(rowArr);
  };

  const handleClearFilter = () => {
    setClearFilter('...');
    setTimeout(() => {
      setClearFilter('');
    }, 50);
  };

  const fetchTableData = async () => {
    try {
      const response = await employeesApi.getAllEmployees();
      const employeesFetchedFromApi = response.data;
      setEmployeesData(employeesFetchedFromApi);
      setTableData(employeesFetchedFromApi); // Set table data here
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false); // Ensure loading is stopped in case of an error
    }
  };


  useEffect(() => {
    fetchTableData();
  }, []);

  // search input
  const [searchValue, setSearchValue] = useState('');
  const [filteredItem, setFilteredItem] = useState<any[]>(tableData);

  useEffect(() => {
    const result = searchByName(tableData, searchValue);
    setFilteredItem(result);
  }, [searchValue, tableData]);


  // Download employees report
  const userIds = selectedRows.map((item: any) => item.original.id);
  const handleReport = async () => {
    employeesApi.getReportForAllUsers({
      data: {
        userIds,
        all: selectedRows.length === 0,
      },
    })
      .then(async (response) => {
        const date = format(new Date(), 'dd/MM/yyyy');
        const fileName = `Report_${date}.xlsx`;
        await createReportForAllUsers(response.data, fileName, t);
        setAnchorDownloadReportMenu(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Download employees wire transfer report
  const handleReportWireTransfers = async () => {
    employeesApi.getReportForAllUsers({
      data: {
        userIds,
        all: selectedRows.length === 0,
      },
    })
      .then(async (response) => {
        const date = format(new Date(), 'dd/MM/yyyy');
        const fileName = `Wire_transfers_${date}.xlsx`;
        await createWireTransfersReportForAllUsers(response.data, fileName, t, encryptionKey, new Date());
        setAnchorDownloadReportMenu(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function SelectedRowsMessage() {
    return (
      <H6 mr={2} mt={isMobile ? 1.2 : 0}>
        {selectedRows.length < 2
          ? t('common.tables.select.numSelectedSingular', { num: selectedRows.length })
          : t('common.tables.select.numSelected', { num: selectedRows.length })
        }
      </H6>
    );
  }

  return (
    <div>
      {loading ? (
        <FlexBox
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ height: '100vh', gap: 5 }}
        >
          <img
            src={emptyTableImage}
            alt="Loading image"
            style={{ width: '250px', height: '250px' }}
          />
          <H6>{t('employees.loading')}</H6>
        </FlexBox>
      ) : (
        <div>
          <ButtonWrapper alignItems="center">
            <FlexBox alignItems="center">
              <SearchInput
                bordered={false}
                placeholder={t('employees.searchEmployees')}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </FlexBox>
            <FlexBox alignItems="center" mr={2}>
              {selectedRows.length > 0 && !isMobile && (
                <SelectedRowsMessage />
              )}
              {selectedRows.length > 0 && isMobile && (
                <SelectedRowsMessage />
              )}
              <Menu
                anchorEl={anchorDownloadReportMenu}
                open={Boolean(anchorDownloadReportMenu)}
                onClose={handleCloseDownloadReportMenu}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 'calc(50% - 5px)',
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={handleReportWireTransfers}
                  sx={{
                    width: '160px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '20px',
                  }}
                >
                  {t('report.downloadWireTransfers')} <Download fontSize="small" />
                </MenuItem>
              </Menu>
            </FlexBox>
            <Button
              id="downloadReportButton"
              size="small"
              color="success"
              variant="contained"
              onClick={handleReport}
              /**
               * MarginTop: We have the problem that from mobile when we have 0 rows selected the button closes with the add employee button.
               * With this condition, when we are from mobile with zero rows selected, we add 1.2 margin top. In all other cases we set the top margin to 0.
               */
              sx={{
                color: 'common.white',
                marginRight: isMobile ? 0 : 2,
                marginTop: isMobile ? selectedRows.length > 0 ? 0 : 1.2 : 0,
              }}
              startIcon={<Download fontSize="small" />}
              endIcon={
                <IconButton
                  onClick={(event) => {
                    handleClickDownloadReportMenu(event);
                  }}
                  style={{ padding: 0 }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              }
            >
              {selectedRows.length > 0 ? t('report.downloadSelected') : t('report.name')}
            </Button>

            {selectedRows.length > 0 && (
              <FlexBox alignItems="center" mr={2} gap={1.2}>
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    setOpenAddEventModal(true);
                  }}
                >
                  Clock
                </Button>

                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    setOpenPaymentModal(true);
                  }}
                >
                  {t('payment.pay')}
                </Button>
              </FlexBox>
            )}

            {!!hasFilter && (
              <FlexBox alignItems="center" mr={2}>
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  sx={{ color: 'common.white' }}
                  onClick={handleClearFilter}
                >
                  {t('common.tables.filter.removeFilters')}
                </Button>
              </FlexBox>
            )}

            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={handleAddEmployee}
            >
              {t('employees.addEmployee')}
            </Button>


          </ButtonWrapper>

          <Card sx={{ marginTop: 3 }}>
            <AllEmployeesDataTable
              data={filteredItem}
              clearFilter={clearFilter}
              handleRowSelect={handleRowSelect}
              onFilterChange={(filters) => {
                setHasFilter(filters.length);
              }}
            />
          </Card>

        </div>
      )}
    </div>
  );
};

export default AllEmployeesTable;

