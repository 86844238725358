import { Box, IconButton, styled, Typography } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';

const TOP_HEADER_AREA = 70;

const SidebarWrapper = styled(Box)<{ compact: number }>(
  ({ theme, compact }) => ({
    height: '100vh',
    position: 'fixed',
    width: compact ? 86 : 280,
    transition: 'all .2s ease',
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    '&:hover': compact && { width: 280 },
  }),
);

const NavWrapper = styled(Box)(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: '100%',
  marginTop: 0,
}));

const StyledLogo = styled(Box)(() => ({
  paddingLeft: 8,
  fontWeight: 700,
  fontSize: 20,
}));

const TenantDisplay = styled(FlexBox)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '8px 16px 16px',
  display: 'flex',
  width: 'calc(100% - 32px)',
}));

const TenantName = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  marginRight: 8,
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 700,
  color: theme.palette.text.secondary,
}));

const TenantLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: theme.typography.fontFamily,
  marginRight: 8,
  color: theme.palette.text.secondary,
}));

const StyledPin = styled(IconButton)(() => ({
  display: 'block',
  transition: 'transform 0.3s ease',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

export {
  TOP_HEADER_AREA,
  SidebarWrapper,
  NavWrapper,
  StyledLogo,
  TenantDisplay,
  TenantName,
  TenantLabel,
  StyledPin,
  StyledIconButton
};
