import { Badge, IconButton, styled } from '@mui/material';
import {
    type FC,
} from 'react';
import { checkTokenRole } from 'utils/checkToken';
import { useHidden } from 'contexts/HiddenContext';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': { backgroundColor: theme.palette.action.hover },
}));


const HiddenPopover: FC = () => {
    const { hidden, setHidden } = useHidden();

    let employeeRole: string = '';

    try {
        employeeRole = checkTokenRole();
    }
    catch (err) {
        console.error(err);
    }

    return (
        <>
            {employeeRole === 'OWNER' || employeeRole === 'MANAGER' || employeeRole === 'DEVELOPER' ?
                (
                    <StyledIconButton
                        onClick={() => {
                            const isOwnerOrManager = employeeRole === 'OWNER' || employeeRole === 'MANAGER' || employeeRole === 'DEVELOPER';
                            if (!isOwnerOrManager) {
                                return;
                            }
                            setHidden(!hidden);
                        }}
                    >
                        <Badge color='error'>
                            {hidden ?
                                <VisibilityOffIcon sx={{ color: 'text.disabled' }} />
                                :
                                <VisibilityIcon sx={{ color: 'text.disabled' }} />
                            }
                        </Badge>
                    </StyledIconButton>

                )


                : (<></>)}

        </>
    );
};


export default HiddenPopover;
