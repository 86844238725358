import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, IconButton, styled, Tab } from '@mui/material';
import { Paragraph } from 'components/Typography';
import NotificationsIcon from 'icons/NotificationsIcon';
import {
  type FC,
  Fragment,
  type SyntheticEvent,
  useRef,
  useState,
  useEffect,
} from 'react';
import PopoverLayout from './PopoverLayout';
import { notificationApi } from 'api';
import { NotificationEntity } from 'api/generated';
import NotificationListItem from './popovers-components/list-item';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



// styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const StyledTab = styled(Tab)(() => ({
  width: '50%',
  marginLeft: 0,
  marginRight: 0,
}));

const NotificationsPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [newNotifications, setNewNotifications] = useState<NotificationEntity[]>([]);
  const [oldNotifications, setOldNotifications] = useState<NotificationEntity[]>([]);
  const [newNotificationCount, setNewNotificationCount] = useState<number>(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToExpiration = (date: Date) => {
    navigate(
      '/dashboard/expiration', {
      state: {
        date
      }
    }
    )
  }

  useEffect(() => {
    notificationsCount();
  })

  const handleTabChange = (_: SyntheticEvent, value: string) => {
    setTabValue(value);
  };

  const fetchNotifications = async () => {
    const newNotifications = await notificationApi.getNewNotifications();
    const oldNotifications = await notificationApi.getOldNotifications();
    setNewNotifications(newNotifications.data);
    setOldNotifications(oldNotifications.data);

    newNotifications.data.forEach((notification) => {
      notificationApi.setReadNotification({ notificationId: notification.id });
    })
  }

  const notificationsCount = async () => {
    setNewNotificationCount((await notificationApi.getNewNotifications()).data.length)
  }


  return (
    <Fragment>
      <StyledIconButton
        ref={anchorRef}
        onClick={() => {
          fetchNotifications();
          setOpen(true);
        }}
      >
        <Badge color='error' badgeContent={newNotificationCount}>
          <NotificationsIcon sx={{ color: 'text.disabled' }} />
        </Badge>
      </StyledIconButton>

      <PopoverLayout
        title={t('notification.Notification')}
        popoverOpen={open}
        anchorRef={anchorRef}
        hiddenViewButton={true}
        popoverClose={() => {
          setOpen(false);
        }}
      >
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange}>
            <StyledTab value='1' label={`${t('notification.Messages')} (${newNotifications.length})`} />
            <StyledTab value='2' label={`${t('notification.Archive')}`} />
          </TabList>

          {newNotifications.length === 0 && tabValue === '1' ? (
            <Paragraph fontWeight='500' textAlign='center' p={2}>
              {t('notification.noNotifications')}
            </Paragraph>
          ) : (
            <TabPanel value='1'>
              {newNotifications.map((msg) => (
                <NotificationListItem key={msg.id} notification={msg} isNew={true} navigateCallBack={navigateToExpiration}/>
              ))}
            </TabPanel>
          )}

          {oldNotifications.length === 0 && tabValue === '2' ? (
            <Paragraph fontWeight='500' textAlign='center' p={2}>
              {t('notification.notArchive')}
            </Paragraph>
          ) : (
            <TabPanel value='2'>
              {oldNotifications.map((msg) => (
                <NotificationListItem key={msg.id} notification={msg} isNew={false} navigateCallBack={navigateToExpiration}/>
              ))}
            </TabPanel>
          )}
        </TabContext>
      </PopoverLayout>
    </Fragment>
  );
};


export default NotificationsPopover;
