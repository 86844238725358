import { Button, Grid } from "@mui/material";
import { turnoverApi } from "api";
import { EmployeeEntity, TurnoverItemEntity } from "api/generated";
import AppModal from "components/AppModal";
import { KeyboardArrowDown } from '@mui/icons-material';
import AppSelectField from "components/input-fields/MultipleChoiceField";
import { H2 } from "components/Typography";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ignoreUTC } from "utils/utils";
import { isAfter, isSameDay } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import { wait } from "@testing-library/user-event/dist/utils";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  fetchTableData: () => void;
  setOpenable: (x: boolean) => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 300,
  minWidth: 200,
}));


const OwnerConfirmModal: FC<ModalProps> = ({ open, onClose, fetchTableData, setOpenable }) => {


  const [controllers, setControllers] = useState<EmployeeEntity[]>([]);
  const [selectedControllerId, setSelectedControllerId] = useState<number>();
  const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>([]);
  const [orderedData, setOrderedData] = useState<Record<string, number>>({});
  const [dateArray, setDateArray] = useState<Date[]>([]);
  const { t } = useTranslation();



  const fetchData = async () => {
    const controllers: EmployeeEntity[] = [];

    const items = (await turnoverApi.findAcceptedByController()).data;

    items.forEach((item) => {
      if (!controllers.find(employee => employee.id === item.controllerId) && item.controller)
        controllers.push(item.controller);
    })

    setTurnoverItems(items);
    setControllers(controllers);

    if (controllers.length > 0)
      setOpenable(true);
    else {
      setOpenable(false);
      if (open) {
        setSelectedControllerId(undefined);
        await wait(2000);
        onClose();
      }
    }

  }

  useEffect(() => {
    fetchData();
  }, [open]);

  useEffect(() => {
    orderData();
  }, [selectedControllerId, turnoverItems]);

  const orderData = () => {
    if (!selectedControllerId) {
      setOrderedData({});
      setDateArray([]);
      return;

    }

    const dataMap: Record<string, number> = {};
    const dates: Date[] = [];
    const filtredItems = turnoverItems.filter(item => item.controllerId === selectedControllerId);

    filtredItems.forEach((item) => {
      const date = ignoreUTC(new Date(item.date));
      const dateString = date.toDateString();
      if (dataMap[dateString])
        dataMap[dateString] += item.value;
      else {
        dataMap[dateString] = item.value;
        dates.push(date);
      }
    });

    setDateArray(dates.sort((a, b) => {
      return isAfter(a, b) ? 1 : -1;
    }));

    dates.forEach((value, index) => {
      if (index !== 0) {
        dataMap[value.toDateString()] += dataMap[dates[index - 1].toDateString()];
      }
    })

    setOrderedData(dataMap);

  }

  return (
    <StyledAppModal open={open} handleClose={() => {
      setSelectedControllerId(undefined);
      onClose();
    }}>

      <Toaster />

      <H2 marginBottom={2}>
    
        { t('ownerConfirmModal.openButton') }
      </H2>


      <AppSelectField
        InputLabelProps={{ shrink: true }}
        select
        fullWidth
        disabled={controllers.length === 0}
        SelectProps={{
          native: true,
          IconComponent: KeyboardArrowDown,
        }}
        onChange={(e) => {
          if (e.target.value) {
            const id = Number(e.target.value);
            setSelectedControllerId(id);
          }
          else
            setSelectedControllerId(undefined);
        }}
        value={selectedControllerId}
      >

        <option value={undefined}>
          -----
        </option>

        {
          controllers.map(controller =>
            <option key={controller.id} value={controller.id}>
              {controller.firstName + ' ' + controller.lastName}
            </option>
          )
        }

      </AppSelectField>


      <Grid container mt={2}>
        {
          dateArray.map((date) => {
            return (
              <Grid key={date.toLocaleDateString()} item container xs={12} padding={2}>
                <Grid item xs={8}>
                  {t('ownerConfirmModal.until') + ' ' +
                    (!isSameDay(date, new Date()) ?
                      date.toLocaleDateString() :
                      t('employees.calendar.buttons.today')).toLowerCase()
                    + ' - ' + orderedData[date.toDateString()] + '€'}
                </Grid>

                <Grid item xs={4}>
                  <Button variant="contained" onClick={() => {
                    if (!selectedControllerId)
                      return;
                    turnoverApi.setAcceptedByOwner(String(selectedControllerId), date.toISOString()).then(() => {
                      toast.success(t('alerts.success'));
                      fetchData().then(() => {
                        fetchTableData();
                      });
                    });
                  }}>
                    {t('confirm')}
                  </Button>
                </Grid>
              </Grid>
            )
          }).reverse()
        }

      </Grid>



    </StyledAppModal>
  )
}

export default OwnerConfirmModal;