import { Button, Card, Grid, styled } from "@mui/material";
import { segmentApi } from "api";
import { CreateSegmentDto, SegmentEntity } from "api/generated";
import FlexBox from "components/flexbox/FlexBox";
import { useSeason } from "contexts/SeasonContext";
import SegmentDataTable from "page-sections/data-table/SegmentDataTable";
import AddSegmentModal from "page-sections/data-table/dataTableV2/AddSegmentModal";
import { FC, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { H6 } from "components/Typography";
import { CustomDialog } from "components/CustomDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import toast, { Toaster } from "react-hot-toast";

const SegmentTable: FC = () => {

    const [segments, setSegments] = useState<SegmentEntity[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const { t } = useTranslation();
    const season = useSeason();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, [season]);


    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleDelete = () => {
        const ids = selectedRows.map((item: any) => item.original.id as number);
        Promise.all(ids.map(async (id) => { await segmentApi._delete(id) })).then(fetchData);
        setDeleteConfirmationOpen(false);
    }

    const fetchData = async () => {
        setSegments((await segmentApi.findAllWithCount(season.seasonId)).data)
    }

    const handleRowSelect = (rowArr: []) => {
        setSelectedRows(rowArr);
    };

    const addSegment = (segment: CreateSegmentDto) => {
        segmentApi.create(segment).then(fetchData).catch(() => {
            toast.error(t('segment.usedName'), {duration: 3000})
        });
        setOpenModal(false);
    }

    const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
        [theme.breakpoints.down(500)]: {
            marginTop: 10,
            width: '100%',
            flexDirection: 'column-reverse',
            '& > .MuiBox-root': {
                width: '100%',
                margin: '10px 0',
                alignItems: 'center',
                flexDirection: 'column',
            },
            '& .MuiButton-root': { minWidth: '100%' },
        },
    }));

    return (
        <Grid container>
            <Toaster/>
            <CustomDialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                onConfirm={handleDelete}
                title={t('dialog.title', {
                    item: t('department.segments'),
                })}
                content={t('dialog.content')}
                icon={<DeleteForeverIcon />}
                confirmText={t('dialog.confirm')}
                confirmColor='error'
                cancelColor='primary'
            />
            <AddSegmentModal
                open={openModal}
                onClose={() => { setOpenModal(false) }}
                onSubmitAction={addSegment}
            />
            <Grid item xs={12}>
                <ButtonWrapper justifyContent={'end'}>
                    {selectedRows.length > 0 && (
                        <FlexBox alignItems='center' mr={2}>
                            <H6 mr={1}>
                                {t('common.tables.select.numSelected', {
                                    num: selectedRows.length,
                                })}
                            </H6>
                            <Button
                                size='small'
                                color='error'
                                variant='contained'
                                onClick={handleOpenDeleteConfirmation}
                                sx={{ color: 'common.white' }}
                            >
                                {t('common.tables.select.deleteSelected')}
                            </Button>
                        </FlexBox>
                    )}
                    <Button
                        startIcon={<AddIcon />}
                        variant='contained'
                        size='small'
                        onClick={() => { setOpenModal(true) }}
                    >
                        {t('common.tables.button.addItem', {
                            item: t('department.segment'),
                        })}
                    </Button>
                </ButtonWrapper>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ width: '100%', marginTop: 3 }}>
                    <SegmentDataTable
                        data={segments}
                        handleRowSelect={handleRowSelect}
                    />
                </Card>
            </Grid>
        </Grid >

    )
}

export default SegmentTable;