import { Autocomplete, Button, Grid, styled } from '@mui/material';
import { shiftApi, shiftTemplateApi } from 'api';
import { SeasonEntity, ShiftEntity, ShiftTemplateEntity } from 'api/generated';
import AppModal from 'components/AppModal';
import { H2 } from 'components/Typography';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import CalendarInput from 'components/input-fields/CalendarInput';
import DaysOfWeekPicker, { DaysOfTheWeek } from 'components/input-fields/DaysOfWeekPicker';
import { useFormik } from 'formik';
import { Department } from 'pages/dashboards/dashboard-main-page';
import { useEffect, type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEndDateValid, isValidDate } from 'utils/dateValidator';
import { shortenData } from 'utils/shortenData';
import * as Yup from 'yup';
import ShiftDataTable from '../ShiftDataTable';
import AddIcon from '@mui/icons-material/Add';
import AddShiftModal from './AddShiftModal';
import { ignoreUTC } from 'utils/utils';
import { useSeason } from 'contexts/SeasonContext';


// component props interface
interface ModalProps {
    data?: ShiftTemplateEntity | null;
    setData: (data: ShiftTemplateEntity) => void;
    open: boolean;
    edit?: boolean;
    onClose: () => void;
    fetchData: () => Promise<void>;
    departmentsData: Department[];
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    minWidth: 200,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
    [theme.breakpoints.down(500)]: {
        marginTop: 10,
        width: '100%',
        flexDirection: 'column-reverse',
        '& > .MuiBox-root': {
            width: '100%',
            margin: '10px 0',
            alignItems: 'center',
            flexDirection: 'column',
        },
        '& .MuiButton-root': { minWidth: '100%' },
    },
}));

const AddShiftTemplateModal: FC<ModalProps> = ({
    open,
    onClose,
    edit,
    data,
    setData,
    fetchData,
    departmentsData,
}) => {
    const { t } = useTranslation();
    const [shifts, setShifts] = useState(data?.shifts);
    const [openShift, setOpenShift] = useState(false);
    const [selectedDays, setSelectedDays] = useState<DaysOfTheWeek[]>((data?.days as DaysOfTheWeek[] | undefined) ?? []);
    const departmentId = data?.departmentId;
    const initialDepartment = departmentsData.find(dept => dept.id === departmentId) || null;
    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number; } | null>(initialDepartment ? { label: initialDepartment.name, id: initialDepartment.id } : null);
    const initialValues = {
        name: data?.name ?? '',
        description: data?.description ?? '',
        departmentId: initialDepartment?.id ?? -1,
        days: data?.days ?? [],
        repeatStart: data?.repeatStart ? new Date(data?.repeatStart) : undefined,
        repeatEnd: data?.repeatEnd ? new Date(data?.repeatEnd) : undefined,
    };
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [shiftModalData, setShiftModalData] = useState<ShiftEntity>();
    const { seasonId, getSeason } = useSeason();
    const [season, setSeason ] = useState<SeasonEntity>();

    const fieldValidationSchema = Yup.object().shape({
        name: Yup.string().trim().min(3, t('common.forms.field.min', {
            field: t('shiftTemplate.name'),
            min: 3,
        })).required(
            t('common.forms.field.required', {
                field: t('shiftTemplate.name'),
            }),
        ),
        departmentId: Yup.number().notOneOf([-1], t('common.forms.field.required', {
            field: t('department.itemName'),
        }),
        ),
        repeatStart: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .nullable()
            .notRequired()
            .test('is-valid-date', 'Invalid date', (value) => {
                if (value) {
                    return isValidDate(value);
                }
                return true;
            })
            .test('isStartDateValid', t('employees.agreements.validation.startDateTime'), function (value) {
                if (value && this.parent.repeatEnd && isValidDate(this.parent.repeatEnd)) {
                    return +value <= this.parent.repeatEnd;
                }
                return true;
            }),
        repeatEnd: Yup.date()
            .nullable()
            .notRequired()
            .test('isValidDate', 'Invalid date', (value) => {
                if (value) {
                    return isValidDate(value);
                }
                return true;
            })
            .test('isEndDateValid', t('employees.agreements.validation.endDateTime'), function (value) {
                if (value) {
                    return isEndDateValid(value, this.parent.repeatStart);
                }
                return true;
            })
            .test('isRepeatEndRequired', t('employees.agreements.validation.typeError'), function (value) {
                const repeatStart = this.parent.repeatStart;
                if (repeatStart && !value) {
                    return false;
                }
                return true;
            })
    });


    const { values, errors, handleChange, handleSubmit, touched, resetForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            if (data?.id) {
                values.days = selectedDays as any[];
                const formattedValues = {
                    ...values,
                    repeatStart: values.repeatStart ? ignoreUTC(values.repeatStart).toISOString() : undefined,
                    repeatEnd: values.repeatEnd ? ignoreUTC(values.repeatEnd).toISOString() : undefined,
                }
                const trimmedData = shortenData(data, formattedValues);
                if (trimmedData) {
                    trimmedData.departmentId = data.departmentId;
                    await shiftTemplateApi
                        .update(data.id, seasonId, trimmedData)
                        .then(({ data }) => data);
                }
                else {
                    onClose();
                    setSelectedDepartment(null);
                    resetForm();
                    return data;
                }
            } else {
                values.days = selectedDays as any[];
                const payload = {
                    name: values.name.trim(),
                    description: values.description.trim(),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    departmentId: selectedDepartment!.id,
                    days: values.days,
                    repeatStart: values.repeatStart ? values.repeatStart.toISOString() : undefined,
                    repeatEnd: values.repeatEnd ? values.repeatEnd.toISOString() : undefined,
                    seasonId
                }
                await shiftTemplateApi
                    .create(payload)
                    .then(() => {
                    })
            }
            onClose();
            setSelectedDepartment(null);
            resetForm();
            fetchData();
        },
    });

    const fetchShifts = async () => {
        if (!data)
            return;

        const shifts = (await shiftApi.findByTemplate(data?.id, selectedDepartment?.id ?? 0, seasonId)).data;

        data.shifts = shifts;

        setShifts(shifts);
    }



    /**
    * This is necessary since the inital values are only used when the modal is rendered for the first
    * time. This happens when the page is loaded. Afterwards we need to take care of setting the values
    * when the user wants to modify an entity 
    */
    useEffect(() => {
        if (data) {
            setFieldValue('name', data.name);
            setFieldValue('description', data.description);
            setSelectedDepartment(initialDepartment ? { label: initialDepartment.name, id: initialDepartment.id } : null);
            setFieldValue('repeatStart', data.repeatStart ? new Date(data.repeatStart) : null);
            setFieldValue('repeatEnd', data.repeatEnd ? new Date(data.repeatEnd) : null);
        }
    }, [data]);

    const handleRowSelect = (rowArr: []) => {
        setSelectedRows(rowArr);
    };

    const handleDelete = async () => {
        const ids = selectedRows.map((item: any) => item.original.id);
        const deleteActions = ids.map(async (id) => shiftApi.remove(id, selectedDepartment?.id ?? 0, seasonId));
        await Promise.all(deleteActions);
        fetchShifts();
    }

    useEffect(() => {
        setSeason(getSeason());
    }, [seasonId])

    const openShiftModal = async (shift?: ShiftEntity) => {

        if (!data) {
            values.days = selectedDays as any[];
            const payload = {
                name: values.name.trim(),
                description: values.description.trim(),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                departmentId: selectedDepartment!.id,
                days: values.days,
                seasonId
            }
            await shiftTemplateApi
                .create(payload)
                .then((data) => {
                    setData(data.data);
                    // fetchData().then(() => {
                    setOpenShift(true);
                    // });
                })
        }

        else {
            setShiftModalData(shift);
            setOpenShift(true);
        }


    }

    return (
        <StyledAppModal open={open} handleClose={() => {
            onClose();
            setSelectedDepartment(null);
            resetForm();
        }}>
            <H2 mb={2}>
                {edit ? t('common.forms.button.edit') + ' ' + t('shiftTemplate.itemName') : t('common.forms.addItemLabel', {
                    item: t('shiftTemplate.itemName'),
                })}
            </H2>

            <form onSubmit={handleSubmit}>
                <Grid container columnSpacing={3} alignItems={'center'}>
                    <Grid item xs={12}>
                        <AppTextField
                            fullWidth
                            size='small'
                            name='name'
                            label={t('shiftTemplate.name')}
                            value={values.name}
                            onChange={handleChange}
                            error={Boolean(errors.name && touched.name)}
                            helperText={(touched.name && errors.name) as string}
                        />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <AppTextField
                            fullWidth
                            size='small'
                            name='description'
                            label={t('shiftTemplate.description')}
                            value={values.description}
                            onChange={handleChange}
                            error={Boolean(errors.description && touched.description)}
                            helperText={(touched.description && errors.description) as string}
                        />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Autocomplete
                            id="department-selector"
                            options={departmentsData?.map((department) => ({
                                label: department.name,
                                id: department.id
                            })) || []}
                            disabled={data?.shifts && data?.shifts?.length > 0}
                            value={selectedDepartment}
                            onChange={(event, value) => {
                                setSelectedDepartment(value ? { label: value.label, id: value.id } : null);
                                setFieldValue('departmentId', value?.id ?? -1);
                            }}
                            renderInput={(params) =>
                                <AppTextField
                                    {...params}
                                    placeholder={t('department.departmentSelector.select')}
                                    label={t('department.itemName')}
                                    error={Boolean(errors.departmentId && touched.departmentId)}
                                    helperText={(touched.departmentId && errors.departmentId) as string}
                                />
                            }
                            isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                        />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <FlexBox justifyContent={'center'} justifyItems={'center'}>
                            <DaysOfWeekPicker
                                selectedDays={selectedDays}
                                onChange={setSelectedDays}
                            />
                        </FlexBox>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={6}>
                                <CalendarInput
                                    format='dd.MM.yyyy'
                                    onChange={(date) => {
                                        if (date && date instanceof Date && !isNaN(+date)) {
                                            setFieldValue('repeatStart', date);
                                        } else {
                                            setFieldValue('repeatStart', null);
                                            setFieldValue('repeatEnd', null);
                                        }
                                    }}
                                    value={values.repeatStart}
                                    minDate={season && ignoreUTC(new Date(season.startDate))}
                                    maxDate={season && ignoreUTC(new Date(season.endDate))}
                                    label={t('season.startDate')}
                                    slotProps={{
                                        textField: {
                                            error: !!errors.repeatStart,
                                            helperText: errors.repeatStart
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CalendarInput
                                    disabled={!values.repeatStart}
                                    format='dd.MM.yyyy'
                                    onChange={(date) => {
                                        if (date && date instanceof Date && !isNaN(+date)) {
                                            setFieldValue('repeatEnd', date);
                                        } else {
                                            setFieldValue('repeatEnd', null);
                                        }
                                    }}
                                    value={values.repeatEnd}
                                    minDate={season && ignoreUTC(new Date(season.startDate))}
                                    maxDate={season && ignoreUTC(new Date(season.endDate))}
                                    label={t('employees.calendar.entry.endedOn')}
                                    slotProps={{
                                        textField: {
                                            error: !!errors.repeatEnd,
                                            helperText: errors.repeatEnd
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <ButtonWrapper alignItems='center'>
                    <FlexBox gap={2} justifyContent={'end'} width={'100%'} mt={2}>
                        {selectedRows.length > 0 && (
                            <Button
                                size='small'
                                color='error'
                                variant='contained'
                                onClick={handleDelete}
                                sx={{ color: 'common.white' }}
                            >
                                {t('common.tables.select.deleteSelected')}
                            </Button>
                        )}
                        <Button
                            disabled={!values.name || !values.departmentId || values.departmentId === -1}
                            endIcon={<AddIcon />}
                            variant='contained'
                            color='primary'
                            type='button'
                            onClick={(e) => { e.stopPropagation(); openShiftModal() }}
                            sx={{ fontSize: 'small' }}
                        >
                            {t('common.tables.button.addItem', {
                                item: t('shift.itemName'),
                            })}
                        </Button>
                    </FlexBox>
                </ButtonWrapper>
                {

                    data && (
                        <>
                            <AddShiftModal
                                template={data}
                                data={shiftModalData}
                                fetchData={fetchShifts}
                                onClose={() => { setOpenShift(false); setShiftModalData(undefined);}}
                                open={openShift}
                                setData={setShiftModalData}
                                departmentId={selectedDepartment?.id ?? 0}
                            />
                            {shifts && shifts.length > 0 && <ShiftDataTable
                                handleRowSelect={handleRowSelect}
                                data={shifts}
                                openShift={openShiftModal}
                            />}
                        </>
                    )
                }
                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        onClose();
                        setSelectedDepartment(null);
                        resetForm();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>

        </StyledAppModal>
    );
};

export default AddShiftTemplateModal;
