import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStartdate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='startdate_svg__b'>
        <path fill='none' d='M0 0h64v93H0z' data-name='Rettangolo 8' />
      </clipPath>
      <clipPath id='startdate_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#startdate_svg__a)' data-name='6B63FE - data inizio'>
        
      <g data-name='Raggruppa 16'>
        <g
          clipPath='url(#startdate_svg__b)'
          data-name='Raggruppa 15'
          transform='translate(56 44)'
        >
          <path
            d='M57 0H7.125A7.123 7.123 0 0 0 0 7.125v16.032a7.15 7.15 0 0 0 2.85 5.7l23.279 17.458L2.85 63.772a7.15 7.15 0 0 0-2.85 5.7V85.5a7.123 7.123 0 0 0 7.125 7.125H57a7.123 7.123 0 0 0 7.128-7.125V69.632a7.16 7.16 0 0 0-2.828-5.686L37.974 46.315 61.3 28.679A7.15 7.15 0 0 0 64.128 23V7.125A7.123 7.123 0 0 0 57 0m0 14.251H7.125V7.125H57ZM57 85.5H7.125V69.472l24.939-18.7L57 69.629ZM32.064 41.861 7.125 23.157v-1.781H57V23Z'
            data-name='Tracciato 12'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgStartdate;
