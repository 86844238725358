import { Box, Card } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { H1, H2, H3 } from 'components/Typography';
import numeral from 'numeral';
import { type FC } from 'react';
import 'numeral/locales/it';
import { TFunction } from 'i18next';
import { Agreements } from 'icons/report/reactIcons';

numeral.locale('it');

interface DataProps {
    agreements: {
        avgNetDaily: number,
        monthly: number,
        total: number
    },
    t: TFunction;
}

const EmployeeAgreement: FC<DataProps> = (props) => {
    const { agreements, t } = props;

    return (
        <Card
            sx={{
                padding: 3,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box width='100%' textAlign='center'>
                <H2 fontWeight={600} mb={3}>
                    {t('employees.report.agreement.title')}
                </H2>
                <Box
                    sx={{
                        width: 40,
                        height: 40,
                        margin: 'auto',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#5479F7',
                    }}
                >
                    <Agreements />
                </Box>

                <H1 color='primary.main' my={1}>
                    {!isNaN(agreements.total) ? numeral(agreements.monthly).format('0,0$') : '-'} / {t('employees.report.agreement.month')}
                </H1>
                <H2 color='#FF9C12' my={1}>
                    {!isNaN(agreements.total) ? numeral(agreements.avgNetDaily).format('0,0.00$') : '-'} / {t('employees.report.agreement.day')}
                </H2>


                <FlexBox alignItems='center' justifyContent='center'>
                    <H3 color='success.main' fontWeight={600} ml={0.5} mb={0}>
                        {t('employees.report.agreement.total')} {!isNaN(agreements.total) ? numeral(agreements.total).format('0,0$') : '-'}
                    </H3>
                </FlexBox>


                {/* <Button
                    variant='contained'
                    fullWidth
                    onClick={() => {
                        alert("Non li ho :(");
                    }}>
                    {t('Vedi dati completi')}
                </Button> */}
            </Box>
        </Card>
    );
};

export default EmployeeAgreement;
