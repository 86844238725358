import { SvgIcon, type SvgIconProps } from '@mui/material';

const PremiumOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d='M16.12,5h0l3.69,4L12,18.46,4.19,9,7.88,5h8.24m0-2H7.88a2,2,0,0,0-1.47.65L2.26,8.16A1,1,0,0,0,2,8.84v.3a1,1,0,0,0,.23.64l9,10.86A1,1,0,0,0,12,21H12a1,1,0,0,0,.77-.36l9-10.86A1,1,0,0,0,22,9.14v-.3a1,1,0,0,0-.26-.68L17.59,3.65A2,2,0,0,0,16.12,3Z' />
    </SvgIcon>
  );
};

export default PremiumOutlined;
