import { Box, Button, Card, FormControlLabel, Grid, IconButton, Link, Switch, Tooltip, Typography, useTheme } from '@mui/material';
import { H3 } from 'components/Typography';
import { TFunction } from 'i18next';
import { FC, useEffect, useState } from 'react';
import DeleteIcon from 'icons/DeleteIcon';
import { employeePaymentsApi } from 'api';
import { CustomDialog } from 'components/CustomDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import { useEncryption } from 'contexts/EncryptionKeyContext';


interface DataProps {

  paymentsData: Array<{
    date: Date;
    toBePayed: number,
    payed: number,
    remainingCredit: number,
    paymentId: number,
    receiptPath?: string,
    isEncrypted: boolean,
  }>;
  t: TFunction;
  isUnlimited: boolean;
  fetchData: () => void;
  setOpenPaymentModal: (isOpen: boolean) => void;
  setShowAllPayment: (show: boolean) => void;
}


const PaymentsTable: FC<DataProps> = (props) => {
  const { t, paymentsData, isUnlimited, fetchData, setOpenPaymentModal, setShowAllPayment } = props;
  const theme = useTheme();
  const [showedData, setShowedData] = useState(paymentsData);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { encryptionKey } = useEncryption();
  const { setPopoverOpen } = useEncryption();
  const isEncryptionMandatory = localStorage.getItem('isEncryptionMandatory');
  const isDisabled = isEncryptionMandatory === 'true' && encryptionKey === '';
  useEffect(() => {
    setShowedData(paymentsData);
  }, [paymentsData]);

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  async function handleDelete(id: number): Promise<void> {
    employeePaymentsApi._delete(id).then(() => {
      fetchData();
    });

  }

  const uplodFile = (id: number, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    employeePaymentsApi.uploadPayment(id,
      {
        data: formData,
      },
    ).then(fetchData);

  };


  return (
    <Card sx={{ overflow: 'auto' }}>

      <CustomDialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        onConfirm={() => {
          handleDelete(deleteId);
          setDeleteConfirmationOpen(false);
        }}
        title={t('dialog.title', {
          item: t('payment.collectionName'),
        })}
        content={t('dialog.confirmContent.payment')}
        icon={<DeleteForeverIcon />}
        confirmText={t('dialog.confirm')}
        confirmColor="error"
        cancelColor="primary"
      />


      <Grid container textAlign={'center'} minWidth={800}>
        {showedData.length > 0 && (
          <Grid container item xs={12} padding={3} alignItems={'center'} bgcolor={theme.palette.action.disabled}>
            <Grid item xs={2}><H3>{t('report.paymentsTable.date')}</H3></Grid>
            <Grid item xs={2}><H3>{t('report.paymentsTable.toBePaid')}</H3></Grid>
            <Grid item xs={2}><H3>{t('report.paymentsTable.paid')}</H3></Grid>
            <Grid item xs={2}><H3>{t('report.paymentsTable.credit')}</H3></Grid>
            <Grid item xs={2}><H3>{t('report.paymentsTable.paymentSlip')}</H3></Grid>
            <Grid item xs={2}><H3>{t('report.paymentsTable.delete')}</H3></Grid>
          </Grid>
        )}

        {showedData.map((row) => {

          return (
            <Grid item xs={12} container padding={3} alignItems={'center'} key={row.paymentId}>
              <Grid item xs={2}><Typography
                fontWeight={'bold'}>{row.date.toLocaleDateString('it-IT')}</Typography></Grid>
              <Grid item xs={2}>
                {row.isEncrypted && (
                  <Tooltip title={t('encryption.tooltip')} arrow>
                    <Box
                      style={{ width: '100%', cursor: 'pointer' }}
                      onClick={() => {
                        if (encryptionKey === '') {
                          setPopoverOpen(true);
                        }
                      }}
                    >
                      <span style={{ filter: 'blur(2px)', opacity: 1 }}>
                        ・・・  €
                      </span>
                    </Box>
                  </Tooltip>
                )}
                {!row.isEncrypted && (
                  <Typography>{row.toBePayed.toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}€</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                {row.isEncrypted && (
                  <Tooltip title={t('encryption.tooltip')} arrow>
                    <Box
                      style={{ width: '100%', cursor: 'pointer' }}
                      onClick={() => {
                        if (encryptionKey === '') {
                          setPopoverOpen(true);
                        }
                      }}
                    >
                      <span style={{ filter: 'blur(2px)', opacity: 1 }}>
                        ・・・  €
                      </span>
                    </Box>
                  </Tooltip>
                )}
                {!row.isEncrypted && (
                  <Typography fontWeight={'bold'}>{row.payed.toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}€</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                {row.isEncrypted && (
                  <Tooltip title={t('encryption.tooltip')} arrow>
                    <Box
                      style={{ width: '100%', cursor: 'pointer' }}
                      onClick={() => {
                        if (encryptionKey === '') {
                          setPopoverOpen(true);
                        }
                      }}
                    >
                      <span style={{ filter: 'blur(2px)', opacity: 1 }}>
                        ・・・  €
                      </span>
                    </Box>
                  </Tooltip>
                )}
                {!row.isEncrypted && (
                  <Typography
                    color={row.remainingCredit >= 0 ? '#03BF62' : theme.palette.error.main}>{row.remainingCredit.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}€</Typography>
                )}
              </Grid>
              <Grid item xs={2}>{row.receiptPath ?

                <Link href={row.receiptPath} target="_blank" rel="noopener noreferrer"> <FileOpenIcon /> </Link>
                : <FileUploadInput
                  maxDimension={30}
                  handleOnChange={(e) => {
                    if (e.target.files)
                      uplodFile(row.paymentId, e.target.files[0]);
                  }} />}</Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => {
                  setDeleteId(row.paymentId);
                  setDeleteConfirmationOpen(true);
                }}>
                  <DeleteIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
              </Grid>
            </Grid>
          );

        })}
        <Grid xs={12} item container paddingBottom={2} paddingTop={showedData.length > 0 ? 0 : 2} alignItems={'center'}>
          <Grid item xs={2} justifyItems={'center'} paddingLeft={3}>
            {
              isUnlimited && (
                <FormControlLabel label={t('report.paymentsTable.showAllPayments')} labelPlacement="start"
                  control={(
                    <Switch size="small"
                      onChange={(e) => {
                        setShowAllPayment(e.target.checked);
                      }}
                    />
                  )}
                />
              )
            }
          </Grid>

          <Grid item xs={8} paddingRight={3}>
            <Tooltip title={isDisabled ? t('encryption.buttonDisabled') : ""} disableHoverListener={!isDisabled}>
              <div>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenPaymentModal(true);
                  }}
                  disabled={isDisabled}
                >
                  {t('payment.addPayment')}
                </Button>
              </div>
            </Tooltip>
          </Grid>

          <Grid item xs={2}></Grid>

        </Grid>
      </Grid>

    </Card>
  );
};

export default PaymentsTable;