import { Button, Grid, Typography, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import { H2, H4 } from 'components/Typography';
import { useFormik } from 'formik';
import { useState, type FC, useEffect } from 'react';
import * as Yup from 'yup';
import CalendarInput from 'components/input-fields/CalendarInput';
import { AgreementEntity, ContractEntityLegalContractTypeEnum, CreateAgreementDto } from 'api/generated';
import { useTranslation } from 'react-i18next';
import { isEndDateValid, isValidDate } from 'utils/dateValidator';
import { ContractFormData } from 'page-sections/employees/Contract';
import { addDays, differenceInDays, isSameDay } from 'date-fns';
import AppTextAreaAutosize from 'components/input-fields/AppTextAreaAutosize';
import { agreementApi } from 'api';
import { decryptData, encryptData } from 'utils/encryptionDecryptionAgreements';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import toast, { Toaster } from 'react-hot-toast';
import { wait } from '@testing-library/user-event/dist/utils';
import DaysOfWeekPicker, { DaysOfTheWeek } from 'components/input-fields/DaysOfWeekPicker';

export type CreateAgreementDtoNoId = Omit<CreateAgreementDto, 'employeeId'>;


// component props interface
interface ModalProps {
    contractData?: ContractFormData;
    contractId: number | null;
    data?: AddAgreementFormData | null;
    agreements: AgreementEntity[];
    id?: number;
    open: boolean;
    edit?: boolean;
    onClose: () => void;
    onSubmit: (data: CreateAgreementDtoNoId) => Promise<any>;
}
export interface AddAgreementFormData {
    net_daily: number;
    startedOn: Date;
    agreementIndex?: number;
    endedOn?: Date;
    downpayment?: number;
    notes?: string;
    freeDays: object[],
    isLongTheWholeContract: boolean,
    id?: number;
    net_daily_encrypted?: string;
}
// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 325,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddAgreememtModal: FC<ModalProps> = ({ open, onClose, edit, contractData, data, id, onSubmit, contractId, agreements }) => {
    const { t } = useTranslation();
    const { encryptionKey } = useEncryption();
    const [startDate, setStartDate] = useState<Date | null | undefined>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [netDaily, setNetDaily] = useState(data?.net_daily_encrypted ? Number(decryptData(data?.net_daily_encrypted, encryptionKey)) : data?.net_daily ?? 0);
    const [netMonthly, setNetMonthly] = useState(0);
    const [totalDaysOff, setTotalDaysOff] = useState(0);
    const [netTotalContract, setNetTotalContract] = useState(0);
    const [netYearly, setNetYearly] = useState(0);
    const [isUnlimited, setIsUnlimited] = useState(contractData?.legalContractType === ContractEntityLegalContractTypeEnum.Unlimited);
    const [isFirstAgreement, setIsFirstAgreement] = useState(true);
    const [isLastAgreement, setIsLastAgreement] = useState(true);

    const initialValues: AddAgreementFormData = {
        net_daily: data?.net_daily_encrypted ? Number(decryptData(data?.net_daily_encrypted, encryptionKey)) : data?.net_daily ?? 0,
        isLongTheWholeContract: data?.isLongTheWholeContract ?? true,
        startedOn: new Date(data?.startedOn ?? contractData?.endedOn ?? new Date()),
        endedOn: data?.endedOn ? new Date(data?.endedOn) : contractData?.endedOn ? new Date(contractData?.endedOn) : undefined,
        downpayment: data?.downpayment ?? 0,
        notes: data?.notes ?? "",
        freeDays: data?.freeDays ?? [],
    };

    const fieldValidationSchema = Yup.object().shape({
        net_daily: Yup.number().required(t('common.forms.field.required', {
            field: t('employees.agreements.field.net'),
        })),
        isLongTheWholeContract: Yup.boolean(),
        startedOn: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('is-valid-date', 'Invalid date', (value) => {
                return isValidDate(value);
            })
            .test('isStartDateValid', t('employees.agreements.validation.startDateTime'), function (value) {
                if (value && this.parent.endedOn && isValidDate(this.parent.endedOn)) {
                    return +value <= this.parent.endedOn;
                }
                return true;
            }),
        endedOn: Yup.date()
            .notRequired()
            .test('isValidDate', 'Invalid date', (value) => {
                if (value && !isUnlimited) {
                    return isValidDate(value);
                }
                return true;
            })
            .test('isEndDateValid', t('employees.agreements.validation.endDateTime'), function (value) {
                if (value && !isUnlimited) {
                    return isEndDateValid(value, this.parent.startedOn);
                }
                return true;
            }),
        downpayment: Yup.number().notRequired(),
        notes: Yup.string().notRequired(),
    });

    const findAgreementPosition = () => {
        if (!data) {
            setIsLastAgreement(false);
            setIsFirstAgreement(false);
            return;
        }
        setIsLastAgreement(agreements.findIndex(value => value.id === data.id) === agreements.length - 1);
        setIsFirstAgreement(agreements.findIndex(value => value.id === data.id) === 0);
    }

    const handleClose = () => {
        formik.resetForm();
        resetModalData();
        onClose();
    }

    const formik = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {
            const validEndDate = endDate instanceof Date && !isNaN(endDate.getTime());
            if (contractId) {
                const updateValues: CreateAgreementDto = {
                    ...values,
                    net_daily: encryptionKey ? undefined : values.net_daily,
                    net_daily_encrypted: encryptionKey ? encryptData(values.net_daily, encryptionKey) : undefined,
                    startedOn: startDate?.toISOString() ?? new Date().toISOString(),
                    endedOn: validEndDate ? endDate.toISOString() : undefined,
                    contractId,
                };


                if (!edit) {
                    if (updateValues.contractId !== null) {
                        onSubmit(updateValues)
                            .then(() => {
                                handleClose();
                            }).catch((e) => {
                                if (e.response.status === 400) {
                                    toast.error(t('employees.agreements.validation.outOfBound'));
                                }
                            })
                    }
                } else if (id) {
                    const updatedData = {
                        ...values,
                        net_daily: encryptionKey ? undefined : values.net_daily,
                        net_daily_encrypted: encryptionKey ? encryptData(values.net_daily, encryptionKey) : undefined,
                        startedOn: values.startedOn.toString(),
                        endedOn: values.endedOn?.toString(),
                    }

                    agreementApi.update(id.toString(), updatedData).then(() => {
                        handleClose();
                    }).catch((e) => {
                        if (e.response.status === 400) {
                            if (e.response.data.message === 'overlap')
                                toast.error(t('employees.agreements.validation.overlap'))
                            else
                                toast.error(t('employees.agreements.validation.outOfBound'));
                        }
                    })
                }
            }
        }
    });


    const resetModalData = () => {
        setNetDaily(0);
        setNetMonthly(0);
        setNetYearly(0);
        setNetTotalContract(0);
        setStartDate(null);
        setEndDate(null);
    }



    /* const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
         values.isLongTheWholeContract = event.target.checked;
         setFieldValue('net_daily', netDaily, true)
     } */


    function isValidDateLocal(dateString: Date | string | null | undefined): boolean {

        if (dateString === null || dateString === undefined) {
            return false;
        }
        if (dateString instanceof Date) {
            return true;
        }
        const parsedDate = Date.parse(dateString);
        return !isNaN(parsedDate);
    }

    useEffect(() => {
        if (contractData) {
            formik.setFieldValue('startedOn', agreements.length > 0 ? undefined : contractData.startedOn, true)
            setStartDate(null);
            const endDate = agreements.length > 1 ? addDays(new Date(agreements[1].startedOn), -1) : contractData.endedOn ? new Date(contractData.endedOn) : null;
            setEndDate(endDate);
            formik.setFieldValue('endedOn', endDate, true)
            setIsUnlimited(contractData?.legalContractType === ContractEntityLegalContractTypeEnum.Unlimited)
        }
        if (data) {
            formik.setFieldValue('startedOn', data.startedOn, true)
            formik.setFieldValue('endedOn', data.endedOn, true)
            formik.setFieldValue('freeDays', data.freeDays, true)
            formik.setFieldValue('downpayment', data.downpayment, true)
            formik.setFieldValue('notes', data.notes, true)
            formik.setFieldValue('endedOn', data.endedOn, true)
            formik.setFieldValue('net_daily', data.net_daily, true)
            setStartDate(data.startedOn ? new Date(data.startedOn) : null);
            setEndDate(data.endedOn ? new Date(data.endedOn) : null);
            setNetDaily(data?.net_daily_encrypted ? Number(decryptData(data?.net_daily_encrypted, encryptionKey)) : data?.net_daily ?? 0);
            updateNetMonthlyAndTotalContract();
        }
        findAgreementPosition();
        formik.validateForm();

    }, [contractData, open]);

    useEffect(() => {
        updateNetMonthlyAndTotalContract();
    }, [netDaily]);

    const updateNetMonthlyAndTotalContract = () => {
        if (isValidDateLocal(formik.values.startedOn)) {
            if (!isUnlimited && isValidDateLocal(formik.values.endedOn)) {
                const daysDifference = differenceInDays(new Date(formik.values.endedOn ?? new Date()), new Date(formik.values.startedOn ?? new Date())) + 1;
                const newNetTotalContract = parseFloat((netDaily * daysDifference).toFixed(4));
                setNetTotalContract(newNetTotalContract);
            } else if (isUnlimited) {
                setNetYearly(parseFloat((netDaily * 420).toFixed(4)));
            }
            setNetMonthly(parseFloat((netDaily * 30).toFixed(4)));
        }
    };
    const calculateTotalDaysOff = (totalDays: number, freeDaysPerWeek: number) => {
        const weeks = totalDays / 7;
        let totalDaysOff = weeks * freeDaysPerWeek;

        // Round to nearest quarter
        totalDaysOff = Math.round(totalDaysOff * 4) / 4;

        return totalDaysOff;
    };
    const integerPart = Math.floor(totalDaysOff);
    const fractionPart = totalDaysOff - integerPart;

    const fractionDisplay = (fraction: number) => {
        if (fraction === 0.25) return '¼';
        if (fraction === 0.5) return '½';
        if (fraction === 0.75) return '¾';
        return '';
    };

    useEffect(() => {
        if (isValidDateLocal(formik.values.startedOn) && isValidDateLocal(formik.values.endedOn)) {
            const days = differenceInDays(new Date(formik.values.endedOn ?? new Date()), new Date(formik.values.startedOn ?? new Date())) + 1;
            setTotalDaysOff(
                calculateTotalDaysOff(
                    days,
                    formik.values.freeDays.filter((elem, index, self) => {
                        return index === self.indexOf(elem);
                    }).length
                )
            );
        }
    }, [
        formik.values.startedOn,
        formik.values.endedOn,
        formik.values.freeDays.filter((elem, index, self) => {
            return index === self.indexOf(elem);
        }).length]
    );

    const totalDays = () => {
        const days = differenceInDays(new Date(formik.values.endedOn ?? new Date()), new Date(formik.values.startedOn ?? new Date())) + 1;
        return days > 0 ? days : '-';
    }



    return (
        <StyledAppModal open={open} handleClose={() => {
            handleClose();
        }}
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <H2 mb={2}>
                {edit ? t('common.tables.button.editWithItem', { item: t('employees.agreements.name') })
                    : t('common.tables.button.addItem', { item: t('employees.agreements.name'), })}
            </H2>


            <form onSubmit={formik.handleSubmit}>
                <Grid container alignContent={'center'} spacing={2}>

                    {(agreements.length > 0 &&
                        <Grid item container spacing={2} marginTop={0} >

                            <Grid item xs={6}>
                                <H4 mb={1}>{t('employees.agreements.field.startDate')}</H4>
                                <CalendarInput
                                    format='dd.MM.yyyy'
                                    value={startDate}
                                    disabled={isFirstAgreement}
                                    onChange={async (newValue) => {
                                        if (newValue) {
                                            const convertedNewValue = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                            if (contractData?.startedOn && +convertedNewValue < +new Date(contractData.startedOn)) {
                                                /*
                                                    To reset calendar picker value it is needed to set another valid date and then 
                                                    to wait to set calendar to null
                                                */
                                                toast.error(t('employees.agreements.validation.previousDate'));
                                                setStartDate(convertedNewValue)
                                                await wait(1)
                                                setStartDate(data ? new Date(data.startedOn) : null);
                                                return;
                                            }



                                            if (contractData?.endedOn && +convertedNewValue > +new Date(contractData.endedOn)) {
                                                /*
                                                    To reset calendar picker value it is needed to set another valid date and then 
                                                    to wait to set calendar to null
                                                */
                                                toast.error(t('employees.agreements.validation.lateDate'));
                                                setStartDate(convertedNewValue)
                                                await wait(1)
                                                setStartDate(data ? new Date(data.startedOn) : null);
                                                return;
                                            }

                                            if (!convertedNewValue)
                                                return;

                                            setStartDate(convertedNewValue);
                                            formik.setFieldValue('startedOn', convertedNewValue.toUTCString(), true);
                                            if (!data) {
                                                const nextAgreementIndex = agreements.findIndex(value => + convertedNewValue <= +new Date(value.startedOn));
                                                if (nextAgreementIndex !== -1) {
                                                    const agreementDate = new Date(agreements[nextAgreementIndex].startedOn);
                                                    if (isSameDay(agreementDate, convertedNewValue)) {
                                                        toast.error(t('employees.agreements.validation.agreementOnThisDate'));
                                                        /*
                                                            To reset calendar picker value it is needed to set another valid date and then 
                                                            to wait to set calendar to null
                                                        */
                                                        setStartDate(convertedNewValue)
                                                        await wait(1)
                                                        setStartDate(null);
                                                        formik.setFieldValue('startedOn', undefined, true);
                                                        return;
                                                    }
                                                    const endDate = addDays(new Date(agreements[nextAgreementIndex].startedOn), -1);
                                                    setEndDate(endDate);
                                                    formik.setFieldValue('endedOn', endDate.toUTCString(), true);
                                                }
                                                else {
                                                    const endDate = contractData?.endedOn ? new Date(contractData?.endedOn) : null;
                                                    setEndDate(endDate);
                                                    formik.setFieldValue('endedOn', endDate?.toUTCString(), true);
                                                }
                                            }


                                        }

                                        else {
                                            setStartDate(null);
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: Boolean(formik.errors.startedOn && formik.touched.startedOn),
                                            helperText: (formik.errors.startedOn && formik.touched.startedOn) ? t('employees.agreements.validation.startDateTime') : undefined,
                                        },

                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <H4 mb={1}>{t('employees.agreements.field.endDate')}</H4>
                                <CalendarInput
                                    format='dd.MM.yyyy'
                                    value={endDate}
                                    // To set end date agreement must be defined and not be the last agreement
                                    disabled={isLastAgreement || !data}
                                    onChange={async (newValue) => {
                                        if (newValue) {
                                            const convertedNewValue = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));

                                            if (contractData?.startedOn && +convertedNewValue < +new Date(contractData.startedOn)) {
                                                toast.error(t('employees.agreements.validation.previousDate'));
                                                /*
                                                    To reset calendar picker value it is needed to set another valid date and then 
                                                    to wait to set calendar to null
                                                */
                                                setEndDate(newValue)
                                                await wait(1)
                                                setEndDate(data?.endedOn ? new Date(data.endedOn) : null);

                                                return;
                                            }

                                            if (contractData?.endedOn && +convertedNewValue > +new Date(contractData.endedOn)) {
                                                toast.error(t('employees.agreements.validation.lateDate'));
                                                /*
                                                    To reset calendar picker value it is needed to set another valid date and then 
                                                    to wait to set calendar to null
                                                */
                                                setEndDate(newValue)
                                                await wait(1)
                                                setEndDate(data?.endedOn ? new Date(data.endedOn) : null);
                                                return;
                                            }

                                            setEndDate(newValue);
                                            formik.setFieldValue('endedOn', newValue.toISOString(), true);
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: Boolean(formik.errors.endedOn && formik.touched.endedOn),
                                            helperText: formik.errors.endedOn && formik.touched.endedOn ? t('employees.agreements.validation.endDateTime') : undefined
                                        }
                                    }}
                                />
                            </Grid>


                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <H4 mb={2}>
                            {t("employees.agreements.pay")}
                        </H4>
                    </Grid>
                    <Grid item xs={(isUnlimited ? 4 : 3)}>
                        <AppTextField
                            fullWidth
                            disabled={!(isValidDateLocal(formik.values.startedOn) && (isValidDateLocal(formik.values.endedOn) || isUnlimited))}
                            size='small'
                            name='netdaily'
                            label={t('employees.agreements.field.net')}
                            type='number'
                            inputProps={{ step: 0.01 }}
                            value={netDaily}
                            onChange={(event) => {
                                setNetDaily(parseFloat(event.target.value));
                            }}
                            onBlur={() => {
                                formik.setFieldValue('net_daily', netDaily, true);
                                updateNetMonthlyAndTotalContract();
                            }}
                            error={Boolean(formik.errors.net_daily && formik.touched.net_daily)}
                            helperText={(formik.touched.net_daily && formik.errors.net_daily) as string}

                        />
                    </Grid>
                    <Grid item xs={isUnlimited ? 4 : 3}>
                        <AppTextField
                            fullWidth
                            size='small'
                            name='netmonthly'
                            label={t('employees.agreements.field.monthly.net')}
                            type='number'
                            inputProps={{ step: 0.01 }}
                            value={netMonthly}
                            onChange={(event) => {
                                setNetMonthly(parseFloat(event.target.value));
                            }}
                            onBlur={() => {
                                const newNetDaily = parseFloat((netMonthly / 30).toFixed(4));
                                setNetDaily(newNetDaily);
                                formik.setFieldValue('net_daily', newNetDaily, true);
                            }}
                        />
                    </Grid>
                    {(isUnlimited &&

                        <Grid item xs={isUnlimited ? 4 : 3}>
                            <AppTextField
                                fullWidth
                                size='small'
                                name='netYearly'
                                label={t('employees.agreements.field.yearly.net')}
                                type='number'
                                inputProps={{ step: 0.01 }}
                                value={netYearly}
                                onChange={(event) => {
                                    setNetYearly(parseFloat(event.target.value));
                                }}
                                onBlur={() => {
                                    const newNetDaily = parseFloat((netYearly / 360).toFixed(4));
                                    setNetDaily(newNetDaily);
                                    formik.setFieldValue('net_daily', newNetDaily, true);
                                }}
                            />
                        </Grid>
                    )}

                    {(!isUnlimited &&

                        <Grid item xs={isUnlimited ? 4 : 3}>
                            <AppTextField
                                fullWidth
                                size='small'
                                name='netTotalContract'
                                label={t('employees.agreements.field.contractDuration.net')}
                                type='number'
                                inputProps={{ step: 0.01 }}
                                value={netTotalContract}
                                onChange={(event) => {
                                    setNetTotalContract(parseFloat(event.target.value));
                                }}
                                onBlur={() => {
                                    if (isValidDateLocal(formik.values.startedOn) && isValidDateLocal(formik.values.endedOn)) {
                                        const daysDifference = differenceInDays(new Date(formik.values.endedOn ?? new Date()), new Date(formik.values.startedOn ?? new Date())) + 1;
                                        const newNetDaily = parseFloat((netTotalContract / daysDifference).toFixed(4));
                                        setNetDaily(newNetDaily);
                                        formik.setFieldValue('net_daily', newNetDaily, true);
                                    }
                                }}
                            />
                        </Grid>
                    )}

                    {(!isUnlimited &&

                        <Grid item xs={3}>
                            <AppTextField
                                fullWidth
                                size='small'
                                name='downpayment'
                                label={t('employees.agreements.field.downpayment')}
                                type='number'
                                inputProps={{ step: 0.01 }}
                                value={formik.values.downpayment}
                                onChange={(event) => {
                                    formik.setFieldValue("downpayment", event.target.value, true);
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <H4 mb={3} marginLeft={1} marginBottom={1}>{t('room.notes')}</H4>
                        <AppTextAreaAutosize
                            style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
                            minRows={2}
                            maxRows={5}
                            name='notes'
                            value={formik.values.notes}
                            onChange={(event) => {
                                formik.setFieldValue("notes", event.target.value, true);
                            }}
                            placeholder={t('employees.agreements.field.notes.placeholder')}
                        />
                    </Grid>
                    {(!isUnlimited &&

                        <Grid item xs={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                {t('employees.agreements.totalDays')}
                            </Typography>
                            <Typography variant="h5">
                                {
                                    totalDays()
                                }
                            </Typography>
                        </Grid>
                    )}
                    {/* Free Days per Week Field */}
                    <Grid item xs={12}>
                        <DaysOfWeekPicker
                            selectedDays={formik.values.freeDays as unknown as DaysOfTheWeek[]}
                            onChange={(event) => {
                                formik.setFieldValue("freeDays", event, true);
                            }}
                        />
                    </Grid>
                    {(!isUnlimited &&
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                {t("employees.agreements.field.totalDaysOff")}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography variant="h5" component="span">
                                    {integerPart}
                                </Typography>
                                <Typography variant="subtitle1" component="span" style={{ marginLeft: '4px' }}>
                                    {fractionDisplay(fractionPart)}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>

                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        handleClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' onClick={() => { console.log(formik.values) }} variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal >
    );
};

export default AddAgreememtModal;
