import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../../assets/geographics/countries.json"
import AppTextField from './AppTextField';
interface CountrySelectProps {
    showPhone?: boolean;
    showCode?: boolean;
    disabled?: boolean;
    label?: string;
    value?: any;
    onChange?: (value: any, reason: string) => void;

}

const CountrySelect: React.FC<CountrySelectProps> = ({
    showPhone = true,
    showCode = true,
    disabled=false,
    label = "Choose a country",
    value,
    onChange
}) => {
    const handleBlur = (e: any) => {
        const value = e.target.value;
        const remainingOptions = countries.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );

        // Check if only one country remains
        if (remainingOptions.length === 1) {
            const singleOption = remainingOptions[0];
            if (onChange) {
                onChange(singleOption, "auto-select");   
            }
        } else {
            if (onChange) {
            //    onChange(null, "blur-reset");
            }
        }
    };
    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%' }}
            options={countries}
            autoHighlight
            value={value}
            disabled={disabled}
            onChange={(event, newValue, reason) => {
                if (onChange) {                    
                    onChange(newValue, reason);
                }
            }}
            getOptionLabel={(option) => option ? option.label : ""}
            onBlur={handleBlur} // Add this line
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label}
                    {showCode && `(${option.code})`}
                    {showPhone && `+${option.phone}`}
                </Box>
            )}
            renderInput={(params) => (
                <AppTextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
}

export default CountrySelect;