export const convertToWebP = async (blob: Blob, showAlertCallback: (errorMessage: string) => void): Promise<Blob> => {
  try {
    const dataUrl = await readBlobAsDataURL(blob, showAlertCallback);
    const img = await loadImage(dataUrl, showAlertCallback);
    const webpBlob = await convertToWebPBlob(img, showAlertCallback);
    return webpBlob;
  } catch (error) {
    showAlertCallback('Error during WebP conversion.');
    throw new Error('Error during WebP conversion.');
  }
};

const readBlobAsDataURL = async (blob: Blob, showAlertCallback: (errorMessage: string) => void): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event?.target?.result && typeof event.target.result === 'string') {
        resolve(event.target.result);
      } else {
        showAlertCallback('Error reading the file.');
        reject(new Error('Error reading the file.'));
      }
    };
    reader.onerror = () => {
      showAlertCallback('Error reading the file.');
      reject(new Error('Error reading the file.'));
    };
    reader.readAsDataURL(blob);
  });
};

const loadImage = async (src: string, showAlertCallback: (errorMessage: string) => void): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => { resolve(img); };
    img.onerror = () => { reject(new Error('Error loading image.')); };
    img.src = src;
  });
};

const convertToWebPBlob = async (img: HTMLImageElement, showAlertCallback: (errorMessage: string) => void): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      showAlertCallback('Could not create canvas 2D context.');
      reject(new Error('Could not create canvas 2D context.'));
      return;
    }

    // This code is used to center the image in the virtual canvas space
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    canvas.toBlob((webpBlob) => {
      if (webpBlob) {
        resolve(webpBlob);
      } else {
        showAlertCallback('Error during WebP conversion.');
        reject(new Error('Error during WebP conversion.'));
      }
    }, 'image/webp', 0.9);
  });
};