import { Grid, Typography } from "@mui/material";
import logo from '../../../assets/images/OneStaff_logo.png'
import { useEffect } from "react";
import FlexBox from "components/flexbox/FlexBox";
import { useTranslation } from "react-i18next";
import confetti from 'canvas-confetti';

const ProfileCompleted = () => {
    const { t } = useTranslation();

     useEffect(() => {
        confetti({
            particleCount: 100,
            spread: 1000,
            origin: { y: 0.4, x: 0.5 },
            zIndex: -1, 
            gravity: 0.5,
            scalar: 1.3
        });
    }, []);     

    return (
        <FlexBox height={'100vh'} justifyContent={'center'} alignItems={'center'} className="mainContainer">
            <FlexBox
                padding={5}
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    textAlign: 'center',
                }}
                flexDirection={'column'}
            >
                <Grid
                    container
                    justifyContent={'center'}
                    padding={2}
                    mt={0}
                >
                    <img src={logo} style={{ width: '30%' }} alt="OneStaff logo" />
                </Grid>
                <Grid
                    container
                    mt={6}
                >
                    <Grid item xs={12} padding={0}>
                        <Typography fontWeight={600} fontSize={24}>
                            {t('employees.profileCompletionByEmployee.completed.success')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} padding={0} mt={2}>
                        <Typography fontWeight={400}>
                            {t('employees.profileCompletionByEmployee.completed.thanks')}
                        </Typography>
                    </Grid>
                </Grid>

            </FlexBox>
        </FlexBox>
    );
};

export default ProfileCompleted;