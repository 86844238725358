import { alpha, styled } from '@mui/material';
import { type SxProps } from '@mui/system';
import { type ReactNode, type CSSProperties } from 'react';
import SimpleBar, { type Props } from 'simplebar-react';

const StyledScrollBar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': { backgroundColor: alpha(theme.palette.grey[400], 0.6) },
    '&.simplebar-visible:before': { opacity: 1 },
  },
  '& .simplebar-track.simplebar-vertical': { width: 9 },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': { height: 6 },
  '& .simplebar-mask': { zIndex: 'inherit' },
}));

// props type
interface ScrollbarProps {
  sx?: SxProps;
  children: ReactNode;
  style?: CSSProperties;
  id?: string,
}

const Scrollbar = ({
  children,
  style,
  sx,
  id,
  ...props
}: ScrollbarProps & Props) => {
  return (
    <StyledScrollBar id={id} style={style} sx={sx} {...props}>
      {children}
    </StyledScrollBar>
  );
};

export default Scrollbar;
