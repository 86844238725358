import { styled, type TextFieldProps } from '@mui/material';
import { StyledTextField } from 'page-sections/authentication/StyledComponents';
import React, { type FC } from 'react';

const StyledSelectField = styled(StyledTextField)<TextFieldProps>(
  ({ theme }) => ({
    ...StyledTextField,
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.text.disabled,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: theme.palette.text.disabled,
    },
  }),
);

const AppSelectField: FC<TextFieldProps> = (props) => {
  return <StyledSelectField {...props} />;
};

export default AppSelectField;
