
import { useTranslation } from "react-i18next";
import DataTable from "./DataTable";
import { FC, useMemo } from "react";
import { AddRewardFormData } from "./dataTableV2/AddRewardModal";
import { formatDate } from "@fullcalendar/core";

interface DataTableProps {
    data?: any[];
    clearFilter?: string;
    onFilterChange?: (filters: any) => void;
    handleRowSelect: (rows: []) => void;
    encryptionKey? : string;
  }

  const RewardsDataTable: FC<DataTableProps> = (props) => {
    const { t } = useTranslation();

    const getColumnShape = (data: any) => {
      return [
        {
          Header: t('employees.reward.name'),
          Cell: ({ row }: any) => {
            const reward = row.original;
            return reward.name.name;
          },
        },
        {
          Header: t('employees.reward.amount'),
          Cell: ({ row }: any) => {
            const reward = row.original as AddRewardFormData;
            return `${parseFloat(String(reward.amount)).toFixed(2)} €`;
          },
        },
        {
          Header: t('employees.reward.earnedon'),
          Cell: ({ row }: any) => {
            const reward = row.original as AddRewardFormData;
            return (formatDate(reward.earnedOn, {locale: 'it'}));
          },
        },
  
  
        {
          Header: t('employees.reward.notes'),
          Cell: ({ row }: any) => {
            const reward = row.original as AddRewardFormData;
            return (String(reward.notes));
          },
        },
      ];
    };
    const columns: any = useMemo(() => getColumnShape(props.data), [t]);
  
    return DataTable({
      ...props,
      columns,
    });
  };
  
  export default RewardsDataTable;