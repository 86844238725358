import { ChangeEvent, type FC } from 'react';
import FileUploadInput from './FileUploadInput';

interface ImageUploadInputProps {
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImageUploadInput: FC<ImageUploadInputProps> = ({ handleOnChange }) => {
  return FileUploadInput({ accept: 'image/*', handleOnChange });
};

export default ImageUploadInput;
