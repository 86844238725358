import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWorkdays = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='workdays_svg__b'>
        <path fill='none' d='M0 0h90v98H0z' data-name='Rettangolo 4' />
      </clipPath>
      <clipPath id='workdays_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#workdays_svg__a)' data-name='03BF62 - giorni lavorativi'>
        
      <g data-name='Raggruppa 8'>
        <g
          clipPath='url(#workdays_svg__b)'
          data-name='Raggruppa 7'
          transform='translate(43 41)'
        >
          <path
            d='M82.509 7.5H71.258V3.75a3.75 3.75 0 0 0-7.5 0V7.5h-37.5V3.75a3.75 3.75 0 1 0-7.5 0V7.5H7.5A7.5 7.5 0 0 0 0 15v75.01a7.5 7.5 0 0 0 7.5 7.5h75.009a7.5 7.5 0 0 0 7.5-7.5V15a7.5 7.5 0 0 0-7.5-7.5M18.752 15v3.75a3.75 3.75 0 1 0 7.5 0V15h37.5v3.75a3.75 3.75 0 1 0 7.5 0V15h11.257v15H7.5V15Zm63.757 75.01H7.5V37.5h75.009ZM64.537 49.851a3.746 3.746 0 0 1 0 5.3l-22.5 22.5a3.75 3.75 0 0 1-5.3 0l-11.26-11.242a3.753 3.753 0 1 1 5.306-5.309l8.6 8.6L59.23 49.851a3.75 3.75 0 0 1 5.3 0'
            data-name='Tracciato 7'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgWorkdays;
