import type { SVGProps } from 'react';
import ReportIconSelected from './ReportIconSelected';
const SvgReportIcon = (props: SVGProps<SVGSVGElement>) => {
  if ((props as any).sx.color !== 'primary.main')
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={17}
        height={16}
        viewBox='0 0 17 16'
        style={{ marginRight: '4px' }}
        {...props}
      >

        <defs>
          <clipPath id='reportIcon_svg__cp1' clipPathUnits='userSpaceOnUse'>
            <path d='M-7.49-8.74h31.98v33.48H-7.49z' />
          </clipPath>
          <clipPath id='reportIcon_svg__cp2' clipPathUnits='userSpaceOnUse'>
            <path d='M.1.03h16.8v15.94H.1z' />
          </clipPath>
        </defs>
        <style>{'.reportIcon_svg__s1{fill:#c0c7d1}'}</style>
        <g
          id='reportIcon_svg__Clip-Path: scheda_collaboratore_grigio_scuro_5F748D-_grigio_chiaro_C0C7D1_'
          clipPath='url(#reportIcon_svg__cp1)'
        >
          <g id='reportIcon_svg__scheda_collaboratore_grigio_scuro_5F748D-_grigio_chiaro_C0C7D1_'>
            <g id='reportIcon_svg__Raggruppa_22'>
              <g
                id='reportIcon_svg__Clip-Path: Raggruppa_21'
                clipPath='url(#reportIcon_svg__cp2)'
              >
                <g id='reportIcon_svg__Raggruppa_21'>
                  <path
                    id='reportIcon_svg__Tracciato_14'
                    fillRule='evenodd'
                    d='M16.9 1.5v13.1q-.1.3-.2.5l-.4.4q-.2.2-.4.3t-.5.1l-.1.1H1.7l-.6-.2q-.3-.1-.5-.4-.2-.2-.4-.5-.1-.3-.1-.7V1.5q.1-.1.1-.3 0-.1.1-.2L.5.8Q.6.6.8.4q.1-.1.4-.2.2-.1.4-.1.2-.1.4-.1h13.2q.1 0 .2.1h.3q.1.1.2.1.2.1.3.2l.3.3q.2.2.3.4t.1.4M1.6 4.8c-.3 0-.3 0-.3.3V14c0 .5.2.7.7.7h13c.5 0 .7-.2.7-.7V5.1c0-.3 0-.3-.2-.3zm6.9-1.2h7c.2 0 .2 0 .2-.2V1.7q-.1-.2-.2-.3 0-.1-.2-.1H1.7q-.1 0-.2.1t-.2.3v1.7c0 .2 0 .2.3.2z'
                    style={{
                      fill: '#5f748d',
                    }}
                  />
                  <path
                    id='reportIcon_svg__Tracciato_15'
                    d='M9.9 10.2h3.8c.3 0 .3 0 .3.3v.8q0 .2-.2.2H6.1c-.4 0-.4 0-.4-.4q.1-.3 0-.7c0-.1.1-.2.3-.2z'
                    className='reportIcon_svg__s1'
                  />
                  <path
                    id='reportIcon_svg__Tracciato_16'
                    d='M9.9 8.1h3.9c.2 0 .2 0 .2.2v.8c0 .2 0 .2-.2.2H6c-.3 0-.3 0-.3-.3v-.7c0-.2.1-.2.3-.2z'
                    className='reportIcon_svg__s1'
                  />
                  <path
                    id='reportIcon_svg__Tracciato_17'
                    d='M5.8 7.2H3.3C3 7.2 3 7.2 3 7v-.8q0-.2.2-.2h5.1c.2 0 .2 0 .2.2V7c0 .2 0 .2-.2.2z'
                    className='reportIcon_svg__s1'
                  />
                  <path
                    id='reportIcon_svg__Tracciato_18'
                    d='M5.8 12.4h2.5c.2 0 .2 0 .2.2v.8c0 .1 0 .2-.2.2H3.2q-.2 0-.2-.2v-.8c0-.2.1-.2.2-.2z'
                    className='reportIcon_svg__s1'
                  />
                  <path
                    id='reportIcon_svg__Tracciato_19'
                    d='M11.8 1.8h3.1c.3 0 .3 0 .3.3v.8q0 .2-.2.2H8.8c-.3 0-.3 0-.3-.4V2c0-.2 0-.2.2-.2h3.1'
                    className='reportIcon_svg__s1'
                  />
                  <path
                    id='reportIcon_svg__Tracciato_20'
                    d='M3.3 2.4q-.1.4-.3.6t-.6.2q-.3 0-.5-.2t-.2-.6v-.3l.2-.2q.1-.1.3-.2.1-.1.3-.1.1 0 .3.1l.2.2.2.2q.1.2.1.3'
                    className='reportIcon_svg__s1'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  else
    return ReportIconSelected(props);

}
export default SvgReportIcon;
