import React from 'react';
import { Card, styled, Theme } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { TFunction } from 'i18next';
import { addDays, parse } from 'date-fns';

interface DataProps {
    t: TFunction;
    startDate: string;
    endDate: string;
    dailyNetEarningsMap: Map<string, number>;  // Map of daily net earnings for each day in the date range
    dailyPayoutsMap: Map<string, number>;
    theme: Theme; // Add the theme prop with correct type
}

interface ChartState {
    series: Array<{ name: string; data: number[] }>;
    options: ApexOptions;
}

const StyledChart = styled(Chart)(() => ({
    minHeight: '300px !important',
    '& .apexcharts-xaxistooltip': { display: 'none !important' },
}));

class EmployeeCostCumulativeBase extends React.Component<DataProps, ChartState> {
    constructor(props: DataProps) {
        super(props);
        this.state = this.initializeChartState(props);
    }

    componentDidUpdate(prevProps: DataProps) {
        if (
            prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate ||
            prevProps.dailyNetEarningsMap !== this.props.dailyNetEarningsMap
        ) {
            this.setState(this.initializeChartState(this.props));
        }
    }

    initializeChartState = (props: DataProps): ChartState => {

        const { dateArray, earningsArray, payoutsArray } = this.getDateLabelsAndEarnings(props.startDate, props.endDate, props.dailyNetEarningsMap, props.dailyPayoutsMap);

        return {
            series: [{
                name: props.t('employees.cumulativeEarnings'),
                data: earningsArray
            },
            {
                name: props.t("employees.cumulativePayouts"),
                data: payoutsArray
            }],
            options: {
                chart: {
                    type: 'area',
                    height: 350,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'stepline'
                },
                xaxis: {
                    type: 'datetime',
                    categories: dateArray,
                    labels: {
                        style: {
                            colors: props.theme.palette.text.disabled,
                            fontFamily: props.theme.typography.fontFamily,
                            fontWeight: 500,
                        },
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(2) + "€";
                        }
                    },
                },
                fill: {
                    opacity: 0.8,
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yyyy'
                    }
                }
            }
        };
    }

    // Function to generate date labels and cumulative earnings
    getDateLabelsAndEarnings = (start: string, end: string, earningsMap: Map<string, number>, payoutsMap: Map<string, number>) => {
        let startDt = parse(start, 'dd/MM/yyyy', new Date());
        const endDt = parse(end, 'dd/MM/yyyy', new Date());
        const dateArray: string[] = [];
        const earningsArray: number[] = [];
        const payoutsArray: number[] = [];

        let cumulativeEarnings = 0;
        let cumulativePayouts = 0;

        while (startDt <= endDt) {
            const dateKey = startDt.toISOString().slice(0, 10);
            dateArray.push(dateKey);
            cumulativeEarnings = (earningsMap.get(dateKey) || cumulativeEarnings);
            cumulativePayouts = (payoutsMap.get(dateKey) || cumulativePayouts);
            earningsArray.push(cumulativeEarnings);
            payoutsArray.push(cumulativePayouts);
            startDt = addDays(startDt, 1);
        }
        return { dateArray, earningsArray, payoutsArray };
    };

    render() {
        return (
            <Card sx={{ padding: 3, height: '100%', overflowX: 'auto' }}>
                <StyledChart
                    options={this.state.options}
                    series={this.state.series}
                    height={300}
                    type="area"
                />
            </Card>
        );
    }
}

export default EmployeeCostCumulativeBase;
