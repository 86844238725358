import { SvgIcon, type SvgIconProps } from '@mui/material';
import React from 'react';

const Chat = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path d='M15,16a4,4,0,0,0,4-4V6a4,4,0,0,0-4-4H5A4,4,0,0,0,1,6V18.5a.5.5,0,0,0,.5.5.49.49,0,0,0,.35-.15l2-2A3,3,0,0,1,6,16ZM3,15V6A2,2,0,0,1,5,4H15a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H6A5,5,0,0,0,3,15ZM21,6v8a4,4,0,0,1-4,4H5a2,2,0,0,0,2,2H18a3,3,0,0,1,2.13.88l2,2a.49.49,0,0,0,.35.15.5.5,0,0,0,.5-.5V8A2,2,0,0,0,21,6Z' />
    </SvgIcon>
  );
};

export default Chat;
