import { Autocomplete, Button, TextField } from "@mui/material";
import { shiftTemplateApi } from "api";
import { ShiftTemplateEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import * as Yup from 'yup';
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useFormik } from "formik";

interface ModalProps {
  fetchTemplates: () => void;
  open: boolean;
  shiftTemplates: ShiftTemplateEntity[];
  openShiftTemplate: (data: ShiftTemplateEntity) => void;
  onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  minWidth: 300,
  maxWidth: 400
}));


const CopyShiftTemplateModal: FC<ModalProps> = ({ shiftTemplates, open, fetchTemplates, onClose, openShiftTemplate }) => {
  const { t } = useTranslation();

  const initialValues = {
    templateId: -1,
    name: '',
  }

  const validationSchema = Yup.object().shape({
    templateId: Yup.number()
      .required().positive(t('common.forms.field.isRequired')),
    name: Yup.string()
      .required(t('common.forms.field.isRequired')),
  });

  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        await copyTemplate(values.templateId, values.name)
      }
    }
  )

  const copyTemplate = async (selectedTemplateId: number, name: string) => {
    if (selectedTemplateId) {
      await shiftTemplateApi.createFromTemplate(selectedTemplateId, name).then((response) => {
        formik.resetForm();
        fetchTemplates();
        openShiftTemplate(response.data);
        onClose();
      })
    }
  }

  const options = shiftTemplates.map((template) => {
    return {
      id: template.id,
      data: template,
      label: template.name

    }
  });

  useEffect(() => {
    if (!open)
      formik.resetForm();
  }, [open])

  return (
    <StyledAppModal open={open} handleClose={() => {
      onClose();
    }}>
      <H2 marginBottom={2}>
        {t('shiftTemplate.copyFrom')}
      </H2>
      <form onSubmit={formik.handleSubmit}>

        <TextField value={formik.values.name}
          onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
          helperText={formik.errors.name ?? undefined}
          error={!!formik.errors.name}
          label={t('shiftTemplate.name')}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Autocomplete

          fullWidth
          value={options.find(template => template.id === formik.values.templateId)}
          onChange={(e, value) => {
            formik.setFieldValue('templateId', value?.id);

          }}

          options={options}

          renderInput={(params) => <TextField {...params}



            helperText={formik.errors.templateId}
            error={!!formik.errors.templateId}
            label={t('shiftTemplate.selectTemplate')} />}

        />

        <FlexBox justifyContent='flex-end' gap={2} marginTop={2}>
          <Button fullWidth size='small' variant='outlined' onClick={() => {
            onClose();
          }}>
            {t('common.forms.button.cancel')}
          </Button>
          <Button fullWidth size='small' type="submit" variant='contained'>
            {t('common.forms.button.save')}
          </Button>
        </FlexBox>

      </form>
    </StyledAppModal>
  )
}

export default CopyShiftTemplateModal;