import { SvgIcon, type SvgIconProps } from '@mui/material';

const LayerGroup = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d='M6.35742 0.141278C6.76484 -0.0470928 7.23516 -0.0470928 7.64258 0.141278L13.6199 2.9039C13.8523 3.01054 14 3.24296 14 3.47538C14 3.75429 13.8523 3.98671 13.6199 4.09609L7.64258 6.85781C7.23516 7.04648 6.76484 7.04648 6.35742 6.85781L0.380898 4.09609C0.148695 3.98671 0 3.75429 0 3.47538C0 3.24296 0.148668 3.01054 0.380898 2.9039L6.35742 0.141278ZM13.6199 9.9039C13.8523 10.0105 14 10.243 14 10.4754C14 10.7543 13.8523 10.9867 13.6199 11.0961L7.64258 13.8578C7.23516 14.0465 6.76484 14.0465 6.35742 13.8578L0.380898 11.0961C0.148695 10.9867 0 10.7543 0 10.4754C0 10.243 0.148668 10.0105 0.380898 9.9039L1.83559 9.23124L5.99102 11.1508C6.63086 11.4488 7.36914 11.4488 8.00898 11.1508L12.1652 9.23124L13.6199 9.9039Z' />
      <path
        className='secondary'
        d='M8.00898 7.65098L12.1652 5.73145L13.6199 6.4041C13.8523 6.51074 14 6.74316 14 6.97559C14 7.25449 13.8523 7.48691 13.6199 7.59629L7.64258 10.358C7.23516 10.5467 6.76484 10.5467 6.35742 10.358L0.380898 7.59629C0.148695 7.48691 0 7.25449 0 6.97559C0 6.74316 0.148668 6.51074 0.380898 6.4041L1.83559 5.73145L5.99102 7.65098C6.63086 7.94902 7.36914 7.94902 8.00898 7.65098Z'
      />
    </SvgIcon>
  );
};

export default LayerGroup;
