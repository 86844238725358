import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ErrorMessage = ({t}:any) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            bgcolor="#f8d7da"
            p={1}
            borderRadius={4}
            border="1px solid #d73d32"
        >
            <ErrorOutlineIcon sx={{ color: '#d73d32', marginRight: 1 }} />
            <Typography sx={{ color: '#d73d32' }} variant="body2">
                {t('employees.calendar.dialog.bodyMessage')}
            </Typography>
        </Box>
    );
};

export const CustomAnimatedDialog = ({ open, onClose, data }: {
    open: boolean;
    onClose: () => void;
    data?: any;
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
        >
            <DialogTitle>{t('employees.calendar.dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ErrorMessage t={t} /><br />
                    {data?.map((item: any, index: number) => (
                        <Box key={index}>
                            {item.employeeName}
                            {index < data.length - 1 && <br />}
                        </Box>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={'primary'} style={{ color: 'white' }} variant='contained'>{t('dialog.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};
