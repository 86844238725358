import { agreementApi, employeeNotesApi } from 'api';
import { AgreementEntity, UpdateNoteDto } from 'api/generated';
import CryptoJS from 'crypto-js';
import { TFunction } from 'i18next';
import toast from 'react-hot-toast';

export const encryptData = (dataInput: any, encryptionKey: string): string | undefined => {
    try {
        const data = String(dataInput)
        const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString();
        return encryptedData;
    } catch (error) {
        console.error('Error encrypting data:', error);
    }
}

export const encryptAllNotes = async (t: TFunction) => {

    const encryptionKey = localStorage.getItem('encryptionKey');

    if (!encryptionKey)
        return;

    const notes = (await employeeNotesApi.findAll()).data;

    const unencryptedNotes = notes.filter(note => note.text);

    for (const note of unencryptedNotes) {
        const textEncrypted = encryptData(note.text, encryptionKey);
        if (!textEncrypted) {
            toast.error(t('encryption.alerts.agreements.encrypt.error'));
            return
        }

        const noteDto: UpdateNoteDto = {
            text: "",
            text_encrypted: textEncrypted
        }
        await employeeNotesApi.update(note.id, noteDto);
    }

    toast.success(t('encryption.alerts.notes.encrypt.success'));
}

export const decryptData = (encryptedData: string, decryptionKey: string): string => {
    try {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, decryptionKey).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (error) {
        console.error('Error decrypting data:', error);
        return '';
    }
}


export const encryptAllAgrements = async (t: TFunction, isDeleteAll?: boolean): Promise<void> => {
    agreementApi.findAll().then(async ({ data }) => {
        const agreements: AgreementEntity[] = data;
        const encryptionKey = localStorage.getItem('encryptionKey');
        const unencryptedAgreements = agreements.filter((agreement) => agreement.net_daily_encrypted === null);
        if (isDeleteAll) {
            const actions = unencryptedAgreements.map(async (agreement) => {
                return agreementApi.remove(String(agreement.id));
            });
            await Promise.all(actions).then(() => {
                toast.success(t('encryption.alerts.agreements.delete.success'));
            }).catch(() => {
                toast.error(t('encryption.alerts.agreements.delete.error'));
            });
            window.location.hash = "";
            history.replaceState(null, document.title, window.location.pathname + window.location.search);
            return;
        }

        if (encryptionKey) {
            const actions = unencryptedAgreements.map(async (agreement) => {

                const netDailyEncrypted = encryptData(agreement.net_daily, encryptionKey);

                if (!netDailyEncrypted){
                    toast.error(t('encryption.alerts.agreements.encrypt.error'));
                    return;
                }

                const updatedData = {
                    ...agreement,
                    net_daily: 0,
                    endedOn: agreement.endedOn ?? undefined,
                    notes: agreement.notes ?? undefined,
                    downpayment: agreement.downpayment ?? undefined,
                    net_daily_encrypted: netDailyEncrypted
                };
                return agreementApi.update(String(agreement.id), updatedData);
            });
            await Promise.all(actions).then(() => {
                toast.success(t('encryption.alerts.agreements.encrypt.success'));
            }).catch(() => {
                toast.error(t('encryption.alerts.agreements.encrypt.error'))
            });
            window.location.hash = "";
            history.replaceState(null, document.title, window.location.pathname + window.location.search);
        }
    });
}