import { Card, useTheme, Grid, IconButton, Divider, LinearProgress, Tooltip, Typography, Box, LinearProgressProps } from "@mui/material";
import { shiftApi } from "api";
import FlexBox from "components/flexbox/FlexBox";
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { Position } from "pages/shifts/shift-assignment";
import { useSeason } from "contexts/SeasonContext";
import AppAvatar from "components/avatars/AppAvatar";
import defaultProfileImage from '../../assets/images/default-profile.png';
import { useNavigate } from "react-router-dom";

export interface EmployeeCardData {
    id: number;
    name: string;
    propicPath: string | null;
    workPosition: string;
    workedHours: number;
    contractHours: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


interface DataPropos {
    data: EmployeeCardData[];
    selectedShiftDetail?: Position;
    fetchData: () => void;
}

const EmployeeCards: FC<DataPropos> = (props) => {

    const { data, selectedShiftDetail, fetchData } = props;

    const theme = useTheme();

    const { t } = useTranslation();

    const { seasonId } = useSeason();

    const navigate = useNavigate();

    // use first position to find first employee that is a boss to the selected position, this solution assumes that api response is ordered by hierarchy

    const firtstPosition = data.at(0)?.workPosition;

    const dividerPosition = data.findIndex((value) => value.workPosition !== firtstPosition)

    const assignEmployee = async (employeeId: number) => {
        if (!selectedShiftDetail)
            return;

        await shiftApi.assignEmployee({
            date: selectedShiftDetail.date.toISOString(),
            employeeId,
            seasonId,
            shiftDetailId: selectedShiftDetail.id
        });
        fetchData();
    }

    const navigateToEmployee = (employeeId: number) => {
        navigate('/dashboard/employees/new', {
            state: {
                employeeId,
                activeTab: 0,
            },
        });
    }

    return (
        <FlexBox flexDirection={'column'} borderRight={1} borderColor={theme.palette.divider} height={'100%'}>
            {



                data.map((card, index) => {
                    return (
                        <>
                            {
                                dividerPosition === index && selectedShiftDetail &&
                                (
                                    <Divider>
                                        {t('shift.otherEmployees')}
                                    </Divider>
                                )
                            }
                            <Card key={card.id} sx={{ borderRadius: 2, display: 'block', padding: 1, margin: 2, marginY: 1, bgcolor: theme.palette.background.default }}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={3} justifyContent={'center'} display={'flex'}>
                                        <AppAvatar sx={{ width: "2.0vw", height: "2.0vw", display: 'flex' }} src={card.propicPath ?? defaultProfileImage} />
                                    </Grid>
                                    <Grid item xs={5} md={7} zeroMinWidth>
                                        <Typography fontWeight={600}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    textDecoration: "underline #000000"
                                                }
                                            }}
                                            overflow={'hidden'} textOverflow={'ellipsis'} onClick={() => { navigateToEmployee(card.id) }}>

                                            {card.name}

                                        </Typography>

                                        <Typography fontStyle={'italic'} fontWeight={50} overflow={'hidden'} textOverflow={'ellipsis'}>
                                            {card.workPosition}
                                        </Typography>
                                        
                                        <Tooltip title={t('shift.workedHours') + ' ' + card.workedHours + '/' + card.contractHours}>
                                            <div>
                                                <LinearProgressWithLabel variant={'determinate'} value={card.workedHours / card.contractHours * 100} />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={4} md={2}>

                                        {
                                            selectedShiftDetail &&
                                            (<IconButton size="medium" color="primary"onClick={() => { assignEmployee(card.id) }}>
                                                <AddIcon></AddIcon>
                                            </IconButton>)
                                        }
                                    </Grid>
                                </Grid>

                            </Card>
                        </>
                    )
                })
            }
        </FlexBox>
    )
}

export default EmployeeCards;