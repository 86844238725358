import { createContext, useContext, useMemo, useState } from 'react';

interface HiddenContextType {
  hidden: boolean;
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
};


const HiddenContext = createContext<HiddenContextType | undefined>(undefined);

export const useHidden = (): HiddenContextType => {
  const context = useContext(HiddenContext);
  if (!context) {
    throw new Error("useEncryption must be used within a HiddenProvider");
  }
  return context;
};

const HiddenProvider = ({ children }: any) => {
  const [hidden, setHidden] = useState(true);

  const contextValue = useMemo(() => ({ hidden, setHidden}), [hidden, setHidden]);

  return (
    <HiddenContext.Provider value={contextValue}>
      {children}
    </HiddenContext.Provider>
  );
};


export default HiddenProvider;