import React, { CSSProperties } from 'react';
import { CircularProgress } from '@mui/material';

const UploadingOverlay: React.FC = () => {
  return (
    <div style={styles.overlay}>
      <CircularProgress color="secondary" />
      <div style={styles.text}>Uploading...</div>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
    overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(128, 128, 128, 0.8)', // grey with 50% opacity
    zIndex: 1000  // ensures the overlay is on top
  },
  text: {
    marginTop: '16px', // spacing between the spinner and text
    color: '#fff',
    fontWeight: 'bold'
  }
};

export default UploadingOverlay;
