import React, { useEffect, useState } from 'react';
import { Container, Grid, useTheme, useMediaQuery, Button } from '@mui/material';
import DefaultUserImage from '../assets/images/defaultUserPage.svg';
import Logo from '../assets/images/logo_onestaff.png';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import SelectSectionModal from 'page-sections/data-table/dataTableV2/SelectSectionModal';
import AddTurnoverModal from 'page-sections/data-table/dataTableV2/AddTurnoverModal';
import { DepartmentEntity, SectionEntity } from 'api/generated';
import { useSeason } from 'contexts/SeasonContext';
import { departmentsApi, paymentSectionsApi } from 'api';
import { Add } from '@mui/icons-material';
import FlexBox from 'components/flexbox/FlexBox';
import { Small } from 'components/Typography';

const DefaultUserPage: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));


    const [sections, setSections] = useState<SectionEntity[]>([]);
    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [selectedSection, setSelectedSection] = useState<SectionEntity>();
    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentEntity>();


    const { seasonId } = useSeason();

    const [openSelectSectionModal, setOpenSelectSectionModal] = useState(false);
    const [openAddTurnoverModal, setOpenAddTurnoverModal] = useState(false);

    const handleOpenAddTurnoverModal = () => {
        if (sections.length === 1) {
            setSelectedSection(sections[0]);
            setOpenAddTurnoverModal(true);
        } else if (departments.length === 1) {
            setSelectedDepartment(departments[0]);
            const filteredSectionsByDepartment = sections.filter((s) => { return s.departmentId === departments[0].id });
            if (filteredSectionsByDepartment.length === 1) {
                setSelectedSection(filteredSectionsByDepartment[0]);
                setOpenAddTurnoverModal(true);
            } else {
                setOpenSelectSectionModal(true);
            }
        } else {
            setOpenSelectSectionModal(true);
        }
    };

    const fetchData = async () => {
        try {

            const sections = (await paymentSectionsApi.findAll(seasonId)).data;
            const departments = (await departmentsApi.findByRole(seasonId)).data;
            setSections(sections);
            setDepartments(departments);

        }
        catch {
            setSections([]);
            setDepartments([]);
        }
    }

    useEffect(() => {
        fetchData();
    }, [seasonId]);


    return (
        <>
            <SelectSectionModal
                open={openSelectSectionModal}
                onClose={() => { setOpenSelectSectionModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                onConfirm={() => {
                    setOpenAddTurnoverModal(true);
                    setOpenSelectSectionModal(false)
                }}
                setSelectedSection={(section: SectionEntity | undefined) => { setSelectedSection(section) }}
                sections={sections}
                selectedSection={selectedSection}
                setSelectedDepartment={(department: DepartmentEntity | undefined) => { setSelectedDepartment(department) }}
                departments={departments}
                selectedDepartment={selectedDepartment}
            />

            <AddTurnoverModal
                open={openAddTurnoverModal}
                section={selectedSection}
                isOwner={false}
                onClose={() => { setOpenAddTurnoverModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                onBack={() => {
                    setOpenAddTurnoverModal(false);
                    const filteredSectionsByDepartemnt = sections.filter((s) => { return s.departmentId === departments[0].id });
                    if (sections.length === 1 || departments.length === 1 && filteredSectionsByDepartemnt.length === 1) {
                        setSelectedDepartment(undefined);
                        setSelectedSection(undefined);
                        fetchData();
                    } else {
                        setOpenSelectSectionModal(true);
                    }
                }}
            />


            <FlexBox marginTop={1} justifyContent={'end'} maxHeight={60}>
                {(departments.length > 0 && sections.length > 0) && <>
                    {
                        (departments.length <= 0 || sections.length <= 0) &&
                        <Small color={theme.palette.warning.main} marginRight={2} width={300}>{t('turnovers.cannotAdd')}</Small>
                    }
                    <Button
                        variant="contained"
                        sx={{ display: 'flex' }}
                        disabled={departments.length <= 0 || sections.length <= 0}
                        startIcon={<Add />}
                        onClick={() => { handleOpenAddTurnoverModal() }}>
                        {t('amount.add')}
                    </Button>
                </>
                }

            </FlexBox>

            <Container
                maxWidth="lg"
                sx={{
                    height: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    position: 'relative',
                    paddingLeft: mobileBreakpoint ? '20px' : '40px',
                    paddingRight: mobileBreakpoint ? '20px' : '40px'
                }}
            >
                {mobileBreakpoint && (
                    <img
                        src={Logo}
                        alt="OneStaff Logo"
                        style={{
                            width: '80px',
                            height: 'auto',
                            position: 'absolute',
                            top: 20,
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }}
                    />
                )}


                <Grid
                    container
                    sx={{
                        flexGrow: 1,
                        justifyContent: mobileBreakpoint ? 'center' : 'flex-start',
                        alignItems: 'center',
                        textAlign: 'left'
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                        <img
                            src={DefaultUserImage}
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: mobileBreakpoint ? '200px' : '400px'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            order: mobileBreakpoint ? 1 : 2,
                            marginTop: mobileBreakpoint ? '20px' : '0'
                        }}
                    >
                        <div style={{
                            fontSize: mobileBreakpoint ? '36px' : '64px',
                            lineHeight: '1.2',
                            textAlign: 'left',
                            marginLeft: '30px'
                        }}>
                            <span style={{ color: 'black' }}>{t('greetings')}</span>
                            <br />
                            <span style={{ color: 'violet', fontWeight: 'bold' }}>
                                {user?.username || 'Guest'}
                            </span>
                        </div>
                    </Grid>
                </Grid>

                {!mobileBreakpoint && (
                    <img
                        src={Logo}
                        alt="OneStaff Logo"
                        style={{
                            width: '120px',
                            height: 'auto',
                            position: 'absolute',
                            bottom: 20,
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }}
                    />
                )}
            </Container>
        </>
    );
};

export default DefaultUserPage;
