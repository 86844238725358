import { createContext, useContext, useMemo, useState } from 'react';

interface EncryptionContextType {
  encryptionKey: string;
  setEncryptionKey: React.Dispatch<React.SetStateAction<string>>;
  popoverOpen: boolean;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
};


const EncryptionContext = createContext<EncryptionContextType | undefined>(undefined);

export const useEncryption = (): EncryptionContextType => {
  const context = useContext(EncryptionContext);
  if (!context) {
    throw new Error("useEncryption must be used within a EncryptionProvider");
  }
  return context;
};

const EncryptionProvider = ({ children }: any) => {
  const [encryptionKey, setEncryptionKey] = useState('');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const contextValue = useMemo(() => ({ encryptionKey, setEncryptionKey, popoverOpen, setPopoverOpen }), [encryptionKey, setEncryptionKey, popoverOpen, setPopoverOpen]);

  return (
    <EncryptionContext.Provider value={contextValue}>
      {children}
    </EncryptionContext.Provider>
  );
};


export default EncryptionProvider;



