import {
  ContractEntityLegalContractTypeEnum,
  EmployeeEntity,
  EmployeeToSeasonEntityStatusTypeEnum,
} from 'api/generated';
import { t } from "i18next";
import { IDFormData } from "page-sections/employees/IDDocuments";

export const searchByName = (listData: any[], searchValue: string) => {

  const lowerSearchValue = searchValue.toLowerCase();
  const fields = ['firstName', 'lastName', 'email'];

  // Filtra la lista solo se il valore di ricerca è presente
  const searchResult = searchValue.length > 0
    ? listData.filter(item => {
      // Normal fields
      // Normal fields
      const normalFieldMatch = fields.some(field =>
        item[field]?.toLowerCase().includes(lowerSearchValue)
      );

      // Name
      const nameMatch = item.name?.toLowerCase().includes(lowerSearchValue);

      // Departments and Segments
      const departmentMatch = item.employeesToDepartments?.some((departmentEntry: any) =>
        departmentEntry.department.name.toLowerCase().includes(lowerSearchValue) ||
        departmentEntry.department.segment?.name.toLowerCase().includes(lowerSearchValue)
      );


      // Workpositions
      const workPositionMatch = item.employeesToDepartments?.some((workPosition: any) =>
        workPosition.workPosition.name.toLowerCase().includes(lowerSearchValue)
      );

      // Status (with null check)
      const statusMatch = item.employeesToSeasons?.at(0)?.statusType === 'CUSTOM'
        ? item.employeesToSeasons?.at(0)?.status?.name.toLowerCase().includes(lowerSearchValue)
        : translateStatus(item.employeesToSeasons?.at(0)?.statusType).name.toLowerCase().includes(lowerSearchValue);
      // Building (with null check for room)
      const buildingMatch = item.room
        ? item.room.building.name.toLowerCase().includes(lowerSearchValue)
        : false;

      // Room (with null check for room)
      const roomMatch = item.room
        ? item.room.name.toLowerCase().includes(lowerSearchValue)
        : false;

      // Shift template
      const shiftTemplateMatch = item?.description?.toLowerCase().includes(lowerSearchValue);
      return normalFieldMatch || nameMatch || departmentMatch || workPositionMatch || statusMatch || buildingMatch || roomMatch || shiftTemplateMatch;
    })
    : listData;



  return searchResult;
};
export const searchDocumentByName = (listData: IDFormData[], searchValue: string) => {
  const lowerSearchValue = searchValue.toLowerCase();
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item, index) => {
      // saving index to have it as a reference in filtred data
      item.index = index;
      if (!item.documentName)
        item.documentName = ""
      // concat document name and typename and look for the search value
      return item.documentName.concat(item.typeName).toLowerCase().includes(lowerSearchValue);
    })
    return searchResult;
  }

  return listData.map((item, index) => { item.index = index; return item });


}
export const getRoute = (pathname: string) => {
  const str = pathname.split('/');
  return `/${str[1]}`;
};
export const translateStatus = (statusType: EmployeeToSeasonEntityStatusTypeEnum) => {
  switch (statusType) {
    case 'ACTIVE':
      return { name: t('status.ACTIVE'), color: "#1fd14c" };
    case 'CANDIDATE':
      return { name: t('status.Candidate'), color: '#030fff' };
    case 'TERMINATED':
      return { name: t('status.Terminated'), color: '#ff0303' };
    default:
      return { name: 'Custom', color: '#54576e' };
  }
};

export const translateContractType = (contractType: ContractEntityLegalContractTypeEnum) => {
  switch (contractType) {
    case "LIMITED":
      return t("employees.contract.types.limited")
    case "UNLIMITED":
      return t("employees.contract.types.unlimited")
    case "INTERNSHIP":
      return t("employees.contract.types.internship")
    case "CALL":
      return t("employees.contract.types.call")
    case "APPRENTICESHIP":
      return t("employees.contract.types.apprenticeship")
    default:
      return t("employees.generalInformation.notAssigned")
  };

};

export function fadeColor(hex: string | undefined, fadeAmount: number = 0.7): string {
  if (!hex) return '#FFFFFF'
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the new r, g, b values by fading them towards white (255,255,255)
  r += Math.round((255 - r) * fadeAmount);
  g += Math.round((255 - g) * fadeAmount);
  b += Math.round((255 - b) * fadeAmount);

  // Ensure that the values are in the correct range
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert r, g, b back to hex and return the result
  const fadedHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  return fadedHex;
}

export const ignoreUTC = (value: Date, toView?: boolean) => {
  const date = new Date(+value);
  if (!toView)
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
  else
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
};
export const getFullNameWithIdConditionally = (employees: EmployeeEntity[]) => {
  const nameCount = new Map();

  employees.forEach(employee => {
    const fullName = `${employee.firstName} ${employee.lastName}`;
    nameCount.set(fullName, (nameCount.get(fullName) || 0) + 1);
  });

  return employees.map(employee => {
    const fullName = `${employee.firstName} ${employee.lastName}`;
    const showId = nameCount.get(fullName) > 1;
    return {
      id: employee.id,
      fullName,
      showId
    };
  });
};
