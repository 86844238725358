import { Box, styled, type SxProps } from '@mui/material';
import { type FC, Fragment, type ReactNode } from 'react';
import LayoutSetting from './LayoutSetting';

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  transition: 'all 0.3s',
  [theme.breakpoints.down(1200)]: {
    width: '100%',
    marginLeft: 0,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: { maxWidth: 2400, margin: 'auto' },
}));

// --------------------------------------------
interface LayoutBodyWrapperProps {
  sx?: SxProps;
  children: ReactNode;
}
// --------------------------------------------

const LayoutBodyWrapper: FC<LayoutBodyWrapperProps> = ({ children, sx }) => {
  return (
    <Fragment>
      <Wrapper sx={sx}>
        <InnerWrapper>{children}</InnerWrapper>
      </Wrapper>

      <LayoutSetting />
    </Fragment>
  );
};

export default LayoutBodyWrapper;
