import { Box, IconButton, MenuItem, Popover, styled } from '@mui/material';
import { userApi } from 'api';
import { SetLanguageDtoLanguageEnum } from 'api/generated';
import { H6 } from 'components/Typography';
import jwtDecode from 'jwt-decode';
import { type FC, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// dummy language options
const languageOptions: Record<string, { icon: string; label: string }> = {
  it: {
    icon: '/static/flags/it.png',
    label: 'Italiano',
  },
  en: {
    icon: '/static/flags/usa.png',
    label: 'English',
  },
  de: {
    icon: '/static/flags/de.png',
    label: 'Deutsch',
  },
  fr: {
    icon: '/static/flags/fr.png',
    label: 'Français'
  }

  //   hi: {
  //     icon: '/static/flags/in.png',
  //     label: 'Hindi',
  //   },
};

// custom styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const IconWrapper = styled(Box)(() => ({
  display: 'flex',
  height: 24,
  width: 24,
  padding: '2px',
  '& img': {
    width: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
}));

const ItemWrapper = styled(Box)(() => ({
  display: 'flex',
  '& img': { width: '100%' },
}));

interface LanguagePopoverProps {
  disableScrollLock?: boolean;
}

const LanguagePopover: FC<LanguagePopoverProps> = ({ disableScrollLock = false }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIdFromToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = jwtDecode<{ sub: number }>(accessToken);
      return decodedToken.sub
    }
    return null;
  }

  const id = getIdFromToken();

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    if (id && !location.pathname.includes("select-tenant")) {
      const languageEnum = language.toUpperCase() as SetLanguageDtoLanguageEnum
      userApi.setLanguage({ id, language: languageEnum })
    }
    setOpen(false);
  };

  let selectedLanguage = languageOptions[i18n.language];
  if (!selectedLanguage) {
    selectedLanguage = languageOptions.en;
  }

  useEffect(() => {
    const fetchLanguageData = async () => {
      if (id && !location.pathname.includes("select-tenant")) {
        const language = await userApi.getLanguage(id);
        const lowerCaseLanguage = language.data.toLocaleLowerCase();
        if (lowerCaseLanguage !== i18n.language) {
          i18n.changeLanguage(lowerCaseLanguage);
        }
      }
    };
    fetchLanguageData();
  }, []);

  return (
    <>
      <StyledIconButton onClick={handleOpen} ref={anchorRef}>
        <IconWrapper>
          <img alt={selectedLanguage.label} src={selectedLanguage.icon} />
        </IconWrapper>
      </StyledIconButton>
      <Popover
        disableScrollLock={disableScrollLock}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        PaperProps={{ sx: { width: 150, padding: '0.5rem 0' } }}
      >
        {Object.keys(languageOptions).map((language: string) => (
          <MenuItem
            key={languageOptions[language].label}
            onClick={() => {
              handleChangeLanguage(language);
            }}
          >
            <ItemWrapper>
              {/* <img
                alt={languageOptions[language].label}
                src={languageOptions[language].icon}
              /> */}
              <H6 fontWeight={600} ml={1}>
                {languageOptions[language].label}
              </H6>
            </ItemWrapper>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
