export const shortenData = <T extends Record<string, any>>(
    oldData: T,
    newData: T,
  ): Partial<T> | undefined => {
    const nonEqualFields = Object.fromEntries(
      Object.entries(newData).filter(([key, val]) => val !== oldData[key]),
    );
  
    if (Object.keys(nonEqualFields).length === 0) {
      return undefined;
    }
  
    return nonEqualFields as Partial<T>;
  };
  