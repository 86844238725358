
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, Link } from "@mui/material";
import { authApi } from "api";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';


const AcceptTermsDialog: FC<{ open: boolean, setOpen: (open: boolean) => void }> = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loginWithJwtTokens } = useAuth();

    const initialValues = {
        hasAcceptedTC: false,
        hasAcceptedPP: false,
    };

    const validationSchema = Yup.object().shape({
        hasAcceptedTC: Yup.boolean().required().isTrue(t('common.forms.field.isRequired'))
        ,
        hasAcceptedPP: Yup.boolean().required()
            .isTrue(t('common.forms.field.isRequired')),
    }
    )

    const { handleBlur, values, touched, errors, setFieldValue, handleSubmit } = useFormik(
        {
            initialValues,
            validationSchema,
            onSubmit: async () => {
                const tokens = (await authApi.acceptTerms()).data;
                loginWithJwtTokens(tokens.access_token, tokens.refresh_token, navigate);
                props.setOpen(false);
            }
        }
    )

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setFieldValue(name, checked);
    };

    const termsOfServiceLink = <Link
        href="https://www.iubenda.com/termini-e-condizioni/32830054">{t('auth.register.termsAndPolicy.tc')}</Link>;
    const privacyPolicyLink = <Link href="https://www.iubenda.com/privacy-policy/32830054">Privacy Policy</Link>;

    const labelTC = (
        <>
            {t('auth.register.termsAndPolicy.start')} {termsOfServiceLink}.
        </>
    );
    const labelPP = (
        <>
            {t('auth.register.termsAndPolicy.start')} {privacyPolicyLink}.
        </>
    );

    return (
        <Dialog open={props.open} >
            <DialogTitle>
                {t('auth.register.termsAndPolicy.dialogTitle')}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                            {t('auth.register.termsAndPolicy.newTerms')}
                        </Grid>
                        <Grid item container xs={12} marginLeft={5} marginBottom={1} marginTop={2}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="hasAcceptedTC"
                                            onChange={handleCheckboxChange}
                                            onBlur={handleBlur}
                                            checked={values.hasAcceptedTC}
                                        />
                                    }
                                    label={labelTC}
                                    style={{ flexGrow: 1 }}
                                />
                            </Box>
                            {touched.hasAcceptedTC && errors.hasAcceptedTC && (
                                <Grid xs={12}>

                                    <FormHelperText error style={{
                                        marginLeft: 8,
                                        flexShrink: 0,
                                    }}>{errors.hasAcceptedTC}</FormHelperText>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item container xs={12} marginLeft={5} marginBottom={2}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="hasAcceptedPP"
                                            onChange={handleCheckboxChange}
                                            onBlur={handleBlur}
                                            checked={values.hasAcceptedPP}
                                        />
                                    }
                                    label={labelPP}
                                    style={{ flexGrow: 1 }}
                                />
                            </Box>
                            <Grid xs={12}>
                                {touched.hasAcceptedPP && errors.hasAcceptedPP && (
                                    <FormHelperText error
                                        style={{ marginLeft: 8, flexShrink: 0 }}>{errors.hasAcceptedPP}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained">{t('confirm')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog >

    )
}

export default AcceptTermsDialog;