import { Button, Grid, styled } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { FC } from "react";
import { useTranslation } from "react-i18next";


interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    date: Date;
    setDate: (date: Date) => void;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const WirePaymentsReportDatePicker: FC<ModalProps> = ({ open, onClose, onSubmit, date, setDate }) => {

    const { t } = useTranslation();

    return (
        <StyledAppModal
            open={open}
            handleClose={() => { onClose() }}
        >
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item xs={12}>
                    <H2> {t('report.selectDate')} </H2>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={date}
                        onChange={(value) => {
                            if (value && !isNaN(+value))
                                setDate(value);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => { onClose() }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth size='small' onClick={() => {
                        onClose();
                        onSubmit();
                    }} variant='contained'>
                        {t('report.downloadReport')}
                    </Button>
                </Grid>
            </Grid>

        </StyledAppModal>
    )

}

export default WirePaymentsReportDatePicker;