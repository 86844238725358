import { Grid } from '@mui/material';
import AppTextField from 'components/input-fields/AppTextField';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import TabBase, { TabResourceProps } from './TabBase';
import * as Yup from 'yup';
export interface BankFormData {
  bankName: string;
  iban: string;
  bicSwift: string;
}

const BankingInformation: FC<TabResourceProps<BankFormData>> = ({
  data,
  onSubmit,
  isProfileCompletition = false,
}) => {
  const { t } = useTranslation();
  const [isViewing, setIsViewing] = useState(!!data && !isProfileCompletition);

  const initialValues: BankFormData = {
    bankName: data?.bankName ?? '',
    iban: data?.iban ?? '',
    bicSwift: data?.bicSwift ?? ''
  };

  const validationSchema = Yup.object({
   /* bankName: Yup.string().required(
      t('common.forms.field.required', { field: t('employees.bank.bankName') })
    ), */
    iban: !isProfileCompletition
      ? Yup.string().nullable()
      : Yup.string().required(
          t('common.forms.field.required', { field: t('employees.bank.iban') })
        ),
  /*  bicSwift: Yup.string().required(
      t('common.forms.field.required', { field: t('employees.bank.bicswift') })
    ), */
  });
  const formikSubmit = (values: BankFormData) => {
    onSubmit(values); 
};

  const { values, handleBlur, handleChange, touched, errors, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit:formikSubmit,
    });

  return (
    <TabBase
      title={t('employees.bank.title')}
      setIsViewing={setIsViewing}
      isViewing={isViewing}
      handleSubmit={handleSubmit}
      isProfileCompletition={isProfileCompletition}
    >
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='bankName'
            label={t('employees.bank.bankName')}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.bankName}
            helperText={touched.bankName && (errors.bankName as string)}
            error={Boolean(touched.bankName && (errors.bankName as string))}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='iban'
            label={t('employees.bank.iban')}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.iban}
            helperText={touched.iban && (errors.iban as string)}
            error={Boolean(touched.iban && (errors.iban as string))}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            fullWidth
            disabled={isViewing}
            name='bicSwift'
            label={t('employees.bank.bicswift')}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.bicSwift}
            helperText={touched.bicSwift && (errors.bicSwift as string)}
            error={Boolean(touched.bicSwift && (errors.bicSwift as string))}
          />
        </Grid>
      </Grid>
    </TabBase>
  );
};

export default BankingInformation;
