import { SvgIcon, type SvgIconProps } from '@mui/material';
import React from 'react';

const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path d='M19,4H18V2.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V4H8V2.5A.5.5,0,0,0,7.5,2h-1a.5.5,0,0,0-.5.5V4H5A2,2,0,0,0,3,6V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V6A2,2,0,0,0,19,4Zm0,15H5V8H19Z' />
    </SvgIcon>
  );
};

export default Calendar;
