import { Box, IconButton, styled } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import Scrollbar from 'components/ScrollBar';
import LayoutDrawer from 'layouts/layout-parts/LayoutDrawer';
import { useEffect, useState, type FC } from 'react';
import MultiLevelMenu from './MultiLevelMenu';
import CloseMenuIcon from '@mui/icons-material/ChevronLeft';
import { TenantDisplay, TenantLabel, TenantName } from 'components/TenantDisplay';
import jwtDecode from 'jwt-decode';
import { authApi } from 'api';
import { DecodedToken } from 'contexts/JWTAuth';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

const TOP_HEADER_AREA = 70;

const NavWrapper = styled(Box)<{ compact: any }>(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: '100%',
}));

const StyledLogo = styled(Box)(() => ({
  paddingLeft: 8,
  fontWeight: 700,
  fontSize: 20,
}));

interface MobileSidebarProps {
  sidebarCompact: boolean;
  showMobileSideBar: boolean;
  setShowMobileSideBar: () => void;
}

const MobileSidebar: FC<MobileSidebarProps> = (props) => {
  const { sidebarCompact, showMobileSideBar, setShowMobileSideBar } = props;
  const [currentTenant, setCurrentTenant] = useState('');
  const [accessToMultipleTenants, setAccessToMultipleTenants] = useState<Boolean>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decoded = jwtDecode<DecodedToken>(accessToken);
      setCurrentTenant(decoded.tenant ?? "");
      authApi.tenants()
        .then(({ data }) => {
          setAccessToMultipleTenants(data.length > 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const handleNavigate = () => {
    navigate('/select-tenant');
  };  
  const tenantNameDisplay = currentTenant.charAt(0).toUpperCase() + currentTenant.slice(1);


  return (
    <LayoutDrawer open={showMobileSideBar} onClose={setShowMobileSideBar}>
      <Box p={2} maxHeight={TOP_HEADER_AREA}>
        <FlexBox ml={1.5} alignItems={'center'}>
          <CloseMenuIcon onClick={setShowMobileSideBar}/>
          <img src='/static/logo/logo_onestaff_new.png' alt='logo' width={50} />
          <StyledLogo>OneStaff</StyledLogo>
        </FlexBox>
      </Box>
      <FlexBox flexDirection="column" pt={1} pr={2} pl={4} pb={2} height={TOP_HEADER_AREA}>
        {( currentTenant ) && (
          <TenantDisplay>
            <TenantLabel>{t("mt.currentTenant")}</TenantLabel>
            <TenantName>{tenantNameDisplay}</TenantName>
            <IconButton onClick={handleNavigate} size="small">
              {accessToMultipleTenants && (<EditIcon />)}
            </IconButton>
          </TenantDisplay>
        )}
      </FlexBox>

      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: 'hidden',
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`,
        }}
      >
        <NavWrapper compact={sidebarCompact}>
          <MultiLevelMenu sidebarCompact={false} />
        </NavWrapper>
      </Scrollbar>
    </LayoutDrawer>
  );
};

export default MobileSidebar;
