import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAgreements = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='agreements_svg__b'>
        <path fill='none' d='M0 0h110.244v88H0z' data-name='Rettangolo 2' />
      </clipPath>
      <clipPath id='agreements_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#agreements_svg__a)' data-name='5479F7 - accordi'>
        
      <g data-name='Raggruppa 4'>
        <g
          fill='#fff'
          clipPath='url(#agreements_svg__b)'
          data-name='Raggruppa 3'
          transform='translate(32.378 40)'
        >
          <path
            d='M54.552 87.931 6.144 88C1.783 86.981-.114 84.249 0 79.785c.148-5.546.014-11.1.042-16.649.027-5.4 2.638-8.824 7.912-8.138 9.657 1.256 16.423-2.439 22.135-9.724 2.706-3.452 6.709-4.984 11.248-4.951 7.468.054 14.941-.169 22.405 0 7.989.183 12.981 4.687 13.4 12.594.178 3.331 1.631 2.638 3.47 2.228 4.98-1.11 9.929-2.369 14.928-3.385 7.86-1.6 12.831 1.752 14.693 9.72.133 6.367-3.256 9.848-8.871 12.571-13.749 6.668-28.557 9.664-43.163 13.409a14.8 14.8 0 0 1-3.655.468m-13.671-7.294A75.5 75.5 0 0 0 64.3 78.208c11.906-2.961 23.629-6.306 34.759-11.536 2.335-1.1 4.193-2.491 3.106-5.324-.944-2.46-2.992-2.935-5.439-2.153-1.385.442-2.814.762-4.238 1.064-13.105 2.778-25.974 7.065-39.7 5.831-2.767-.249-5.2-.54-5.136-3.854.062-3.01 2.3-3.641 4.96-3.563 3.514.1 7.036.12 10.549 0 4.06-.139 6.724-2.475 6.365-5.669-.416-3.711-2.88-5.088-6.446-5.061q-10.55.079-21.1 0a9.6 9.6 0 0 0-6.348 2.5c-6.994 5.663-9.307 11.859-9.94 21.9a19 19 0 0 0 .008 3.5c.518 3.953.378 4.917 4.644 4.892 3.492-.021 7.024-.085 10.54-.085M7.592 71.417c0 1.6.007 3.207-.037 4.789-.1 3.646.927 4.695 4.551 4.523 6.264-.3 6.274-.071 6.275-6.226 0-2.47.054-5.03.065-7.4.018-4.281-1.266-5.015-5.034-4.825-5.808.292-5.819.075-5.82 6.087v3.051'
            data-name='Tracciato 3'
          />
          <path
            d='M94.662 44.1c-7.532.152-12.306-2.812-15.5-8.216a4.48 4.48 0 0 0-4.312-2.642c-8.7-.313-14.789-5.935-16.043-14.413a16.574 16.574 0 0 1 30.868-10.39c1.039 1.8 1.828 2.555 4.165 2.633 9.883.327 16.59 7.7 16.277 17.341-.292 8.967-7.368 15.687-15.455 15.687m7.83-16.568a8.984 8.984 0 0 0-8.93-8.888 9.165 9.165 0 0 0-9.027 9.3c.158 4.686 4.619 8.953 9.209 8.807a9.335 9.335 0 0 0 8.749-9.219M75.336 7.861a8.906 8.906 0 0 0-9.123 7.97c-.413 4.021 2.759 8.616 6.762 9.529 2.2.5 3.2.441 4.709-2.614a53 53 0 0 1 3.912-6.641c2.09-3.27 1.633-3.972-.25-5.864a8.54 8.54 0 0 0-6.01-2.38'
            data-name='Tracciato 4'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAgreements;
