import { Box, Card, Grid, useTheme } from '@mui/material';
import LongCard from 'page-sections/dashboards/CRM/LongCard';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { Ill, Longevity, Permission, Vacation } from 'icons/report/reactIcons';
import FlexBox from 'components/flexbox/FlexBox';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { H2, H6 } from 'components/Typography';

interface DataProps {
  stats: {
    unpaidLeave: number,
    ill: number,
    vacation: number,
    seniority: number
  },
  t: TFunction;
}

const EmployeeStats: FC<DataProps> = (props) => {
  const { t } = props;
  const { stats } = props;
  const statsList = [
    {
      id: 1,
      amount: stats.unpaidLeave,
      Icon: Permission,
      title: t('employees.report.stats.unpaidLeave'),
      color: '#5479F7',
    },
    {
      id: 2,
      amount: stats.ill,
      Icon: Ill,
      title: t('employees.report.stats.ill'),
      color: '#E5402A',
    },
    {
      id: 3,
      amount: stats.vacation,
      Icon: Vacation,
      title: t('employees.report.stats.holidays'),
      color: '#FF9C12',
    },
    {
      id: 4,
      amount: stats.seniority,
      Icon: Longevity,
      title: t('employees.report.stats.seniority'),
      color: '#FADF70',
    },
  ];
  const theme = useTheme();
  const seasons = statsList.at(3);
  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={8} xs={12}>
          {<LongCard list={statsList.slice(0, 3)} />}
        </Grid>
        {seasons &&
          <Grid item md={4} xs={12}>
            <Card sx={{ padding: 3, height: '100%' }}>
              <FlexBox
                key={seasons.id}
                gap={1.5}
                alignItems='center'
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                    marginBottom: '1.5rem !important',
                    '&:last-of-type': { marginBottom: '0 !important' },
                  },
                }}
              >
                <FlexRowAlign
                  flexShrink={0}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 2,
                    backgroundColor: seasons.color,
                  }}
                >
                  <seasons.Icon />
                </FlexRowAlign>

                <Box>
                  <H6 lineHeight={1.5} mb={0.4}>
                    {seasons.title}
                  </H6>
                  <H2 color={seasons.color} lineHeight={1}>
                    {seasons.amount}
                  </H2>
                </Box>
              </FlexBox>
            </Card>

          </Grid>}
      </Grid>

    </>
  );
};

export default EmployeeStats;
