import { Button, Grid, styled } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEndDateValid, isValidDate } from "utils/dateValidator";
import * as Yup from 'yup';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (startDate: Date, endDate: Date) => Promise<void>;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 700,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const CalendarReportDatesPicker: FC<ModalProps> = ({ open, onClose, onSubmit }) => {

    const { t } = useTranslation();


    const validationSchema = Yup.object({
        startDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('is-valid-date', 'Invalid date', (value) => {
                return isValidDate(value);
            }),
        endDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('isValidDate', 'Invalid date', (value) => {
                return isValidDate(value);
            })
            .test('isEndDateValid', t('employees.agreements.validation.endDateTime'), function (value) {
                return isEndDateValid(value, this.parent.startDate);
            }),
    });

    const initialValues = {
        startDate: new Date(),
        endDate: new Date(),
    }



    const { values, errors, setFieldValue, handleSubmit } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            values.startDate.setUTCHours(0, 0, 0);
            values.endDate.setUTCHours(0, 0, 0);
            await onSubmit(values.startDate, values.endDate);
            onClose();
        },
    });


    // reset dates on close and on open modal
    useEffect(() => {
        setFieldValue('startDate', new Date());
        setFieldValue('endDate', new Date());
    }, [open])

    return (
        <StyledAppModal
            open={open}
            handleClose={() => { onClose() }}
        >
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item xs={12}>
                    <H2> {t('report.selectDate')} </H2>
                </Grid>
                <Grid item xs={6} marginBottom={2}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={values.startDate}
                        label={t('season.startDate')}
                        onChange={(value) => {
                            if (value && !isNaN(+value)){
                                setFieldValue('startDate', value, true);
                            }
                        }}
                        slotProps={{
                            textField: {
                                error: !!errors.startDate,
                                helperText: (errors.startDate as any)
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={6} marginBottom={2}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={values.endDate}
                        label={t('season.endDate')}
                        onChange={(value) => {
                            if (value && !isNaN(+value)){
                                setFieldValue('endDate', value, true);
                            }
                        }}
                        slotProps={{
                            textField: {
                                error: !!errors.endDate,
                                helperText: (errors.endDate as any)
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => { onClose() }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth size='small' onClick={() => {
                        handleSubmit();
                    }} variant='contained'>
                        {t('report.downloadReport')}
                    </Button>
                </Grid>
            </Grid>

        </StyledAppModal>
    )

}

export default CalendarReportDatesPicker;