import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomToast.css';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { OverridableStringUnion } from '@mui/types';
import { SvgIconPropsColorOverrides } from '@mui/material';

interface CustomToastProps {
  message: string;
  onClose: () => void;
  duration: number;
  icon?: any;
  color?: OverridableStringUnion<"inherit" | "disabled" | "action" | "error" | "primary" | "secondary" | "info" | "success" | "warning", SvgIconPropsColorOverrides> 
}

const CustomToast: React.FC<CustomToastProps> = ({ message, onClose, duration, icon, color }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => { clearTimeout(timer); }
  }, [onClose]);

  const handleToastClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div className={`custom-toast ${isVisible ? 'visible' : 'hidden'}`}>
      <p>{message}</p>
      {icon ?
        React.cloneElement(icon, { color: color ?? 'success', })
        :
        <HighlightOffRoundedIcon onClick={handleToastClose} color={color ?? 'error'} style={{ cursor: 'pointer' }} />
      }
    </div>
  );
};

CustomToast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired
};

export default CustomToast;