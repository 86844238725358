import { Send } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Stack,
  styled,
} from '@mui/material';
import { employeeNotesApi } from 'api';
import { NoteEntity } from 'api/generated';
import FlexBetween from 'components/flexbox/FlexBetween';
import Scrollbar from 'components/ScrollBar';
import NotesBubble from 'page-sections/notes/NotesBubble';
import React, { useEffect, type FC, useState, ChangeEvent, Dispatch, SetStateAction } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Small } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { checkToken } from 'utils/checkToken';
import { decryptData, encryptData } from 'utils/encryptionDecryptionAgreements';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import TabBase from 'page-sections/employees/TabBase';

interface UpdateData {
  text?: string;
  text_encrypted?: string;
};


// styled components
const StyledIconButton = styled(IconButton)(() => ({ padding: 0 }));

const EmployeeNotes: FC<{employeeId: number, onSubmit: (data: {}, setIsLoading?: Dispatch<SetStateAction<boolean>>, switchTab?: boolean) => Promise<any>; }> = ({ onSubmit, employeeId }) => {
  const [notesData, setNotesData] = useState<readonly NoteEntity[]>([]);
  const [note, setNote] = useState('');
  const notesEnd = React.useRef<HTMLInputElement>(null);
  const [noteActionId, setNoteActionId] = useState(-1);
  const [disableHover, setDisableHover] = useState(false);
  const { t } = useTranslation();
  const { encryptionKey } = useEncryption();
  const [isNoteEncrypted, setIsNoteEncrypted] = useState(false);

  // Exit edit mode by clicking 'Esc'
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetEditModeNote();
    }
  };

  const setEncrypted = (id: number, text: string, textEncrypted: string) => {
    employeeNotesApi.update(id, {
      text, text_encrypted: textEncrypted,
    }).then(() => {
      fetchNotesData();
    });
  };


  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const createNote = () => {
    if (note.trim().length > 0) {
      const author = checkToken();
      employeeNotesApi.create({
        employeeId,
        authorId: author,
        text: note,
        text_encrypted: '',
      }).then(() => {
        fetchNotesData();
        setDisableHover(true);
      });
      setNote('');
    }
  };

  const editNote = (note: NoteEntity) => {
    setNoteActionId(note.id);
    if (note.text_encrypted) {
      setNote(decryptData(note.text_encrypted, encryptionKey));
      setIsNoteEncrypted(true);
    } else {
      setNote(note.text);
      setIsNoteEncrypted(false);
    }
    const inputField = document.getElementById('noteInput');
    if (inputField) {
      inputField.focus();
    }
  };

  const updateNote = () => {

    if (note.trim().length > 0) {
      const author = checkToken();
      const updateData: UpdateData = {};
      if (encryptionKey && isNoteEncrypted) {
        updateData.text_encrypted = encryptData(note, encryptionKey);
      } else {
        updateData.text = note;
      }
      employeeNotesApi.update(noteActionId, { ...updateData, authorId: author })
        .then(() => {
          fetchNotesData();
          setNote('');
          setNoteActionId(-1);
        });
    }
  };

  const resetEditModeNote = () => {
    setNote('');
    setNoteActionId(-1);
  };
  useEffect(() => {
    fetchNotesData();

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [employeeId]);

  useEffect(() => {
    notesEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [notesData]);

  const fetchNotesData = () => {
    employeeNotesApi.findAll(employeeId).then(({ data }) => {
      data.sort((firstElement, secondElement) => new Date(firstElement.createdAt).valueOf() - new Date(secondElement.createdAt).valueOf());
      setNotesData([...data]);
    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <TabBase
      title={t('employees.notes.title')}
      setIsViewing={() => { }}
      isViewing={false}
      handleSubmit={() => { onSubmit({}) }}
    >
      <Grid container justifyContent="center">
        <Grid item xl={12} md={12} xs={12}>
          <Box
            position="relative"
            onMouseMove={() => {
              if (disableHover) {
                setDisableHover(false);
              }
            }}
          >
            {noteActionId > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.2)',
                  backdropFilter: 'blur(1px)',
                  zIndex: 1,
                }}
              />
            )}

            <Scrollbar style={{ maxHeight: 562 }}>
              <Stack spacing={4} px={3} py={2}>
                {notesData.map((note) => (
                  <div ref={notesEnd} key={note.id}>
                    <NotesBubble
                      key={encryptionKey}
                      noteInstance={note}
                      fetchNotesDataCallback={fetchNotesData}
                      editNoteCallback={editNote}
                      disableHover={disableHover}
                      setEncryptedCallback={setEncrypted}
                    />
                  </div>
                ))}
              </Stack>
            </Scrollbar>

          </Box>

          {noteActionId > 0 && (
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(92, 84, 112, 0.3)' }}>
              <KeyboardArrowLeftIcon fontSize="small"
                onClick={resetEditModeNote}
              />
              <Small fontWeight={600} fontSize={10}>{t('notes.edit')}</Small>
            </div>
          )}


          <Divider />

          <FlexBetween padding={2} gap={2}>

            <InputBase
              id="noteInput"
              fullWidth
              multiline
              placeholder={t('notes.inputPlaceholder')}
              sx={{ fontSize: 12, fontWeight: 500 }}
              value={note}
              onChange={handleNoteChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  noteActionId < 0 ? createNote() : updateNote();
                }

              }}
            />

            <StyledIconButton
              onClick={noteActionId < 0 ? createNote : updateNote}
              disabled={note.trim().length === 0 || noteActionId >= 0}
            >
              <Send sx={{ color: (note.trim().length === 0) ? 'gray' : 'primary.main' }} />
            </StyledIconButton>
          </FlexBetween>
        </Grid>
      </Grid>
    </TabBase>
  );
};

export default EmployeeNotes;
