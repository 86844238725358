import BasicInformation from './BasicInformation';
import BankingInformation from './BankingInformation';
import Contract from './Contract';
import IDDocument from './IDDocuments';
import Housing from './Housing';
import EmployeeNotes from '../../pages/employees/notes';
import AdditionalInformation from './AdditionalInformation';

const Tabs = {
  BasicInformation,
  BankingInformation,
  AdditionalInformation,
  Contract,
  IDDocument,
  EmployeeNotes,
  Housing
};
export default Tabs;
