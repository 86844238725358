import { CreateAdditionalInformationDtoFoodRequirmentsEnum } from "api/generated";
import { TFunction } from "i18next";

export const ConvertAllergies = (allergy: CreateAdditionalInformationDtoFoodRequirmentsEnum, t: TFunction<"translation", undefined>) => {
    switch(allergy) {
        case "GLUTEN":
            return t('allergies.gluten');
        case "DAIRY":
            return t('allergies.dairy');
        case "EGG":
            return t('allergies.egg');
        case "FISH":
            return t('allergies.fish');
        case "SOY":
            return t('allergies.soy');
        case "PEANUT":
            return t('allergies.peanut');
        case "VEGETERIAN":
            return t('allergies.vegeterian');
        case "VEGAN":
            return t('allergies.vegan');
        case "HALAL":
            return 'Halal'
        case "KOSHER":
            return 'Kosher';
        case "CRUSTACEAN":
            return t('allergies.crustacean');
        case "NUT":
            return t('allergies.nut');
        case "CELERY":
            return t('allergies.celery');
        case "MUSTARD":
            return t('allergies.mustard');
        case "SESAME":
            return t('allergies.sesame');
        case "CLAM":
            return t('allergies.clam');
        case "LUPINE":
            return t('allergies.lupine');
        case "SOLFITE":
            return t('allergies.solfite');
    }
}