import { Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { DepartmentEntity, SectionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H3, H4, H6, Small } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import AppTextField from "components/input-fields/AppTextField";
import FlexBox from "components/flexbox/FlexBox";
import { useFormik } from "formik";
import { depositsApi, turnoverApi } from "api";
import { useSeason } from "contexts/SeasonContext";
import CurrencyInput from "react-currency-input-field";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    sections?: SectionEntity[];
    departments?: DepartmentEntity[];
    addMode: boolean;
    defaultDate: Date;
    fetchTableData: () => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 500,
    maxHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '80%' : 700,
    overflowY: "auto",
}));

const AddDepositModal: FC<ModalProps> = (
    {
        open,
        onClose,
        sections,
        departments,
        addMode,
        defaultDate,
        fetchTableData,
    }
) => {

    const theme = useTheme()
    const { seasonId } = useSeason();
    const [date, setDate] = useState<Date | null>(addMode ? new Date() : defaultDate);
    const [isDateValid, setIsDateValid] = useState(true);
    const [alreadyExistsDeposits, setAlreadyExistsDeposits] = useState(false);

    const [deposits, setDeposits] = useState<Array<
        {
            value: number,
            stringValue?: string,
            paymentSection: SectionEntity,
            description: string,
            turnoverSum: number,
            depositsSum: number,
            depName: string
        }
    >>([]);


    const fetchData = async () => {
        if (sections && date) {
            const temp: Array<{
                value: number,
                stringValue?: string,
                paymentSection: SectionEntity,
                description: string,
                turnoverSum: number,
                depositsSum: number,
                depName: string
            }> = [];

            for (const section of sections) {
                const deposit = (await depositsApi.findUnique(section.id, date.getTime())).data;
                if (deposit) setAlreadyExistsDeposits(true);
                const turnoverSum = (await turnoverApi.getDepositableSum(seasonId, section.id, +date)).data;
                const depositsSum = addMode || !deposit ?
                    (await depositsApi.getSum(section.id, +date)).data :
                    (await depositsApi.getSum(section.id, +date)).data - deposit.value;
                const value = (addMode || !deposit) ?
                    (turnoverSum - depositsSum) >= 0 ? (turnoverSum - depositsSum) : 0
                    : deposit.value;
                const description = addMode || !deposit ? "" : deposit.description;
                const depName = departments?.find((dep) => {
                    return dep.id === section.departmentId
                })?.name || '\u00A0';
                temp.push({
                    value,
                    stringValue: String(value),
                    paymentSection: section,
                    description,
                    turnoverSum,
                    depositsSum,
                    depName
                })
            }
            setDeposits(temp);
        }
    }

    useEffect(() => {
        setAlreadyExistsDeposits(false);
        fetchData();
    }, [sections, date])

    useEffect(() => {
        setDate(addMode ? new Date() : defaultDate)
    }, [addMode, defaultDate, open])

    const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: deposits,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (date) {
                for (let i = 0; i < values.length; i++) {
                    if ((addMode && values[i].value !== 0) || !addMode) {
                        depositsApi.findUnique(
                            values[i].paymentSection.id,
                            date.getTime()
                        ).then(({ data }) => {
                            if (data) {
                                if (addMode) {
                                    depositsApi.update(
                                        data.id,
                                        {
                                            value: values[i].value + data.value,
                                            description: values[i].description
                                        }
                                    ).then(() => { fetchTableData(); })
                                } else {
                                    if (!addMode && values[i].value === 0) {
                                        depositsApi.remove(data.id).then(() => { fetchTableData(); });
                                    } else if ((!addMode && values[i].value !== deposits[i].value)) {
                                        depositsApi.update(
                                            data.id,
                                            {
                                                value: values[i].value,
                                                description: values[i].description
                                            }
                                        ).then(() => { fetchTableData(); })
                                    }
                                }
                            } else {
                                if (values[i].value !== 0)
                                    depositsApi.create(
                                        seasonId,
                                        {
                                            date: date.toISOString(),
                                            value: values[i].value,
                                            description: values[i].description,
                                            paymentSectionId: values[i].paymentSection.id
                                        }
                                    ).then(() => { fetchTableData(); })
                            }
                        })
                    }
                }
                resetForm();
                onClose();
                setAlreadyExistsDeposits(false);
            }
        },
    })

    const moneyFormatter = Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        currencySign: 'standard',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    });

    return (
        <StyledAppModal open={open} handleClose={() => {
            setAlreadyExistsDeposits(false); resetForm(); onClose(); setDate(new Date());
        }} alignContent={"center"} alignItems={"center"}>

            <H2 mb={1}>{addMode ? t('deposit.add') : t('deposit.update')}</H2>

            <CalendarInput
                sx={{ marginBottom: 2, marginTop: 2 }}
                format='dd.MM.yyyy'
                label={t('deposit.date')}
                value={date}
                onChange={(newValue) => {
                    if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                        setDate(newValue);
                        setIsDateValid(true);
                    }
                    else
                        setIsDateValid(false);
                }}
                slotProps={{
                    textField: {
                        helperText: !isDateValid && t('date.error'),
                        error: !isDateValid
                    },
                }}
            />

            <Grid container>
                {
                    values && values.length > 0 ? (<>
                        {values.map((el, index) => {
                            return <Grid item padding={1} xs={12} md={6}>
                                <Grid container marginTop={1} marginBottom={3}>
                                    <Grid item xs={12}>
                                        <H3 textOverflow={'ellipsis'}>
                                            {el.depName}
                                        </H3>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Small>{el.paymentSection.name}</Small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CurrencyInput
                                            style={{
                                                width: '100%',
                                                margin: 1,
                                                borderRadius: 4,
                                                position: "relative",
                                                backgroundColor: theme.palette.background.paper,
                                                border: el.value <= el.turnoverSum - el.depositsSum ?
                                                    `1px solid ${theme.palette.primary.light}` :
                                                    `1px solid  ${theme.palette.warning.main}`,
                                                fontSize: 16,
                                                padding: "10px 12px",
                                                transition: theme.transitions.create(["border-color", "box-shadow"]),
                                                borderColor: el.value <= el.turnoverSum - el.depositsSum ?
                                                    theme.palette.primary.main :
                                                    theme.palette.warning.main,
                                                boxShadow: el.value <= el.turnoverSum - el.depositsSum ?
                                                    `0 0 0 2px ${theme.palette.primary.light}` :
                                                    `0 0 0 2px ${theme.palette.warning.main}`,
                                                fontWeight: 500,
                                                fontFamily: "Montserrat"
                                            }}
                                            type="text"
                                            value={el.stringValue}
                                            decimalsLimit={2}
                                            onValueChange={(value, _name, values) => {
                                                setFieldValue(`[${index}].stringValue`, value);
                                                setFieldValue(`[${index}].value`, values?.float);
                                            }}
                                            intlConfig={{
                                                locale: 'de-DE',
                                                currency: 'EUR',
                                            }}
                                            step={1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginBottom={1}>
                                        <Small color={el.value <= el.turnoverSum - el.depositsSum ? "" : theme.palette.warning.main}>
                                            {t('deposit.toBePaid')} {moneyFormatter.format((el.turnoverSum - el.depositsSum) >= 0 ? el.turnoverSum - el.depositsSum : 0)}
                                        </Small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            fullWidth
                                            size="small"
                                            maxRows={1}
                                            placeholder={t('deposit.description')}
                                            inputProps={{
                                                min: 0,
                                                step: 0.01,
                                                style: { textAlign: 'right' },
                                            }}
                                            label={t('deposit.description')}
                                            value={el.description}
                                            onChange={(e) => {
                                                setFieldValue(`[${index}].description`, e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                        {
                            addMode && alreadyExistsDeposits && <Grid item xs={12} marginBottom={1}>
                                <H6 color={theme.palette.warning.main}> {t('deposit.alreadyExists')} </H6>
                            </Grid>
                        }
                        {
                            <Grid item xs={12} marginBottom={1}>
                                <H6 color={theme.palette.warning.main}> {t('deposit.overflow')} </H6>
                            </Grid>
                        }
                        <Grid item padding={1} xs={12} sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', justifySelf: 'center' }}>
                            <H4>
                                {t('deposit.total')} {values.reduce((acc, el) => acc + el.value, 0)}€
                            </H4>
                        </Grid>
                    </>) : <>
                        {t('sections.empty')}
                    </>
                }
            </Grid>

            <FlexBox justifyContent='flex-end' gap={2} marginTop={3}>
                <Button fullWidth variant="contained" color="error" onClick={() => { setAlreadyExistsDeposits(false); resetForm(); onClose(); setDate(new Date()); }}>
                    {t('cancel')}
                </Button>

                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => { handleSubmit() }}
                >
                    {addMode ? t('add') : t('save')}
                </Button>
            </FlexBox>
        </StyledAppModal >
    )
}

export default AddDepositModal;