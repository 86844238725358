import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPermission = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='permission_svg__b'>
        <path fill='none' d='M0 0h101v91.351H0z' data-name='Rettangolo 7' />
      </clipPath>
      <clipPath id='permission_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#permission_svg__a)' data-name='5479F7 - permessi'>
        
      <g data-name='Raggruppa 14'>
        <g
          clipPath='url(#permission_svg__b)'
          data-name='Raggruppa 13'
          transform='translate(37 44.324)'
        >
          <path
            d='M86.38 19.351c-9.346-4.671-21.754-7.142-35.88-7.142s-26.534 2.471-35.88 7.142S0 30.595 0 37.459v21.643C0 65.963 5.328 72.56 14.62 77.21s21.755 7.142 35.88 7.142 26.534-2.471 35.88-7.142S101 65.963 101 59.102V37.459c0-6.864-5.328-13.458-14.62-18.108m-35.88.073c28.244 0 43.286 10.475 43.286 18.036S78.744 55.495 50.5 55.495 7.214 45.024 7.214 37.459 22.256 19.424 50.5 19.424m-3.607 43.22v14.429a81.6 81.6 0 0 1-21.643-3.377V59.574a91.6 91.6 0 0 0 21.643 3.07m7.214 0a91.6 91.6 0 0 0 21.643-3.07v14.119a81.6 81.6 0 0 1-21.643 3.376ZM7.214 59.102v-8.329a37 37 0 0 0 7.405 4.794 57 57 0 0 0 3.416 1.547v13.71C10.9 67.29 7.214 62.839 7.214 59.102m75.75 11.722v-13.71a53 53 0 0 0 3.416-1.547 37 37 0 0 0 7.405-4.794v8.329c0 3.737-3.683 8.188-10.821 11.723'
            data-name='Tracciato 10'
          />
          <path
            d='M94.839 83.551a4.571 4.571 0 0 1-6.464 6.464L50.5 52.133 12.629 90.015a4.571 4.571 0 0 1-6.464-6.464l37.879-37.875L6.165 7.801a4.57 4.57 0 0 1 6.464-6.46L50.5 39.22 88.375 1.341a4.57 4.57 0 0 1 6.464 6.46L56.961 45.676Z'
            data-name='Tracciato 11'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPermission;
