import { SvgIcon, type SvgIconProps } from '@mui/material';

const Education = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d='M22,9.92V20.5a.5.5,0,0,1-.5.5h-.75a.5.5,0,0,1-.5-.5V11.6l-7.14,5.14a1.49,1.49,0,0,1-.84.26h-.54a1.49,1.49,0,0,1-.84-.26L2.22,10.49A.49.49,0,0,1,2,10.08V9.92a.49.49,0,0,1,.22-.41l8.67-6.25A1.49,1.49,0,0,1,11.73,3h.54a1.49,1.49,0,0,1,.84.26l8.67,6.25A.49.49,0,0,1,22,9.92Zm-9.73,8.83h-.54a3.33,3.33,0,0,1-1.87-.59L6,15.37V17c0,2.21,2.69,4,6,4s6-1.79,6-4V15.37l-3.9,2.81A3.25,3.25,0,0,1,12.27,18.75Z' />
    </SvgIcon>
  );
};

export default Education;
