import {
    Box,
    Card,
    useTheme,
} from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { H2, H4, H6 } from 'components/Typography';
import { type FC } from 'react';

interface DataProps {
    id: number;
    title: string;
    color: string;
    text: string | number;
    fraction?: string;
    Icon: any;
    contentStyle?: React.CSSProperties;
}

const CustomCard: FC<DataProps> = (props) => {
    const { id, title, color, text, fraction, Icon } = props;
    const theme = useTheme();

    return (
        <Card sx={{ padding: 3, height: '100%', width: '100%', display:'flex' }}>
            <FlexBox
                key={id}
                gap={1.5}
                alignItems='center'
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        marginLeft: 0,
                        marginBottom: '1.5rem !important',
                        '&:last-of-type': { marginBottom: '0 !important' },
                    },
                }}
            >
                <FlexRowAlign
                    flexShrink={0}
                    sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 2,
                        backgroundColor: color,
                        display: 'flex'
                    }}
                >
                    <Icon sx={{ color: 'common.white', display: 'flex'}} ></Icon>
                </FlexRowAlign>

                <Box sx={props.contentStyle}>
                    <Box>
                        <H6 lineHeight={1.5} mb={0.4}>
                            {title}
                        </H6>
                        <H2 color={color} lineHeight={1} display={'block'}>
                            {text}
                            {fraction &&

                                <H4 display={'inline'} color={color} marginLeft={0.2} marginTop={1} lineHeight={0.5}>
                                    {fraction}
                                </H4>}

                        </H2>
                    </Box>

                </Box>
            </FlexBox>
        </Card>
    );
};

export default CustomCard;
