import { departmentsEmployeesApi } from "api";
import jwtDecode from "jwt-decode";


export const checkToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ employeeId: number }>(accessToken);
        return decodedToken.employeeId;
    }
    throw new Error("No valid token found!");
}

export const checkTokenRole = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ role: string }>(accessToken);
        return decodedToken.role;
    }
    return '';
    // throw new Error("No valid token found!");
}

export const checkSeasonalRole = async (seasonId: number) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ role: string }>(accessToken);
        if (decodedToken.role === 'DEPCHIEF') {
            return  (await departmentsEmployeesApi.isDepchief(seasonId)).data ? 'DEPCHIEF' : 'USER';
        }; 
        return decodedToken.role;
    }
    return '';
    // throw new Error("No valid token found!");
}

export const checkTokenTenant = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        const decodedToken = jwtDecode<{ tenant: string }>(accessToken);
        return decodedToken.tenant;
    }
    throw new Error("No valid token found!");
}