import { Autocomplete, Button, styled, useMediaQuery } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import AppTextField from "components/input-fields/AppTextField";
import { DepartmentEntity, SectionEntity } from "api/generated";
import FlexBox from "components/flexbox/FlexBox";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    setSelectedDepartment: (department: DepartmentEntity | undefined) => void;
    setSelectedSection: (section: SectionEntity | undefined) => void;
    departments?: DepartmentEntity[];
    selectedDepartment?: DepartmentEntity;
    sections?: SectionEntity[];
    selectedSection?: SectionEntity;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 450,
    maxHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '80%' : 700,
    overflowY: "auto",
}));

const SelectSectionModal: FC<ModalProps> = (
    {
        open,
        onClose,
        onConfirm,
        setSelectedDepartment,
        setSelectedSection,
        departments,
        selectedDepartment,
        sections,
        selectedSection
    }
) => {

    const [filteredSectionsByDepartment, setFilteredSectionsByDepartment] = useState<SectionEntity[]>([]);

    useEffect(() => {
        if (sections && selectedDepartment) {
            const temp = sections.filter((s) => { return s.departmentId === selectedDepartment.id });
            setFilteredSectionsByDepartment(temp);
            if (temp.length === 1) {
                setSelectedSection(temp[0]);
            } else {
                setSelectedSection(undefined);
            }            
        } else {
            setFilteredSectionsByDepartment([]);
        }
    }, [sections, selectedDepartment]);

    return (
        <StyledAppModal open={open} handleClose={() => { onClose() }} >
            <H2>{t('department.select')}</H2>
            <Autocomplete
                sx={{ marginTop: 2 }}
                value={selectedDepartment ?? null}
                options={departments && sections ? departments.filter((dep) => {
                    return sections?.find((sec) => {
                        return sec.departmentId === dep.id;
                    })
                }) : []}
                getOptionLabel={(department) => { return department.name }}
                renderInput={(params) => (
                    <AppTextField
                        {...params}
                        label={t('department.select')}
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
                onChange={(_event, value) => {
                    if (value && sections && departments) {
                        setSelectedDepartment(value);
                    }
                }
                }
            />
            <Autocomplete
                sx={{ marginTop: 2 }}
                value={selectedSection ?? null}
                options={filteredSectionsByDepartment ?? []}
                disabled={!selectedDepartment || (filteredSectionsByDepartment.length <= 1)}
                getOptionLabel={(section) => { return section.name }}
                renderInput={(params) => (
                    <AppTextField
                        {...params}
                        label={t('amountSection.select')}
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
                onChange={(_event, value) => {
                    if (value) {
                        setSelectedSection(value);
                    }
                }}
            />

            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => { onClose() }}>
                    {t('cancel')}
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    disabled={!selectedSection}
                    onClick={() => { onConfirm() }}>
                    {t('next')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default SelectSectionModal;