import React, { useMemo } from 'react';
import { Container, Typography, Paper, Grid, useTheme, useMediaQuery, styled, Toolbar } from '@mui/material';
import NoAccessImage from '../assets/images/no-access.svg';
import Logo from '../assets/images/logo_onestaff.png';
import { useTranslation } from 'react-i18next';
import LanguagePopover from 'layouts/layout-parts/popovers/LanguagePopover';
const NoAccessPage: React.FC = () => {
    const styledToolBarStyle = useMemo(() => ({
        '@media (min-width: 0px)': {
            paddingLeft: 0,
            paddingRight: 0,
            right: 20,
            top: 10,
            position: "fixed",
            minHeight: 'auto',
            marginBottom: -30,
            zIndex: 1500

        },
    }), []);

    const StyledToolBar = useMemo(() => styled(Toolbar)(styledToolBarStyle), [styledToolBarStyle]);


    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
            {mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '80px', height: 'auto', position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}
            <StyledToolBar>
                <LanguagePopover />
            </StyledToolBar>

            <Grid container spacing={2} sx={{ marginTop: 20, flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                    <img src={NoAccessImage} style={{ width: '100%', height: 'auto' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 1 : 2 }}>
                    <Paper elevation={3} sx={{ p: mobileBreakpoint ? 2 : 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: theme.typography.fontFamily, fontWeight: 'bold', fontSize: mobileBreakpoint ? '1.5rem' : '2rem' }}>
                            {t('na.title')}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: theme.typography.fontFamily, fontWeight: 'medium', fontSize: mobileBreakpoint ? '1rem' : '1.25rem' }}>
                            {t('na.subtitle')}
                        </Typography>
                        <Typography variant="body1" sx={{ fontFamily: theme.typography.fontFamily, fontSize: mobileBreakpoint ? '0.875rem' : '1rem', mb: 2 }}>
                            {t("na.description")}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily, fontSize: mobileBreakpoint ? '0.875rem' : '1rem' }}>
                            {t("na.contactText")} <a href="mailto:info@marcatech.it">info@marcatech.it</a>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            {!mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '120px', height: 'auto', position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}
        </Container>
    );
};

export default NoAccessPage;
