import { SvgIcon, type SvgIconProps } from '@mui/material';

const MoneyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d='M21,4H15.89a9.08,9.08,0,0,0-4,1l-.62.31A6.93,6.93,0,0,1,8.11,6H3A1,1,0,0,0,2,7V19a1,1,0,0,0,1,1H8.11a9.08,9.08,0,0,0,4-.95l.62-.31A6.93,6.93,0,0,1,15.89,18H21a1,1,0,0,0,1-1V5A1,1,0,0,0,21,4Zm-1,9a3,3,0,0,0-3,3H15.89a9.11,9.11,0,0,0-4,.95l-.62.31A7.06,7.06,0,0,1,8.11,18H7a3,3,0,0,0-3-3V11A3,3,0,0,0,7,8H8.11a9.11,9.11,0,0,0,4-1l.62-.31A7.06,7.06,0,0,1,15.89,6H17a3,3,0,0,0,3,3Zm-5-1a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z' />
    </SvgIcon>
  );
};

export default MoneyIcon;
