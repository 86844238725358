import React, { useEffect, useMemo, useState } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Paper, Grid, useTheme, useMediaQuery, styled, Toolbar } from '@mui/material';
import { authApi } from 'api';
import { useNavigate } from 'react-router-dom';
import TenantSelect from '../assets/images/multitenant_select.svg';
import Logo from '../assets/images/logo_onestaff.png';
import { useTranslation } from 'react-i18next';
import LanguagePopover from 'layouts/layout-parts/popovers/LanguagePopover';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import { useSeason } from 'contexts/SeasonContext';
import { useEncryption } from 'contexts/EncryptionKeyContext';

const SelectTenantPage: React.FC = () => {
    const [tenantData, setTenantData] = useState<string[]>([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const employeeContext = useEmployeeContext();
    const seasonContext = useSeason();
    const encryptionContext = useEncryption();
    const styledToolBarStyle = useMemo(() => ({
        '@media (min-width: 0px)': {
            paddingLeft: 0,
            paddingRight: 0,
            right: 20,
            top: 10,
            position: "fixed",
            minHeight: 'auto',
            marginBottom: -30,
            zIndex: 1500  

        },
    }), []);

    const StyledToolBar = useMemo(() => styled(Toolbar)(styledToolBarStyle), [styledToolBarStyle]);


    const fetchTableData = async () => {
        authApi.tenants()
            .then(({ data }) => {
                setTenantData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleTenantSelection = (tenant: string) => {
        authApi.selectTenant({ data: { tenant } })  // Assuming this is how you have set up your API call
            .then(({ data }) => {
                localStorage.clear();
                // Reset contexts
                employeeContext.setEmployeeId(-1);
                seasonContext.setSeasonId(0);
                seasonContext.setSeasons([]);
                encryptionContext.setEncryptionKey("");
                encryptionContext.setPopoverOpen(false);
                localStorage.setItem('accessToken', data.access_token);
                localStorage.setItem('refreshToken', data.refresh_token);

                navigate('/');  // Redirect to the dashboard using navigate
            })
            .catch((error) => {
                console.error('Error selecting tenant:', error);
                alert('Failed to select tenant: ' + error.message);
            });
    };

    useEffect(() => {
        fetchTableData();
    }, []);
    return (

        <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
            {mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '80px', height: 'auto', position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}
            <StyledToolBar>
                <LanguagePopover />
            </StyledToolBar>
            <Grid container spacing={2} sx={{ marginTop: 20, flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                    <img src={TenantSelect} alt="Select Tenant" style={{ width: '100%', height: 'auto' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 1 : 2 }}>
                    <Paper elevation={3} sx={{ p: mobileBreakpoint ? 2 : 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: theme.typography.fontFamily, fontWeight: 'bold', fontSize: mobileBreakpoint ? '1.5rem' : '2rem' }}>
                            {t('mt.title')}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: theme.typography.fontFamily, fontWeight: 'medium', fontSize: mobileBreakpoint ? '1rem' : '1.25rem' }}>
                            {t('mt.subtitle')}
                        </Typography>
                        <Typography variant="body1" sx={{ fontFamily: theme.typography.fontFamily, fontSize: mobileBreakpoint ? '0.875rem' : '1rem', mb: 2 }}>
                            {t("mt.description")}
                        </Typography>
                        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                            {tenantData.map((tenant) => (
                                <ListItem
                                    key={tenant}
                                    onClick={() => { handleTenantSelection(tenant); }}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': { bgcolor: 'action.hover' },
                                    }}
                                >
                                    <ListItemText primary={<Typography style={{ fontWeight: '700', fontSize: mobileBreakpoint ? '1em' : '1.4em' }}><u>{tenant.charAt(0).toUpperCase() + tenant.slice(1)}</u></Typography>} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            {!mobileBreakpoint && (
                <img src={Logo} alt="OneStaff Logo" style={{ width: '120px', height: 'auto', position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }} />
            )}
        </Container>
    );
};



export default SelectTenantPage;
