import { Button, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { calendarEntriesShiftTemplateApi } from 'api';
import AppModal from 'components/AppModal';
import { EventImpl } from '@fullcalendar/core/internal';
import { H2 } from 'components/Typography';
import { useSeason } from 'contexts/SeasonContext';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    fetchCalendarData: () => void;
    event?: EventImpl;
    departmentId?: number;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const ViewEventModalShift: FC<ModalProps> = ({ open, onClose, fetchCalendarData, event, departmentId }) => {
    const { t } = useTranslation();
    const { seasonId } = useSeason();

    async function handleDelete() {
        await calendarEntriesShiftTemplateApi.remove(Number(event?.id), seasonId, {
            // departmentId is added for depchief security check in backend
            params: {
                departmentId
            }
        });
        fetchCalendarData();
        onClose();
    }

    return (
        <StyledAppModal open={open} handleClose={onClose}>
            <H2 mb={2}>
                {event?.title}
            </H2>
            <Button
                size='small'
                color='error'
                variant='contained'
                onClick={handleDelete}
                sx={{ color: 'common.white' }}
            >
                {t('employees.calendar.entry.deleteEntry')}
            </Button>
        </StyledAppModal>
    )
};

export default ViewEventModalShift;
