import { Autocomplete, Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import AppTextField from 'components/input-fields/AppTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC } from 'react';
import { H2 } from 'components/Typography';


interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  data?: any;
  fetchRewards: () => void;
  usedNames: string[];
}
export interface AddRewardFormData {
  name: string;
  amount: number;
  earnedOn: Date;
  notes?: number;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 900,
  maxHeight: 800,
  padding: theme.spacing(5),
  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddRewardModal: FC<ModalProps> = ({ open, onClose, onSubmit, data, fetchRewards, usedNames }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      rewardName: data?.rewardName || '',
      amount: data?.amount || '',
      date: data?.date || null,
      notes: data?.notes || '',
    },
    validationSchema: Yup.object({
      rewardName: Yup.string().required(t('common.forms.field.required')),
      amount: Yup.number().required(t('common.forms.field.required')),
      date: Yup.date().nullable().required(t('common.forms.field.required')),
      notes: Yup.string().max(300),
    }),
    onSubmit: async values => {
      onSubmit(values);
      fetchRewards();
      formik.resetForm();
    },
  });

  function handleNameChange(name: string | null) {
    formik.setFieldValue('rewardName', name, true)
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledAppModal open={open} handleClose={onClose}>
        <H2>{t('employees.reward.add')}</H2>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4} marginTop={1}>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                disableClearable
                options={usedNames}
                onChange={(e, value) => {
                  handleNameChange(value);
                }}
                renderInput={(params) => {
                  return (
                    <AppTextField
                      {...params}
                      label={t('employees.reward.name')}
                      fullWidth
                      size='small'
                      name='RewardName'
                      placeholder='Nome'
                      value={formik.values.rewardName}
                      onChange={(e) => {
                        handleNameChange(e.target.value);
                      }}
                    />
                  )
                }}

              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="amount"
                label={t('employees.reward.amount')}
                type="number"
                fullWidth
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                sx={{ width: '100%' }}
                label={t('employees.reward.earnedon')}
                value={formik.values.date}
                onChange={async (value) => await formik.setFieldValue('date', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="notes"
                label={t('employees.reward.notes')}
                fullWidth
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button variant='outlined' fullWidth onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                  {t('common.forms.button.cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" fullWidth variant='contained'>
                  {t('common.forms.button.save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledAppModal>
    </LocalizationProvider>
  );
};

export default AddRewardModal;
