import { Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import ImageUploadInput from 'components/input-fields/ImageUploadInput';
import Scrollbar from 'components/ScrollBar';
import { H2, H6 } from 'components/Typography';
import { useFormik } from 'formik';
import { type FC } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { realAxios } from '../../../api';

// component props interface
interface ModalProps {
  data?: any;
  open: boolean;
  edit?: boolean;
  onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 450,
  minWidth: 200,

  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddEmployeeModal: FC<ModalProps> = ({ open, onClose, edit, data }) => {
  const initialValues = {
    name: data?.name || '',
    username: data?.username || '',
    email: data?.email || '',
    avatar: data?.avatar || '',
    position: data?.role || '',
    experience: data?.experience || 1,
    team: data?.team || 1,
    phone: data?.phone || '',
    dateOfBirth: data?.dateOfBirth || '',
    address: data?.address || '',
    status: data?.status || '',
  };

  const fieldValidationSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short').required('Name is Required!'),
    username: Yup.string()
      .min(3, 'Too Short')
      .required('Username is Required!'),
    email: Yup.string().required('Email is Required!'),
    // avatar: Yup.string().required("Avatar is Required!"),
    position: Yup.string().required('Position is Required!'),
    phone: Yup.string().required('Phone is Required!'),
    address: Yup.string().required('Address is Required!'),
    dateOfBirth: Yup.string().required('Date Of Birth is Required!'),
    experience: Yup.number().required('Experience is Required!'),
    team: Yup.number().required('Team Member is Required!'),
    status: Yup.string().required('Status is Required!'),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: fieldValidationSchema,
    onSubmit: (values) => {
      realAxios
        .post('/api/tableData2/new', {
          name: values.name,
          username: values.username,
          email: values.email,
          position: values.position,
          experience: values.experience,
          team: values.team,
          phone: values.phone,
          dateOfBirth: values.dateOfBirth,
          address: values.address,
          status: values.status,
        })
        .then(() => {
          onClose();
          toast.success('New Data Added Successfully');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2}>
        {edit ? 'Modifica dipendente' : 'Aggiungi nuovo dipendente'}
      </H2>

      <form onSubmit={handleSubmit}>
        <Scrollbar style={{ maxHeight: 400 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <H6 mb={1}>Nome</H6>
              <AppTextField
                fullWidth
                size='small'
                name='name'
                placeholder='Nome'
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name && touched.name)}
                helperText={(touched.name && errors.name) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Cognome</H6>
              <AppTextField
                fullWidth
                size='small'
                name='username'
                placeholder='Cognome'
                onChange={handleChange}
                value={values.username}
                error={Boolean(errors.username && touched.username)}
                helperText={(touched.username && errors.username) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>E-Mail</H6>
              <AppTextField
                fullWidth
                size='small'
                name='email'
                placeholder='E-Mail'
                onChange={handleChange}
                value={values.email}
                error={Boolean(errors.email && touched.email)}
                helperText={(touched.email && errors.email) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Data di nascita</H6>
              <AppTextField
                fullWidth
                size='small'
                name='dateOfBirth'
                placeholder='gg/mm/aaaa'
                type='date'
                onChange={handleChange}
                value={values.dateOfBirth}
                error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
                helperText={
                  (touched.dateOfBirth && errors.dateOfBirth) as string
                }
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Telefono</H6>
              <AppTextField
                fullWidth
                size='small'
                name='phone'
                placeholder='Telefono'
                onChange={handleChange}
                value={values.phone}
                error={Boolean(errors.phone && touched.phone)}
                helperText={(touched.phone && errors.phone) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Indirizzo</H6>
              <AppTextField
                fullWidth
                size='small'
                name='address'
                placeholder='Indirizzo'
                onChange={handleChange}
                value={values.address}
                error={Boolean(errors.address && touched.address)}
                helperText={(touched.address && errors.address) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Livello</H6>
              <AppTextField
                fullWidth
                size='small'
                name='position'
                placeholder='Capo reparto'
                onChange={handleChange}
                value={values.position}
                error={Boolean(errors.position && touched.position)}
                helperText={(touched.position && errors.position) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Reparto</H6>
              <AppTextField
                fullWidth
                size='small'
                name='experience'
                placeholder='Reparto'
                onChange={handleChange}
                value={values.experience}
                error={Boolean(errors.experience && touched.experience)}
                helperText={(touched.experience && errors.experience) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Data assunzione</H6>
              <AppTextField
                fullWidth
                size='small'
                name='team'
                type='date'
                placeholder='gg/mm/aaaa'
                value={values.team}
                onChange={handleChange}
                error={Boolean(errors.team && touched.team)}
                helperText={(touched.team && errors.team) as string}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>Tipologia contratto</H6>
              <AppTextField
                fullWidth
                size='small'
                name='status'
                placeholder='Contratto determinato'
                value={values.status}
                onChange={handleChange}
                error={Boolean(errors.status && touched.status)}
                helperText={(touched.status && errors.status) as string}
              />
            </Grid>

            <Grid item xs={12}>
              <H6 mb={1}>Carica una foto</H6>

              <ImageUploadInput handleOnChange={() => {}} />
            </Grid>
          </Grid>
        </Scrollbar>

        <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
          <Button fullWidth size='small' variant='outlined' onClick={onClose}>
            Cancel
          </Button>
          <Button fullWidth size='small' type='submit' variant='contained'>
            Save
          </Button>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AddEmployeeModal;
