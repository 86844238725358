import { Grid } from '@mui/material';
import CustomCard from 'components/CustomCard';
import { TFunction } from 'i18next';
import { FC } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';

interface DataProps {
  totalEarned: number,
  totalPaid: number,
  t: TFunction,
}

const EmployeeTotalPayments: FC<DataProps> = (props) => {
  const { totalEarned, totalPaid, t } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <CustomCard
          title={t('employees.report.cost.totalearnings')}
          Icon={AttachMoneyIcon}
          color="#E5412A"
          id={1}
          text={!totalEarned ? '-' : totalEarned.toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '€'}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CustomCard
          title={t('employees.report.cost.paid')}
          Icon={AttachMoneyIcon}
          color="#03BF62"
          id={2}
          text={!totalPaid ? '-' : totalPaid.toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '€'}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CustomCard
          title={t('employees.report.cost.currentCredit')}
          Icon={WorkIcon}
          color="#E5412A"
          id={1}
          text={!(totalEarned || totalPaid)
            ? '-'
            : (totalEarned - totalPaid).toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '€'}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeTotalPayments;