import { Button, Grid, styled } from "@mui/material";
import { CreateSegmentDto } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H6 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { t } from "i18next";
import { FC } from "react";
import * as Yup from 'yup';

interface ModalProps {
    data?: CreateSegmentDto;
    open: boolean;
    onClose: () => void;
    onSubmitAction: (segment: CreateSegmentDto) => void;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddSegmentModal: FC<ModalProps> = (
    {
        data,
        open,
        onClose,
        onSubmitAction,
    }

) => {

    const initialValues = {
        name: data?.name ?? '',
    };

    const fieldValidationSchema = Yup.object().shape({
        name: Yup.string().trim().min(3, t('common.forms.field.min', {
            field: t('departments.name'),
            min: 3,
        })).required(
            t('common.forms.field.required', {
                field: t('departments.name'),
            }),
        ),
    });

    const { values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            onSubmitAction(values);
            onClose();
            resetForm();
        },

    });

    return (
        <StyledAppModal
            open={open}
            handleClose={onClose}
        >

            <H2 mb={2}>
                {t('common.forms.addItemLabel', {
                    item: t('department.segment'),
                })}
            </H2>
            <Grid container alignItems={'center'}>
                <Grid item xs={12}>
                    <H6 mb={1}>{t('departments.name')}</H6>
                </Grid>
                <Grid item xs={12}>
                    <AppTextField
                        fullWidth
                        size='small'
                        name='name'
                        placeholder={t('departments.name')}
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(errors.name && touched.name)}
                        helperText={(touched.name && errors.name) as string}
                    />
                </Grid>
            </Grid>
            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button fullWidth size='small' variant='outlined' onClick={() => {
                    onClose();
                }}>
                    {t('common.forms.button.cancel')}
                </Button>
                <Button fullWidth size='small' type='submit' variant='contained' onClick={() => { handleSubmit() }}>
                    {t('common.forms.button.save')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default AddSegmentModal;