import { Autocomplete, Button, styled } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import { t } from "i18next";
import { FC, useState } from "react";
import AppTextField from "components/input-fields/AppTextField";
import { EmployeeEntity, SeasonEntity } from "api/generated";
import FlexBox from "components/flexbox/FlexBox";
import { seasonApi } from "api";
import { useSeason } from "contexts/SeasonContext";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    row?: any;
    seasons?: SeasonEntity[];
    confirmToast: () => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: 450,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AssignSeasonModal: FC<ModalProps> = (
    {
        open,
        onClose,
        row,
        seasons,
        confirmToast
    }
) => {
    const { seasonId } = useSeason();
    const [selectedSeason, setSelectedSeason] = useState<SeasonEntity>();

    const onConfirm = () => {
        if (row && selectedSeason) {
            const employee = row.original as EmployeeEntity;
            seasonApi.assignEmployeesToSeason({
                employeesOptions: [{
                    employeeId: employee.id,
                    position: true,
                    room: true,
                    status: true,
                }],
                seasonId: selectedSeason.id,
                seasonToCopyFromId: seasonId,

            })
            onClose();
            confirmToast();
        }
    }

    return (
        <StyledAppModal open={open} handleClose={() => { onClose() }} >
            <H2>{t('assignSeason.name')}</H2>
            <Autocomplete
                sx={{ marginTop: 2 }}
                value={selectedSeason ?? null}
                options={seasons ?? []}
                getOptionLabel={(season) => { return season.name }}
                renderInput={(params) => (
                    <AppTextField
                        {...params}
                        label={t('season.select')}
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
                onChange={(_event, value) => {
                    if (value) {
                        setSelectedSeason(value);
                    }
                }}
            />

            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => { onClose() }}>
                    {t('cancel')}
                </Button>
                <Button
                    fullWidth
                    disabled={!selectedSeason}
                    variant="contained"
                    onClick={() => { onConfirm() }}>
                    {t('confirm')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default AssignSeasonModal;