import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; 
import translationEn from './locales/en.json';
import translationIt from './locales/it.json';
import translationDe from './locales/de.json';
import translationFr from './locales/fr.json';

const resources = {
  en: {
    translation: translationEn,
  },
  it: {
    translation: translationIt,
  },
  de: {
    translation: translationDe,
  },
  fr: {
    translation: translationFr,
  }
};

i18next
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false, 
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage'],
  }});

export default i18next;