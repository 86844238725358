import { Box } from '@mui/material';
import AppAvatar from 'components/avatars/AppAvatar';
import FlexBox from 'components/flexbox/FlexBox';
import { Tiny } from 'components/Typography';
import { type FC } from 'react';

interface OutgoingMsgProps {
  msg: {
    message: string;
    imageSrc: string;
    author: string;
    date: string;
  };
}

const CommunicationsOutgoingMsg: FC<OutgoingMsgProps> = ({ msg }) => {
  return (
    <Box>
      <FlexBox mt={3} justifyContent='end'>
      <Tiny >{msg.author}</Tiny>
      </FlexBox>
      <FlexBox justifyContent='end'>
        <AppAvatar
          src={msg.imageSrc}
          sx={{ width: 28, height: 28 }}
        />
        <Box
          sx={{
            padding: 2,
            maxWidth: 250,
            marginLeft: 1.5,
            borderRadius: '8px 0px 8px 8px',
            backgroundColor: 'primary.main',
          }}
        >
          <Tiny display='block' color='white' fontWeight={500} lineHeight={1.7}>
            {msg.message}
          </Tiny>
        </Box>
      </FlexBox>
      <FlexBox justifyContent='end'>
        <Tiny justifyContent='end'>{msg.date}</Tiny>
      </FlexBox>
    </Box>
  );
};

export default CommunicationsOutgoingMsg;
