import { Divider, Typography, styled, IconButton, Button, Chip, Grid, Tooltip, InputAdornment } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { useState, type FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import AppTextField from 'components/input-fields/AppTextField';
import KeyIcon from '@mui/icons-material/Key';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// component props interface
interface ModalProps {
    data?: any;
    open: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    handleBlur?: (event: any) => void;
    handleChange?: (event: any) => void;
    recoveryKeyValue?: string;
    recoveredKey?: string;
    errorCode?: number;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 200,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const EncryptionRecoveryKeyModal: FC<ModalProps> = ({ open, onClose, onSubmit, handleBlur, handleChange, recoveryKeyValue, recoveredKey, errorCode }) => {
    const { t } = useTranslation();
    const [showEncryptionKey, setShowEncryptionKey] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => { setShowPassword((show) => !show) };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <StyledAppModal open={open} handleClose={() => {
            setShowEncryptionKey(false);
            onClose();
        }}
        >
            <FlexBox alignItems={'center'} justifyContent={'space-between'}>
                <Typography fontWeight={600}>{t('encryption.popover.encryptionKeyRecovery')}</Typography>
                <IconButton
                    onClick={() => {
                        setShowEncryptionKey(false);
                        onClose();
                    }}
                >
                    <CloseIcon fontSize='small' sx={{ color: 'text.disabled' }} />
                </IconButton>
            </FlexBox>
            <Divider />

            {!recoveredKey &&
                <form onSubmit={onSubmit}>
                    <Grid container mt={2} rowSpacing={2}>
                        <Grid item xs={12}>
                            <AppTextField
                                inputRef={input => input?.focus()}
                                name='recoveryKey'
                                label="Recovery key"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                size='small'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={recoveryKeyValue}
                                sx={{ width: '100%' }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FlexBox justifyContent={'center'}>
                                <Button type='submit' variant='contained'>
                                    {t('encryption.popover.recover')}
                                </Button>
                            </FlexBox>
                        </Grid>
                        <Grid item xs={12}>
                            {errorCode === 403 &&
                                <FlexBox justifyContent={'center'}>
                                    <Chip icon={<ErrorOutlineOutlinedIcon />} label={t('encryption.popover.keyDecryptionError')} variant="outlined" color='error' />
                                </FlexBox>
                            }
                        </Grid>
                    </Grid>
                </form>
            }

            {recoveredKey &&
                <FlexBox justifyContent={'center'} mt={2} alignItems={'center'}>
                    <Chip icon={<KeyIcon />} label={showEncryptionKey ? recoveredKey : '・・・'} variant="outlined" style={{ filter: showEncryptionKey ? '' : 'blur(3px)' }} />
                    {!showEncryptionKey &&
                        <Tooltip title={t('encryption.popover.showEncryptionKey')} arrow>
                            <IconButton
                                onClick={() => {
                                    setShowEncryptionKey(true);
                                }}
                            >
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    }
                </FlexBox>
            }
        </StyledAppModal >
    );
};

export default EncryptionRecoveryKeyModal;
