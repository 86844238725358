import { FC, useEffect, useState } from "react";
import TabBase, { TabResourceProps } from "./TabBase";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Divider, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { CreateAdditionalInformationDtoFoodRequirmentsEnum, CreateAdditionalInformationDtoShirtSizeEnum, CreateEmergencyContactDto, EmergencyContactEntity, LicenceEntity } from "api/generated";
import { additionalInformationsApi, licenceApi } from "api";
import AddLicenceModal from "page-sections/data-table/dataTableV2/AddLicenceModal";
import { FormControl } from "@mui/base";
import { useFormik } from "formik";
import { ConvertAllergies } from "utils/convertAllergies";
import EmergencyContactDataTable from "page-sections/data-table/EmergencyContacsDataTable";
import FlexBox from "components/flexbox/FlexBox";
import { H6 } from "components/Typography";
import AddIcon from '@mui/icons-material/Add';
import AddEmergencyContactModal from "page-sections/data-table/dataTableV2/AddEmergencyContactModal";


export interface AdditionalFormData {
    id?: number,
    customFoodRequirments?: string | null,
    foodRequirments: string[],
    pantsSize?: number | null,
    shirtSize?: string | null,
    licences?: LicenceEntity[],
    emergencyContacts?: EmergencyContactEntity[],
}

const AdditionalInformation: FC<TabResourceProps<AdditionalFormData>> = ({
    data,
    setNewDataCallback,
    onSubmit,
    isProfileCompletition,
    id,
    buttonFontSize,
    userMode
}) => {
    const { t } = useTranslation();
    const [isViewing, setIsViewing] = useState(!!data && !isProfileCompletition);
    const [licences, setLicences] = useState<LicenceEntity[]>([]);
    const [openAddLicence, setOpenLicence] = useState(false);
    const [openAddEmergencyContact, setOpenAddEmergencyContact] = useState(false);
    const [selectedLicences, setSelectedLicences] = useState<Array<{
        label: string,
        value: number
    }>>([]);


    const [shirtSize, setShirtSize] = useState<string | null>();
    const [pantsSize, setPantsSize] = useState<number | null>();

    const sizeKeys = Object.keys(CreateAdditionalInformationDtoShirtSizeEnum);
    const foodTypes = Object.keys(CreateAdditionalInformationDtoFoodRequirmentsEnum);

    const pantSizesCompute = (start: number, end: number) => {
        const sizes: number[] = [];
        for (let i = start; i <= end; i++) {
            if (i % 2 === 0)
                sizes.push(i);
        }

        return sizes;
    }

    const sizes = pantSizesCompute(40, 66);

    const [selectedFoodTypes, setSelectedFoodTypes] = useState<string[]>([]);

    const [customFoodRequirments, setCustomFoodRequirments] = useState<string | null>();

    const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContactEntity[]>([]);

    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const fetchLicences = async () => {
        const data = (await licenceApi.findAll()).data;
        setLicences(data);
    }

    const setupData = () => {
        if (data) {            
            if (data.shirtSize) {                
                setShirtSize(String(data.shirtSize.toUpperCase()));
            }
            let foodTypes: string[] = [];
            setPantsSize(data.pantsSize);
            if (data.foodRequirments) {
                foodTypes = foodTypes.concat(selectedFoodTypes.concat(data.foodRequirments.map(x => String(x))));
                if (data.customFoodRequirments && !foodTypes.includes('OTHER'))
                    foodTypes.push('OTHER');
            }
            setSelectedFoodTypes(foodTypes);
            setCustomFoodRequirments(data.customFoodRequirments)

            if (data.licences)
                setSelectedLicences(data.licences.map((licence) => { return { label: licence.name, value: licence.id } }))

            if (data.emergencyContacts)
                setEmergencyContacts(data.emergencyContacts);
        }
    }

    const fetchContacts = async (id?: number) => {
        if (!isProfileCompletition) {
            if (data?.id)
                setEmergencyContacts((await additionalInformationsApi.findEmergencyContactByAdditionalInfomrationsID(data.id)).data);
            else if (id) {
                setEmergencyContacts((await additionalInformationsApi.findEmergencyContactByAdditionalInfomrationsID(id)).data);
            }
        }
        else {
            // Get the 'token' parameter from the URL
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const tenant = urlParams.get('tenant')
            if (!token || !tenant)
                return;
            if (data?.id)
                setEmergencyContacts((await additionalInformationsApi.findEmergencyContactByAdditionalInfomrationsIDWithToken(data.id, tenant,
                    {
                        params: {
                            token
                        }
                    })).data);
            else if (id) {
                setEmergencyContacts((await additionalInformationsApi.findEmergencyContactByAdditionalInfomrationsIDWithToken(id, tenant,
                    {
                        params: {
                            token
                        }
                    })).data);
            }
        }

    }

    const handleDeleteContacts = async () => {
        const ids = selectedRows.map((item: any) => item.original.id);
        // Get the 'token' parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const tenant = urlParams.get('tenant');

        // use token if is profile completition
        const deleteActions = !isProfileCompletition ? ids.map(async (id) => additionalInformationsApi.removeEmergencyContact(+id)) :
            ids.map(async (id) => additionalInformationsApi.removeEmergencyContactWithToken(+id, tenant ?? '', { params: { token } }));
        await Promise.all(deleteActions);
        fetchContacts();
    }

    const createContactWithToken = async (contact: Omit<CreateEmergencyContactDto, 'additionalInformationId'>) => {
        // Get the 'token' parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const tenant = urlParams.get('tenant')

        if (data?.id && tenant && token) {
            await additionalInformationsApi.createEmergencyContactWithToken(tenant,
                {
                    additionalInformationId: data.id,
                    ...contact
                },
                {
                    params: {
                        token
                    }
                }
            );
            fetchContacts();
        }
        else if (id && tenant && token) {
            const informations = (await additionalInformationsApi.createWithToken(tenant, {
                employeeId: id,
                foodRequirments: [] as any,
            },
                {
                    params: {
                        token
                    }
                }
            )).data;
            if (setNewDataCallback)
                setNewDataCallback({
                    id: informations.id,
                    ...data
                });
            await additionalInformationsApi.createEmergencyContactWithToken(tenant,
                {
                    additionalInformationId: informations.id,
                    ...contact
                },
                {
                    params: {
                        token
                    }
                }
            );
            fetchContacts(informations.id);
        };
    }

    const createContact = async (contact: Omit<CreateEmergencyContactDto, 'additionalInformationId'>) => {
        if (data?.id) {
            await additionalInformationsApi.createEmergencyContact(
                {
                    additionalInformationId: data.id,
                    ...contact
                }
            );
            fetchContacts();
        }
        else if (id) {
            const informations = (await additionalInformationsApi.create({
                employeeId: id,
                foodRequirments: [] as any,
            })).data;
            if (setNewDataCallback)
                setNewDataCallback({
                    id: informations.id,
                    ...data
                });
            await additionalInformationsApi.createEmergencyContact(
                {
                    additionalInformationId: informations.id,
                    ...contact
                }
            );
            fetchContacts(informations.id);
        };



    }



    const { handleSubmit } = useFormik({
        initialValues: {},
        onSubmit: () => {
            onSubmit({
                id: data?.id,
                customFoodRequirments: selectedFoodTypes.includes('OTHER') ? customFoodRequirments : null,
                foodRequirments: selectedFoodTypes.filter(x => x !== 'OTHER') as any[],
                pantsSize,
                shirtSize: shirtSize?.toUpperCase(),
                licences: selectedLicences.map((licence) => {
                    return {
                        id: licence.value,
                        name: licence.label
                    }
                })
            })
        }
    })

    useEffect(() => {
        if (!isProfileCompletition && !userMode)
            fetchLicences();
        setupData();
    }, [])


    return (
        <>
            <TabBase
                title={t('employees.additionalInformation.title')}
                setIsViewing={setIsViewing}
                isViewing={isViewing}
                handleSubmit={handleSubmit}
                isProfileCompletition={isProfileCompletition}
            >
                {!userMode && <AddLicenceModal
                    fetchData={fetchLicences}
                    onClose={() => { setOpenLicence(false) }}
                    open={openAddLicence}
                />}
                <Grid container spacing={3}>

                    <Divider sx={{ mt: 2 }}>{t('employees.additionalInformation.sizes')}</Divider>
                    <Grid item container xs={12} spacing={3}>

                        <Grid item xs={6} sm={2} >
                            <FormControl>
                                <InputLabel> {t('employees.additionalInformation.shirt')}</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={isViewing}
                                    value={shirtSize ?? '0'}
                                    onChange={(e) => {
                                        setShirtSize(e.target.value === '0' ? null : e.target.value);
                                    }}>
                                    <MenuItem value={'0'}>
                                        ---
                                    </MenuItem>
                                    {

                                        sizeKeys.map((key) => {
                                            return (
                                                <MenuItem key={key} value={key.toUpperCase()}>
                                                    {key.toUpperCase()}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <FormControl>
                                <InputLabel> {t('employees.additionalInformation.pants')}</InputLabel>
                                <Select
                                    fullWidth
                                    disabled = {isViewing}
                                    value={pantsSize ?? 0}
                                    onChange={(e) => {
                                        setPantsSize(e.target.value === '0' ? null : Number(e.target.value));
                                    }}>
                                    <MenuItem value={0}>
                                        ---
                                    </MenuItem>
                                    {
                                        sizes.map((size) => {
                                            return (
                                                <MenuItem key={size} value={size}>
                                                    {size}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Divider sx={{ mt: 2 }}>{t('employees.additionalInformation.allergies')}</Divider>
                    <Grid item container xs={12} spacing={3}>

                        <Grid item xs={12}>
                            <Autocomplete
                                sx={{ display: 'flex' }}
                                // map options format, add an other alergies option, filter already selected options

                                disabled = {isViewing}

                                options={[{ label: 'OTHER' }, ...foodTypes.map(type => ({ label: type.toUpperCase() }))].

                                    filter(x => !selectedFoodTypes.find(y => y === x.label))}
                                multiple
                                value={selectedFoodTypes.map(x => ({ label: x }))}
                                getOptionLabel={(value) => {
                                    if (value.label === 'OTHER')
                                        return t('employees.additionalInformation.other')
                                    else
                                        return ConvertAllergies(value.label as CreateAdditionalInformationDtoFoodRequirmentsEnum, t);
                                }}
                                renderInput={(params) => <TextField rows={2} multiline {...params} />}
                                onChange={(e, values) => {

                                    setSelectedFoodTypes(values.map(value => value.label))
                                }}
                            />
                        </Grid>
                        {selectedFoodTypes.find(x => x === 'OTHER') &&
                            (<Grid item xs={12}>
                                <TextField
                                    value={customFoodRequirments ?? ''}
                                    onChange={(e) => {
                                        setCustomFoodRequirments(e.target.value);
                                    }}
                                    fullWidth
                                    label={t('employees.additionalInformation.other')}
                                    multiline
                                    rows={2}
                                />
                            </Grid>)
                        }
                    </Grid>

                    {
                        !isProfileCompletition && (
                            <>
                                <Divider sx={{ mt: 2 }}>{t('employees.additionalInformation.licences')}</Divider>
                                <Grid item xs={12} >
                                    <Autocomplete
                                        multiple
                                        disabled={userMode || isViewing}
                                        value={selectedLicences}
                                        // map options format, add an add licence option, filter already selected options
                                        options={licences.map((x) => { return { label: x.name, value: x.id } })
                                            .concat({
                                                label: t('common.forms.addItemLabel', {
                                                    item: t('employees.additionalInformation.licence')
                                                }), value: 0
                                            })
                                            .filter(x => !selectedLicences.find(y => y.value === x.value))}
                                        onChange={(e, value) => {
                                            setSelectedLicences(value.filter(x => x.value !== 0))
                                        }}

                                        renderOption={(params, value) => {
                                            return (
                                                <MenuItem
                                                    onClick={() => {
                                                        if (value.value === 0)
                                                            setOpenLicence(true)
                                                        else if (!selectedLicences.find(x => x.value === value.value))
                                                            setSelectedLicences(selectedLicences.concat(value))

                                                    }}
                                                >
                                                    {value.label}
                                                </MenuItem>
                                            )
                                        }}
                                        renderInput={(params) => <TextField rows={2} multiline {...params} />}
                                    />
                                </Grid>
                            </>
                        )
                    }


                </Grid>
            </TabBase >
            <TabBase
                title={t('employees.additionalInformation.emergencyContacts')}
                isViewing={false}
                handleSubmit={() => { }}
                setIsViewing={() => { }}
            >
                <AddEmergencyContactModal
                    open={openAddEmergencyContact}
                    onClose={() => { setOpenAddEmergencyContact(false) }}
                    onSubmit={isProfileCompletition ? createContactWithToken : createContact}
                />

                {selectedRows.length > 0 && emergencyContacts.length > 0 && (
                    <FlexBox alignItems='center' justifyContent={'flex-end'}>
                        <H6 mr={1}>
                            {t('common.tables.select.numSelected', {
                                num: selectedRows.length,
                            })}
                        </H6>

                        <Button
                            size='small'
                            color='error'
                            variant='contained'
                            onClick={handleDeleteContacts}
                            sx={{ color: 'common.white' }}
                        >
                            {t('common.tables.select.deleteSelected')}
                        </Button>
                    </FlexBox>
                )}
                {emergencyContacts.length > 0 && (<EmergencyContactDataTable
                    data={emergencyContacts}
                    handleRowSelect={(rows) => { setSelectedRows(rows) }}

                />)}
                <Grid container justifyContent="center" paddingTop={1}>
                    <Button
                        variant="contained"
                        disabled = {isViewing}
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setOpenAddEmergencyContact(true);
                        }}
                        style={{ fontSize: buttonFontSize }}
                    >
                        {t('common.tables.button.addItem', { item: t('employees.additionalInformation.emergencyContact') })}
                    </Button>
                </Grid>
            </TabBase>

        </>
    );
};

export default AdditionalInformation;
