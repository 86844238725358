import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "./DataTable";

interface DataTableProps {
    data?: any[];
    clearFilter?: string;
    onFilterChange?: (filters: any) => void;
    handleRowSelect: (rows: []) => void;
}

const EmergencyContactDataTable: FC<DataTableProps> = (props) => {
    const { t } = useTranslation();

    const getColumnShape = (data: any) => {
        return [

            {
                Header: t('employees.generalInformation.firstName'),
                Cell: ({ row }: any) => {
                    return row.original.name;
                }
            },

            {
                Header: t('employees.generalInformation.lastName'),
                Cell: ({ row }: any) => {
                    return row.original.lastName;
                }
            },

            {
                Header: t('employees.generalInformation.phone'),
                Cell: ({ row }: any) => {
                    return row.original.phone;
                }
            },

            {
                Header: t('employees.additionalInformations.relation'),
                Cell: ({ row }: any) => {
                    return row.original.relation;
                }
            },

            {
                Header: t('employees.additionalInformations.spokenLanguages'),
                Cell: ({ row }: any) => {
                    return row.original.language;
                }
            },


        ];
    };
    const columns: any = useMemo(() => getColumnShape(props.data), [t]);

    return DataTable({
        ...props,
        columns,
    });
};

export default EmergencyContactDataTable;
