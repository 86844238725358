import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { JWTAuthProvider } from 'contexts/JWTAuth';
import 'nprogress/nprogress.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import "react-image-lightbox/style.css";
import 'react-quill-new/dist/quill.snow.css';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import App from './App';
import SettingsProvider from 'contexts/settingsContext';
import reportWebVitals from './reportWebVitals';
import './__fakeData__';
import SeasonProvider from 'contexts/SeasonContext';
import EncryptionProvider from 'contexts/EncryptionKeyContext';
import HiddenProvider from 'contexts/HiddenContext';
import EmployeeProvider from 'contexts/EmployeeContext';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import itLocale from 'date-fns/locale/it';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);


root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
        <SettingsProvider>
          <JWTAuthProvider>
            <SeasonProvider>
              <EncryptionProvider>
                <HiddenProvider>
                  <EmployeeProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </EmployeeProvider>
                </HiddenProvider>
              </EncryptionProvider>
            </SeasonProvider>
          </JWTAuthProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </I18nextProvider>

  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

