import { type FC } from 'react';
import { Grid } from '@mui/material';
import { TFunction } from 'i18next';
import LongCard from 'page-sections/dashboards/CRM/LongCard';




interface DataProps {
    contract: {
        startDate: string,
        endDate: string,
        days: number,
        totalDaysOff: number,
        todayDaysOff: number,
        daysOff: number,
    },
    t: TFunction;
}

const EmployeeDaysOff: FC<DataProps> = (props) => {
    const { t } = props;
    // let totalDaysOffFlored = Math.floor(props.contract.totalDaysOff);
    // const totalDaysOffdecimalPart = props.contract.totalDaysOff - totalDaysOffFlored // > 0.5? "½" : undefined;

    const convertToFraction = (value: number) => {
        const days = Math.floor(value);

        let workDaysFraction = "";

        if (value >= days + 0.25)
            workDaysFraction = "¼";
        if (value >= days + 0.5)
            workDaysFraction = "½";

        return String(days) + workDaysFraction;
    }

    const statsList = [
        {
            id: 1,
            amount: !isNaN(props.contract.daysOff) ? convertToFraction(props.contract.daysOff) : "-",
            title: t('employees.report.contract.used'),
            color: '#5479F7',
        },
        {
            id: 2,
            amount: !isNaN(props.contract.todayDaysOff) ? convertToFraction(props.contract.todayDaysOff) : "-",
            title: t('employees.report.contract.due'),
            color: '#E5402A',

        },
        {
            id: 3,
            amount: !isNaN(props.contract.totalDaysOff) ? convertToFraction(props.contract.totalDaysOff) : "-",
            title: t('employees.report.contract.total'),
            color: '#FF9C12',
        },
    ];
    /*  let totalDaysfraction;
  
      if (totalDaysOffdecimalPart < 0.25)
          totalDaysfraction = undefined;
      else if (totalDaysOffdecimalPart > 0.25 && totalDaysOffdecimalPart < 0.75)
          totalDaysfraction = "½";
      else if (totalDaysOffdecimalPart > 0.75) {
          totalDaysfraction = undefined;
          totalDaysOffFlored++;
      }
  */

    /* const daysOff = Math.floor(props.contract.daysOff);

    let daysOffFraction;

    if (props.contract.daysOff > daysOff)
        daysOffFraction = "½";
*/
    // integer part must be equal and fraction either both do not exist or are equal
    // const isDayOffOk = daysOff === totalDaysOffFlored && (!(daysOffFraction ?? totalDaysfraction) || daysOffFraction === totalDaysfraction);

    return (
        <Grid item md={12} xs={12}>
            <LongCard list={statsList} longCardTitle={t('employees.report.contract.daysOffGeneralTitle')} />
        </Grid>
    );
};

export default EmployeeDaysOff;
