import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import BlankCheckBoxIcon from 'icons/BlankCheckBoxIcon';
import CheckBoxIcon from 'icons/CheckBoxIcon';
import { type FC, forwardRef, useEffect, useMemo, useRef } from 'react';
import { useFilters, useRowSelect, useTable, useSortBy } from 'react-table';
import ScrollBar from 'simplebar-react';
import {
  StyledSearchIcon,
  StyledSearchInput,
  StyledTableBodyRow,
} from './dataTableV2/styledComponents';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DataTableProps {
  data?: any[];
  columns: any;
  clearFilter?: string;
  onFilterChange?: (filters: any) => void;
  handleRowSelect?: (rows: []) => void;
  notSelectableRows?: boolean;
}

const SelectCheckBox = forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        {...rest}
        ref={resolvedRef}
        disableRipple
        checkedIcon={<CheckBoxIcon fontSize='small' color='primary' />}
        icon={<BlankCheckBoxIcon fontSize='small' color='primary' />}
      />
    );
  },
);

function SearchFilter({ column }: any) {
  const { filterValue, setFilter } = column;
  const theme = useTheme();
  return (
    <StyledSearchInput
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value)}
      startAdornment={<StyledSearchIcon sx={{ color: 'text.disabled' }} />}
      sx={{
        backgroundColor:
          theme.palette.mode === 'light' ? '#ECEFF5' : theme.palette.divider,
        borderRadius: '8px',
      }}
    />
  );
}

const DataTable: FC<DataTableProps> = ({
  data,
  columns,
  clearFilter,
  handleRowSelect,
  onFilterChange,
  notSelectableRows
}) => {
  const tableData: any = useMemo(() => data, [data]);
  const defaultColumn: any = useMemo(() => ({ Filter: SearchFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    rows,
    setAllFilters,
    selectedFlatRows,
  }: any = useTable(
    {
      columns,
      defaultColumn,
      data: tableData,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!notSelectableRows)
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <SelectCheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => {
              return <SelectCheckBox {...row.getToggleRowSelectedProps()} />;
            },
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    if (handleRowSelect)
      handleRowSelect(selectedFlatRows);
  }, [selectedFlatRows, handleRowSelect]);

  useEffect(() => {
    setAllFilters([]);
  }, [clearFilter, setAllFilters]);

  useEffect(() => {
    if (onFilterChange != null) {
      onFilterChange(state.filters);
    }
  }, [onFilterChange, state.filters]);

  // handle pagination
  /* const handleChange = (_: any, currentPageNo: number) => {
     gotoPage(currentPageNo - 1);
   }; */

  const selectedRow = (selectId: any) => {
    const rowId = Object.keys(state.selectedRowIds);
    const findId = rowId.find((id) => id === selectId);
    if (findId) return true;
    return false;
  };


  return (
    <Box maxHeight={'75vh'} sx={{ overflowY: 'auto' }}>
      <ScrollBar>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize: 12,
                      fontWeight: 600,
                      minWidth: column.minWidth,
                      borderColor: 'secondary.300',
                      '&:first-child': { paddingLeft: '16px' },
                      '&:last-child': { paddingRight: '16px' },
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {column.render('Header')}
                        <span style={{ marginLeft: '6px', marginTop: '8px' }}>
                          {column.isSorted
                            ? (column.isSortedDesc ? <ExpandMoreIcon fontSize='small' /> : <ExpandLessIcon fontSize='small' />)
                            : (column.canSort ? <UnfoldMoreIcon fontSize='small' /> : "")}
                        </span>
                      </div>
                    </div>

                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <StyledTableBodyRow
                  {...row.getRowProps()}
                  selected_row={selectedRow(row.id) ? 'select' : ''}
                >
                  {row.cells.map((cell: any) => (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        borderColor: 'secondary.300',
                        '&:first-child': { paddingLeft: '16px' },
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </StyledTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </ScrollBar>
    </Box>
  );
};

export default DataTable;
