import { Divider, Typography, styled, IconButton, Button } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { Fragment, type FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { encryptAllAgrements, encryptAllNotes } from 'utils/encryptionDecryptionAgreements';
import { CustomDialog } from 'components/CustomDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// component props interface
interface ModalProps {
    data?: any;
    open: boolean;
    onClose: () => void;
    setOpenSettingsModalCallback: (value: boolean) => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 200,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const EncryptionSettingsModal: FC<ModalProps> = ({ open, onClose, setOpenSettingsModalCallback }) => {
    const { t } = useTranslation();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
        setOpenSettingsModalCallback(false);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setOpenSettingsModalCallback(true);
    };

    const handleConfirmDelete = async () => {
        encryptAllAgrements(t, true);
        setDeleteConfirmationOpen(false);
        setOpenSettingsModalCallback(false);
    };

    return (
        <Fragment>
            <StyledAppModal open={open} handleClose={() => {
                onClose();
            }}
            >
                <FlexBox alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontWeight={600}>{t('encryption.popover.settings')}</Typography>
                    <IconButton
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <CloseIcon fontSize='small' sx={{ color: 'text.disabled' }} />
                    </IconButton>
                </FlexBox>
                <Divider />
                <FlexBox mt={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={12}>{t('encryption.agreementsTable.tooltip.encryptAll')}</Typography>
                    <Button variant='GreyOutlined'
                        onClick={() => {
                            encryptAllAgrements(t).then(() => {
                                setOpenSettingsModalCallback(false);
                            });
                        }}
                        sx={{ p: 1 }}
                    >
                        {t('encryption.popover.settingsModal.encryptAll')}
                    </Button>
                </FlexBox>
                <FlexBox mt={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={12}>{t('encryption.encryptAllNotes')}</Typography>
                    <Button variant='GreyOutlined'
                        onClick={() => {
                            encryptAllNotes(t).then(() => {
                                setOpenSettingsModalCallback(false);
                            });
                        }}
                        sx={{ p: 1 }}
                    >
                        {t('encryption.popover.settingsModal.encryptAll')}
                    </Button>
                </FlexBox>
                <FlexBox mt={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={12}>{t('encryption.agreementsTable.tooltip.deleteAll')}</Typography>
                    <Button variant='contained' color='error'
                        onClick={() => { handleOpenDeleteConfirmation(); }}
                        sx={{ p: 1 }}
                    >
                        {t('encryption.popover.settingsModal.deleteAll')}
                    </Button>
                </FlexBox>
            </StyledAppModal >

            <CustomDialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                onConfirm={handleConfirmDelete}
                title={t('dialog.title', {
                    item: t("employees.report.agreement.title"),
                })}
                content={t('dialog.content')}
                icon={<DeleteForeverIcon />}
                confirmText={t('dialog.confirm')}
                confirmColor='error'
                cancelColor='primary'
            />
        </Fragment>
    );
};

export default EncryptionSettingsModal;
