import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface DataPropos {
    workPositionsIds: Array<{ id: number, name: string }>;
    workPositionMaxDimension: Record<number, number>;
    columns: number;
    FIXED_HEIGHT: number;
    isWorkPositionVisible: Record<number, boolean>;
    setIsWorkPositionVisible: (data: Record<number, boolean>) => void;
}

const WorkPositionColumn: FC<DataPropos> = (props) => {
    const { workPositionsIds, workPositionMaxDimension, columns, FIXED_HEIGHT, isWorkPositionVisible, setIsWorkPositionVisible } = props;
    const theme = useTheme();
    return (
        <Grid item xs={2} container columns={columns} position={'sticky'} left={0} bgcolor={theme.palette.background.paper}>

            {
                workPositionsIds.flatMap((id) => {
                    const items: ReactNode[] = [];
                    if ((workPositionMaxDimension[id.id] ?? 0) === 0)
                        return items;
                    const newWorkPositionVisible = { ...isWorkPositionVisible };
                    items.push(
                        <Tooltip title={id.name}>
                            <Grid item xs={columns} padding={1} paddingLeft={0} height={FIXED_HEIGHT} display={'flex'} alignItems={'center'}
                                onClick={() => {
                                    newWorkPositionVisible[id.id] = !isWorkPositionVisible[id.id];
                                    setIsWorkPositionVisible(newWorkPositionVisible);
                                }}

                            >
                                <Typography textOverflow={'ellipsis'} overflow={'hidden'} fontWeight={600}>
                                    {id.name}
                                </Typography>
                                {isWorkPositionVisible[id.id] ?

                                    <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Grid>
                        </Tooltip>
                    )

                    if (isWorkPositionVisible[id.id]) {
                        for (let i = 1; i <= workPositionMaxDimension[id.id]; i++) {

                            items.push(
                                <Grid item xs={columns} padding={1} height={FIXED_HEIGHT} >
                                    {'#' + i}
                                </Grid>
                            )
                        }
                    }


                    return items;

                })

            }
        </Grid>
    )
}

export default WorkPositionColumn;