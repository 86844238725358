import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='ill_svg__b'>
        <path fill='none' d='M0 0h75.875v70H0z' data-name='Rettangolo 3' />
      </clipPath>
      <clipPath id='ill_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#ill_svg__a)' data-name='E5402A - malattia'>
        
      <g data-name='Raggruppa 6'>
        <g
          fill='#fff'
          clipPath='url(#ill_svg__b)'
          data-name='Raggruppa 5'
          transform='translate(49.563 55)'
        >
          <path
            d='M0 62.538V18.33a6.63 6.63 0 0 1 1.91-4.73c1.5-1.487 3.563-2.084 6.194-1.937 4.005.223 8.033.049 12.445.049a10.9 10.9 0 0 1 1.662-7.829A9.4 9.4 0 0 1 29.9 0h16.424a9.22 9.22 0 0 1 7.1 3.235c1.782 2.136 2.509 4.964 2.236 8.481 4.472 0 8.624-.037 12.775.009 5.552.062 7.431 2 7.434 7.607q.012 21.207 0 42.414c0 6.475-1.718 8.193-8.275 8.2-19.715.011-39.429-.057-59.143.057a16 16 0 0 1-2.01-.105A7.384 7.384 0 0 1 0 62.538m69.594-44.515H6.479v45.67h63.115ZM49.2 11.519c.539-4.536-.057-5.308-4.092-5.332-4.751-.028-9.5-.037-14.252 0-3.856.037-4.612 1.041-4.056 5.332Z'
            data-name='Tracciato 5'
          />
          <path
            d='M35.144 37.726c0-2.247-.4-5.115.071-6.7a2.982 2.982 0 0 1 5.644-.129c.5 1.569.176 4.46.176 6.754 1.727 0 3.29-.066 4.845.017 2.008.106 3.838.753 3.775 3.144-.06 2.286-1.629 3.116-3.815 3.016-1.475-.068-2.956-.013-4.779-.013 0 2.011.377 3.836-.118 5.383a2.8 2.8 0 0 1-.278.6 3.035 3.035 0 0 1-5.168.03 2.6 2.6 0 0 1-.245-.534c-.5-1.556-.12-3.392-.12-5.484-2.036 0-3.859.366-5.422-.116a2.8 2.8 0 0 1-.587-.264 3.162 3.162 0 0 1-.247-5.275 2.3 2.3 0 0 1 .572-.3c1.535-.537 3.4-.13 5.7-.13'
            data-name='Tracciato 6'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgIll;
