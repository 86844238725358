import {
  Box,
  IconButton,
  styled,
  type Theme,
  useMediaQuery,
} from '@mui/material';
import FlexBetween from 'components/flexbox/FlexBetween';
import FlexBox from 'components/flexbox/FlexBox';
import Scrollbar from 'components/ScrollBar';
import { type FC, useEffect, useState } from 'react';
import MultiLevelMenu from './MultiLevelMenu';
import MobileSidebar from './MobileSidebar';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'contexts/JWTAuth';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { authApi } from 'api';
import PushPinIcon from '@mui/icons-material/PushPin';
import { NavWrapper, SidebarWrapper, StyledIconButton, StyledLogo, TenantDisplay, TenantName, TOP_HEADER_AREA } from 'components/TenantDisplay';
interface DashboardSidebarProps {
  sidebarCompact: boolean;
  showMobileSideBar: boolean;
  setSidebarCompact: () => void;
  setShowMobileSideBar: () => void;
}
const StyledPin = styled(PushPinIcon)({
  display: 'block',
  transition: 'transform 0.3s ease',
  // Apply additional styles if necessary
});


const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar,
    setSidebarCompact,
  } = props;
  const [currentTenant, setCurrentTenant] = useState('');
  const [accessToMultipleTenants, setAccessToMultipleTenants] = useState<Boolean>();
  const [onHover, setOnHover] = useState(false);
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  // Activate compact when toggle button clicked and not on hover state
  const COMPACT = sidebarCompact && !onHover ? 1 : 0;
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decoded = jwtDecode<DecodedToken>(accessToken);
      setCurrentTenant(decoded.tenant ?? "");
      authApi.tenants()
        .then(({ data }) => {
          setAccessToMultipleTenants(data.length > 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const handleNavigate = () => {
    navigate('/select-tenant');
  };
  const tenantNameDisplay = currentTenant.charAt(0).toUpperCase() + currentTenant.slice(1);



  //   IF MOBILE
  if (downLg) {
    return (
      <MobileSidebar
        sidebarCompact={!!COMPACT}
        showMobileSideBar={!!showMobileSideBar}
        setShowMobileSideBar={setShowMobileSideBar}
      />
    );
  }

  return (
    <SidebarWrapper
      compact={sidebarCompact ? 1 : 0}
      onMouseEnter={() => {
        setOnHover(true);
      }}
      onMouseLeave={() => {
        sidebarCompact && setOnHover(false);
      }}
    >
      <FlexBetween pt={3} pr={2} pl={props.sidebarCompact ? 2 : 4} pb={1} height={TOP_HEADER_AREA}>
        {/* LOGO */}
        <FlexBox>
          <img src='/static/logo/logo_onestaff_new.png' alt='logo' width={50} />
          {!COMPACT && <StyledLogo>OneStaff</StyledLogo>}
        </FlexBox>
        <Box mx={'auto'}></Box>

        {/* SIDEBAR COLLAPSE BUTTON */}
        <StyledIconButton
          onClick={setSidebarCompact}
          sx={{ display: COMPACT ? 'none' : 'block' }}
        >
          <StyledPin fontSize='small' style={{
            transform: sidebarCompact ? 'rotate(0deg)' : 'rotate(45deg)'
          }} />
        </StyledIconButton>
      </FlexBetween>
      <FlexBox flexDirection="column" pt={1} pr={2} pl={4} pb={2} height={TOP_HEADER_AREA}>
        {(!props.sidebarCompact && currentTenant || onHover) && (
          <TenantDisplay>
            <TenantName>{tenantNameDisplay}</TenantName>
            <IconButton onClick={handleNavigate} size="small">
              {accessToMultipleTenants && (<EditIcon />)}
            </IconButton>
          </TenantDisplay>
        )}
      </FlexBox>
      {/* NAVIGATION ITEMS */}
      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: 'hidden',
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`,
        }}
      >
        <NavWrapper>
          <MultiLevelMenu sidebarCompact={!!COMPACT} />
        </NavWrapper>
      </Scrollbar>
    </SidebarWrapper>
  );
};

export default DashboardSidebar;
