import { SvgIcon, type SvgIconProps } from '@mui/material';
import React from 'react';

const DataTable = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
      <path d='M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM4,9V6H20V9Zm12,1.75h4V18H16Zm-6.25,0h4.5V18H9.75ZM4,10.75H8V18H4Z' />
    </SvgIcon>
  );
};

export default DataTable;
