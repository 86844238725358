import { Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "./DataTable";

interface DataTableProps {
    data?: any[];
    handleRowSelect: (rows: []) => void;
   // editSeasonCallback: (data?: SegmentEntity | null) => void;
}


const SegmentDataTable: FC<DataTableProps> = (props) => {

    const { t } = useTranslation()
    const columns: any = useMemo(() => getColumnShape(props.data), [t]);

    function getColumnShape(data: any) {
        return [
            {
                minWidth: 50,
                Header: t('departments.name'),
                accessor: 'name',
                Cell: ({ row }: any) => {
                    const { name, id } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <FlexBox flexDirection='column' ml={1.2}>
                                <Small mb={0.5}>{name}</Small>
                                <Tiny sx={{ fontSize: 10, color: 'text.secondary' }}>
                                    ID: {id}
                                </Tiny>
                            </FlexBox>
                        </FlexBox>
                    );
                }
            },
            {
                minWidth: 50,
                Header: t('segment.count'),
                accessor: 'count',
                Cell: ({ row }: any) => {
                    const { employeesNumber } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <FlexBox flexDirection='column' ml={1.2}>
                                <Small mb={0.5}>{employeesNumber}</Small>
                            </FlexBox>
                        </FlexBox>
                    );
                }
            }
        ]
    }

    return DataTable({
        ...props,
        columns,
      });
}

export default SegmentDataTable;