import { ShiftEntity } from "api/generated";
import { Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import DataTable from "./DataTable";
import { Box, Button } from "@mui/material";

interface DataTableProps {
    data?: any[];
    handleRowSelect: (rows: []) => void;
    openShift: (data: ShiftEntity) => void;
}



const ShiftDataTable: FC<DataTableProps> = (props) => {

    const { t } = useTranslation()
    const columns: any = useMemo(() => getColumnShape(), [t]);

    const timeOption: any = {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit'
    }

    function getColumnShape() {
        return [
            {
                minWidth: 50,
                Header: t('season.name'),

                Cell: ({ row }: CellProps<ShiftEntity>) => {
                    const { name } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{name}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                maxWidth: 100,
                Header: t('shift.startTime'),
                Cell: ({ row }: CellProps<ShiftEntity>) => {
                    const startTime = new Date(row.original.startTime).toLocaleTimeString(undefined, timeOption);
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{startTime}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                Header: t('shift.endTime'),
                Cell: ({ row }: CellProps<ShiftEntity>) => {
                    const endTime = new Date(row.original.endTime).toLocaleTimeString(undefined, timeOption);
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{endTime}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                Header: '  ',
                Cell: ({ row }: CellProps<ShiftEntity>) => {
                    return (
                        <Button
                            onClick={() => { props.openShift(row.original) }}
                        >
                            {t('shift.manage')}
                        </Button>
                    );
                },
            }
        ]
    }

    return(
        <Box maxHeight={300} overflow={'auto'}>
            {
                DataTable({
                    ...props,
                    columns,
                })
            }
        </Box>
    ) 

}
export default ShiftDataTable;

