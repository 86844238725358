import { Avatar, Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Switch } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import TabBase, { TabResourceProps } from './TabBase';
import { BuildingEntity, RoomEntity, RoomToSeasonEntity } from 'api/generated';
import { buildingApi, roomApi } from 'api';
import { H5, H6, Tiny } from 'components/Typography';
import defaultProfileImage from '../../assets/images/default-profile.png';
import { CustomDialog } from 'components/CustomDialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useTheme } from '@mui/material/styles';
import { useSeason } from 'contexts/SeasonContext';
import FlexBox from 'components/flexbox/FlexBox';



interface ExtendedBuildingEntity extends BuildingEntity {
  occupancy?: number;
  rooms?: Array<{ id: number; maxTenants: number }>;
}

export interface HousingFormData {
  id: number;
  name: string;
  buildingId: number;
  maxTenants: number;
  notes?: string;
  seasonNotes?: RoomToSeasonEntity[] | undefined;
}

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  filePath: string;
  avatar: string;
}

interface EmployeeWrapper {
  employee: Employee;
}

const Housing: FC<TabResourceProps<HousingFormData> & { currentEmployeeId: number }> = ({
  data,
  employeeToSeasonData,
  onSubmit,
  currentEmployeeId,
  fetchEmployee,
}) => {
  const { seasonId } = useSeason();
  const { t } = useTranslation();
  const [isViewing, setIsViewing] = useState(!!data);
  const [choosenBuilding, setChoosenBuilding] = useState<BuildingEntity>();
  const [choosenRoom, setChoosenRoom] = useState<RoomEntity>();
  const [roomSelectorState, setRoomSelectorState] = useState(false);
  const [exeedCapacityDialog, setExeedCapacityDialog] = useState(false);
  const [shouldResetFields, setShouldResetFields] = useState(false);
  const [roomEmployees, setRoomEmployees] = useState<Employee[]>([]);
  const [occupancyData, setOccupancyData] = useState<Array<{ id: number; name: string; availableTenants: number; maxTenants: number }>>([]);
  const [seasonNote, setSeasonNote] = useState(data?.seasonNotes?.at(0)?.notes);
  const theme = useTheme();
  const LOWER_THRESHOLD_PERCENT = 10;
  const UPPER_THRESHOLD_PERCENT = 35;

  const handleRoomSelectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoomSelectorState(event.target.checked);
    if (roomSelectorState) {
      setShouldResetFields(true);
      setChoosenBuilding(undefined);
      setChoosenRoom(undefined);
      // This set is necessary to remove an employee from the assigned room
      setFieldValue('id', -1, true);
      setFieldValue('buildingId', -1, true)
    }
    if (!roomSelectorState) {
      setShouldResetFields(false);
    }
  }

  const mapColor = (freeCapacity: number, maxCapacity: number): string => {
    const freeCapacityPercentage = (freeCapacity / maxCapacity) * 100;
    const shade = theme.palette.mode === 'dark' ? 'dark' : 'light';
    if (theme.palette) {
      if (freeCapacityPercentage <= LOWER_THRESHOLD_PERCENT || freeCapacity < 1) {
        return theme.palette.red?.[shade] || '';
      } else if (freeCapacityPercentage <= UPPER_THRESHOLD_PERCENT) {
        return theme.palette.orange?.[shade] || '';
      } else {
        return theme.palette.green?.[shade] || '';
      }
    }

    return '';
  }

  const [buildings, setBuildings] = useState<
    BuildingEntity[]
  >([]);
  const [rooms, setRooms] = useState<
    RoomEntity[]
  >([]);
  const initialValues: HousingFormData = {
    id: data?.id ?? -1,
    name: data?.name ?? "",
    buildingId: data?.buildingId ?? -1,
    maxTenants: data?.maxTenants ?? 0,
    notes: data?.notes ?? '',
    seasonNotes: data?.seasonNotes ?? [],
  };

  const handleExeedCapacityDialog = () => {
    setExeedCapacityDialog(false);
  };

  const handleOverwrite = () => {
    setExeedCapacityDialog(false);
    onSubmit(values);
  };

  const updateSeasonNotes = async () => {
    if (data?.seasonNotes?.at(0)) {
      if (choosenRoom?.id && seasonNote !== data?.seasonNotes?.at(0)?.notes) {
        await roomApi.updateNotes({
          roomId: choosenRoom.id,
          seasonId,
          notes: seasonNote ?? ""
        });
      }
    } else {

      if (choosenRoom?.id && seasonNote !== "") {
        await roomApi.createNotes({
          roomId: choosenRoom.id,
          seasonId,
          notes: seasonNote ?? ""
        });
      }
    }
  }


  const formikSubmit = (values: HousingFormData) => {

    updateSeasonNotes();

    /* 
    * If room id is -1 or indefinite and the room id taken from the database is -1 or indefinite it will not submit the form.
    * The control on roomSelectState is used to send the call if a room is never set and 
    * if the user doesn't want to set a room to the employee and return.
    */
    if ((values.id === -1 || values.id === undefined)
      && (data?.id === -1 || data?.id === undefined)
      && roomSelectorState) {
      return;
    }
    if (values.id !== data?.id && roomSelectorState) {
      const newRoom = rooms.find(room => room.id === values.id);
      const availabilityNewRoom = newRoom ? newRoom?.maxTenants - (newRoom?.employees ? newRoom.employees.length : 0) : 0;
      if (availabilityNewRoom < 1) {
        setExeedCapacityDialog(true);
        return;
      }
    }
    onSubmit(values);
  };

  useEffect(() => {
    Promise.all([
      buildingApi.findAll(seasonId).then(({ data: buildingData }) => {
        setBuildings(buildingData);
        const occupancyData = calculateOccupancy(buildingData);
        occupancyData.sort((a, b) => b.availableTenants - a.availableTenants);
        setOccupancyData(occupancyData);
        if (data?.buildingId) {
          setChoosenBuilding(buildingData.find(building => building.id === data.buildingId));
        }
      }),
      roomApi.findAll(undefined, seasonId).then(({ data: roomData }) => {
        setRooms(roomData);
        if (data?.id) {
          setChoosenRoom(roomData.find(room => room.id === data.id));
          if (roomData.find(room => room.id === data.id)) {
            setRoomSelectorState(true);
          }
        }
      }),
    ]);
  }, []);

  const calculateOccupancy = (buildingData: ExtendedBuildingEntity[]): Array<{ id: number, name: string; availableTenants: number; maxTenants: number }> => {
    return buildingData.map((building) => {
      const { rooms, occupancy = 0 } = building;
      let maxTenants = 0;

      if (rooms) {
        maxTenants = rooms.reduce((acc, room) => acc + (room.maxTenants ?? 0), 0);
      }

      const availableTenants = maxTenants - occupancy;
      return {
        id: building.id,
        name: building.name,
        availableTenants,
        maxTenants,
      };
    });
  };

  const { values, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit: formikSubmit,
  });

  async function fetchRoomEmployees() {
    if (values.id) {
      return roomApi.findOne(String(values.id), seasonId).then(({ data }) => {
        const employeesWithAvatars = (data?.employees as EmployeeWrapper[])?.filter((employeeWrapper: EmployeeWrapper) => employeeWrapper.employee.id !== currentEmployeeId)
          .map((employeeWrapper: EmployeeWrapper) => {
            const avatar = employeeWrapper.employee?.filePath || defaultProfileImage;
            return { ...employeeWrapper.employee, avatar };
          }) ?? [];
        setRoomEmployees(employeesWithAvatars);
      });
    }
    setRoomEmployees([]);
  }

  async function fetchRoomNotes() {
    if (values.id) {
      return roomApi.getNotes(values.id, seasonId).then(({ data }) => {
        const roomNotes = data?.notes ? data.notes : "";
        setSeasonNote(roomNotes);
      });
    }
  }



  const navigateToEmployee = (id: number) => {
    if (fetchEmployee)
      fetchEmployee(id);
  }

  useEffect(() => {
    if (values.id < 0) {
      return;
    }
    if (values.id) {
      fetchRoomEmployees();
      fetchRoomNotes();
    }
    else {
      setRoomEmployees([]);
    }
  }, [values.id, seasonId]);

  return (
    <>
      <TabBase
        title={t('housing.title')}
        setIsViewing={setIsViewing}
        isViewing={isViewing}
        handleSubmit={handleSubmit}
      >
        <CustomDialog
          open={exeedCapacityDialog}
          onClose={handleExeedCapacityDialog}
          onConfirm={handleOverwrite}
          title={t("housing.exceedCapacity.dialog.title")}
          content={t("housing.exceedCapacity.dialog.content")}
          confirmColor='warning'
          cancelColor='primary'
          confirmText={t("housing.exceedCapacity.dialog.confirm")}
          icon={<CheckCircleOutlinedIcon />}
        />
        <Grid item xs={12}>
          <Tiny fontWeight={500}>
            {t('housing.assignRoom')}
          </Tiny>
          <Switch
            disabled={isViewing}
            name='roomSelector'
            onBlur={handleBlur}
            checked={roomSelectorState}
            onChange={handleRoomSelectorChange}
          />
          <H6 mb={3}></H6>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="buildingLabel">{t('housing.itemName')}</InputLabel>
              <Select
                labelId='buildingLabel'
                label={t('housing.itemName')}
                disabled={isViewing || !roomSelectorState}
                fullWidth
                name='buildingName'
                variant='outlined'
                input={<OutlinedInput label={t('housing.itemName')} />}
                onChange={(event: SelectChangeEvent<number>) => {
                  const newValue = event.target.value;
                  const selectedBuilding = buildings.find(building => building.id === newValue);
                  setRoomEmployees([]);
                  setChoosenRoom(undefined);
                  setFieldValue('id', -1);
                  setChoosenBuilding(selectedBuilding);
                  handleChange(event)
                }}
                onBlur={handleBlur}
                value={shouldResetFields ? -1 : (choosenBuilding ? choosenBuilding.id : -1)}
              >
                <MenuItem value={-1}>
                  <span>{t('housing.default')}</span>
                </MenuItem>

                {occupancyData.map((building) => (
                  <MenuItem value={building.id} key={building.id}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <span>{building.name}</span>
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '5px 10px',
                          borderRadius: '50px',
                          backgroundColor: mapColor(building.availableTenants, building.maxTenants)
                        }}
                      >
                        {building.availableTenants}/{building.maxTenants}
                      </span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="roomLabel">{t('housing.room.label')}</InputLabel>
              <Select
                labelId="roomLabel"
                disabled={isViewing || !choosenBuilding}

                label={t('housing.itemName')}
                name='roomName'
                variant='outlined'
                onChange={(event: SelectChangeEvent<number>) => {
                  const newValue = event.target.value;
                  const selectedRoom = rooms.find(room => room.id === newValue);
                  setFieldValue("id", selectedRoom?.id, true);
                  setChoosenRoom(selectedRoom);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                value={shouldResetFields ? -1 : (choosenRoom ? choosenRoom.id : -1)}
              >
                <MenuItem value={-1}>
                  {t('housing.room.default')}
                </MenuItem>
                {rooms
                  // Show only rooms inside the currently selected building
                  .filter((room) => room.buildingId === choosenBuilding?.id)
                  // Calculate how many beds are available in the rooms
                  .map((room) => {
                    const availableTenants = room.maxTenants - (room.employees ? room.employees.length : 0);
                    return { availableTenants, ...room };
                  })
                  // Sort by showing the empties rooms first
                  .sort((a, b) => b.availableTenants - a.availableTenants)
                  // Map rooms to MUI Menu Items
                  .map((room) => (
                    <MenuItem key={room.id} value={room.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <span>{room.name}</span>
                        <span
                          style={{
                            display: 'inline-block',
                            padding: '5px 10px',
                            borderRadius: '50px',
                            backgroundColor: mapColor(room.availableTenants, room.maxTenants)
                          }}
                        >
                          {room.availableTenants}/{room.maxTenants}
                        </span>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

          </Grid>
        </Grid>
      </TabBase>
      {choosenBuilding && choosenRoom && (
      <TabBase
        title={t('housing.seasonNote')}
        setIsViewing={() => false}
        isViewing={false}
      >
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <FormControl fullWidth margin="normal" >
            <InputLabel htmlFor="notes">{t('housing.notes.label')}</InputLabel>
            <OutlinedInput
              id="notes"
              name="notes"
              label={t('housing.notes.label')}
              value={seasonNote}
              onChange={(e) => {
                setSeasonNote(e.target.value);
              }}
              onBlur={handleBlur}
              disabled={isViewing}
              multiline
              rows={2}
            />
          </FormControl>
        </Grid>
      </TabBase>
      )}

      {choosenBuilding && choosenRoom && (
        <TabBase
          titleComponent={<FlexBox minWidth={"100%"} justifyContent={'space-between'}><H5 padding={3}>{t('housing.assignedEmployees')}</H5> <H5 padding={3}>{t('housing.roomNotes')}</H5></FlexBox>}
          title=''
          setIsViewing={() => false}
          isViewing={false}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {roomEmployees.map((employee, index) => (

                <Grid container item xs={12} key={employee.id} sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
                  <Avatar src={employee.avatar} alt='Avatar' sx={{ width: 30, height: 30, marginRight: '0.5rem' }} />
                  <Box
                    sx={{ flexGrow: 1, padding: 1, cursor: "pointer", "&:hover": { backgroundColor: theme.palette.action.hover, borderRadius: 2 } }}

                    onClick={() => {
                      navigateToEmployee(employee.id);
                    }}>
                    {employee.firstName} {employee.lastName}
                  </Box>
                </Grid>
              ))}
            </Grid>
            {choosenRoom?.notes && (
              <Grid item xs={6} sx={{ textAlign:"right", flexWrap:'initial', maxWidth:"150px"}}>
                <Box sx={{ flexShrink: 0, paddingX: 2 }}>
                  <Tiny fontStyle="italic">{choosenRoom.notes}</Tiny>
                </Box>
              </Grid>
            )}
          </Grid>
        </TabBase>
      )}
    </>
  );
};

export default Housing;
