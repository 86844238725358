import { depositsApi } from "api";
import { DepartmentEntity, DepositEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from "api/generated";
import { addDays, format, isSameDay } from "date-fns";
import * as ExcelJS from 'exceljs';
import { t } from "i18next";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { ignoreUTC } from "utils/utils";

async function addLogoImageToReport(worksheet: ExcelJS.Worksheet, workbook: ExcelJS.Workbook) {
    const imageSrc = "/static/logo/logo_onestaff_new.png";
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    const logo = workbook.addImage({
        buffer,
        extension: 'png',
    });
    worksheet.addImage(logo, {
        tl: { col: 0.3, row: 0.3 },  // Coordinates
        ext: { width: 120, height: 80 } // Image size
    });
}

const getDates = (start: Date, end: Date) => {
    if (start <= end) {
        const dates: Date[] = []
        let date = new Date(start);
        while (date.getTime() <= end.getTime()) {
            dates.push(date)
            date = addDays(date, 1);
        }
        return dates;
    }
    return [];
};

const getColumnLetter = (columnIndex: number) => {
    let letter = '';
    let temp = columnIndex;
    while (temp > 0) {
        const remainder = (temp - 1) % 26;
        letter = String.fromCharCode(65 + remainder) + letter;
        temp = Math.floor((temp - 1) / 26);
    }
    return letter;
}

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'OneStaff';
    workbook.lastModifiedBy = 'OneStaff';
    workbook.created = new Date();
    return workbook;
}

const createWoksheet = (workbook: ExcelJS.Workbook) => {
    const worksheet = workbook.addWorksheet(t('turnover.reportName'));
    worksheet.properties.defaultColWidth = 35;
    worksheet.properties.defaultRowHeight = 20;
    return worksheet;
}

const addTitleRow = (worksheet: ExcelJS.Worksheet, columnsNumber: number) => {
    const titleRow = worksheet.getRow(1);
    const lastTitleRowCell = titleRow.getCell(columnsNumber - 1);
    lastTitleRowCell.merge(titleRow.getCell(1));
    titleRow.getCell(1).value = t('turnover.reportName');
    titleRow.height = 30;
    titleRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 14,
        bold: true,
    };
}

const styleWorksheet = (worksheet: ExcelJS.Worksheet, rowsNumber: number, columnsNumber: number) => {
    for (let i = 1; i < rowsNumber; i++) {
        for (let j = 1; j < columnsNumber; j++) {
            const cell = worksheet.getCell(i, j);
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.numFmt = '0.00'
            if (i % 2 === 0) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd6eaf8' }
                };
            } else {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'aed6f1' }
                };
            }
        }
    }
}

const addHeaderRows = (
    worksheet: ExcelJS.Worksheet,
    rowsNumber: number,
    departments: DepartmentEntity[],
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    pmLength: number
) => {
    const departmentsRow = worksheet.getRow(2);
    const sectionsRow = worksheet.getRow(3);
    const methodsRow = worksheet.getRow(4);

    departmentsRow.height = 25;
    departmentsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    sectionsRow.height = 25;
    sectionsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    methodsRow.height = 25;
    methodsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    // Fill paymentMethods header row
    let precedentCells = 0;
    let downPaymentColumns = 0;
    for (let i = 0; i < sections.length; i++) {
        let sectionWithDownPayment = false;
        if (sections[i].paymentMethods) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            for (let j = 0; j < sections[i].paymentMethods!.length; j++) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                methodsRow.getCell(precedentCells + 2).value = translatePaymentTypes(sections[i].paymentMethods![j].type, t);
                precedentCells = precedentCells + 1;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (sections[i].paymentMethods![j].type.toLowerCase().includes('downpayment')) {
                    sectionWithDownPayment = true;
                }
            }
        }
        methodsRow.getCell(precedentCells + 2).value = t('turnover.report.totSection');
        precedentCells = precedentCells + 1;

        if (sectionWithDownPayment) {
            methodsRow.getCell(precedentCells + 2).value = t('turnover.report.downpaymentSection');
            methodsRow.getCell(precedentCells + 3).value = t('turnover.report.amountSection') + '\n' + t('turnover.report.amountFormula')

            precedentCells = precedentCells + 2;
            downPaymentColumns = downPaymentColumns + 2;
        }
    }
    const totHeaderCell = departmentsRow.getCell(pmLength + 2 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + 2 + downPaymentColumns + sections.length).merge(totHeaderCell);
    totHeaderCell.value = t('turnover.report.total');
    totHeaderCell.border = {
        left: {
            style: 'thin',
            color: { argb: 'FF000000' },
        },
        right: {
            style: 'thin',
            color: { argb: 'FF000000' },
        }
    }

    const totDownPaymentHeaderCell = departmentsRow.getCell(pmLength + 3 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + 3 + downPaymentColumns + sections.length).merge(totDownPaymentHeaderCell);
    totDownPaymentHeaderCell.value = t('turnover.report.totalDownpayment');

    const totAmountHeaderCell = departmentsRow.getCell(pmLength + 4 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + 4 + downPaymentColumns + sections.length).merge(totAmountHeaderCell);
    totAmountHeaderCell.value = t('turnover.report.totalAmount') +'\n' + t('turnover.report.amountFormula');;
    totAmountHeaderCell.border = {
        right: {
            style: 'thin',
            color: { argb: 'FF000000' },
        }
    }

    for (let i = 0; i < paymentMethods.length; i++) {
        const cell = departmentsRow.getCell(pmLength + 5 + i + downPaymentColumns + sections.length);
        methodsRow.getCell(pmLength + 5 + i + downPaymentColumns + sections.length).merge(cell);
        cell.value = 'TOT\n' + translatePaymentTypes(paymentMethods[i].type, t);
    }

    const depositsHeaderCell = departmentsRow.getCell(pmLength + paymentMethods.length + 5 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + paymentMethods.length + 5 + downPaymentColumns + sections.length).merge(depositsHeaderCell);
    depositsHeaderCell.value = t('turnover.report.deposits');
    depositsHeaderCell.border = {
        left: {
            style: 'thin',
            color: { argb: 'FF000000' }
        }
    }

    const toBePaidHeaderCell = departmentsRow.getCell(pmLength + paymentMethods.length + 6 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + paymentMethods.length + 6 + downPaymentColumns + sections.length).merge(toBePaidHeaderCell);
    toBePaidHeaderCell.value = t('depositable').toUpperCase().replace(' - ', '\n');

    // Fill and merge cells in sections header row
    precedentCells = 0;
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].paymentMethods) {
            let sectionWithDownPayment = false;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            for (const pm of sections[i].paymentMethods!) {
                if (pm.type.toLowerCase().includes('downpayment')) {
                    sectionWithDownPayment = true;
                }
            }
            const cell = sectionsRow.getCell(precedentCells + 2);
            cell.value = sections[i].name;
            const columnIndex = precedentCells + 2 + ((sections[i].paymentMethods ?? []).length + (sectionWithDownPayment ? 2 : 0))
            sectionsRow.getCell(columnIndex).merge(cell);
            for (let j = sectionsRow.number; j < rowsNumber; j++) {
                worksheet.getCell(j, columnIndex).border = {
                    right: {
                        style: 'thin',
                        color: { argb: 'FF000000' }
                    }
                };
            }
            precedentCells = columnIndex - 1;
        }
    }

    // Fill departments header row and merge cells
    precedentCells = 0;
    for (let i = 0; i < departments.length; i++) {
        const depSections = sections.filter((sec) => {
            return sec.departmentId === departments[i].id;
        })
        const depSectionsWithDownPayment = depSections.filter((sec) => {
            return sec.paymentMethods?.find((pm) => {
                return pm.type.toLowerCase().includes('downpayment');
            })
        })
        const c1 = departmentsRow.getCell(2 + precedentCells);
        c1.value = departments[i].name;

        let depSectionsLength = 0;
        for (const depSection of depSections) {
            if (depSection.paymentMethods)
                depSectionsLength = depSectionsLength + depSection.paymentMethods.length;
        }
        const c2 = departmentsRow.getCell(1 + precedentCells + depSectionsLength + depSections.length + 2 * depSectionsWithDownPayment.length);
        precedentCells = precedentCells + depSectionsLength + depSections.length + 2 * depSectionsWithDownPayment.length;
        c2.merge(c1);

        for (let j = departmentsRow.number; j < rowsNumber; j++) {
            worksheet.getCell(j, 1 + precedentCells).border = {
                right: {
                    style: 'thin',
                    color: { argb: 'FF000000' }
                }
            };
        }
    }
}

const addDatesRows = async (
    worksheet: ExcelJS.Worksheet,
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    dates: Date[],
    pmLength: number,
    columnsNumber: number,
    seasonId: number
) => {
    for (let i = 0; i < dates.length; i++) {
        const totPaymentMethods = new Map<number, number>();
        const dateRow = worksheet.getRow(i + 5);
        const dateCell = dateRow.getCell(1);
        dateCell.value = format(dates[i], 'dd/MM/yyyy');
        let totDownPayment = 0;
        let totDate = 0;
        let precedentCells = 1;
        let downPaymentColumns = 0;
        for (let j = 0; j < sections.length; j++) {
            let totSection = 0;
            let totSectionDownPayment = 0;
            let sectionWithDownPayment = false;
            if (sections[j].paymentMethods) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                for (let k = 0; k < sections[j].paymentMethods!.length; k++) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const pm = sections[j].paymentMethods![k];
                    const cell = dateRow.getCell(precedentCells + 1);
                    precedentCells = precedentCells + 1;
                    let value = 0;
                    const items = turnoverItems.filter((item) => {
                        return (
                            item.paymentSectionId === sections[j].id &&
                            item.paymentMethodId === pm.id &&
                            isSameDay(new Date(item.date), ignoreUTC(dates[i]))
                        );
                    });
                    for (const item of items) {
                        value = value + item.value;
                    }
                    cell.value = value;
                    totDate = totDate + value;
                    totSection = totSection + value;
                    if (pm.type.toLowerCase().includes('downpayment')) {
                        sectionWithDownPayment = true;
                        totDownPayment = totDownPayment + value;
                        totSectionDownPayment = totSectionDownPayment + value;
                    }
                    totPaymentMethods.set(pm.id, (totPaymentMethods.get(pm.id) ?? 0) + value);
                }
            }
            const sectionTotCell = dateRow.getCell(precedentCells + 1);
            sectionTotCell.value = totSection;
            precedentCells = precedentCells + 1;

            if (sectionWithDownPayment) {
                downPaymentColumns = downPaymentColumns + 2;
                const sectionDownPaymentCell = dateRow.getCell(precedentCells + 1);
                sectionDownPaymentCell.value = totSectionDownPayment;

                const sectionAmountCell = dateRow.getCell(precedentCells + 2);
                sectionAmountCell.value = totSection - totSectionDownPayment;

                precedentCells = precedentCells + 2;
            }
        }
        // Fill the cell with total turnover of the date
        const totCell = dateRow.getCell(2 + pmLength + sections.length + downPaymentColumns);
        totCell.value = totDate;
        totCell.border = {
            left: {
                style: 'thin',
                color: { argb: 'FF000000' }
            },
            right: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        };

        const downPayment = dateRow.getCell(3 + pmLength + sections.length + downPaymentColumns);
        downPayment.value = totDownPayment;
        downPayment.border = {};

        const amountCell = dateRow.getCell(4 + pmLength + sections.length + downPaymentColumns);
        amountCell.value = totDate - totDownPayment;
        amountCell.border = {
            right: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        };

        // Fill the cells with total turnover by payment method
        for (let j = 0; j < paymentMethods.length; j++) {
            const value = totPaymentMethods.get(paymentMethods[j].id);
            const cell = dateRow.getCell(5 + j + pmLength + sections.length + downPaymentColumns);
            cell.value = value ?? 0
        }

        // Fill the cell with total deposit of the date
        const depositsCell = dateRow.getCell(5 + pmLength + paymentMethods.length + sections.length + downPaymentColumns);
        const dateDeposits = deposits.filter((deposit) => {
            return isSameDay(new Date(deposit.date), ignoreUTC(dates[i]));
        })
        let depositValue = 0;
        for (const deposit of dateDeposits) {
            depositValue = depositValue + deposit.value;
        }
        depositsCell.value = depositValue;
        depositsCell.border = {
            left: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        };

        // Fill the cell with cumulative to be paid import of the date
        const toBePaidCell = dateRow.getCell(6 + pmLength + paymentMethods.length + sections.length + downPaymentColumns);
        const toBePaidValue = (await depositsApi.getDateSum(dates[i].getTime(), seasonId)).data;
        toBePaidCell.value = toBePaidValue;
    }
    const totRow = worksheet.getRow(5 + dates.length)
    totRow.getCell(1).value = 'TOT';
    totRow.getCell(1).border = {
        top: {
            style: 'thin',
            color: { argb: 'FF000000' }
        }
    }
    for (let i = 2; i < columnsNumber - 1; i++) {
        const cell = totRow.getCell(i);
        const cellColumn = getColumnLetter(Number(cell.col));
        cell.value = {
            formula: 'SUM(' + cellColumn + '5:' + cellColumn + String(4 + dates.length) + ')',
            result: undefined,
            date1904: false
        };
        cell.border = {
            top: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        }
    }
}

const createReport = async (
    departments: DepartmentEntity[],
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    startDate: Date,
    endDate: Date,
    seasonId: number
) => {
    const dates = getDates(startDate, endDate);

    let pmLength = 0;
    for (const section of sections) {
        if (section.paymentMethods)
            pmLength = pmLength + section.paymentMethods?.length;
    }

    const sectionsWithDownPayment = sections.filter((sec) => {
        return sec.paymentMethods?.find((pm) => {
            return pm.type.toLowerCase().includes("downpayment");
        })
    })

    const columnsNumber = 7 + pmLength + paymentMethods.length + sections.length + sectionsWithDownPayment.length * 2;

    const rowsNumber = dates.length + 6;

    const workbook = createWorkbook();
    const worksheet = createWoksheet(workbook);

    addTitleRow(worksheet, columnsNumber);

    styleWorksheet(worksheet, rowsNumber, columnsNumber);

    addHeaderRows(worksheet, rowsNumber, departments, sections, paymentMethods, pmLength);

    await addDatesRows(worksheet, sections, paymentMethods, turnoverItems, deposits, dates, pmLength, columnsNumber, seasonId);

    await addLogoImageToReport(worksheet, workbook);

    return workbook;
}

export const downloadReport = async (
    departments: DepartmentEntity[],
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    startDate: Date,
    endDate: Date,
    seasonId: number
) => {
    const filteredSections = sections.filter((sec) => {return sec.paymentMethods && sec.paymentMethods.length > 0 })

    const workbook = await createReport(
        [...departments].sort((a, b) => a.id - b.id).filter((dep) => {
            return filteredSections.find((sec) => {
                return sec.departmentId === dep.id;
            })
        }),
        filteredSections.sort((a, b) => a.departmentId - b.departmentId),
        paymentMethods,
        [...turnoverItems].filter((item) => item.status === 'CONFIRMED'),
        deposits,
        startDate,
        endDate,
        seasonId
    );
    workbook.xlsx.writeBuffer().then(function (buffer: ArrayBuffer) {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
        const downloadLink = document.createElement('a');

        // Use the blob to set the href for the link
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;

        // Set the download attribute for the link to specify the filename
        downloadLink.download = '.report.xlsx';

        // Append the link to the document, trigger a click, and then remove it
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    });
}