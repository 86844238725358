import { Alert, Autocomplete, BottomNavigation, BottomNavigationAction, Box, Card, Divider, Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { departmentsApi, employeesApi } from "api";
import EmployeeContract from "page-sections/employees/report-components/employee-contract";
import EmployeeCost from "page-sections/employees/report-components/employee-cost";
import EmployeeStats from "page-sections/employees/report-components/employee-stats";
import { EmployeeEntity } from "api/generated";
import { useLocation } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import { H6 } from "components/Typography";
import { useSeason } from "contexts/SeasonContext";
import emptyTableImage from 'assets/images/empty-table.png';
import CustomToast from "components/CustomToast/customToast";
import PaymentsTable from "page-sections/employees/report-components/payments-table";
import EmployeeTotalPayments from "page-sections/employees/report-components/employee-payments-total";
import AddSinglePaymentModal from "page-sections/data-table/dataTableV2/AddSinglePaymentModal";
import { useEncryption } from "contexts/EncryptionKeyContext";
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import { fetchCurrentPaymentData, generateCumulativePayouts, generateDailyNetEarnings, getEmployeeReport } from "./reportUtils";
import { range } from "lodash";
import AppSelectField from "components/input-fields/MultipleChoiceField";
import { useEmployeeContext } from '../../contexts/EmployeeContext';
import CalendarInput from "components/input-fields/CalendarInput";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AvatarBadge from "components/avatars/AvatarBadge";
import { ImageWrapper, DepartmentAssignment } from './new-employee';
import AppAvatar from "components/avatars/AppAvatar";
import defaultProfileImage from '../../assets/images/default-profile.png';
import EmployeeAgreement from "page-sections/employees/report-components/employee-agreement";
import EmployeeDaysOff from "page-sections/employees/report-components/employee-days-off";
import EmployeeCostCumulative from "page-sections/employees/report-components/employee-cost-cumulative";
import { CoverPicWrapper, ContentWrapper } from './calendar';
import { getFullNameWithIdConditionally } from 'utils/utils';
import BarChartIcon from '@mui/icons-material/BarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { parse } from "date-fns";

const defaultData = {
    name: "",
    contract: {
        startDate: '-',
        endDate: '-',
        days: '-',
        totalDaysOff: '-',
        daysOff: '-',
    },
    agreements: {
        avgNetDaily: '-',
        monthly: '-',
        total: '-',
    },
    stats: {
        unpaidLeave: '-',
        ill: '-',
        vacation: '-',
        seniority: '-'
    },
    chart: {
        paid: [],
        due: [],
        credit: []
    },
    paymentsData: []
}



const EmployeesReport: FC = () => {

    const { state } = useLocation();
    const [employeeId, setEmployeeId] = useState<number>()
    const [employee, setEmployee] = useState<EmployeeEntity>();
    const season = useSeason();
    const [data, setData] = useState<any>(defaultData);
    const [employees, setEmployees] = useState<EmployeeEntity[] | any>(undefined);
    const [showToast, setShowToast] = useState(false);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [dailyEarining, setDailyEarning] = useState<Map<string, number>>();
    const [dailyPayout, setDailyPayout] = useState<Map<string, number>>();
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [isUnlimited, setIsUnlimited] = useState(false);
    const { encryptionKey, setPopoverOpen } = useEncryption();
    const [hasEncryptedAgreements, setHasEncryptedAgreements] = useState(false);
    const [year, setYear] = useState<number>();
    const [validYears, setValidYears] = useState<number[]>([]);
    const [showAllPayments, setShowAllPayments] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [filteredEmployees, setFilteredEmployees] = useState<EmployeeEntity[]>([]);
    const [displayAvatar, setDisplayAvatar] = useState<string>(defaultProfileImage);
    const [allDepartmentsState, setAllDepartmentsState] = useState<string[]>([]);
    const [selectedDepartmentsState, setSelectedDepartmentsState] = useState<string[]>([]);
    const [value, setValue] = useState(1);
    const theme = useTheme();
    const employeeContext = useEmployeeContext();
    const fetchData = async (update: boolean = false) => {

        const employeesEntities = await employeesApi.findAll({
            params: {
                seasonId: season.seasonId,
                excludeNoContractInThisSeason: true,
            },
        });

        setEmployees(employeesEntities.data)
        if (!update && state?.employeeId) {
            setEmployeeId(state.employeeId);
            employeeContext.setEmployeeId(state.employeeId);
            state.employeeId = null;
        }
        if (!update && employeeContext.employeeId !== -1) {
            setEmployeeId(employeeContext.employeeId);
        }



    }
    const changeEmployee = async (direction: 'next' | 'previous') => {
        if (filteredEmployees && filteredEmployees.length > 0 && employeeId) {
            const employeeIds = filteredEmployees.map((employee) => employee.id);
            // The change function is only available after the employee's instance has been created and/or already existed
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const currentIndex = employeeIds.indexOf(employeeId);
            let nextIndex: number;
            if (direction === 'next') {
                nextIndex = (currentIndex + 1) % employeeIds.length;
            } else if (direction === 'previous') {
                nextIndex = (currentIndex - 1 + employeeIds.length) % employeeIds.length;
            } else {
                return;
            }
            employeeContext.setEmployeeId(employeeIds[nextIndex]);
            setEmployeeId(employeeIds[nextIndex])
            fetchData(true);
        }
        else {
            // setOpenSnackbar(true);
        }
    };

    const fetchDepartments = async () => {
        const departments = (await departmentsApi.findAll()).data;
        setAllDepartmentsState(departments.map(value => value.name))
    }

    const fetchReportData = async () => {
        try {
            if (employeeId) {
                const employeeEntity = await employeesApi.findOne(employeeId, {
                    params: {
                        seasonId: season.seasonId,
                    },
                });
                setEmployee(employeeEntity.data);
                getEmployeeReport(employeeId, season.seasonId, encryptionKey, setHasEncryptedAgreements, setIsUnlimited, year, selectedDate)
                    .then((data) => {
                        fetchCurrentPaymentData(employeeId, isUnlimited ? null : season.seasonId, data?.contarctId ?? null, encryptionKey, setTotalEarned, setTotalPaid, selectedDate);
                        setData(data);
                        fetchDepartments();
                        selectedDate.setHours(0, 0, 0, 0)

                    });

            }
        } catch (error: any) {
            if (error.response?.status === 400) {
                // show toast only if season is defined
                if (season.seasonId)
                    setShowToast(true);
                setEmployee(undefined);
                employeeContext.setEmployeeId(-1);
                setData(defaultData);
            }
        }
    }
    useEffect(() => {

        async function setDailyEarningFunction() {
            if (data?.contract && employeeId) {


                const endDate = parseDate(data.contract.endDate, year);

                const dailyNetEarningsMap = await generateDailyNetEarnings(employeeId, season.seasonId, data.contract.id, encryptionKey, data.contract.startDate, endDate);
                setDailyEarning(dailyNetEarningsMap);

                const dailyPayoutMap = await generateCumulativePayouts(data.paymentsData, data.contract.startDate, data.contract.endDate);
                setDailyPayout(dailyPayoutMap);

            }
        }
        setDailyEarningFunction();
    }, [data])
    const parseDate = (date: string | null, fallbackYear: number = new Date().getFullYear()): string => {
        if (!date) {
            return `${fallbackYear}-12-31`;
        }
        const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
        if (isNaN(parsedDate.getTime())) {
            return `${fallbackYear}-12-31`;
        }
        return parsedDate.toISOString().slice(0, 10);
    };

    const handleFiltersChange = (value?: string[]) => {
        const filteredEmployees = value ? employees.filter((employee: any) =>
            value.some((selectedDepartment) =>
                employee.employeesToDepartments.some(
                    (assignment: DepartmentAssignment) => assignment.department.name === selectedDepartment
                )
            )
        )
            : employees;
        setFilteredEmployees(filteredEmployees);
    };

    useEffect(() => {
        handleFiltersChange(selectedDepartmentsState.length > 0 ? selectedDepartmentsState : undefined);
    }, [employees, selectedDepartmentsState])

    const handleToastClose = () => {
        setShowToast(false);
    };

    useEffect(() => {
        fetchData();
        fetchReportData();
    }, [season])

    useEffect(() => {
        fetchReportData();
    }, [employeeId, encryptionKey, year, selectedDate])

    useEffect(() => {
        if (employee?.filePath)
            setDisplayAvatar(employee?.filePath);
        else
            setDisplayAvatar(defaultProfileImage);
    }, [employee]);

    useEffect(() => {

        const today = new Date();
        const startDate = formatDateFromIT(data?.contract?.startDate);
        if (!startDate) { return; }
        if (year) {
            if (today.getFullYear() === year) {
                if (+startDate > +today)
                    setSelectedDate(startDate);
                else
                    setSelectedDate(today);
            }
            else if (today.getFullYear() < year) {
                setSelectedDate(new Date(year, 0, 1));
            }

            else if (today.getFullYear() > year)
                setSelectedDate(new Date(year, 11, 31));
        }

        else {
            if (+startDate > +today)
                setSelectedDate(startDate);
            else
                setSelectedDate(today);
        }

    }, [data?.contract?.startDate])


    useEffect(() => {
        if (isUnlimited) {
            const startYear = new Date(data.effectiveContractStartDate).getFullYear() ?? 2023;
            const endYear = (new Date()).getFullYear() + 2;
            const years = range(startYear, endYear);
            setValidYears(years);
            const yearIndex = years.findIndex(value => value === new Date().getFullYear());
            if (!year && yearIndex !== -1)
                setYear(years[yearIndex]);
        }
        else {
            setYear(undefined);
            setValidYears([]);
        }
    }, [isUnlimited, data])

    function formatDateFromIT(date: string) {
        if (!date) { return; }
        const formattedDate = date.split('/');
        const startUTCDate = Date.UTC(Number(formattedDate[2]), Number(formattedDate[1]) - 1, Number(formattedDate[0]));
        return new Date(startUTCDate);
    }


    const { t } = useTranslation();

    return (
        <Grid container spacing={3} paddingRight={3}>
            {showToast && (
                <CustomToast
                    message={(t('alerts.employeeNotFoundInSeason'))}
                    onClose={handleToastClose}
                    duration={3000}
                />
            )}

            {employee && (
                <AddSinglePaymentModal
                    employee={employee}
                    open={openPaymentModal}
                    onClose={() => {
                        setOpenPaymentModal(false);
                        fetchReportData();
                    }}
                />
            )}
            <Grid item md={3} xs={12}>
                <Card sx={{ padding: 3, position: 'relative' }}>
                    <CoverPicWrapper>
                        <img
                            width='100%'
                            height='100%'
                            alt='Team Member'
                            src='/static/background/user-cover-pic.png'
                            style={{ objectFit: 'cover' }}
                        />
                    </CoverPicWrapper>

                    <ContentWrapper>
                        <FlexBox justifyContent='center'>
                            <AvatarBadge>
                                <ImageWrapper>
                                    <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='Team Member' sizes='large' />
                                </ImageWrapper>
                            </AvatarBadge>
                        </FlexBox>

                        <Box mt={2}>
                            {employeeId &&
                                <Divider style={{ marginBottom: "1rem" }}>{t("account.basicInformation.selectEmployee")}</Divider>
                            }
                            <Autocomplete
                                id="employee-selector"
                                disableClearable
                                options={filteredEmployees ? getFullNameWithIdConditionally(filteredEmployees.sort((a: EmployeeEntity, b: EmployeeEntity) => {
                                    if (a.lastName < b.lastName) return -1;
                                    if (a.lastName > b.lastName) return 1;
                                    if (a.firstName < b.firstName) return -1;
                                    if (a.firstName > b.firstName) return 1;
                                    return 0;
                                })) : []}
                                getOptionLabel={(option) => option.fullName}
                                renderInput={(params) => <TextField {...params} label={t('dashboard.employees.expiringContracts.table.employee')} />}
                                onChange={(event, value) => {
                                    const selectedEmployeeId = value.id;
                                    setEmployeeId(selectedEmployeeId);
                                    employeeContext.setEmployeeId(selectedEmployeeId)
                                }}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography component="span">
                                            {option.fullName}
                                            {option.showId && (
                                                <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.6rem', color: 'gray' }}>
                                                    {' ID ' + option.id + ''}
                                                </Typography>
                                            )}
                                        </Typography>
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                                value={employee ? { id: employee.id, fullName: employee.firstName + ' ' + employee.lastName, showId: false } : { id: 0, fullName: t('report.selectEmployee'), showId: false }}
                            />
                            {employeeId &&
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>

                                    <IconButton onClick={() => { changeEmployee("previous"); }} size='small'>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <H6 fontStyle={"italic"} alignContent={"center"}>{t("account.basicInformation.changeEmployee")}</H6>
                                    <IconButton onClick={() => { changeEmployee("next"); }} size='small'>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            }
                        </Box>
                    </ContentWrapper>
                    {employeeId ?
                        <>
                            <Divider style={{ marginTop: "1rem" }}>{t("account.basicInformation.filterEmployees")}</Divider>
                            <Autocomplete
                                multiple
                                id="filters"
                                size="small"
                                options={allDepartmentsState}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={t("account.basicInformation.filterPlaceholder")} />
                                )}
                                onChange={(e, value) => {
                                    setSelectedDepartmentsState(value);
                                    // handleFiltersChange(value.length > 0 ? value : undefined);

                                }}
                                style={{ marginTop: '1rem' }}
                            />
                            <Divider style={{ marginTop: "1rem" }}>{t('report.selectDate')}</Divider>
                            {data?.contract &&
                                <CalendarInput
                                    format='dd.MM.yyyy'
                                    minDate={formatDateFromIT((data?.contract?.startDate as string))}
                                    label={t('report.selectDate')}
                                    disableFuture={false}
                                    localeText={{
                                        fieldDayPlaceholder: () => t("DD"),
                                        fieldMonthPlaceholder: () => t("MM"),
                                        fieldYearPlaceholder: () => t("YYYY"),
                                    }}
                                    value={selectedDate}
                                    onChange={async (newValue) => {
                                        if (newValue && !isNaN(new Date(newValue).getTime())) {
                                            const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                            setSelectedDate(utcDate);
                                        }
                                    }}
                                    sx={{ marginTop: '1rem' }}

                                />
                            }
                        </> : ''
                    }

                    {isUnlimited ? (<>
                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }}>{t("report.selectYear")}</Divider>

                        <AppSelectField
                            InputLabelProps={{ shrink: true }}
                            select
                            fullWidth
                            name='typeName'
                            variant='outlined'
                            placeholder=''
                            value={year}
                            defaultValue={validYears[0]}
                            SelectProps={{
                                native: true,
                                IconComponent: KeyboardArrowDown,
                            }}
                            sx={{ display: 'flex', width: '100%' }}

                            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                if (e.target.value)
                                    setYear(Number(e.target.value));
                            }}>
                            {
                                validYears.map((year) => {
                                    return (<option value={year}> {year} </option>)
                                })
                            }
                        </AppSelectField>
                    </>) : (<></>)}
                </Card>
            </Grid>





            <Grid item md={9} xs={12}>
                <Grid container spacing={3}>
                    {data ? (
                        <>
                            {hasEncryptedAgreements && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        {t('encryption.report.alert')}
                                    </Alert>
                                </Grid>
                            )}
                            {employee?.employeesToSeasons?.at(0)?.contract?.isDraft && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        {t('contract.isDraft')}
                                    </Alert>
                                </Grid>
                            )

                            }
                            <Grid container spacing={3} paddingLeft={3} paddingTop={3}>
                                <Grid item md={8} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <EmployeeContract
                                                contract={data?.contract}
                                                t={t}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EmployeeDaysOff
                                                contract={data?.contract}
                                                t={t}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EmployeeTotalPayments
                                                totalPaid={totalPaid}
                                                totalEarned={totalEarned}
                                                t={t}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <EmployeeAgreement
                                        agreements={data.agreements}
                                        t={t}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <EmployeeStats
                                    stats={data.stats}
                                    t={t}
                                />
                            </Grid>


                            {// employee && data.paymentsCount > 0 &&
                                <Grid item xs={12}>
                                    <PaymentsTable
                                        t={t}
                                        isUnlimited={isUnlimited}
                                        paymentsData={showAllPayments || !isUnlimited ? data.paymentsData :
                                            (data.paymentsData as Array<{ date: Date }>).filter(payment => {
                                                if (!year) {
                                                    return false;
                                                }
                                                const startDate = new Date(Date.UTC(year, 0, 1));
                                                const endDate = new Date(Date.UTC(year, 11, 31, 23, 59, 59, 59));
                                                return payment.date >= startDate && payment.date <= endDate;

                                            })
                                        }
                                        fetchData={fetchReportData}
                                        setOpenPaymentModal={setOpenPaymentModal}
                                        setShowAllPayment={setShowAllPayments}
                                    />
                                </Grid>}
                            <Grid item xs={12}>
                                {employee && (
                                    <Card sx={{ padding: 1 }}>
                                        {hasEncryptedAgreements &&
                                            <FlexBox style={{ cursor: 'pointer' }} justifyContent={'right'}
                                                onClick={() => {
                                                    if (encryptionKey === '') {
                                                        setPopoverOpen(true);
                                                    }
                                                }}
                                            >
                                                <Tooltip title={t('encryption.graph.tooltip')} arrow placement='right'>
                                                    <AnnouncementOutlinedIcon fontSize='small' color='warning' style={{ marginRight: '2rem' }} />
                                                </Tooltip>
                                            </FlexBox>
                                        }
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            <BottomNavigation
                                                showLabels
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                            >
                                                <BottomNavigationAction label={t('report.cumulative')} icon={<StackedLineChartIcon />} />
                                                <BottomNavigationAction label={t('report.barChart')} icon={<BarChartIcon />} />
                                            </BottomNavigation>

                                        </Box>

                                        {value && <EmployeeCost
                                            t={t}
                                            startDate={data?.contract?.startDate}
                                            endDate={data?.contract?.endDate}
                                            monthlyPayments={data.chart.paid}
                                            monthlyDues={data.chart.due}
                                            credit={data.chart.credit}
                                        />}
                                        {dailyEarining && dailyPayout && !value &&
                                            <EmployeeCostCumulative
                                                t={t}
                                                startDate={data?.contract?.startDate}
                                                endDate={data?.contract?.endDate}
                                                dailyNetEarningsMap={dailyEarining}
                                                dailyPayoutsMap={dailyPayout}
                                                theme={theme}
                                            />
                                        }
                                    </Card>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} justifyContent={'center'} textAlign={'center'} >
                            <Card sx={{ paddingTop: '10px', paddingBottom: '30px' }}>
                                <Grid container justifyContent={'center'} >
                                    <Grid item sm={12}>
                                        <img
                                            src={emptyTableImage}
                                            alt='Loading image'
                                            style={{ width: '250px', height: '250px' }} // Adjust as needed
                                        />
                                    </Grid>
                                    <Grid item sm={12} justifyItems={'center'}>
                                        <Typography
                                            display={'inline-flex'}
                                            variant='h4'
                                            align='center'
                                            width={'70%'}
                                            sx={{ fontWeight: 'bold' }} // Adjust as needed
                                        >
                                            {t('report.contractNotPresent')}
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Card>

                        </Grid>
                    )

                    }
                </Grid>
            </Grid>
        </Grid >
    );
};

export default EmployeesReport;

