import { Box, useTheme } from '@mui/material';
import AppAvatar from 'components/avatars/AppAvatar';
import FlexBox from 'components/flexbox/FlexBox';
import { Tiny } from 'components/Typography';
import { type FC } from 'react';

interface IncomingMsgProps {
  msg: {
    author: string;
    message: string
    imageSrc: string;
    date: string
  };
}

const CommuicationsIncomingMsg: FC<IncomingMsgProps> = ({ msg }) => {
  const theme = useTheme();
  const lightTheme = theme.palette.mode === 'light';
  return (
    <Box>
      <FlexBox mt={3} justifyContent='start'>
      <Tiny >{msg.author}</Tiny>
      </FlexBox>
      <FlexBox>
        <AppAvatar
          src={msg.imageSrc}
          sx={{ width: 28, height: 28 }}
        />
        <Box
          sx={{
            padding: 2,
            maxWidth: 250,
            marginLeft: 1.5,
            borderRadius: '0px 8px 8px 8px',
            backgroundColor: lightTheme ? 'primary.100' : 'divider',
          }}
        >
          <Tiny display='block' fontWeight={500} lineHeight={1.7}>
            {msg.message}
          </Tiny>
        </Box>
      </FlexBox>
      <Tiny justifyContent='end'>{msg.date}</Tiny>
    </Box>
  );
};

export default CommuicationsIncomingMsg;
