import { Box, Button, Grid } from '@mui/material';
import { employeesApi } from 'api';
import { FC, useEffect, useRef, useState } from 'react';
import { convertToWebP } from 'utils/converterToWebP';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CloseIcon from '@mui/icons-material/Close';

interface DataProps {
    setShowCameraCallback: (value: boolean) => void;
    setDisplayAvatarCallback?: (avatarUrl: string) => void;
    id: number;
    tokenState?: string;
    tenantState?: string;
    showAlertCallback?: (errorMessage: string) => void;
    isConfirmationOpen? : boolean;
    handleConfirmation: () => void;
}

const Camera: FC<DataProps> = (props) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [facingMode, setFacingMode] = useState<"user" | "environment">("environment");

    useEffect(() => {
        // If there is already a stream, stop it first
        if (stream) {
            stream.getTracks().forEach(track => { track.stop() });
        }

        // Accessing the camera
        navigator.mediaDevices.getUserMedia({ video: { facingMode } })
            .then(newStream => {
                setStream(newStream);
                if (videoRef.current) {
                    videoRef.current.srcObject = newStream;
                }
            })
            .catch(console.error);

        // Cleaning at unmount
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => { track.stop() });
            }
        };
    }, [facingMode]);

    const capturePhoto = () => {
        props.setShowCameraCallback(false);
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(async blob => {
                    if (blob) {
                        if (props.setDisplayAvatarCallback && props.showAlertCallback && props.tokenState) {
                            const webpBlob = await convertToWebP(blob, props.showAlertCallback).catch(console.error);
                            if (!webpBlob) return;

                            const formData = new FormData();
                            formData.append('file', webpBlob, 'avatar.webp');
                            if(!props.tenantState){
                                return;
                            }
                            await employeesApi.createFileWithToken(String(props.id), props.tenantState, {
                                data: formData,
                                params: { token: props.tokenState },
                            }).then(() => {
                                if (props.setDisplayAvatarCallback) {
                                    props.setDisplayAvatarCallback(URL.createObjectURL(webpBlob));
                                }
                                if (props.isConfirmationOpen)
                                    props.handleConfirmation();
                            }).catch(console.error);
                        }
                    }
                });
            }
        }
    };

    // This function is currently not use, as for mobile users we are using the phone's camera and desktop users are expected to have one camera only
    /* const toggleCamera = () => {
        setFacingMode(prevMode => prevMode === "environment" ? "user" : "environment");
    }; */

    return (
        <Box>
            <Grid container style={{ backgroundColor: '#1D242F', borderRadius: '3px' }} width={500}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button
                        onClick={() => { props.setShowCameraCallback(false) }}
                        style={{
                            zIndex: 10,
                            color: 'white',
                            borderRadius: '50%',
                            minWidth: '56px',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CloseIcon />
                    </Button>
                    <div style={{ color: 'white' }}>
                        Scatta foto
                    </div>

                </Grid>


                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <video ref={videoRef} autoPlay playsInline style={{ width: '100%', }}></video>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }} mb={2.5}>
                    <Grid container justifyContent="center" alignItems="center" style={{ maxWidth: '100%' }} mt={-3.5}>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                onClick={capturePhoto}
                                style={{
                                    zIndex: 10,
                                    backgroundColor: '#6C63FF',
                                    color: 'white',
                                    borderRadius: '50%',
                                    minWidth: '56px',
                                    height: '56px',
                                }}
                            >
                                <PhotoCameraIcon />
                            </Button>
                        </Grid>
                        {/* <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            onClick={toggleCamera}
                            style={{
                                zIndex: 10,
                                backgroundColor: '#6C63FF',
                                color: 'white',
                                borderRadius: '50%',
                                minWidth: '56px',
                                height: '56px',
                            }}
                        >
                            <CameraswitchIcon />
                        </Button>
                    </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Camera;
