import { Box, Button, useTheme } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { H1, Paragraph } from 'components/Typography';
import { useState, type FC } from 'react';
import { Token, TokenResponses } from './data-compiled-by-employee';
import { TokenType } from '../new-employee';
import toast from 'react-hot-toast';
import { employeesApi, tokenApi } from 'api';
import { useTranslation } from 'react-i18next';

interface InvalidTokenPageProps {
    tokenData: Token;
    tokenState: string;
    tenantState: string;
}
const InvalidTokenPage: FC<InvalidTokenPageProps> = ({
    tokenData,
    tokenState,
    tenantState
}) => {
    const theme = useTheme();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { t, i18n } = useTranslation();


    const sendNewDataCompilationRequestoToEmployee = async (
        tokenData: Token,
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const employee = await employeesApi.findOneWithExpiredToken(String(tokenData.id!), { params: { token: tokenState, tenant: tenantState } });
        const generateTokenDto = {
            employeeEmail: employee.data.email,
            type: TokenType.COMPLETE_DATA_REQUEST,
            language: i18n.language.substring(0,2).toUpperCase() as any,
        };
        await tokenApi.generateTokenWithExpiredToken(generateTokenDto, { params: { token: tokenState, tenant:tenantState } })
            .then(() => {
                toast.success(t('alerts.request.send'), { duration: 4000 });
            })
            .catch(() => {
                toast.error(t('alerts.request.error'), { duration: 4000 });
            })
            .finally(() => {
                setIsButtonDisabled(true);
            });

    }


    return (
        <FlexBox
            p={4}
            height={'100vh'}
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
        >

            <Box maxWidth={350}>
                <img
                    src='/static/illustration/error-page.svg'
                    width='100%'
                    alt='Error 404'
                />
            </Box>
            {tokenData.reason === TokenResponses.EXPIRED && (
                <Box textAlign={'center'}>
                    <H1 fontSize={64} fontWeight={700} color='primary.main' mt={3}>
                        {t('token.expired')}
                    </H1>
                    <Paragraph color='text.disabled' fontWeight='500'>
                        {t('token.requestNew')}
                    </Paragraph>
                </Box>
            )}
            {tokenData.reason === TokenResponses.INVALID && (
                <H1 fontSize={64} fontWeight={700} color='primary.main' mt={3}>
                    {t('token.invalid')}
                </H1>
            )}

            {tokenData.reason === TokenResponses.EXPIRED && (
                <Button
                    disabled={isButtonDisabled}
                    style={{
                        display: 'block',
                        marginTop: '0.5rem',
                        fontWeight: 600,
                        fontSize: 20,
                        textDecoration: 'underline',
                        color: theme.palette.primary.main,
                    }}
                    onClick={() => { sendNewDataCompilationRequestoToEmployee(tokenData) }}
                >
                    {t('token.sendEmail')}
                </Button>
            )}
        </FlexBox>
    );
};

export default InvalidTokenPage;
