import { Card, styled, useMediaQuery, useTheme } from '@mui/material';
import { type FC } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { TFunction } from 'i18next';

interface DataProps {
    t: TFunction;
    startDate: string;
    endDate: string;
    monthlyPayments: number[];
    monthlyDues: number[];
    credit: number[];
}

const StyledChart = styled(Chart)(() => ({
    minHeight: '300px !important',
    '& .apexcharts-xaxistooltip': { display: 'none !important' },
}));

const EmployeeCost: FC<DataProps> = (props) => {
    const { t } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const getMonthsBetweenDates = (startDate: string, endDate: string) => {
        const dates = [];

        // this function recevies dates as italian format date string, splitting by '/' is possible to obtain date, month and year
        const splittedStartDate = startDate.split('/').map((component) => {
            return Number(component);
        });

        const splittedEndDate = endDate.split('/').map((component) => {
            return Number(component);
        });
        // -1 on months to translate from human months to Javascript months
        let currentDate = new Date(splittedStartDate[2], splittedStartDate[1] - 1, splittedStartDate[0]);

        const convertedEndDate = new Date(splittedEndDate[2], splittedEndDate[1] - 1, splittedEndDate[0]);

        while (currentDate <= convertedEndDate) {
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const formattedDate = `${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}.${firstDayOfMonth.getFullYear().toString().padStart(2, '0')}`;
            dates.push(formattedDate);

            // Move to the next month
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        }

        return dates;
    };

    const chartCategories = getMonthsBetweenDates(props.startDate, props.endDate);



    const chartOptions: ApexOptions = {
        chart: {
            background: 'trasparent',
            type: 'bar',
            height: 350,
            stacked: false,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        dataLabels: {
            formatter: (val) => {
                return val + '€'
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                horizontal: false,
                borderRadius: 2,
                borderRadiusApplication: 'end',
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetY: -10,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900,
                        }
                    }
                }
            },
        },
        colors: [
            '#FADF70',
            '#FF9C12',
            '#E5412A'

        ],
        grid: { show: false },
        theme: { mode: theme.palette.mode },
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
            categories: chartCategories,
            labels: {
                style: {
                    colors: theme.palette.text.disabled,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 500,
                },
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val + "€";
                }
            },
        },
        legend: {
            position: 'right',
            offsetY: 40,
            inverseOrder: true
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            shared: true,
            inverseOrder: true
        }
    }

    const chartSeries = [
        { name: t('employees.report.cost.paid'), data: props.monthlyPayments, type: 'bar' },
        { name: t('employees.report.cost.monthlyDue'), data: props.monthlyDues, type: 'bar' },
        { name: t('employees.report.cost.totalCredit'), data: props.credit, type: 'bar' },
    ];



    return (
        <Card sx={{ padding: 3, height: '100%', overflowX: 'auto' }}>

            <div style={{
                minWidth: isMobile ? 400 : 'auto'
            }}>
                <StyledChart
                    options={chartOptions}
                    series={chartSeries}
                    height={300}
                />
            </div>
        </Card>
    );
};

export default EmployeeCost;
