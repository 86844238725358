import { Button, Grid, styled } from "@mui/material";
import { CreateEmployeeDto, SetLanguageDtoLanguageEnum } from "api/generated";
import AppModal from "components/AppModal";
import AppSelectField from "components/input-fields/MultipleChoiceField";
import { t } from "i18next";
import { FC, useState } from "react";
import { KeyboardArrowDown } from '@mui/icons-material';
import { H2 } from "components/Typography";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    sendDataCompilationRequestCallback?: (values: CreateEmployeeDto, language: SetLanguageDtoLanguageEnum) => Promise<any>
    values: CreateEmployeeDto | undefined;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const SelectLanguageModal: FC<ModalProps> = ({ open, values, onClose, sendDataCompilationRequestCallback }) => {

    const [selectedLanguage, setSelectedLanguage] = useState<SetLanguageDtoLanguageEnum>(SetLanguageDtoLanguageEnum.En);
    return (
        <StyledAppModal open={open} handleClose={onClose}>
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item xs={12}>
                    <H2> {t('language.selectEmailLanguage')} </H2>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                    <AppSelectField
                        select
                        fullWidth
                        SelectProps={{
                            native: true,
                            IconComponent: KeyboardArrowDown,
                        }}
                        onChange={(e) => { setSelectedLanguage(e.target.value as SetLanguageDtoLanguageEnum) }}>
                        {Object.values(SetLanguageDtoLanguageEnum).map((language) => {
                            const showedText = (language: SetLanguageDtoLanguageEnum) => {
                                switch (language) {
                                    case 'EN':
                                        return t('language.english');
                                    case 'DE':
                                        return t('language.german')
                                    case 'IT':
                                        return t('language.italian')
                                }
                            }

                            return (
                                <option key={language} value={language}>
                                    {showedText(language)}
                                </option>
                            )
                        })}
                    </AppSelectField>
                </Grid>

                <Grid item xs={5}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {setSelectedLanguage('EN'); onClose()}}>
                        {t('common.forms.button.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth size='small' onClick={() => {

                        if (sendDataCompilationRequestCallback && values) {
                            sendDataCompilationRequestCallback(values, selectedLanguage)
                            setSelectedLanguage('EN');
                        }



                    }} variant='contained'>
                        {t('common.forms.button.send')}
                    </Button>
                </Grid>
            </Grid>
        </StyledAppModal >
    )
}

export default SelectLanguageModal