import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

interface StepItem {
  key: string;
  name: string;
}

interface CustomStepperProps {
  activeStep: number;
  steps: StepItem[];
}

const CustomStepper: React.FC<CustomStepperProps> = ({ activeStep, steps }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(({ key, name }, index) => (
        <Step key={key}>
          <StepLabel></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
