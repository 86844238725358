import { Autocomplete, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { EmployeeEntity, PaymentMethodEntity, SectionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H3, H5, Small } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { departmentsEmployeesApi, employeesApi, turnoverApi } from "api";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useSeason } from "contexts/SeasonContext";
import FlexBox from "components/flexbox/FlexBox";
import toast, { Toaster } from "react-hot-toast";
import CurrencyInput from "react-currency-input-field";
import AppTextField from "components/input-fields/AppTextField";
import { format, isSameDay } from "date-fns";
import { translatePaymentTypes } from "utils/convertPaymentTypes";

interface ModalProps {
    section?: SectionEntity;
    open: boolean;
    isOwner: boolean;
    onClose: () => void;
    onBack?: () => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 450,
    maxHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '80%' : 800,
    overflowY: "auto",
}));

const AddTurnoverModal: FC<ModalProps> = (
    {
        open,
        onClose,
        onBack,
        section,
        isOwner
    }
) => {

    const [date, setDate] = useState(new Date());
    const [isDateValid, setIsDateValid] = useState(true);
    const [dateDepchiefError, setDateDepchiefError] = useState(false);

    const [itemOfToday, setItemOfToday] = useState<boolean>(false);
    const [itemOfYesterday, setItemOfYesterday] = useState<boolean>(false);

    const [items, setItems] = useState<Array<{ value: number, stringValue?: string, paymentMethod: PaymentMethodEntity }>>([]);
    const { seasonId } = useSeason();
    const theme = useTheme();

    const [employees, setEmployees] = useState<EmployeeEntity[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeEntity>();

    const [today, setToday] = useState(new Date());
    const [yesterday, setYesterday] = useState(new Date(today.getDate() - 1));

    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();

    const [isThereItem, setIsThereItem] = useState(false);

    const [canChangeTurnoversDates, setCanChangeTurnoversDates] = useState(false);


    const [total, setTotal] = useState(0);
    const [totalString, setTotalString] = useState('0');
    const [totalOverflowError, setTotalOverflowError] = useState(false);
    // cash payment method index on payment methods array
    const [cashIndex, setCashIndex] = useState<number>();

    useEffect(() => {
        if (section && !isOwner) {
            setToday(new Date);
            setYesterday(new Date(new Date().getDate() - 1))
            turnoverApi.itemOfToday(seasonId, section.id).then(({ data }) => {
                setItemOfToday(data);
            });
            turnoverApi.itemOfYesterday(seasonId, section.id).then(({ data }) => {
                setItemOfYesterday(data);
            })
        }
    }, [section])

    useEffect(() => {
        setMinDate(isOwner || canChangeTurnoversDates ? undefined : !itemOfYesterday && today.getHours() < 6 ? yesterday : !itemOfToday ? today : undefined)
        setMaxDate(isOwner || canChangeTurnoversDates ? undefined : !itemOfToday ? today : !itemOfYesterday && today.getHours() < 6 ? yesterday : undefined)
        if (!isOwner && !canChangeTurnoversDates) {
            if (itemOfToday) {
                setDate(today);
            } else if (itemOfYesterday) {
                setDate(yesterday);
            }
        }
    }, [itemOfToday, itemOfYesterday, today, yesterday, canChangeTurnoversDates])

    useEffect(() => {
        if (
            !isOwner && minDate && maxDate && (
                (
                    date.getFullYear() < minDate.getFullYear() ||
                    (date.getFullYear() === minDate.getFullYear() && date.getMonth() < minDate.getMonth()) ||
                    (date.getFullYear() === minDate.getFullYear() && date.getMonth() === minDate.getMonth() && date.getDate() < minDate.getDate())
                ) ||
                (
                    date.getFullYear() > maxDate.getFullYear() ||
                    (date.getFullYear() === minDate.getFullYear() && date.getMonth() > maxDate.getMonth()) ||
                    (date.getFullYear() === minDate.getFullYear() && date.getMonth() === minDate.getMonth() && date.getDate() > maxDate.getDate())
                )
            )
        ) {
            setDateDepchiefError(true);
        } else {
            setDateDepchiefError(false);
        }
    }, [date, minDate, maxDate])

    const fetchData = async () => {

        setCashIndex(undefined);
        if (section?.paymentMethods && selectedEmployee) {
            const temp: Array<{ value: number, stringValue?: string, paymentMethod: PaymentMethodEntity }> = [];

            // check if user can change date, if not then they do not need to check if there is an item on selected day
            const canChangeTurnoversDates = isOwner || await departmentsEmployeesApi.canChangeTurnoversDate(section?.departmentId ?? 0, seasonId
            ).then(({ data }) => {
                setCanChangeTurnoversDates(data);
                return data;
            });

            let total = 0;
            if (!isOwner && canChangeTurnoversDates) {
                setIsThereItem((await turnoverApi.itemOfDay(date.getTime(), seasonId, section.id)).data);
            }
            else {
                setIsThereItem(false);
            }
            for (const paymentMethod of section.paymentMethods) {
                let value = 0;
                if (isOwner) {
                    const turnoverItem = (await turnoverApi.findUnique(date.getTime(), section.id, paymentMethod.id, seasonId, selectedEmployee.id)).data
                    value = turnoverItem ? turnoverItem.value : 0.00;
                    total += value;
                }
                if (paymentMethod.type === 'CASH')
                    setCashIndex(temp.length);
                temp.push({
                    value,
                    stringValue: String(value),
                    paymentMethod
                })
            }
            setTotal(total);
            setTotalString(total.toString());
            setItems(temp);
        } else {
            setItems([]);
        }
        if (isOwner) {
            employeesApi.findAllDepchief(seasonId, true, section?.departmentId).then(({ data }) => {
                setEmployees(data);
            })
        } else {
            employeesApi.getMySelf().then(({ data }) => {
                setSelectedEmployee(data);
            });
        }
    }

    useEffect(() => {
        fetchData().then(() => {
            formik.resetForm();
        })
    }, [date, section?.id, selectedEmployee?.id])

    const fieldValidationSchema = Yup.array().of(
        Yup.object({
            value: Yup.number().required(t('value.notValid'))
        })
    ).required(t('value.notValid'));

    const formik = useFormik({
        initialValues: items,
        enableReinitialize: true,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {

            const createItem = (section: SectionEntity, i: number) => {
                turnoverApi.create(
                    seasonId,
                    isOwner && selectedEmployee ? selectedEmployee.id : -1,
                    {
                        date: date.toISOString(),
                        paymentMethodId: values[i].paymentMethod.id,
                        paymentSectionId: section.id,
                        value: values[i].value,
                    }
                ).then(() => {
                    onClose();
                    if (isOwner) {
                        setSelectedEmployee(undefined);
                    }
                    setDate(new Date());
                    resetForm();
                }).catch(() => {
                    toast.error(t('turnover.dateErrorText'), {
                        duration: 5000
                    });
                });
            }


            if (section && selectedEmployee) {
                for (let i = 0; i < values.length; i++) {
                    if (isOwner && (values[i] !== items[i] || values[i].value === 0)) {
                        // if it is owner and a value exists, try to update it, otherwise create a new item
                        turnoverApi.findUnique(
                            date.getTime(),
                            section.id,
                            values[i].paymentMethod.id,
                            seasonId,
                            selectedEmployee.id
                        ).then(({ data }) => {
                            if (data) {
                                turnoverApi.update(String(data.id), { value: values[i].value }).then(() => {
                                    onClose();
                                    if (isOwner) {
                                        setSelectedEmployee(undefined);
                                    }
                                    setDate(new Date());
                                    resetForm();
                                }).catch(() => {
                                    toast.error(t('turnover.dateErrorText'), {
                                        duration: 5000
                                    });
                                })
                            }
                            else {
                                createItem(section, i);
                            }
                        })
                    } else {
                        createItem(section, i);
                    }

                }
            }
        }
    })


    const { values, handleSubmit, setFieldValue } = formik;

    const resetForm = () => {
        formik.resetForm();
        setTotal(0);
        setTotalString('0');
    }

    useEffect(() => {
        if (cashIndex !== undefined) {
            let notCash = 0;
            values.forEach((value) => {
                if (value.paymentMethod?.type !== 'CASH')
                    notCash += value.value;
            })
            const value = total - notCash;

            // sum of all values overflows total
            if (value < 0)
                setTotalOverflowError(true)
            else if (totalOverflowError)
                setTotalOverflowError(false);

            setFieldValue(`[${cashIndex}].stringValue`, value);
            setFieldValue(`[${cashIndex}].value`, value);
        }
        else {
            let total = 0;
            values.forEach((value) => {
                total += value.value;
            })
            setTotal(total);
            setTotalString(total.toString());
        }
    }, [total, values])

    return (
        <StyledAppModal
            open={open}
            handleClose={() => {
                onClose();
                if (isOwner) {
                    setSelectedEmployee(undefined);
                }
                setDate(new Date())
                resetForm();
            }}
            alignContent={"center"}
            alignItems={"center"}
        >
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <H2 mb={1}>{t('amount.add')} </H2>
            <H2 mb={1}>{section?.name} - {section?.taxPercentage}%</H2>
            {
                isOwner || canChangeTurnoversDates || !itemOfToday && (!itemOfYesterday && today.getHours() < 6) ? <CalendarInput

                    sx={{ marginBottom: 2 }}
                    format='dd.MM.yyyy'
                    value={date}
                    onChange={(newValue) => {
                        if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                            setDate(newValue);
                            setIsDateValid(true);
                        }
                        else
                            setIsDateValid(false);
                    }}
                    slotProps={{
                        textField: {
                            helperText: (!isDateValid && t('date.error')) || (dateDepchiefError && t('turnover.depchiefDateError')),
                            error: !isDateValid || dateDepchiefError
                        },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    disableFuture={!isOwner}
                /> : <H2 marginTop={2} textAlign={"center"}>
                    {format(date, 'dd/MM/yyyy')}
                </H2>

            }
            {
                !isSameDay(date, today) && !dateDepchiefError && <H5 marginBottom={3} color={theme.palette.warning.main}>
                    {t('date.notToday')}
                </H5>
            }
            {
                isOwner && <Autocomplete
                    sx={{ marginBottom: 2 }}
                    value={selectedEmployee ?? null}
                    options={employees}
                    disabled={!isOwner || (employees.length < 1)}
                    getOptionLabel={(employee) => { return employee.firstName + " " + employee.lastName }}
                    renderInput={(params) => (
                        <AppTextField
                            {...params}
                            label={t('employee.select')}
                            inputProps={{
                                ...params.inputProps,
                            }}
                            error={!selectedEmployee && isOwner}
                            helperText={!selectedEmployee && isOwner && t('turnover.employeeError')}

                        />
                    )}
                    onChange={(_event, value) => {
                        if (value) {
                            setSelectedEmployee(value);
                        }
                    }}
                />
            }

            {
                !isThereItem ? (
                    <Grid container>
                        {values.length > 0 && <Grid item xs={12}>
                            <H3 textOverflow={'ellipsis'}>{t('total')}</H3>
                            <CurrencyInput
                                style={{
                                    width: '100%',
                                    margin: 1,
                                    borderRadius: 4,
                                    position: "relative",
                                    backgroundColor: theme.palette.background.paper,
                                    border: "1px solid #ced4da",
                                    fontSize: 16,
                                    padding: "10px 12px",
                                    transition: theme.transitions.create(["border-color", "box-shadow"]),
                                    borderColor: theme.palette.primary.main,
                                    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
                                    fontWeight: 500,
                                    fontFamily: "Montserrat",
                                    color: cashIndex === undefined ? theme.palette.text.disabled : theme.palette.text.primary
                                }}
                                type="text"
                                value={totalString}
                                disabled={cashIndex === undefined}
                                decimalsLimit={2}
                                onValueChange={(value, _name, values) => {
                                    setTotal(values?.float ?? 0);
                                    setTotalString(value ?? '0')
                                }}

                                intlConfig={{
                                    locale: 'de-DE',
                                    currency: 'EUR',
                                }}
                                step={1}
                            />
                            {totalOverflowError && <Small color={theme.palette.warning.main}>
                                {t('paymentMethod.totalOverflow')}
                            </Small>}

                        </Grid>}
                        {
                            values &&
                                values.length > 0 ? values.map((item, index) => {
                                    const disabled = item.paymentMethod?.type === 'CASH' || (total === 0 && cashIndex !== undefined);
                                    return <Grid item padding={1} xs={12} md={6}>
                                        <Grid container marginTop={2} marginBottom={3}>
                                            <Grid item xs={12}>
                                                <H3 textOverflow={'ellipsis'}>{translatePaymentTypes(item.paymentMethod?.type ?? '', t)}</H3>
                                                <Small color={item.paymentMethod.tenantBankingInformation ? theme.palette.text.primary : theme.palette.background.default}>{item.paymentMethod.tenantBankingInformation?.bankName ?? '-'}</Small>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Small>{item.paymentMethod?.description || '\u00A0'}</Small>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CurrencyInput
                                                    style={{
                                                        width: '100%',
                                                        margin: 1,
                                                        borderRadius: 4,
                                                        position: "relative",
                                                        backgroundColor: theme.palette.background.paper,
                                                        border: "1px solid #ced4da",
                                                        fontSize: 16,
                                                        padding: "10px 12px",
                                                        transition: theme.transitions.create(["border-color", "box-shadow"]),
                                                        borderColor: theme.palette.primary.main,
                                                        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
                                                        fontWeight: 500,
                                                        fontFamily: "Montserrat",
                                                        color: disabled ? theme.palette.text.disabled : theme.palette.text.primary
                                                    }}
                                                    type="text"
                                                    value={item.stringValue}
                                                    disabled={disabled}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, _name, values) => {
                                                        setFieldValue(`[${index}].stringValue`, value);
                                                        setFieldValue(`[${index}].value`, values?.float);
                                                    }}
                                                    intlConfig={{
                                                        locale: 'de-DE',
                                                        currency: 'EUR',
                                                    }}
                                                    step={1}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }) :
                                <></>
                        }
                        <>{(!values || values.length === 0) && selectedEmployee && t('paymentMethod.empty')}</>
                        <>{!selectedEmployee && t('employee.select')}</>

                    </Grid>
                ) :
                    (<Small color={theme.palette.warning.main}>
                        {t('turnoverModal.isThereItem')}
                    </Small>)
            }



            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                {
                    onBack && <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        onClick={() => {
                            onBack();
                            setDate(new Date());

                            if (isOwner) {
                                setSelectedEmployee(undefined);
                            }
                            resetForm();
                        }}>
                        {t('back')}
                    </Button>
                }

                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => {
                        onClose();
                        if (isOwner) {
                            setSelectedEmployee(undefined);
                        }
                        setDate(new Date());
                        resetForm();

                    }}
                >
                    {t('cancel')}
                </Button>


                <Button fullWidth disabled={(isOwner && !selectedEmployee) || dateDepchiefError || totalOverflowError || isThereItem}
                    variant="contained" onClick={() => { handleSubmit() }}>
                    {t('save')}
                </Button>
            </FlexBox>
        </StyledAppModal >
    )
}

export default AddTurnoverModal;