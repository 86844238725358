import { additionalInformationsApi, bankingInformationApi, employeesApi, idDocumentsApi, tokenApi } from 'api';
import { ChangeEvent, useEffect, useState } from 'react';
import { BasicInformationFormData } from 'page-sections/employees/BasicInformation';
import { AdditionalInformationsEntity, CreateBankingInformationDto, CreateEmployeeDocumentDto, CreateEmployeeDto } from 'api/generated';
import { Alert, Box, Button, Card, Drawer, Grid, IconButton, Menu, MenuItem, Snackbar, Tab, Tabs, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import ProfileCompleted from './profile-completed';
import TabComponent from 'page-sections/employees';
import Icons from 'icons/account';
import { IDFormData } from 'page-sections/employees/IDDocuments';
import { BankFormData } from 'page-sections/employees/BankingInformation';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import styled from 'styled-components';
import { CameraAlt, InsertDriveFile, PhotoCamera } from '@mui/icons-material';
import AppAvatar from 'components/avatars/AppAvatar';
import AvatarBadge from 'components/avatars/AvatarBadge';
import FlexBox from 'components/flexbox/FlexBox';
import { ImageWrapper } from '../new-employee';
import WallMan from '../../../assets/images/wall_man.svg';
import SitWoman from '../../../assets/images/sit_woman.svg';
import { useTranslation } from 'react-i18next';
import CustomStepper from 'components/CustomStepper';
import InvalidTokenPage from './invalid-token';
import defaultProfileImage from '../../../assets/images/default-profile.png';
import { convertToWebP } from 'utils/converterToWebP';
import toast, { Toaster } from 'react-hot-toast';
import LanguagePopover from 'layouts/layout-parts/popovers/LanguagePopover';
import { CustomDialog } from 'components/CustomDialog';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import './camera.css';
import Camera from 'components/Camera';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { AdditionalFormData } from 'page-sections/employees/AdditionalInformation';

export enum TokenResponses {
    VALID = "TOKEN_VALID",
    INVALID = "TOKEN_INVALID",
    EXPIRED = "TOKEN_EXPIRED"
}

export interface Token {
    id?: number;
    isValid: boolean;
    reason: TokenResponses;
}

type IncludeId<T> = T & { id: number };

interface FormData {
    basicInformation?: IncludeId<BasicInformationFormData>;
    idDocuments?: Array<IncludeId<IDFormData>>;
    bank?: IncludeId<BankFormData>;
    additionalInformations?: IncludeId<AdditionalFormData>;
}

interface DocumentDtoWithId extends CreateEmployeeDocumentDto {
    id?: number;
}

type FormDataKeys = keyof FormData;

const tabs: Array<{
    key: FormDataKeys;
    name: string;
    Icon: any;
}> = [
        {
            key: 'basicInformation',
            name: 'employees.generalInformation.title',
            Icon: Icons.UserOutlined,
        },
        {
            key: 'idDocuments',
            name: 'employees.id.title',
            Icon: Icons.Link,
        },
        {
            key: 'additionalInformations',
            name: 'employees.additionalInformation.title',
            Icon: ContactEmergencyIcon,
        },
        {
            key: 'bank',
            name: 'employees.bank.title',
            Icon: AccountBalanceIcon,
        },
    ];

const MyStyledButton = styled(Button)(() => ({
    fontSize: useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? '0.8rem' : '1.1rem',
    padding: '1rem 2rem',
}))

const StyledToolBar = styled(Toolbar)(() => ({
    '@media (min-width: 0px)': {
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 'auto',
        marginBottom: -30
    },
}));

const CompleteEmployeeProfilePage = () => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isSmallDevice = useMediaQuery('(max-width:400px)');
    const { t } = useTranslation();
    const [isValidToken, setIsValidToken] = useState(false);
    const [data, setData] = useState<FormData | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showProfileCompleted, setShowProfileCompleted] = useState(false);
    const [employeeId, setEmployeeId] = useState<number | undefined>(undefined);
    const [activeTab, setActiveTab] = useState(0);
    const [tokenState, setTokenState] = useState('');
    const [tenantState, setTenantState] = useState('');
    const [tokenData, setTokenData] = useState<Token>({ id: 0, isValid: false, reason: TokenResponses.INVALID });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [avatarDialog, setAvatarDialog] = useState(false);
    const [isAvatarSet, setIsAvatarSet] = useState(false);
    const [allDocumentsUploaded, setAllDocumentsUploaded] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [cameraAvailable, setCameraAvailable] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleAvatarDialog = () => {
        setAvatarDialog(false);
    };

    const prevTab = () => {
        if (activeTab <= 0) {
            return;
        }
        setActiveTab(activeTab - 1);
    };
    const nextTab = () => {
        if (activeTab >= tabs.length - 1) {
            return;
        }
        setActiveTab(activeTab + 1);
    };


    useEffect(() => {
        const fetchEmployeeData = async () => {
            if (employeeId) {
                await fetchData();
            }
        };
        fetchEmployeeData();
    }, [employeeId]);


    useEffect(() => {
        // Get the 'token' parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const tenant = urlParams.get('tenant');
        if (!token || !tenant) {
            return;
        }

        tokenApi.isTokenValid(token, tenant)
            .then(async ({ data }: any) => {
                setTokenData(data);
                setTokenState(token);
                setTenantState(tenant);
                if (data.isValid) {
                    setIsValidToken(true);
                    setEmployeeId(data.id);
                }
            })
            .catch(() => {
                setIsValidToken(false);
            });
    }, []);


    const fetchData = async () => {

        function removeEmployeeId<T extends { employeeId: number }>(
            entity?: T | null,
        ) {
            if (!entity) {
                return undefined;
            }

            const { employeeId, ...rest } = entity;
            return rest;
        }

        const { data: employeeEntity } = await employeesApi.findOneWithToken(String(employeeId), {
            params: {
                includeBankingInformation: true,
                includeContract: true,
                includeDocuments: true,
                includeRoom: false,
                token: tokenState,
                tenant: tenantState
            },
        });

        const {
            bankingInformation: bankingInformationEntity,
            //    contract: contractEntity,
            documents: documentsEntity,
            room,
            ...employee
        } = employeeEntity;
        const bank = removeEmployeeId(bankingInformationEntity);

        const additionalInformations = (await additionalInformationsApi.getByTokenAndEmployee(String(employeeId), tenantState, {
            params: {
                token: tokenState
            }
        })).data;

        setData({
            basicInformation: employee,
            bank,
            idDocuments: documentsEntity !== null ? documentsEntity : undefined,
            additionalInformations: {
                ...additionalInformations,
                foodRequirments: additionalInformations.foodRequirments as any[],
                shirtSize: additionalInformations.shirtSize as any,
            }
        });

        if (employeeEntity.filePath !== null) {
            setIsAvatarSet(true);
        }

        if (documentsEntity) {
            const allDocumentsUploaded = documentsEntity.every((document) => document.documentFiles && document.documentFiles?.length > 0);
            setAllDocumentsUploaded(allDocumentsUploaded);
        }

    };

    const setAdditionalInformations = (additionalInformations: AdditionalInformationsEntity) => {
        setData(
          {
            ...data,
            additionalInformations: {
              ...additionalInformations,
              foodRequirments: additionalInformations.foodRequirments as any[],
              shirtSize: additionalInformations.shirtSize as any,
              licences: additionalInformations.licences ?? []
            }
          }
        )
      }

    const fetchAdditionalInformations = async () => {
        if (!employeeId)
            return;
        const additionalInformations = (await additionalInformationsApi.getByTokenAndEmployee(String(employeeId), tenantState, {
            params: {
                token: tokenState
            }
        })).data;
        setData(
            {
                ...data,
                additionalInformations: {
                    ...additionalInformations,
                    foodRequirments: additionalInformations.foodRequirments as any[],
                    shirtSize: additionalInformations.shirtSize as any,
                    licences: additionalInformations.licences ?? []
                }
            }
        );
    }

    const submitAdditionalInformations = async (submit: AdditionalFormData) => {
        if (!employeeId)
            return;
        // additionalInformations exists
        if (submit.id) {
            await additionalInformationsApi.updateWithToken(String(submit.id), tenantState,
                {
                    customFoodRequirments: submit.customFoodRequirments as any,
                    foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
                    pantsSize: submit.pantsSize as any,
                    shirtSize: submit.shirtSize?.toUpperCase() as any
                },
                {
                    params: {
                        token: tokenState,
                    }
                });
        }
        else {
            await additionalInformationsApi.createWithToken( tenantState,
                {
                    employeeId,
                    customFoodRequirments: submit.customFoodRequirments as any,
                    foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
                    pantsSize: submit.pantsSize as any,
                    shirtSize: submit.shirtSize?.toUpperCase() as any
                },
                {
                params: {
                    token: tokenState,
                }
            });
        };

        await fetchAdditionalInformations();
        nextTab();
    }

    const submitWrapper = async (submit: () => Promise<any>) => {
        setIsSubmitting(true);
        await submit();
        setIsSubmitting(false);
    };

    function shortenData<T extends Record<string, any>>(
        oldData: T,
        newData: T,
    ): Partial<T> | undefined {
        const nonEqualFields = Object.fromEntries(
            Object.entries(newData).filter(([key, val]) => val !== oldData[key]),
        );
        if (Object.keys(nonEqualFields).length === 0) {
            return undefined;
        }
        return nonEqualFields as Partial<T>;
    }

    async function updateEmployee(
        newData: CreateEmployeeDto,
    ): Promise<IncludeId<CreateEmployeeDto>> {
        if (!data?.basicInformation) {
            return employeesApi.create(newData).then(({ data }) => data);
        }
        newData.filePath = data.basicInformation.filePath; // Used to avoid updating the avatar image in case the user clicks next without having set any new avatar
        const trimmedData = shortenData(data.basicInformation, newData);
        if (trimmedData) {
            return employeesApi
                .updateWithToken(String(data.basicInformation.id), tenantState, trimmedData, {
                    params: {
                        token: tokenState,
                    }
                })
                .then(({ data }) => data);
        } else {
            return data.basicInformation;
        }
    }

    const submitBasicInformation = async (
        basicInformation: BasicInformationFormData,
    ) => {
        return submitWrapper(async () => {
            const entity = await updateEmployee(basicInformation);
            setData({
                ...data,
                basicInformation: entity,
            });
            nextTab();
        });
    };

    const submitIDDocuments = async (idDocuments: IDFormData[]) => {
        if (idDocuments.length === 0 && (data?.idDocuments?.length === 0 || !data?.idDocuments)) {
            nextTab();
            return;
        }

        await submitWrapper(async () => {
            const newDataArray = idDocuments.map(iddocument => {
                const { tempKey, ...rest } = iddocument;
                return {
                    ...rest,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    employeeId: data!.basicInformation!.id,
                };
            });

            const updatedEntities = await updateIDDocuments(newDataArray);

            setData({
                ...data,
                idDocuments: updatedEntities.data,
            });

            const allDocumentsUploaded = updatedEntities.data.every((document) => document.documentFiles && document.documentFiles?.length > 0);
            setAllDocumentsUploaded(allDocumentsUploaded);
            if (!allDocumentsUploaded) {
                toast.error(t('alerts.uploadAllDocuments'));
                return;
            }

            nextTab();
        });
    };

    async function updateIDDocuments(newDataArray: DocumentDtoWithId[]) {
        // Documents newly added that are not saved in the BE/AWS
        const newDocuments = newDataArray.filter(newData => !newData.id);
        // Existing documents that were modifies
        const updatedDocuments = newDataArray.filter(newData => newData.id && data?.idDocuments?.some((oldData: any) => oldData.id === newData.id));
        // Document present on AWS/BE but were removed by the user
        const deletedDocuments = data?.idDocuments?.filter((oldData: any) => !newDataArray.some(newData => newData.id === oldData.id));

        // Create new documents
        const createPromises = newDocuments.map(async newData => idDocumentsApi.createwithToken(tenantState, newData, { params: { token: tokenState } }).then(({ data }) => data));
        // Update existing documents
        const updatePromises = updatedDocuments.map(async newData => {
            const oldData = data?.idDocuments?.find((oldData: any) => oldData.id === newData.id);
            if (oldData) {
                if (typeof newData.id !== 'undefined') {
                    const shortenedData = shortenData(oldData, newData as IncludeId<IDFormData>);
                    if (shortenedData) {
                        return idDocumentsApi.updateWithToken(String(newData.id), tenantState, shortenedData, {
                            params: {
                                token: tokenState
                            }
                        }).then(({ data }) => data);
                    }
                }
                return [];
            }
        });

        // Delete removed documents
        const deletePromises = (deletedDocuments?.map(async (oldData: any) => idDocumentsApi.remove(String(oldData.id)).then(({ data }) => data))) ?? [];

        // Wait for all operations to complete
        await Promise.all([...createPromises, ...updatePromises, ...deletePromises]);

        // Fetch and return the updated state from the database

        // basicInformation is always set because the tab is before the documents
        const updatedState = await idDocumentsApi.findAllWithToken(tenantState, {
            params: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employeeId: data?.basicInformation!.id,
                token: tokenState,
            }
        });
        return updatedState;
    }

    const submitBank = async (bank: BankFormData) => {
        return submitWrapper(async () => {
            const newData: CreateBankingInformationDto = {
                ...bank,
                // basicInformation is always set because the tab is before the bank
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employeeId: data!.basicInformation!.id,
            };
            const entity = await updateBankingInformation(newData);
            setData({
                ...data,
                bank: entity,
            });
            if (!isAvatarSet) {
                setAvatarDialog(true);
                return;
            }
            completeProfile();
        });
    };

    const completeProfile = async () => {
        setAvatarDialog(false);
        setShowProfileCompleted(true);
        await tokenApi.updateWithToken(tokenState, tenantState);
    }

    async function updateBankingInformation(
        newData: CreateBankingInformationDto,
    ) {
        if (!data?.bank) {
            return (await bankingInformationApi.createWithToken(tenantState, tokenState, newData)).data
        }
        const { id, ...oldData } = data.bank;
        const trimmedData = shortenData(oldData, newData);
        if (trimmedData) {
            return (await bankingInformationApi.updateWithToken(String(id), tenantState, tokenState, trimmedData)).data
        } else {
            return data.bank;
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue === 2 && !allDocumentsUploaded) {
            toast.error(t('alerts.uploadAllDocuments'));
        } else {
            setActiveTab(newValue);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    // Upload avatar image
    const avatar = data?.basicInformation?.filePath;
    const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / (1024 * 1024); // Dimension in MB
        if (fileSize > 30) {
            showAlert(t('alerts.uploadSizeErrorMessage', {
                dimension: '30',
            })); // Open Snackbar
            event.target.value = ''; // Reset input value
            return;
        }
        // If user is set file can be uploaded directly
        if (data?.basicInformation && data.basicInformation.id) {
            await handleFileUpload({ id: data.basicInformation.id, file });
            if (avatarDialog)
                completeProfile();
        }
    };

    const handleFileUpload = async ({ id, file }: { id: number, file?: File }) => {
        const formData = new FormData();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const blob = new Blob([file!], { type: file!.type });
        const webpBlob = await convertToWebP(blob, showAlert).catch((error) => { console.error(error) });
        if (!webpBlob) {
            return;
        }
        formData.append('file', webpBlob, 'avatar.webp');
        await employeesApi.createFileWithToken(String(id), tenantState, {
            data: formData,
            params: {
                token: tokenState,
            }
        }).then(() => {
            setDisplayAvatar(URL.createObjectURL(webpBlob));
            toast.success(t('alerts.uploadSuccess'), { duration: 5000 });
            setIsAvatarSet(true);
        });
    }

    function showAlert(errorMessage: string) {
        setErrorMessage(errorMessage);
        setOpenSnackbar(true);
    }

    const UploadProfileImmage = (isConfirmModal?: boolean) => {
        return (
            <div>

                {isConfirmModal ?
                    (<Button onClick={handleClick} fullWidth variant='contained' endIcon={(<CameraAlt />)}>
                        {t('uploadFile.uploadImage')}
                    </Button>) :
                    (
                        <IconButton aria-label='upload picture' component='span' onClick={handleClick}>
                            <CameraAlt sx={{ fontSize: 16, color: 'text.disabled' }} />
                        </IconButton>
                    )
                }

                {downMd ? (
                    <Drawer
                        anchor='bottom'
                        open={drawerOpen}
                        onClose={handleClose}
                        PaperProps={{ sx: { borderTopLeftRadius: '16px', borderTopRightRadius: '16px' } }}>
                        <Box
                            sx={{ width: 'auto', backgroundColor: '#1D242F' }}
                            onKeyDown={handleClose}
                        >
                            <FlexBox flexDirection={'row'} padding={3} justifyContent={'space-evenly'}>
                                <label htmlFor='icon-button-file-take-picture-mobile'>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        capture='environment'
                                        id='icon-button-file-take-picture-mobile'
                                        style={{ display: 'none' }}
                                        onChange={(e) => { handleFileSelect(e); handleClose(); }}
                                    />
                                    <FlexBox alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
                                        <Box
                                            sx={{
                                                borderRadius: '50%',
                                                border: '1px solid',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 40,
                                                height: 40,
                                                color: '#6C63Fe',
                                            }}
                                        >
                                            <PhotoCamera fontSize='small' style={{ color: '#6C63Fe' }} />
                                        </Box>
                                        <Box>
                                            <Typography mt={1} color={'white'} fontSize={12}>{t('uploadFile.camera')}</Typography>
                                        </Box>
                                    </FlexBox>
                                </label>
                                <label htmlFor='icon-button-file-mobile'>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        id='icon-button-file-mobile'
                                        style={{ display: 'none' }}
                                        onChange={(e) => { handleFileSelect(e); handleClose(); }}
                                    />
                                    <FlexBox alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
                                        <Box
                                            sx={{
                                                borderRadius: '50%',
                                                border: '1px solid',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 40,
                                                height: 40,
                                                color: '#6C63Fe',
                                            }}
                                        >
                                            <InsertDriveFile fontSize='small' style={{ color: '#6C63Fe' }} />

                                        </Box>
                                        <Box >
                                            <Typography mt={1} color={'white'} fontSize={12}>{t('uploadFile.gallery')}</Typography>
                                        </Box>
                                    </FlexBox>
                                </label>
                            </FlexBox>
                        </Box>
                    </Drawer>
                ) : (
                    <Menu
                        id="upload-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {!downMd && (
                            <MenuItem onClick={() => { checkCameraAvailability(); setShowCamera(true); handleClose(); }}>
                                <FlexBox alignItems={'center'}>
                                    <PhotoCamera fontSize='small' />
                                    <Box ml={1} >
                                        {t('uploadFile.takePhotos')}
                                    </Box>
                                </FlexBox>
                            </MenuItem>
                        )}
                        {downMd && (
                            <MenuItem onClick={handleClose}>
                                <label htmlFor='icon-button-file-take-picture'>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        capture='environment'
                                        id='icon-button-file-take-picture'
                                        style={{ display: 'none' }}
                                        onChange={handleFileSelect}
                                    />
                                    <FlexBox alignItems={'center'}>
                                        <PhotoCamera fontSize='small' />
                                        <Box ml={1}>
                                            {t('uploadFile.takePhotos')}
                                        </Box>
                                    </FlexBox>
                                </label>
                            </MenuItem>
                        )}

                        <MenuItem onClick={handleClose}>
                            <label htmlFor='icon-button-file'>
                                <input
                                    type='file'
                                    accept='image/*'
                                    id='icon-button-file'
                                    style={{ display: 'none' }}
                                    onChange={handleFileSelect}
                                />
                                <FlexBox alignItems={'center'}>
                                    <InsertDriveFile fontSize='small' />
                                    <Box ml={1}>
                                        {t('uploadFile.uploadImage')}
                                    </Box>
                                </FlexBox>
                            </label>
                        </MenuItem>
                    </Menu>
                )}
            </div>
        )
    }


    // Display avatar image
    const [displayAvatar, setDisplayAvatar] = useState<string>(
        avatar || defaultProfileImage
    );
    // Update avatar image without refresh the page
    useEffect(() => {
        setDisplayAvatar(avatar || defaultProfileImage);
    }, [avatar]);

    const handleClick = (event: any) => {
        if (downMd) {
            setDrawerOpen(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDrawerOpen(false);
    };

    const checkCameraAvailability = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                stream.getTracks().forEach(track => { track.stop() });
                setCameraAvailable(true);
            })
            .catch(() => {
                toast.error(t('alerts.noCameraAvailable'), { duration: 5000 });
                setCameraAvailable(false);
            });
    };

    return (
        <div>
            {showCamera && cameraAvailable && (
                <div className={showCamera ? "fullscreen-camera-container" : ""}>
                    <div className={showCamera ? 'camera-container' : ''}>
                        <Camera
                            setShowCameraCallback={setShowCamera}
                            setDisplayAvatarCallback={setDisplayAvatar}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            id={data!.basicInformation!.id}
                            tokenState={tokenState}
                            tenantState={tenantState}
                            showAlertCallback={showAlert}
                            isConfirmationOpen={avatarDialog}
                            handleConfirmation={completeProfile}
                        />
                    </div>
                </div>
            )}

            {!showProfileCompleted && (
                <Grid container>
                    <Grid item md={2}></Grid>
                    <Grid item md={8} container justifyContent="flex-end">
                        <StyledToolBar>
                            <LanguagePopover />
                        </StyledToolBar>
                    </Grid>
                </Grid>
            )}
            <Toaster />
            <CustomDialog
                zIndex={80}
                open={avatarDialog}
                onClose={handleAvatarDialog}
                onConfirm={() => {
                    completeProfile();
                }}
                title={t('employees.profileCompletionByEmployee.dialog.title')}
                content={
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12}>
                            {t('employees.profileCompletionByEmployee.dialog.content')}
                        </Grid>

                        <Grid item xs={6} paddingTop={2}>
                            {UploadProfileImmage(true)}
                        </Grid>


                    </Grid>
                }
                confirmColor='warning'
                cancelColor='primary'
                confirmText={t('employees.profileCompletionByEmployee.dialog.confirm')}
                icon={<CheckCircleOutlinedIcon />}
            />
            {isValidToken ?
                <>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={8000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert severity='error' style={{ borderRadius: '10px' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    {!showProfileCompleted && (
                        <Grid container>
                            <Grid container md={2} justifyContent={'end'} marginRight={0} alignContent={'end'} zIndex={1}>
                                {!downMd && (
                                    <img src={WallMan} alt='Man holding a phone' style={{ marginBottom: '0.4rem', marginRight: '-2.8rem', maxWidth: '40%', height: 'auto' }} />
                                )}
                            </Grid>
                            <Grid container padding={3} md={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Card sx={{ padding: '1rem' }}>
                                            {downMd && (
                                                <FlexBox mb={2} justifyContent={'center'}>
                                                    <CustomStepper activeStep={activeTab} steps={tabs} />
                                                </FlexBox>
                                            )}
                                            <Typography fontSize={downMd ? 20 : 30} fontWeight={600} fontFamily={"Montserrat"} justifyContent={'center'}>
                                                <Grid container>
                                                    <Grid md={12}>
                                                        {t('employees.profileCompletionByEmployee.title')}
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                            <Typography fontSize={downMd ? 14 : 20} fontWeight={100} fontFamily={"Montserrat"} marginTop={downMd ? 2 : 1}>
                                                {t('employees.profileCompletionByEmployee.description')}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Card sx={{
                                            padding: '1rem 0',
                                            marginTop: '24px',
                                            height: downMd ? '2' : '32rem',

                                        }}>
                                            <FlexBox justifyContent='center' mb={downMd ? 0 : 4}>
                                                <AvatarBadge
                                                    badgeContent={
                                                        UploadProfileImmage()
                                                    }
                                                >
                                                    <ImageWrapper>
                                                        <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='You' sizes='large' />
                                                    </ImageWrapper>
                                                </AvatarBadge>
                                            </FlexBox>
                                            {!downMd && (
                                                <Tabs orientation='vertical' value={activeTab} onChange={handleChange}>
                                                    {tabs.map(({ key, name, Icon }, index) => (
                                                        <Tab
                                                            icon={<Icon sx={{ color: 'text.secondary' }} />}
                                                            label={t(name)}
                                                            id={`tab-${index}`}
                                                            key={`tab-${index}`}
                                                            aria-controls={`tab-${index}`}
                                                            sx={{
                                                                margin: '0',
                                                                width: '100%',
                                                            }}
                                                        ></Tab>
                                                    ))}
                                                </Tabs>
                                            )}
                                        </Card>
                                    </Grid>
                                    <Grid item md={10} xs={12}>
                                        {downMd && (
                                            <FlexBox justifyContent={'end'}>
                                                <img src={SitWoman} alt='Sit woman' style={{ marginBottom: isSmallDevice ? '-13vh' : '-12vh', maxWidth: '100%', height: '20vh' }} />
                                            </FlexBox>
                                        )}

                                        {
                                            ((activeTab === 0 && (
                                                <div role='tabpanel' id='tab-0' aria-labelledby='tab-0'>
                                                    <TabComponent.BasicInformation
                                                        key={data?.basicInformation?.id}
                                                        data={data?.basicInformation}
                                                        onSubmit={submitBasicInformation}
                                                        isProfileCompletition={true}
                                                        userMode = {true}
                                                    />
                                                </div>
                                            )) ||
                                                ((activeTab === 1 && (
                                                    <div role='tabpanel' id='tab-1' aria-labelledby='tab-1'>
                                                        <TabComponent.IDDocument
                                                            key={data?.basicInformation?.id}
                                                            data={data?.idDocuments}
                                                            onSubmit={submitIDDocuments}
                                                            isProfileCompletition={true}
                                                            userMode = {true}
                                                        />
                                                    </div>

                                                )))
                                                ||
                                                (activeTab === 2 && (
                                                    <div role='tabpanel' id='tab-2' aria-labelledby='tab-2'>
                                                        <TabComponent.AdditionalInformation
                                                            key={data?.basicInformation?.id}
                                                            data={data?.additionalInformations}
                                                            onSubmit={submitAdditionalInformations}
                                                            isProfileCompletition={true}
                                                            setNewDataCallback={setAdditionalInformations}
                                                            id={employeeId}
                                                            userMode = {true}
                                                        />
                                                    </div>
                                                )
                                                )
                                                ||
                                                ((activeTab === 3 && (
                                                    <div role='tabpanel' id='tab-2' aria-labelledby='tab-2'>
                                                        <TabComponent.BankingInformation
                                                            key={data?.basicInformation?.id}
                                                            data={data?.bank}
                                                            onSubmit={submitBank}
                                                            isProfileCompletition={true}
                                                            userMode = {true}
                                                        />
                                                    </div>
                                                )))
                                            )
                                        }
                                    </Grid>
                                    <Grid item xl={2} xs={3}>
                                        <FlexBox justifyContent={'left'}>
                                            {activeTab > 0 && (
                                                <MyStyledButton
                                                    variant='contained'
                                                    onClick={prevTab}
                                                >
                                                    {t('common.forms.button.previous')}
                                                </MyStyledButton>
                                            )}
                                        </FlexBox>
                                    </Grid>

                                    <Grid item xl={2} xs={2}></Grid>
                                    <Grid item xl={4} xs={2}>
                                        <Box mt={2}>
                                            {!downMd && (
                                                <CustomStepper activeStep={activeTab} steps={tabs} />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xl={2} xs={2}></Grid>
                                    <Grid item xl={2} xs={3}>
                                        <FlexBox justifyContent={'flex-end'}>
                                            {activeTab < tabs.length && (
                                                <MyStyledButton
                                                    variant='contained'
                                                    type='submit'
                                                    form='tab-form'
                                                    disabled={isSubmitting}
                                                >
                                                    {activeTab === tabs.length - 1
                                                        ? t('common.forms.button.save')
                                                        : t('common.forms.button.next')}
                                                </MyStyledButton>
                                            )}
                                        </FlexBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container md={2}></Grid>
                        </Grid>
                    )}
                    {showProfileCompleted && <ProfileCompleted />}
                </>
                : <InvalidTokenPage
                    tokenData={tokenData}
                    tokenState={tokenState}
                    tenantState={tenantState}
                />
            }
        </div >
    );
};

export default CompleteEmployeeProfilePage;
