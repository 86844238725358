import { SvgIcon, type SvgIconProps } from '@mui/material';

const AdminEcommerce = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 15' {...props}>
      <path
        d='M12.7012 1.90002C12.7012 2.00338 12.4873 3.28638 12.4873 3.28638L12.1636 8.02295C12.1636 8.02295 11.9093 9.27551 11.9093 9.36669H2.1068C1.78892 9.36669 1.49415 9.22681 1.30341 8.97756C1.11268 8.72823 1.04332 8.39382 1.11268 8.07158L1.92763 4.1011C2.03745 3.54781 2.51139 3.10389 3.04313 3.03706L12.6954 1.90611C12.6954 1.90002 12.6954 1.90002 12.7012 1.90002Z'
        className='secondary'
      />
      <path d='M14.8382 0.0333252H13.7684C12.9645 0.0333252 12.3871 0.918579 12.0927 2.02358L11.8889 3.44877L11.5719 8.33964L11.3285 9.72717C11.0624 10.3738 10.6322 10.468 10.2869 10.468H1.16168C0.816367 10.468 0.533325 10.7819 0.533325 11.1649C0.533325 11.5479 0.816367 11.8555 1.16168 11.8555H10.2869C11.572 11.8555 12.4608 10.9138 12.7324 9.30652C12.7324 9.3003 12.7324 9.3003 12.7324 9.3003C12.7494 9.19357 12.7664 9.08684 12.7778 8.97383C12.789 8.87965 12.8003 8.77919 12.806 8.67874L13.157 3.31699C13.2363 2.14921 13.6495 1.47742 13.7684 1.42091H14.8382C15.1836 1.42091 15.4667 1.11327 15.4667 0.73029C15.4667 0.347308 15.1836 0.0333252 14.8382 0.0333252Z' />
      <path d='M5.02208 5.56552V7.29433C5.02208 7.60432 4.75682 7.84874 4.42112 7.84874C4.08548 7.84874 3.82021 7.60432 3.82021 7.29433V5.56552C3.82021 5.25553 4.08548 5.01111 4.42112 5.01111C4.75682 5.01111 5.02208 5.25553 5.02208 5.56552ZM7.37694 5.56552V7.29433C7.37694 7.60432 7.11709 7.84874 6.7815 7.84874C6.44586 7.84874 6.18054 7.60432 6.18054 7.29433V5.56552C6.18054 5.25553 6.44586 5.01111 6.7815 5.01111C7.11709 5.01111 7.37694 5.25553 7.37694 5.56552ZM9.73727 5.56552V7.29433C9.73727 7.60432 9.47742 7.84874 9.14178 7.84874C8.80613 7.84874 8.54092 7.60432 8.54092 7.29433V5.56552C8.54092 5.25553 8.80613 5.01111 9.14178 5.01111C9.47742 5.01111 9.73727 5.25553 9.73727 5.56552ZM3.49545 13.6432C3.49545 14.3705 2.95409 14.9667 2.28821 14.9667C1.62776 14.9667 1.0864 14.3705 1.0864 13.6432C1.0864 12.91 1.62776 12.3198 2.28821 12.3198C2.95409 12.3198 3.49545 12.91 3.49545 13.6432ZM11.042 13.6432C11.042 14.3705 10.5006 14.9667 9.84013 14.9667C9.17426 14.9667 8.6329 14.3705 8.6329 13.6432C8.6329 12.91 9.17426 12.3198 9.84013 12.3198C10.5006 12.3198 11.042 12.91 11.042 13.6432Z' />
    </SvgIcon>
  );
};

export default AdminEcommerce;
