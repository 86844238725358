import {
    Box,
    Card,
    Grid,
    styled,
    Tab,
    Tabs,
    type Theme,
    useMediaQuery,
    Snackbar,
    Button,
} from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import Icons from 'icons/account';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TabComponent from 'page-sections/employees';
import React, { type FC, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import AvatarBadge from 'components/avatars/AvatarBadge';
import { CameraAlt } from '@mui/icons-material';
import { BankFormData } from '../../page-sections/employees/BankingInformation';
import { BasicInformationFormData } from '../../page-sections/employees/BasicInformation';
import { useLocation, useNavigate } from 'react-router-dom';
import { additionalInformationsApi, bankingInformationApi, employeesApi, idDocumentsApi } from '../../api';
import {
    AdditionalInformationsEntity,
    CreateEmployeeDocumentDto,
    CreateEmployeeDto,
    CreateMyBankingInformationDto,
} from '../../api/generated';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import toast from 'react-hot-toast';
import { convertToWebP } from '../../utils/converterToWebP';
import AppAvatar from 'components/avatars/AppAvatar';
import defaultProfileImage from '../../assets/images/default-profile.png';
import { IDFormData } from '../../page-sections/employees/IDDocuments';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { AdditionalFormData } from 'page-sections/employees/AdditionalInformation';

export enum TokenType {
    COMPLETE_DATA_REQUEST = 'COMPLETE_DATA_REQUEST',
    RESET_PASSWORD = 'RESET_PASSWORD',
}

// Set alert graphics
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContentWrapper = styled(Box)(({ theme }) => ({
    zIndex: 1,
    marginTop: 55,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const CoverPicWrapper = styled(Box)(({ theme }) => ({
    top: 0,
    left: 0,
    height: 125,
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    margin: 'auto',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: 'white',
    backgroundColor: theme.palette.background.default,
}));
const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
    },
    '& .MuiTab-root': {
        minWidth: "100%!important",
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
    },
    '& .MuiTabs-indicator': {
        height: 4,
    },
}));
const TabsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
}));
type IncludeId<T> = T & { id: number };
interface DocumentDtoWithId extends CreateEmployeeDocumentDto {
    id?: number;
}

interface FormData {
    basicInformation?: IncludeId<BasicInformationFormData>;
    idDocuments?: Array<IncludeId<IDFormData>>;
    bank?: IncludeId<BankFormData>;
    additionalInformations?: IncludeId<AdditionalFormData>;
}

export interface DepartmentAssignment {
    department: {
        id: number;
        name: string;
    };
    workPosition: {
        name: string;
    };
};

const tabs: Array<{
    name: string;
    Icon: any;
}> = [
        {
            name: 'employees.generalInformation.title',
            Icon: Icons.UserOutlined,
        },
        {
            name: 'employees.id.title',
            Icon: Icons.Link,
        },
        {
            name: 'employees.additionalInformation.title',
            Icon: ContactEmergencyIcon,
        },
        {
            name: 'employees.bank.title',
            Icon: AccountBalanceIcon,
        }
    ];

const UniqueEmployee: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useLocation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [data, setData] = useState<FormData>(
    );
    // isSubmitting hook because formik's submitting hook cannot be accessed here
    const [isSubmitting, setIsSubmitting] = useState(false);
    // activeTab in the state is used to navigate to one specific tab of the employee informations
    const [activeTab, setActiveTab] = useState(state?.activeTab ? state.activeTab : 0);
    const [tempAvatarImage, setTempAvatarImage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const buttonFontSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? '0.7rem' : '1.1rem';
    const sectionsButtonFontSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) ? '0.6rem' : '0.7rem';

    const prevTab = () => {
        if (activeTab <= 0) {
            return;
        }
        setActiveTab(activeTab - 1);
    };
    const nextTab = () => {
        if (activeTab >= tabs.length - 1) {
            return;
        }
        setActiveTab(activeTab + 1);
    };

    const submitWrapper = async (submit: () => Promise<any>) => {
        setIsSubmitting(true);
        await submit();
        setIsSubmitting(false);
    };

    function shortenData<T extends Record<string, any>>(
        oldData: T,
        newData: T,
    ): Partial<T> | undefined {
        const nonEqualFields = Object.fromEntries(
            Object.entries(newData).filter(([key, val]) => val !== oldData[key]),
        );
        if (Object.keys(nonEqualFields).length === 0) {
            return undefined;
        }
        return nonEqualFields as Partial<T>;
    }

    const fetchData = async (newEmployeeId?: number) => {

        function removeEmployeeId<T extends { employeeId: number }>(
            entity?: T | null,
        ) {
            if (!entity) {
                return;
            }

            const { employeeId, ...rest } = entity;
            return rest;
        }

        const employeeEntity = (await employeesApi.getMySelf()).data

        const {
            bankingInformation: bankingInformationEntity,
            documents: documentsEntity,
            ...employee
        } = employeeEntity;
        const bank = removeEmployeeId(bankingInformationEntity);

        const additionalInformations = (await additionalInformationsApi.findOneByMyself()).data

        setData({
            basicInformation: employee,
            bank,
            idDocuments: documentsEntity !== null ? documentsEntity : undefined,
            additionalInformations: {
                ...additionalInformations,
                foodRequirments: additionalInformations.foodRequirments as any[],
                shirtSize: additionalInformations.shirtSize as any,
            } 
        });
    };

    useEffect(() => {
        fetchData();
    }, [])

    async function updateEmployee(
        newData: CreateEmployeeDto,
    ): Promise<IncludeId<CreateEmployeeDto> | undefined> {
        if (!data) {
            return
        }
        if (!data?.basicInformation) {
            // if mail field is empty delete it
            if (newData.email === '')
                delete newData.email
        }
        if (data.basicInformation) {
            newData.filePath = data.basicInformation.filePath; // Used to avoid updating the avatar image in case the user clicks next without having set any new avatar
            const trimmedData = shortenData(data.basicInformation, newData);
            if (trimmedData && trimmedData?.email === ''){
                trimmedData.email = undefined;
            } 
            if (trimmedData) {
                return (await employeesApi.updateMySelf(trimmedData)).data;
            } else {
                return data.basicInformation;
            }
        }
    }

    const submitBasicInformation = async (basicInformation: BasicInformationFormData) => {
        return submitWrapper(async () => {
            try {
                const entity = await updateEmployee(basicInformation);
                if (entity) {
                    setData({
                        ...data,
                        basicInformation: entity,
                    });
                    if (tempAvatarImage && entity.id) {
                        await handleFileUpload({ id: entity.id, isCalledBySubmit: true });
                    }
                    nextTab();
                }
            } catch (error: any) {
                if (error.response.status === 400) {
                    toast.error(t('alerts.employeeExists'));
                }
            }
        });
    };



    // This function was used to avoid errors related to async / await 
    async function getAvatarBlob() {
        const response = await fetch(tempAvatarImage);
        const blob = await response.blob();
        return blob;
    }

    async function updateBankingInformation(
        newData: CreateMyBankingInformationDto,
    ) {
        if (!data?.bank) {
            return (await bankingInformationApi.createMyself(newData)).data;
        }
        const { id, ...oldData } = data.bank;
        const trimmedData = shortenData(oldData, newData);
        if (trimmedData) {
            return (await bankingInformationApi.updateMyself(id, trimmedData)).data
        } else {
            return data.bank;
        }
    }

    const submitBank = async (bank: BankFormData) => {
        return submitWrapper(async () => {
            navigate('/employee/defaultUserPage');
            const newData: CreateMyBankingInformationDto = {
                ...bank,
            };
            const entity = await updateBankingInformation(newData);
            setData({
                ...data,
                bank: entity,
            });
            nextTab();
        });
    };

    function showAlert(errorMessage: string) {
        setErrorMessage(errorMessage);
        setOpenSnackbar(true);
    }


    // Upload avatar image
    const avatar = data?.basicInformation?.filePath;
    const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / (1024 * 1024); // Dimension in MB
        if (fileSize > 30) {
            showAlert(t('alerts.uploadSizeErrorMessage', {
                dimension: '30',
            })); // Open Snackbar
            event.target.value = ''; // Reset input value
            return;
        }
        // If user is set file can be uploaded directly
        if (data?.basicInformation && data.basicInformation.id) {
            await handleFileUpload({ id: data.basicInformation.id, file });
        }
        // If user is not set only display the image, the actual upload happens at onSubmitBasicInformation{} 
        else {
            setTempAvatarImage(URL.createObjectURL(file));
            setDisplayAvatar(URL.createObjectURL(file));
        }
    };

    const handleFileUpload = async ({ id, file, isCalledBySubmit = false }: { id: number, file?: File, isCalledBySubmit?: boolean }) => {
        const formData = new FormData();
        // File is always defined because if the file is not defined isCalledBySubmit is true and the problematic code is never reached
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const blob = isCalledBySubmit ? await getAvatarBlob() : new Blob([file!], { type: file!.type });
        const webpBlob = await convertToWebP(blob, showAlert).catch((error) => { console.error(error) });
        if (!webpBlob) {
            return;
        }
        formData.append('file', webpBlob, 'avatar.webp');
        await employeesApi.createFile(String(id), {
            data: formData,
        });
        setDisplayAvatar(URL.createObjectURL(webpBlob));
        toast.success(t('alerts.uploadSuccess'), { duration: 5000 });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    async function updateIDDocuments(newDataArray: DocumentDtoWithId[]) {

        // Existing documents that were modified
        const updatedDocuments = newDataArray.filter(newData => newData.id && data?.idDocuments?.some(oldData => oldData.id === newData.id));

        // Update existing documents
        const updatePromises = updatedDocuments.map(async newData => {
            const oldData = data?.idDocuments?.find(oldData => oldData.id === newData.id);
            if (oldData) {
                if (typeof newData.id !== 'undefined') {
                    const shortenedData = shortenData(oldData, newData as IncludeId<IDFormData>);
                    if (shortenedData) {
                        // force typeName in update data to upload new possible names
                        shortenedData.typeName = oldData.typeName;
                        return await idDocumentsApi.updateByMyself(String(newData.id), shortenedData).then(({ data }) => data);
                    }
                }
                return [];
            }
        });

        // Wait for all operations to complete
        await Promise.all([...updatePromises]);

        // Fetch and return the updated state from the database

        // basicInformation is always set because the tab is before the documents
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const updatedState = await idDocumentsApi.findMyself();
        return updatedState;
    }

    const submitIDDocuments = async (idDocuments: IDFormData[]) => {
        if (idDocuments.length === 0 && (data?.idDocuments?.length === 0 || !data?.idDocuments)) {
            nextTab();
            return;
        }

        await submitWrapper(async () => {
            const newDataArray = idDocuments.map(iddocument => {
                const { tempKey, ...rest } = iddocument;
                return {
                    ...rest,
                    // basicInformation is always set because the tab is before the bank
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    employeeId: data!.basicInformation!.id,
                };
            });

            const updatedEntities = await updateIDDocuments(newDataArray);
            setData({
                ...data,
                idDocuments: updatedEntities.data,
            });
            const allDocumentsUploaded = updatedEntities.data.every((document) => document.documentFiles && document.documentFiles?.length > 0);
            if (!allDocumentsUploaded) {
                toast.error(t('alerts.uploadAllDocuments'));
                return;
            }
            nextTab();
        })
    }

    const fetchAdditionalInformations = async () => {
        const employeeId = data?.basicInformation?.id ?? state?.employeeId;
        if (!employeeId)
            return;
        const additionalInformations = (await additionalInformationsApi.findOneByMyself()).data;
        setData(
            {
                ...data,
                additionalInformations: {
                    ...additionalInformations,
                    foodRequirments: additionalInformations.foodRequirments as any[],
                    shirtSize: additionalInformations.shirtSize as any,
                    licences: additionalInformations.licences ?? []
                }
            }
        );
    }

    const setAdditionalInformations = (additionalInformations: AdditionalInformationsEntity) => {
        setData(
            {
                ...data,
                additionalInformations: {
                    ...additionalInformations,
                    foodRequirments: additionalInformations.foodRequirments as any[],
                    shirtSize: additionalInformations.shirtSize as any,
                    licences: additionalInformations.licences ?? []
                }
            }
        )
    }

    const submitAdditionalInformations = async (submit: AdditionalFormData) => {
        const employeeId = data?.basicInformation?.id ?? state?.employeeId;
        if (!employeeId)
            return;

        // additionalInformations exists
        if (submit?.id) {
           await additionalInformationsApi.updateByMyself({
                customFoodRequirments: submit.customFoodRequirments as any,
                foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
                pantsSize: submit.pantsSize as any,
                shirtSize: submit.shirtSize?.toUpperCase() as any
            });
        }
        else {
            await additionalInformationsApi.createByMyself({
                employeeId,
                customFoodRequirments: submit.customFoodRequirments as any,
                foodRequirments: submit.foodRequirments.map(x => x.toUpperCase()) as any,
                pantsSize: submit.pantsSize as any,
                shirtSize: submit.shirtSize?.toUpperCase() as any
            });
        };

        await fetchAdditionalInformations();
        nextTab();
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (data && Object.keys(data).length !== 0) {
            setActiveTab(newValue);
        }
    };
    // Diplay avatar image
    const [displayAvatar, setDisplayAvatar] = useState<string>(
        avatar || defaultProfileImage
    );
    // Update avatar image without refresh the page
    useEffect(() => {
        setDisplayAvatar(avatar || defaultProfileImage);
    }, [avatar]);

    return (
        <Box pt={2} pb={4}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleCloseSnackbar}
            >
                <Alert severity='error' style={{ borderRadius: '10px' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Card sx={{ padding: 3, position: 'relative' }}>
                        <CoverPicWrapper>
                            <img
                                width='100%'
                                height='100%'
                                alt='Team Member'
                                src='/static/background/user-cover-pic.png'

                                style={{ objectFit: 'cover' }}
                            />
                        </CoverPicWrapper>

                        <ContentWrapper>
                            <FlexBox justifyContent='center'>
                                <AvatarBadge
                                    badgeContent={
                                        <label htmlFor='icon-button-file'>
                                            <input
                                                type='file'
                                                accept='image/*'
                                                id='icon-button-file'
                                                style={{ display: 'none' }}
                                                onChange={handleFileSelect}
                                            />

                                            <IconButton aria-label='upload picture' component='span'>
                                                <CameraAlt
                                                    sx={{ fontSize: 16, color: 'text.disabled' }}
                                                />
                                            </IconButton>
                                        </label>
                                    }
                                >
                                    <ImageWrapper>
                                        <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='Team Member' sizes='large' />
                                    </ImageWrapper>
                                </AvatarBadge>
                            </FlexBox>

                            {!downMd && (
                                <TabsContainer>

                                    <CustomTabs
                                        orientation='vertical'
                                        value={activeTab}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"

                                        allowScrollButtonsMobile
                                        sx={{
                                            borderRight: 1, borderColor: 'divider', width: "100%",
                                            justifyItems: 'center'
                                        }}
                                    >
                                        {tabs.map(({ name, Icon }, index) => (
                                            <Tab
                                                icon={<Icon sx={{ color: 'text.secondary' }} />}
                                                label={t(name)}
                                                id={`tab-${index}`}
                                                key={`tab-${index}`}
                                                aria-controls={`tab-${index}`}
                                                style={{
                                                    minWidth: "100%!important"
                                                }}
                                                sx={{
                                                    display: "flex",
                                                    textAlign: 'center',
                                                    minWidth: "100%",
                                                    marginLeft: 0
                                                }}
                                            ></Tab>
                                        ))}
                                    </CustomTabs>
                                </TabsContainer>
                            )}
                        </ContentWrapper>
                    </Card>
                </Grid>

                <Grid item md={9} xs={12}>

                    {data &&
                        ((activeTab === 0 && (
                            <div role='tabpanel' id='tab-0' aria-labelledby='tab-0'>
                                <TabComponent.BasicInformation
                                    key={data?.basicInformation?.id}
                                    data={data.basicInformation}
                                    onSubmit={submitBasicInformation}
                                    userMode={true}
                                />
                            </div>
                        )) ||
                            (activeTab === 1 && (
                                <div role='tabpanel' id='tab-1' aria-labelledby='tab-1'>
                                    <TabComponent.IDDocument
                                        key={data?.basicInformation?.id}
                                        data={data.idDocuments}
                                        onSubmit={submitIDDocuments}
                                        buttonFontSize={sectionsButtonFontSize}
                                        userMode={true}

                                    />
                                </div>
                            )) ||
                            (activeTab === 2 && (
                                <div role='tabpanel' id='tab-2' aria-labelledby='tab-2'>
                                    <TabComponent.AdditionalInformation
                                        key={data?.basicInformation?.id}
                                        data={data.additionalInformations}
                                        onSubmit={submitAdditionalInformations}
                                        id={data.basicInformation?.id}
                                        setNewDataCallback={setAdditionalInformations}
                                        userMode={true}

                                    />
                                </div>
                            )

                            ) ||
                            (activeTab === 3 && (
                                <div role='tabpanel' id='tab-3' aria-labelledby='tab-3'>
                                    <TabComponent.BankingInformation
                                        key={data?.basicInformation?.id}
                                        data={data.bank}
                                        onSubmit={submitBank}
                                        userMode={true}

                                    />
                                </div>
                            )))}
                    <Grid item>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Grid item xs={4} sm={4} textAlign={'right'}>
                                {activeTab > 0 && (
                                    <Button
                                        variant='contained'
                                        onClick={prevTab}
                                        style={
                                            {
                                                fontSize: buttonFontSize
                                            }
                                        }
                                    >
                                        {t('common.forms.button.previous')}
                                    </Button>
                                )}

                            </Grid>

                            <Grid item xs={4} sm={2} textAlign={'right'}>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    form='tab-form'

                                    style={
                                        {
                                            fontSize: buttonFontSize
                                        }
                                    }
                                    disabled={isSubmitting}
                                >
                                    {activeTab === tabs.length - 1
                                        ? t('common.forms.button.save')
                                        : t('common.forms.button.next')}
                                </Button>

                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UniqueEmployee;
