import { Grid, Button, Card, styled } from "@mui/material";
import { CustomDialog } from "components/CustomDialog";
import { H2, H4, H6 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useEffect, useState } from "react"
import { Toaster } from "react-hot-toast";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { CreatePaymentMethodDto, PaymentMethodEntity } from "api/generated";
import { paymentMethodsApi } from "api";
import PaymentMethodsDataTable from "page-sections/data-table/PaymentMethodsDataTable";
import AddPaymentMethodModal from "page-sections/data-table/dataTableV2/AddPaymentMethodModal";
import AppModal from "components/AppModal";
import SearchInput from "components/input-fields/SearchInput";
import { searchByName } from "utils/utils";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { useTranslation } from "react-i18next";

const PaymentMethods: FC = () => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
    const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<PaymentMethodEntity[]>([]);
    const [selectedRows, setSelectedRows] = useState<PaymentMethodEntity[]>([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openErrorDeleteDialog, setOpenErrorDeleteDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState<PaymentMethodEntity>();
    const { t } = useTranslation();
    const [avaiblePaymentTypes, setAvaiblePaymentTypes] = useState<Array<{ value: string, label: string }>>([])

   /* const avaiblePaymentTypes = Object.keys(PaymentMethodEntityTypeEnum).filter(value => !paymentMethods
        .find(existingMethod => existingMethod.type.toUpperCase() === value.toUpperCase()))
        .map(value => {
            return {
                value,
                label: translatePaymentTypes(value as any, t) ?? ''
            }

        })
        */

    const ButtonWrapper = styled(FlexBox)(({ theme }) => ({
        [theme.breakpoints.down(500)]: {
            marginTop: 10,
            width: '100%',
            flexDirection: 'column-reverse',
            '& > .MuiBox-root': {
                width: '100%',
                margin: '10px 0',
                alignItems: 'center',
                flexDirection: 'column',
            },
            '& .MuiButton-root': { minWidth: '100%' },
        },
    }));

    const fetchData = async () => {
        const pm = await paymentMethodsApi.findAll();
        const avaiblePaymentTypes = (await paymentMethodsApi.findAllAvaible()).data;
        setPaymentMethods(pm.data);
        setAvaiblePaymentTypes(avaiblePaymentTypes.map((type: any) => {
            return {
                label: translatePaymentTypes(type, t),
                value: type
            }
        }))
    }

    const addPaymentMethod = async (paymentMethods: CreatePaymentMethodDto) => {
        if (data === undefined) {
            await paymentMethodsApi.create(paymentMethods);
        } else {
            await paymentMethodsApi.update(String(data.id), paymentMethods);
        }
        fetchData();
        setOpenAddModal(false);
        setData(undefined);
    }

    const handleDelete = async () => {
        for (const method of selectedRows) {
            try {
                await paymentMethodsApi.remove(String(filteredPaymentMethods[method.id].id));
            } catch (e: any) {
                if (e.response.status === 405) {
                    setOpenErrorDeleteDialog(true);
                }
            }
        }
        setOpenDeleteDialog(false);
        setSelectedRows([]);
        fetchData();
    }

    const handleEdit = (data: PaymentMethodEntity) => {
        setData(data)
        setOpenAddModal(true);
    }

    const handleRowSelect = (rowArr: PaymentMethodEntity[]) => {
        setSelectedRows(rowArr);
    }


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setFilteredPaymentMethods(searchByName(paymentMethods, searchValue))
    }, [paymentMethods, searchValue])

    return <Grid container>
        <Toaster />
        <CustomDialog
            open={openDeleteDialog}
            onClose={() => { setOpenDeleteDialog(false) }}
            onConfirm={handleDelete}
            title={t('dialog.title', {
                item: t('amountType.item'),
            })}
            content={t('dialog.content')}
            icon={<DeleteForeverIcon />}
            confirmText={t('dialog.confirm')}
            confirmColor='error'
            cancelColor='primary'
        />
        <Grid item xs={12}>
            <SearchInput
                bordered={false}
                placeholder={t('common.tables.button.searchItems', {
                    items: t('amountType.item'),
                })}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
            />
            <ButtonWrapper justifyContent={'end'}>
                {selectedRows.length > 0 && (
                    <FlexBox alignItems='center' mr={2}>
                        <H6 mr={1}>
                            {t('common.tables.select.numSelected', {
                                num: selectedRows.length,
                            })}
                        </H6>
                        <Button
                            size='small'
                            color='error'
                            variant='contained'
                            onClick={() => { setOpenDeleteDialog(true) }}
                            sx={{ color: 'common.white' }}
                        >
                            {t('common.tables.select.deleteSelected')}
                        </Button>
                    </FlexBox>
                )}
                <Button
                    startIcon={<AddIcon />}
                    variant='contained'
                    size='small'
                    onClick={() => { setOpenAddModal(true) }}
                    disabled={avaiblePaymentTypes.length === 0}
                >
                    {t('common.tables.button.addItem', {
                        item: t('amountType.item'),
                    })}
                </Button>
            </ButtonWrapper>
        </Grid>
        <Grid item xs={12}>
            <Card sx={{ width: '100%', marginTop: 3 }}>
                <PaymentMethodsDataTable
                    data={filteredPaymentMethods.sort((a, b) => { return translatePaymentTypes(a.type, t).localeCompare(translatePaymentTypes(b.type, t)) })}
                    handleRowSelect={handleRowSelect}
                    editCallback={handleEdit}
                />
            </Card>
        </Grid>
        <AddPaymentMethodModal
            open={openAddModal}
            onClose={() => { setOpenAddModal(false); setData(undefined) }}
            onSubmitAction={addPaymentMethod} data={data}
            avaiblePaymentTypes={avaiblePaymentTypes} 
            />
        <AppModal open={openErrorDeleteDialog} handleClose={() => { setOpenErrorDeleteDialog(false) }}>
            <H2>{t('delete.error')}</H2>
            <H4 marginTop={2}>{t('paymentMethod.error.delete')}</H4>
            <ButtonWrapper justifyContent={'end'}>
                <Button size='small' variant='contained' color='error' onClick={() => { setOpenErrorDeleteDialog(false) }}>{t('dialog.confirm')}</Button>
            </ButtonWrapper>
        </AppModal>
    </Grid >
}

export default PaymentMethods;