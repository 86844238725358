import { SvgIcon, type SvgIconProps } from '@mui/material';

const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 10 12' {...props}>
      <path
        d='M4.23535 5.64706H4.94123V9.52942H4.23535V5.64706Z'
        fill='currentColor'
      />
      <path
        d='M2.82349 5.64706H3.52937V9.52942H2.82349V5.64706Z'
        fill='currentColor'
      />
      <path
        d='M5.64722 5.64706H6.3531V9.52942H5.64722V5.64706Z'
        fill='currentColor'
      />
      <path
        d='M9.17647 1.41176H6.35294V1.05882C6.35294 0.474988 5.87798 0 5.29412 0H3.88235C3.29849 0 2.82353 0.474988 2.82353 1.05882V1.41176H0V3.52941H0.735247L1.38428 10.994C1.41231 11.5588 1.87518 12 2.44179 12H6.73466C7.30127 12 7.76414 11.5588 7.79217 10.994L8.44123 3.52941H9.17647V1.41176ZM3.52941 1.05882C3.52941 0.864212 3.68774 0.705882 3.88235 0.705882H5.29412C5.48875 0.705882 5.64706 0.864212 5.64706 1.05882V1.41176H3.52941V1.05882ZM0.705882 2.11765H8.47059V2.82353H0.705882V2.11765ZM7.08732 10.9559C7.07941 11.1455 6.92452 11.2941 6.73468 11.2941H2.44179C2.25195 11.2941 2.09706 11.1456 2.08915 10.9559C1.39861 3.00981 1.44584 3.55313 1.44376 3.52941H7.73271C7.73082 3.55071 7.77478 3.04544 7.08732 10.9559Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
};

export default DeleteIcon;
