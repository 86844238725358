import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVacation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='vacation_svg__b'>
        <path fill='none' d='M0 0h91v73H0z' data-name='Rettangolo 6' />
      </clipPath>
      <clipPath id='vacation_svg__a'>
        <path d='M0 0h175v180H0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#vacation_svg__a)' data-name='FF9C12 - vacanza'>
        
      <g data-name='Raggruppa 12'>
        <g
          clipPath='url(#vacation_svg__b)'
          data-name='Raggruppa 11'
          transform='translate(42 54)'
        >
          <path
            d='M76.879 0a3.494 3.494 0 0 0 0 6.989 6.987 6.987 0 0 1 6.989 6.989v24.461H6.989V13.978a6.987 6.987 0 0 1 6.989-6.989 3.494 3.494 0 1 0 0-6.989A13.98 13.98 0 0 0 0 13.978v40.187a19.22 19.22 0 0 0 38.439 0v-8.737h13.978v8.736a19.22 19.22 0 0 0 38.439 0V13.978A13.98 13.98 0 0 0 76.879 0M82.4 59.98 67.842 45.428h16.026v8.736A12.16 12.16 0 0 1 82.4 59.98M6.989 54.165v-7.29l18.046 18.049A12.233 12.233 0 0 1 6.989 54.165m24.461 0a12.16 12.16 0 0 1-1.471 5.815L15.425 45.428H31.45Zm27.956 0v-7.29l18.046 18.049a12.233 12.233 0 0 1-18.046-10.76'
            data-name='Tracciato 9'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgVacation;
