import { Button, Grid, styled } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import { FC } from 'react';
import { H2, H4 } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import AppSelectField from '../../../components/input-fields/MultipleChoiceField';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  CalendarEntryEntityTypeEnum,
  CreateCalendarEntryDto,
} from '../../../api/generated';
import AppModal from '../../../components/AppModal';
import FlexBox from '../../../components/flexbox/FlexBox';
import { format } from 'date-fns';
import CalendarInput from 'components/input-fields/CalendarInput';
import { isEndDateValid, isValidDate } from 'utils/dateValidator';

export type AddWorkFormData = Omit<
  CreateCalendarEntryDto,
  'startedOn' | 'endedOn'
> & {
  startedOn: Date;
  endedOn: Date;
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: AddWorkFormData) => Promise<void>;
  data?: Partial<AddWorkFormData>;
  employees?: any;

}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 450,
  minWidth: 200,

  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddEventModal: FC<ModalProps> = ({ open, onClose, onSubmit, data, employees }) => {
  const { t } = useTranslation();

  const initialValues: AddWorkFormData = {
    startedOn: data?.startedOn ?? toUTC(new Date()),
    endedOn: data?.endedOn ?? toUTC(new Date()),
    type: data?.type ?? CalendarEntryEntityTypeEnum.Ill,
  };

  const validationSchema: ObjectSchema<AddWorkFormData> = Yup.object().shape({
    startedOn: Yup.date().required(
      t('common.forms.field.required', {
        field: t('employees.calendar.entry.startedOn'),
      }),
    ).typeError(t('employees.calendar.typeError'))
      .test('is-valid-date', 'Invalid date', (value) => {
        return isValidDate(value);
      }),
    endedOn: Yup.date()
      .typeError(t('employees.calendar.typeError'))
      .required(t('common.forms.field.required', {
        field: t('employees.calendar.entry.endedOn'),
      }),)
      .test('isValidDate', 'Invalid date', (value) => {
        return isValidDate(value);
      })
      .test('isEndDateValid', t('employees.calendar.entry.endDateAfterStartDate'), function (value) {
        return isEndDateValid(value, this.parent.startedOn);
      }),
    type: Yup.mixed<CalendarEntryEntityTypeEnum>()
      .oneOf(Object.values(CalendarEntryEntityTypeEnum))
      .required(
        t('common.forms.field.required', {
          field: t('employees.calendar.entry.type.label'),
        }),
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    formik;

  function toUTC(date: Date) {
    const dateStr = format(date, 'yyyy-MM-dd');
    return new Date(`${dateStr}T00:00:00.000Z`);
  }


  return (
    <StyledAppModal open={open} handleClose={onClose} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      <H2 mb={2}> {t('employees.calendar.entry.title')}</H2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <CalendarInput
              format='dd.MM.yyyy'
              onChange={(date: Date | null) => {
                formik.setFieldValue('startedOn', date);
              }}
              value={values.startedOn}
              label={t('employees.calendar.entry.startedOn')}
              slotProps={{
                textField: {
                  error: !!errors.startedOn,
                  helperText: errors.endedOn ? String(errors.startedOn) : undefined
                }
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CalendarInput
              format='dd.MM.yyyy'
              onChange={(date: Date | null) => {
                formik.setFieldValue('endedOn', date);
              }}
              value={values.endedOn}
              label={t('employees.calendar.entry.endedOn')}
              slotProps={{
                textField: {
                  error: !!errors.endedOn,
                  helperText: errors.endedOn ? String(errors.endedOn) : undefined
                }
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <AppSelectField
              InputLabelProps={{ shrink: true }}
              select
              fullWidth
              name='type'
              variant='outlined'
              label={t('employees.calendar.entry.type.label')}
              placeholder=''
              SelectProps={{
                native: true,
                IconComponent: KeyboardArrowDown,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.type}
              helperText={touched.type && (errors.type as string)}
              error={Boolean(touched.type && (errors.type as string))}
            >
              <option value={''}>
                {t('employees.calendar.entry.type.default')}
              </option>

              {Object.values(CalendarEntryEntityTypeEnum).map((workingType) => (
                <option key={workingType} value={workingType}>
                  {t(`employees.calendar.types.${workingType.toLowerCase()}`)}
                </option>
              ))}
            </AppSelectField>
          </Grid>
        </Grid>
        {employees?.length && (
          <FlexBox flexDirection={'column'} marginTop={'2rem'} gap={2}>
            <H4>{t('employees.title')}</H4>
            {employees.map((employee: any) => {
              return (
                <FlexBox key={employee.original.id} justifyContent={'space-between'} alignItems={'center'}>
                  <span>• {employee.original.firstName} {employee.original.lastName}</span>
                </FlexBox>
              );
            })}
          </FlexBox>
        ) 
          }

        <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
          <Button fullWidth size='small' variant='outlined' onClick={onClose}>
            {t('common.forms.button.cancel')}
          </Button>
          <Button fullWidth size='small' type='submit' variant='contained'>
            {t('common.forms.button.save')}
          </Button>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AddEventModal;
