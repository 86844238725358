import { createContext, useContext, useMemo, useState } from 'react';

interface EmployeeContextType {
  employeeId: number;
  setEmployeeId: React.Dispatch<React.SetStateAction<number>>;
};


const EmployeeContext = createContext<EmployeeContextType | undefined>(undefined);

export const useEmployeeContext = (): EmployeeContextType => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error("employeeContext must be used within a EmployeeProvider");
  }
  return context;
};

const EmployeeProvider = ({ children }: any) => {
  const [employeeId, setEmployeeId] = useState(-1);

  const contextValue = useMemo(() => ({ employeeId, setEmployeeId}), [employeeId, setEmployeeId]);

  return (
    <EmployeeContext.Provider value={contextValue}>
      {children}
    </EmployeeContext.Provider>
  );
};


export default EmployeeProvider;