import { Button, Grid, styled } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { shiftApi } from "api";
import { ShiftEntity, ShiftTemplateEntity, ShiftDetailEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { enGB, it } from "date-fns/locale";
import { useFormik } from "formik";
import PositionAccordion from "page-sections/shifts/positions-accordion";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shortenData } from "utils/shortenData";
import { ignoreUTC } from "utils/utils";
import * as Yup from 'yup';
import AssignPositionToShiftModal from "./AssignPositionsToShiftModal";
import { useSeason } from "contexts/SeasonContext";



interface ModalProps {
    data?: ShiftEntity | null;
    open: boolean;
    template: ShiftTemplateEntity;
    onClose: () => void;
    fetchData: () => void;
    setData: (data: ShiftEntity | undefined) => void;
    departmentId: number;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 1100,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddShiftModal: FC<ModalProps> = ({
    fetchData,
    onClose,
    template,
    open,
    data,
    setData,
    departmentId
}) => {


    const { t } = useTranslation();

    const [shiftDetails, setShiftDetails] = useState<ShiftDetailEntity[]>([]);
    const [openAssignPositionToShift, setOpenAssignPositionToShift] = useState(false);
    const [areDetailsEdited, setAreDetailsEdited] = useState(false);
    const { seasonId } = useSeason();

    const initialValues = {
        name: '',
        startTime: new Date(),
        endTime: new Date(),
    };

    // effect to set data when editing a shift
    useEffect(() => {

        if (data) {
            setFieldValue('name', data.name, true);
            setFieldValue('startTime', ignoreUTC(new Date(data.startTime), true));
            setFieldValue('endTime', ignoreUTC(new Date(data.endTime), true));
            setShiftDetails(data.shiftDetails ?? []);
        }
        else {
            setFieldValue('name', initialValues.name, true);
            setFieldValue('startTime', initialValues.startTime, true);
            setFieldValue('endTime', initialValues.endTime, true);
            setShiftDetails([]);
        }

    }, [data])

    const fieldValidationSchema = Yup.object().shape({
        name: Yup.string().trim().min(3, t('common.forms.field.min', {
            field: t('shiftTemplate.name'),
            min: 3,
        })).required(
            t('common.forms.field.required', {
                field: t('shiftTemplate.name'),
            }),
        ),
        startTime: Yup.date().typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            })),
        endTime: Yup.date().typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
    });

    const fetchShiftAssigns = async () => {
        if (!data)
            return;
        const result = (await shiftApi.findShiftDetails(data?.id, departmentId, seasonId)).data;
        setShiftDetails(result);
        data.shiftDetails = result;
    }


    const { values, errors, handleChange, handleSubmit, touched, resetForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            if (!data) {
                await shiftApi.create({
                    name: values.name,
                    startTime: ignoreUTC(values.startTime).toISOString(),
                    endTime: ignoreUTC(values.endTime).toISOString(),
                    templateId: template.id,
                    departmentId,
                    seasonId
                }).then(() => { fetchData(); onClose() })
            }
            else {
                const formattedData = {
                    name: values.name,
                    startTime: ignoreUTC(values.startTime).toISOString(),
                    endTime: ignoreUTC(values.endTime).toISOString(),
                }
                const trimmedData: {
                    name?: string;
                    startTime?: string;
                    endTime?: string;
                    seasonId?: number;
                    departmentId?: number;
                } | undefined = shortenData(data, formattedData)
               
                if (trimmedData) {
                    trimmedData.seasonId = seasonId;
                    trimmedData.departmentId = departmentId;
                    await shiftApi
                        .update(data.id, trimmedData)
                        .then(() => { fetchData(); onClose(); });
                }
                else {
                    onClose();

                }
            }

            resetForm();

        },
    })

    const openShiftAssign = async () => {
        if (!data) {
            await shiftApi.create({
                name: values.name,
                startTime: ignoreUTC(values.startTime).toISOString(),
                endTime: ignoreUTC(values.endTime).toISOString(),
                templateId: template.id,
                seasonId,
                departmentId,
            }).then((data) => { fetchData(); setData(data.data) });
        }
        setOpenAssignPositionToShift(true);


    }

    const handleTimeChange = (isStart: boolean, value: Date | null) => {

        if (value && !isNaN(+value)) {
            // ignore seconds
            value.setSeconds(0, 0);
            setFieldValue(isStart ? 'startTime' : 'endTime', value, true);
        }

        else
            setFieldValue(isStart ? 'startTime' : 'endTime', undefined, true);
    }

    const timePicker = document.getElementById('startTime');

    return (
        <StyledAppModal
            open={open}
            handleClose={onClose}

        >



            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems={'stretch'} display={'flex'}  >
                    <Grid item xs={12} display={'flex'} mb={2}>
                        <H2>
                            {data ? t('common.forms.button.edit') + ' ' + t('shift.itemName') : t('common.forms.addItemLabel', {
                                item: t('shift.itemName'),
                            })}
                        </H2>
                    </Grid>
                    <Grid item xs={4}>
                        <AppTextField
                            InputProps={{
                                style: { height: timePicker?.clientHeight ?? 56, display: 'flex', alignSelf: 'flex-end' }
                            }}
                            sx={{ display: 'flex' }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            placeholder=""
                            size='small'
                            name='name'
                            label={t('shiftTemplate.name')}
                            value={values.name}
                            onChange={handleChange}
                            error={Boolean(errors.name && touched.name)}
                            helperText={(touched.name && errors.name) as string}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                            <TimePicker
                                label={t('shift.startTime')}
                                value={values.startTime}
                                onChange={(value) => { handleTimeChange(true, value) }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: !!errors.startTime,
                                        id: 'startTime',
                                        helperText: errors.startTime as string
                                    }
                                }}

                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <TimePicker
                                label={t('shift.endTime')}
                                value={values.endTime}
                                onChange={(value) => { handleTimeChange(false, value) }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: !!errors.endTime,
                                        helperText: errors.endTime as string
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <PositionAccordion
                    data={shiftDetails}
                    fetchDetailsData={fetchShiftAssigns}
                    openAddShiftDetail={openShiftAssign}
                    turnStart={values.startTime}
                    turnEnd={values.endTime}
                    values={values}
                    isEdited={areDetailsEdited}
                    setIsEdited={setAreDetailsEdited}
                    departmentId={departmentId}
                />

                {
                    data && (


                        <AssignPositionToShiftModal
                            fetchData={fetchShiftAssigns}
                            departmentId={template.departmentId}
                            shiftId={data.id}
                            open={openAssignPositionToShift}
                            onClose={() => { setOpenAssignPositionToShift(false) }}
                        />

                    )
                }



                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        onClose();
                        resetForm();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained' disabled={areDetailsEdited}>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>

            </form>
        </StyledAppModal>
    )
}

export default AddShiftModal;