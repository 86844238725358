import { SvgIcon, type SvgIconProps } from '@mui/material';

const ProjectChart = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 16 15' {...props}>
      <path
        d='M7.25334 14.9666C5.47108 14.9666 3.76182 14.2586 2.50157 12.9984C1.24132 11.7381 0.533325 10.0289 0.533325 8.24662C0.533325 6.46437 1.24132 4.7551 2.50157 3.49486C3.76182 2.23461 5.47108 1.52661 7.25334 1.52661C7.35141 1.52656 7.44853 1.54583 7.53914 1.58333C7.62976 1.62084 7.71209 1.67583 7.78144 1.74518C7.85078 1.81453 7.90578 1.89686 7.94328 1.98748C7.98079 2.07809 8.00006 2.17521 8.00001 2.27328V7.49996H13.2267C13.3248 7.4999 13.4219 7.51918 13.5125 7.55668C13.6031 7.59418 13.6854 7.64918 13.7548 7.71853C13.8241 7.78787 13.8791 7.87021 13.9166 7.96082C13.9541 8.05144 13.9734 8.14855 13.9733 8.24662C13.9714 10.0283 13.2628 11.7364 12.003 12.9962C10.7431 14.2561 9.035 14.9647 7.25334 14.9666Z'
        className='secondary'
      />
      <path d='M14.72 6.00667H10.24C10.1419 6.00672 10.0448 5.98745 9.95418 5.94995C9.86357 5.91244 9.78123 5.85745 9.71189 5.7881C9.64254 5.71875 9.58754 5.63642 9.55004 5.5458C9.51254 5.45519 9.49326 5.35807 9.49332 5.26V0.779993C9.49326 0.681924 9.51254 0.584805 9.55004 0.49419C9.58754 0.403575 9.64254 0.32124 9.71189 0.251894C9.78123 0.182549 9.86357 0.127552 9.95418 0.090048C10.0448 0.0525443 10.1419 0.0332694 10.24 0.0333253C11.6261 0.0336816 12.9553 0.584462 13.9354 1.56458C14.9155 2.54469 15.4663 3.87391 15.4667 5.26C15.4667 5.35807 15.4474 5.45519 15.4099 5.5458C15.3724 5.63642 15.3174 5.71875 15.2481 5.7881C15.1787 5.85745 15.0964 5.91244 15.0058 5.94995C14.9152 5.98745 14.8181 6.00672 14.72 6.00667V6.00667Z' />
    </SvgIcon>
  );
};

export default ProjectChart;
