
import { SeasonEntity } from 'api/generated';
import { createContext, useContext, useMemo, useState } from 'react';

interface SeasonContextType {
  seasonId: number;
  setSeasonId: React.Dispatch<React.SetStateAction<number>>;
  seasons: SeasonEntity[];
  setSeasons: React.Dispatch<React.SetStateAction<SeasonEntity[]>>;
  getSeason: () => SeasonEntity | undefined;
};

const SeasonContext = createContext<SeasonContextType | undefined>(undefined);

export const useSeason = (): SeasonContextType => {
  const context = useContext(SeasonContext);
  if (!context) {
    throw new Error("useSeason must be used within a SeasonProvider");
  }
  return context;
};

const SeasonProvider = ({ children }: any) => {
  const [seasonId, setSeasonId] = useState(0);
  const [seasons, setSeasons] = useState<SeasonEntity[]>([]);
  const getSeason = () => {
    return seasons.find(season => season.id === seasonId);
  }
  const contextValue = useMemo(() => ({ seasonId, setSeasonId, seasons, setSeasons, getSeason }), [seasonId, setSeasonId, seasons, setSeasons, getSeason]);

  return (
    <SeasonContext.Provider value={contextValue}>
      {children}
    </SeasonContext.Provider>
  );
};


export default SeasonProvider;



