import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLongevity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='5rem'
    height='5rem'
    viewBox='0 0 175 180'
    fill='white'
    {...props}
  >
    <defs>
      <clipPath id='longevity_svg__b'>
        <path fill='none' d='M0 0h82v113H0z' data-name='Rettangolo 9' />
      </clipPath>
    </defs>
    <g
      clipPath='url(#longevity_svg__clip-FF9C12_-_anzianit\xE0)'
      data-name='FF9C12 - anzianit\xE0'
    >
        
      <g data-name='Raggruppa 18'>
        <g
          clipPath='url(#longevity_svg__b)'
          data-name='Raggruppa 17'
          transform='translate(47 34)'
        >
          <path
            d='M82.489 41.27A41.244 41.244 0 1 0 15 73.062v35.7a3.746 3.746 0 0 0 5.426 3.356l20.821-10.386 20.822 10.407a3.787 3.787 0 0 0 5.422-3.378v-35.7a41.18 41.18 0 0 0 15-31.792m-74.99 0a33.745 33.745 0 1 1 33.743 33.746A33.743 33.743 0 0 1 7.5 41.27m52.493 61.424L42.917 94.16a3.75 3.75 0 0 0-3.356 0L22.5 102.694V77.999a41.2 41.2 0 0 0 37.495 0ZM41.244 67.516A26.246 26.246 0 1 0 15 41.27a26.246 26.246 0 0 0 26.244 26.246m0-44.994A18.747 18.747 0 1 1 22.5 41.27a18.75 18.75 0 0 1 18.744-18.748'
            data-name='Tracciato 13'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLongevity;
