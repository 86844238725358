
import {
    Add,
    Clear,
    KeyboardArrowLeft,
    Send,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Breakpoint,
    Button,
    Card,
    Divider,
    Drawer,
    Grid,
    IconButton,
    InputBase,
    styled,
    Theme,
    Typography,
    useMediaQuery,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { communicationsApi, employeesApi } from "api";
import { EmployeeEntity } from "api/generated";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import Scrollbar from "components/ScrollBar";
import { H4, H6, Tiny } from "components/Typography";
import CommunicationsConversationBox from "page-sections/communications/CommunicationsConversationBox";
import CommunicationsIncomingMsg from "page-sections/communications/CommunicationsIncomingMsg";
import CommunicationsOutgoingMsg from "page-sections/communications/CommunicationsOutgoingMsg";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import emptyTableImage from 'assets/images/empty-table.png';
import { searchByName } from "utils/utils";
import { checkToken } from "utils/checkToken";
import { useNavigate } from "react-router-dom";

// styled components
const LeftButton = styled(Box)<{ screen: number | Breakpoint }>(
    ({ theme, screen = "md" }) => ({
        top: 110,
        padding: 5,
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        position: "absolute",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        [theme.breakpoints.up(screen)]: { display: "none" },
    })
);

const Communications: FC = () => {
    const navigate = useNavigate()

    const { t } = useTranslation();
    const [openLeft, setOpenLeft] = useState(false);
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    const [conversationList, setConversationList] = useState<EmployeeEntity[]>([]);
    const [noConversationList, setNoConversationList] = useState<EmployeeEntity[]>([]);
    const [conversationFilteredList, setConversationFilteredList] = useState<EmployeeEntity[]>([]);
    const [noConversationFilteredList, setNoConversationFilteredList] = useState<EmployeeEntity[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<EmployeeEntity>();
    const [newConversation, setNewConversation] = useState(false);

    const chatEnd = React.useRef<HTMLInputElement>(null);
    const employeesStart = React.useRef<HTMLInputElement>(null);

    const [searchValue, setSearchValue] = useState('');

    const [message, setMessage] = useState('');

    const fetchEmployeesWithCommunications = () => {
        employeesApi.getIfCommunications().then(async (emp) => {
            for (const employee of emp.data) {
                await communicationsApi.find(employee.id).then((comm) => {
                    comm.data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                    employee.communications = comm.data
                })
            }
            emp.data.sort((a, b) => {
                if (a.communications && b.communications) {
                    return (
                        new Date(b.communications[b.communications.length - 1].createdAt).getTime() -
                        new Date(a.communications[a.communications.length - 1].createdAt).getTime()
                    )
                }
                return 0;
            })
            setConversationList(emp.data);
        })
    }

    const fetchEmployeesWithoutCommunications = () => {
        employeesApi.getIfNoCommunications().then((emp) => {
            emp.data.sort((a, b) => {
                return (a.firstName.toLowerCase() + a.lastName.toLowerCase()).localeCompare(
                    (b.firstName.toLowerCase() + b.lastName.toLowerCase())
                )
            })
            setNoConversationList(emp.data)
        })
    }

    const filterList = () => {
        setConversationFilteredList(searchByName(conversationList, searchValue))
        setNoConversationFilteredList(searchByName(noConversationList, searchValue))
    }

    const sendMessage = () => {
        if (selectedConversation !== undefined) {
            communicationsApi.create({
                text: message,
                senderId: checkToken(),
                receiverId: selectedConversation.id
            }).then(async () => {
                setMessage('');
                fetchEmployeesWithCommunications();
                fetchEmployeesWithoutCommunications();
                setSelectedConversation({
                    ...selectedConversation,
                    communications: (await communicationsApi.find(selectedConversation.id)).data.sort((a, b) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                })
                if (newConversation) {
                    setNewConversation(false)
                }
            })
        }
    }

    useEffect(() => {
        fetchEmployeesWithCommunications();
        fetchEmployeesWithoutCommunications();
    }, []);


    useEffect(() => {
        filterList();
        employeesStart.current?.scrollIntoView({ behavior: 'smooth' });
    }, [searchValue, conversationList, noConversationList]);

    useEffect(() => {
        chatEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedConversation]);

    const leftContent = (
        <Fragment>
            <Box ml={1}>
                <SearchInput
                    placeholder="Search..."
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                />
            </Box>
            {
                newConversation ? (
                    <Card sx={{ marginTop: 3, marginLeft: 1 }}>
                        <Grid container mt={2} >
                            <Grid item xs={10}>
                                <H6 px={2} mb={1} display={'inline-flex'}>
                                    New Chat
                                </H6>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    sx={{
                                        justifySelf: 'right',
                                        display: 'inline-flex',
                                        backgroundColor: "primary.main",
                                        "&:hover": { backgroundColor: "primary.main" },
                                    }}
                                    onClick={() => { setNewConversation(false) }}
                                >
                                    <Clear sx={{ fontSize: 18, color: "background.paper" }} />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12}>
                                {noConversationFilteredList.length > 0 ? <Scrollbar style={{ height: 500 }}>
                                    <div ref={employeesStart}></div>
                                    {noConversationFilteredList.map((item, index) => {
                                        return <CommunicationsConversationBox key={index} conversation={
                                            {
                                                name: item.firstName + " " + item.lastName,
                                                image: item.filePath ? item.filePath : "/static/avatar/001-man.svg",
                                                lastMsg: '',
                                                time: '',
                                                employee: item,
                                                selected: item.id === selectedConversation?.id,
                                                handleSelectConversation: setSelectedConversation
                                            }
                                        } />
                                    })}
                                </Scrollbar> :
                                    // 42, answer to everything
                                    <Tiny height={42} textAlign={'center'}>{t('chat.nochat')}</Tiny>
                                }
                            </Grid>

                        </Grid>
                    </Card>
                ) : (
                    <Card sx={{ marginTop: 3, marginLeft: 1 }}>
                        <Grid container mt={2} >
                            <Grid item xs={8}>
                                <H6 px={2} mb={1}>
                                    Recent Chats
                                </H6>
                            </Grid>
                            <Grid item xs={12}>
                                {conversationFilteredList?.length > 0 ?
                                    <Scrollbar style={{ height: 500, overflowAnchor: 'none' }}>
                                        {conversationFilteredList.map((item, index) => {
                                            return <CommunicationsConversationBox key={index} conversation={
                                                {
                                                    name: item.firstName + " " + item.lastName,
                                                    image: item.filePath ? item.filePath : "/static/avatar/001-man.svg",
                                                    lastMsg: item.communications ? (item.communications[item.communications.length - 1]).text : '',
                                                    time: item.communications ? new Date((item.communications[item.communications.length - 1]).createdAt).toLocaleString() : '',
                                                    employee: item,
                                                    selected: item.id === selectedConversation?.id,
                                                    handleSelectConversation: setSelectedConversation
                                                }
                                            } />
                                        })}

                                    </Scrollbar> :
                                    <Tiny height={42} textAlign={'center'}>{t('chat.noEmployees')}</Tiny>

                                }
                            </Grid>
                            <Grid item xs={10}></Grid>
                            <Grid item xs={2} mb={2}>
                                <IconButton
                                    sx={{
                                        justifySelf: 'right',
                                        marginLeft: 1.5,
                                        backgroundColor: "primary.main",
                                        "&:hover": { backgroundColor: "primary.main" },
                                    }}
                                    onClick={() => { setNewConversation(true) }}
                                >
                                    <Add sx={{ fontSize: 18, color: "background.paper" }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Card>)
            }
        </Fragment>
    );

    return (
        <Box pt={2} pb={4}>
            <Grid container spacing={3}>
                {downMd ? (
                    <Drawer
                        anchor="left"
                        open={openLeft}
                        onClose={() => { setOpenLeft(false) }}
                    >
                        <Box width={280} padding={2}>
                            {leftContent}
                        </Box>
                    </Drawer>
                ) : (
                    <Grid item lg={3} md={5}>
                        {leftContent}
                    </Grid>
                )}

                <Grid item lg={9} md={7} xs={12}>
                    <Card sx={{ position: "relative" }}>
                        <LeftButton screen="md" onClick={() => { setOpenLeft(true) }}>
                            <KeyboardArrowLeft fontSize="small" color="disabled" />
                        </LeftButton>
                        {selectedConversation ?
                            <>
                                <FlexBetween px={3} py={4}>
                                    <FlexBox alignItems="center">
                                        <Avatar
                                            src={selectedConversation.filePath ? selectedConversation.filePath : "/static/avatar/001-man.svg"}
                                            sx={{ backgroundColor: "primary.200" }}
                                        />
                                        <Box marginLeft={1}
                                            onClick={() => {
                                                navigate('/dashboard/employees/new', {
                                                    state: {
                                                        employeeId: selectedConversation.id,
                                                    },
                                                });
                                            }}
                                        >
                                            <H4>{selectedConversation.firstName + " " + selectedConversation?.lastName}</H4>
                                        </Box>
                                    </FlexBox>
                                </FlexBetween>

                                <Divider />

                                <Scrollbar style={{ height: 500 }} id={'conversation'} >
                                    <Box p={3} >
                                        {
                                            selectedConversation?.communications?.map((communication) => {
                                                if ((communication).senderId === selectedConversation.id) {
                                                    return <CommunicationsIncomingMsg msg={{
                                                        author: selectedConversation.firstName + ' ' + selectedConversation.lastName,
                                                        message: communication.text,
                                                        imageSrc: selectedConversation.filePath ? selectedConversation.filePath : "/static/avatar/001-man.svg",
                                                        date: new Date((communication).createdAt).toLocaleString()
                                                    }} />

                                                } else {
                                                    const imagePath = (communication.sender as Pick<EmployeeEntity, 'firstName' | 'lastName' | 'filePath'>).filePath
                                                    return <CommunicationsOutgoingMsg msg={{
                                                        message: communication.text,
                                                        imageSrc: imagePath || "/static/avatar/001-man.svg",
                                                        author: (communication.sender as Pick<EmployeeEntity, 'firstName' | 'lastName' | 'filePath'>).firstName + ' ' + (communication.sender as Pick<EmployeeEntity, 'firstName' | 'lastName' | 'filePath'>).lastName,
                                                        date: new Date((communication).createdAt).toLocaleString()
                                                    }} />
                                                }
                                            })
                                        }
                                        <div ref={chatEnd}></div>
                                    </Box>
                                </Scrollbar>

                                <Divider />

                                <FlexBetween p={3}>
                                    <InputBase
                                        placeholder={t('chat.write')}
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                        sx={{
                                            width: "100%",
                                            fontSize: 15,
                                            fontWeight: 500,
                                            color: "text.secondary",
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && !event.shiftKey) {
                                                event.preventDefault();
                                                sendMessage()
                                            }
                                        }}
                                    />

                                    <IconButton
                                        sx={{
                                            marginLeft: 1.5,
                                            backgroundColor: "primary.main",
                                            "&:hover": { backgroundColor: "primary.main" },
                                        }}
                                        onClick={() => { sendMessage() }}
                                        disabled={message.trim().length === 0}
                                    >
                                        <Send sx={{ fontSize: 18, color: "background.paper" }} />
                                    </IconButton>
                                </FlexBetween>
                            </> :
                            (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="80vh" // This will center the content vertically
                                    sx={{ gap: 5 }} // This adds space between the child elements
                                >
                                    <img
                                        src={emptyTableImage}
                                        alt="Loading image"
                                        style={{ width: '250px', height: '250px' }} // Adjust as needed
                                    />
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{ fontWeight: 'bold' }} // Adjust as needed
                                    >
                                        {t('employees.emptyTable.description')}
                                    </Typography>{' '}
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { setNewConversation(true) }}
                                        sx={{ fontSize: 'larger' }} // Makes the button text larger
                                    >
                                        {t('employees.newChat')}
                                    </Button>
                                </Box>
                            )}
                    </Card>
                </Grid>
            </Grid>
        </Box >
    );
};


export default Communications;