import { Avatar, Box, useTheme } from '@mui/material';
import { EmployeeEntity } from 'api/generated';
import FlexBox from 'components/flexbox/FlexBox';
import { H6, Tiny } from 'components/Typography';
import { FC } from 'react';

// component props interface
interface CommunicationsConversationBoxProps {
  conversation: {
    name: string;
    image: string;
    lastMsg: string;
    time: string;
    employee: EmployeeEntity;
    selected: boolean;
    handleSelectConversation: (e: EmployeeEntity) => void
  };
}

const CommunicationsConversationBox: FC<CommunicationsConversationBoxProps> = ({ conversation }) => {
  const theme = useTheme();

  if (conversation.lastMsg.length>35){
    conversation.lastMsg = conversation.lastMsg.slice(0, 34) + '...'
  }

  return (
        <FlexBox
        onClick={() => {conversation.handleSelectConversation(conversation.employee)}}
        p={2}
        borderBottom={1}
        alignItems='center'
        justifyContent='space-between'
        bgcolor={conversation.selected ? theme.palette.action.selected : ''}
        borderColor={theme.palette.mode === 'light' ? 'action.hover' : 'divider'}
        sx={{ '&:last-of-type': { borderBottom: 0 }, cursor: 'pointer' }}
        >
        <FlexBox alignItems='center'>
            <Avatar
            src={conversation.image}
            sx={{
                width: 30,
                height: 30,
                backgroundColor: 'primary.200',
            }}
            />
            <Box marginLeft={1}>
            <Tiny color='text.disabled'>{conversation.time}</Tiny>
            <H6>{conversation.name}</H6>

            <Tiny ellipsis display='block' color='text.disabled'>
                {conversation.lastMsg}
            </Tiny>
            </Box>
        </FlexBox>
        </FlexBox>
  );
};

export default CommunicationsConversationBox;
