import { Alert, Box, Button, Card, Grid, Snackbar, styled, useTheme } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { type FC, useEffect, useState } from 'react';
import AvatarBadge from 'components/avatars/AvatarBadge';
import Calendar from '../../page-sections/employees/calendar/Calendar';
import { CalendarEntryEntityTypeEnum, EmployeeEntity } from '../../api/generated';
import { useTranslation } from 'react-i18next';
import defaultProfileImage from '../../assets/images/default-profile.png';
import AppAvatar from 'components/avatars/AppAvatar';
import { alpha } from '@mui/material/styles';
import CustomToast from 'components/CustomToast/customToast';
import { employeesApi } from 'api';


export const ContentWrapper = styled(Box)(({ theme }) => ({
    zIndex: 1,
    marginTop: 55,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

export const CoverPicWrapper = styled(Box)(({ theme }) => ({
    top: 0,
    left: 0,
    height: 125,
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    margin: 'auto',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: 'white',
    backgroundColor: theme.palette.background.default,
}));

export interface GlobalCursorState {
    cursor: any;
    label: string;
    type: CalendarEntryEntityTypeEnum | null;
    color: string;
}

const UniqueEmployeeCalendar: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [employee, setEmployee] = useState<EmployeeEntity>();
    // define the method to set eventCount from Calendar class
    const [eventCount, setEventCount] = useState<Record<string, number>>()
    const [displayAvatar, setDisplayAvatar] = useState<string>(defaultProfileImage);
    const [showToast, setShowToast] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        employeesApi.getMySelf().then(({data}) =>{
            setEmployee(data)
            if (data.filePath){
                setDisplayAvatar(data.filePath);
            }
        })
    }, []);

    const [globalCursor, setGlobalCursor] = useState<GlobalCursorState>({
        cursor: 'default',
        label: 'default',
        type: null,
        color: 'black'
    });
    const [selectedColor, setSelectedColor] = useState('');

    const handleChangeCursorClick = (item: GlobalCursorState) => {
        setGlobalCursor({
            cursor: item.cursor,
            label: item.label,
            type: item.type,
            color: item.color
        });
        setSelectedColor(item.color);
    };
    const items = [
        { label: t('employees.calendar.types.half_day'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.HalfDay, color: '#00eaff' },
        { label: t('employees.calendar.types.day_off'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.DayOff, color: theme.palette.warning.main },
        { label: t('employees.calendar.types.vacation'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.Vacation, color: theme.palette.success.main },
        { label: t('employees.calendar.types.ill'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.Ill, color: theme.palette.error.main },
        { label: t('employees.calendar.types.unpaid_leave'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.UnpaidLeave, color: '#6c25be' },
    ];

    useEffect(() => {
        document.body.style.cursor = globalCursor.cursor;
        // Clean the cursor when the component is disassembled
        return () => {
            document.body.style.cursor = 'default';
        };
    }, [globalCursor]);

    const handleToastClose = () => {
        setShowToast(false);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    return (
        <Box pt={2} pb={4}>
            {showToast && (
                <CustomToast
                    message={(t('alerts.employeeNotFoundInSeason'))}
                    onClose={handleToastClose}
                    duration={3000}
                />
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleCloseSnackbar}
            >
                <Alert severity='error' style={{ borderRadius: '10px' }}>
                    {t('alerts.noEmployeesFoundForFilters')}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Card sx={{ padding: 3, position: 'relative' }}>
                        <CoverPicWrapper>
                            <img
                                width='100%'
                                height='100%'
                                alt='Team Member'
                                src='/static/background/user-cover-pic.png'
                                style={{ objectFit: 'cover' }}
                            />
                        </CoverPicWrapper>

                        <ContentWrapper>
                            <FlexBox justifyContent='center'>
                                <AvatarBadge>
                                    <ImageWrapper>
                                        <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='Team Member' sizes='large' />
                                    </ImageWrapper>
                                </AvatarBadge>
                            </FlexBox>

                        </ContentWrapper>
                    </Card>
                    <Box pt={2}>
                        <Card sx={{ padding: 3, position: 'relative' }}>
                            <Box
                                style={{
                                    padding: '8px',
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[300],
                                    marginBottom: '8px',
                                    borderRadius: '4px',
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        {t('employees.calendar.types.work')}:
                                    </Grid>
                                    <Grid item xs={2}>
                                        {eventCount ? eventCount.WORK : ""}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Box>
                    <Box pt={2}>
                        <Card sx={{ padding: 3, position: 'relative' }}>
                            <FlexBox justifyContent={'center'} flexDirection={'column'}>
                                <span style={{ marginBottom: '8px' }}>{t('employees.calendar.quickSelectionTool')}</span>
                                {items.map((item) => (
                                    <Box
                                        key={item.label}
                                        onClick={() => { handleChangeCursorClick(item) }}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '8px',
                                            border: `1px solid ${item.color}`,
                                            marginBottom: '8px',
                                            borderRadius: '4px',
                                            backgroundColor: selectedColor === item.color ? alpha(item.color, 0.2) : 'transparent',
                                            transition: 'background-color 0.5s'
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={10}>
                                                {item.label}:
                                            </Grid>
                                            <Grid item xs={2}>
                                                {eventCount ? eventCount[item.type] : ""}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                                {globalCursor.cursor !== 'default' && (
                                    <Button
                                        onClick={() => {
                                            setGlobalCursor({
                                                cursor: 'default',
                                                label: 'default',
                                                type: null,
                                                color: 'black'
                                            });
                                            setSelectedColor('');
                                        }}
                                        variant='contained'
                                        color='primary'
                                    >
                                        {t('employees.calendar.entry.resetTool')}
                                    </Button>
                                )}

                            </FlexBox>
                        </Card>
                    </Box>
                </Grid>

                <Grid item md={9} xs={12}>
                    {employee && (
                        <Calendar employeeId={employee.id} cursorType={globalCursor} setEventCount={setEventCount} userMode={true}/>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default UniqueEmployeeCalendar;
