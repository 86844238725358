
import { documentTypesApi, idDocumentsApi } from '../../api';
import React, { type FC, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Autocomplete, Box, Button, FormControlLabel, Grid, Switch, Typography, useTheme } from '@mui/material';
import {
  CreateEmployeeDocumentDto,
  DocumentFileEntity,
  DocumentTypeEntity,
  EmployeeDocumentEntity
} from '../../api/generated';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TabBase, { TabResourceProps } from './TabBase';
import AddDocumentTypeModal from 'page-sections/data-table/dataTableV2/AddDocumentTypeModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'icons/DeleteIcon';
import { v4 as uuidv4 } from 'uuid';
import UploadingOverlay from 'components/UploadOverlay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddDocumentModal from 'page-sections/data-table/dataTableV2/AddDocumentModal';
import AppTextField from 'components/input-fields/AppTextField';
import FlexBox from 'components/flexbox/FlexBox';
import { H3 } from 'components/Typography';
import SearchInput from 'components/input-fields/SearchInput';
import { searchDocumentByName } from 'utils/utils';
import CustomToast from 'components/CustomToast/customToast';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CalendarInput from 'components/input-fields/CalendarInput';
import DocumentFilesModal from 'page-sections/documents/DocumentFIlesModal';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import { AxiosResponse } from 'axios';
// import { ErrorSharp, TouchAppRounded } from '@mui/icons-material';

export type IDFormData = Omit<CreateEmployeeDocumentDto, 'employeeId'> & {
  id?: number;
  tempKey?: string;
  filePath?: string | null;
  isVisibleForUser?: boolean;
  index?: number;
  documentName?: string;
  expiringDate: string | null;
  documentFiles?: DocumentFileEntity[]
};


const IDDocument: FC<TabResourceProps<IDFormData[]>> = ({
  data,
  onSubmit,
  isProfileCompletition = false,
  onNewDocument,
  buttonFontSize,
  userMode
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const tenant = urlParams.get('tenant');

  const { t } = useTranslation();
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([]);
  const [isViewing, setIsViewing] = useState(!!data && !isProfileCompletition);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openDocumentTypeModal, setOpenDocumentTypeModal] = useState(false);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const initialValues: IDFormData[] = data ?? [];
  const [showToast, setShowToast] = useState({ show: false, message: '', type: '' });
  const [usedNames, setUsedNames] = useState<Record<string, string[]>>({});
  const [openDocumentFileModal, setOpenDocumentFileModal] = useState(false);
  const [modalFiles, setModalFiles] = useState<DocumentFileEntity[]>([])
  const [fileModalDocumentId, setFileModalDocumentId] = useState<number>();
  const [fileModalDocumentName, setFileModalDocumentName] = useState<string>();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>();


  const submitWithSpinner = (data: IDFormData[]) => {
    onSubmit(data, setIsLoading);
  }

  const validationSchema = Yup.array().of(
    Yup.object({
      tempKey: Yup.string(),
      isVisibleForUser: Yup.boolean().required(""),
      documentName: Yup.string().required(
        t('common.forms.field.required', {
          field: t('employees.id.documentName'),
        })
      ),

      expiringDate: !userMode && !isProfileCompletition ? Yup.string().nullable() :
        Yup.string().required(
          t('common.forms.field.required', {
            field: t('employees.id.documents.expirationDate'),
          })
        ),
      filePath: Yup.string().notRequired(),
      typeName: Yup.string().required(
        t('common.forms.field.required', {
          field: t('employees.id.documentTypes.label'),
        })
      ),
    }),
  ).required();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitWithSpinner
  });

  const [filteredItem, setFilteredItem] = useState(formik.values);

  const closeFileModal = () => {
    setOpenDocumentFileModal(false);
    // update document files in local datas
    if (currentDocumentIndex !== undefined && formik?.values?.at(currentDocumentIndex))
      formik.values[currentDocumentIndex].documentFiles = modalFiles;
  }

  useEffect(() => {
    const result = searchDocumentByName(formik.values, searchValue);
    setFilteredItem(result);
  }, [searchValue, formik.values]);

  const handleNameChange = (index: number, name: string | null) => {
    formik.setFieldValue(`[${index}].documentName`, name, true);
  };

  const { handleSubmit } =
    formik;

  const addDocumentFile = async (files: File[], documentId: number, index: number) => {
    const formData = new FormData();

    files.forEach(file => { formData.append('files', file) });

    // Early exit if no documentId is provided
    if (!documentId) return;

    if (setIsLoading) setIsLoading(true);

    const handleApiResponse = async (apiFunc: Promise<AxiosResponse<DocumentFileEntity[], any>>
    ) => {
      try {
        const response = await apiFunc;
        setShowToast({ show: true, message: t('alerts.fileEntred'), type: 'Created' });
        return response;
      } catch (error) {
        setShowToast({ show: true, message: t('alerts.fileFailed'), type: 'Error' });
        throw error;
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    };

    const apiFunction = token && tenant
      ? idDocumentsApi.createFileWithToken(String(documentId), tenant, { data: formData, params: { token } })
      : idDocumentsApi.createFile(String(documentId), { data: formData });

    const newDocument = await handleApiResponse(apiFunction);

    if (!newDocument) {
      setShowToast({ show: true, message: t('alerts.fileFailed'), type: 'Error' });
      return;
    }
    const currentDocumentFiles = formik.values[index].documentFiles || [];

    const updatedFiles = currentDocumentFiles.concat(newDocument.data);
    formik.values[index].documentFiles = updatedFiles;
  };

  const fetchDocumentTypes = () => {
    const handleResponse = ({ data }: any) => {
      setDocumentTypes(data);
      fetchUsedNames(data);
    }

    
    if (token && tenant) {
      documentTypesApi.findAllWithToken(tenant, { params: { token } })
        .then(handleResponse)
    } else {
      documentTypesApi.findAll()
        .then(handleResponse)
    }


  };

  const fetchUsedNames = (documentTypes: DocumentTypeEntity[]) => {
    const nameRecord: Record<string, string[]> = {};

    for (const documentType of documentTypes) {
      if (documentType.usedDocumentsNames)
        nameRecord[documentType.name] = documentType.usedDocumentsNames?.map(data => data.name);
    }
    setUsedNames(nameRecord);
  }

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    formik.setFieldValue(`[${index}].isVisibleForUser`, (event.target.checked), true)
  }


  const addNewDocumentFromModal = async (newDocument: IDFormData) => {
    newDocument.tempKey = uuidv4()
    if (onNewDocument)
      newDocument = await onNewDocument(newDocument) as EmployeeDocumentEntity;
    // saving index to reference the newDocument in the formik array when filtering
    newDocument.index = formik.values.length;
    const newArray = [...formik.values, newDocument];
    formik.setValues(newArray);
    setShowToast({ show: true, message: t('alerts.document'), type: 'Created' })
    return newArray;
  }

  const updateDocumentFromModal = (document: IDFormData, index: number) => {
    formik.setFieldValue(`[${index}].documentName`, document.documentName, true);
    formik.setFieldValue(`[${index}].isVisibleForUser`, document.isVisibleForUser, true);
    formik.setFieldValue(`[${index}].typeName`, document.typeName, true);
    formik.setFieldValue(`[${index}].expiringDate`, document.expiringDate, true);
  }

  const removeDocument = (indexToRemove: number) => {
    formik.setValues([
      ...formik.values.slice(0, indexToRemove),
      ...formik.values.slice(indexToRemove + 1)
    ]);
  };

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      if (token && tenant) {
        documentTypesApi.findAllWithToken(tenant, { params: { token } }).then(({ data }) => { setDocumentTypes(data); fetchUsedNames(data) });
      } else {
        documentTypesApi.findAll().then(({ data }) => { setDocumentTypes(data); fetchUsedNames(data) });
      }
    };

    fetchDocumentTypes();

  }, []);

  const handleCloseToast = () => {
    setShowToast({ show: false, message: '', type: '' });
  }

  function CategoryAccordion(types: DocumentTypeEntity[]) {

    const Accordions = types.map((type) => {


      const filtred = filteredItem.filter((value) => {
        return value.typeName === type.name
      })


      if (filtred.length > 0) {

        return (
          <Accordion key={type.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignContent={'center'} justifyContent={'center'} padding={"10px"} >

                <Grid item xs={9} sm={11} zeroMinWidth >
                  <H3
                    style={{
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      minWidth: '0',
                      maxWidth: '100%', // Ensures the text does not exceed the parent width
                      display: 'flex',
                      padding: '5px 10px',
                    }}>
                    {type.name}
                  </H3>
                </Grid>

                <Grid item xs={3} sm={1}>
                  <H3
                    style={{
                      textAlign: 'center',
                      display: 'inline-block',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      borderRadius: '150px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                      width: '70%'
                    }}>
                    {filtred.length}
                  </H3>
                </Grid>

              </Grid>
            </AccordionSummary>

            {
              filtred.map((currentFile) => {
                return (
                  <FlexBox sx={{ borderTop: 1, borderColor: "lightgray", padding: 2, paddingLeft: '1rem' }} key={currentFile.index}>
                    {
                      <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={6} sm={4} display={'flex'} alignItems={'center'}>
                          <Autocomplete
                            freeSolo
                            options={usedNames[currentFile.typeName]}
                            disabled={isProfileCompletition || isViewing || userMode}
                            value={currentFile.documentName}
                            onChange={(e, value) => {
                              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                              handleNameChange(currentFile.index!, value)
                            }}
                            fullWidth
                            disableClearable
                            renderInput={(params) => {
                              return (
                                <AppTextField
                                  {...params}
                                  fullWidth
                                  disabled = {userMode}
                                  size='small'
                                  name='documentName'
                                  onChange={(e) => {
                                    if (e.target.value !== "")
                                      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                      handleNameChange(currentFile.index!, e.target.value)
                                    else
                                      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                      handleNameChange(currentFile.index!, null);
                                  }}

                                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                  error={Boolean(formik.errors[currentFile.index!]?.documentName)}
                                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                  helperText={formik.errors[currentFile.index!]?.documentName}
                                />
                              )
                            }}

                          />
                        </Grid>
                        <Grid xs={6} sm={4} alignSelf={'stretch'} sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end'
                        }}>
                          {(!isProfileCompletition && !userMode &&
                            <FormControlLabel
                              sx={{ minWidth: '0px' }}
                              label={<Typography noWrap textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.id.documentTypes.isVisible')}</Typography>}
                              labelPlacement='start'
                              control=
                              {<Switch
                                disabled={isViewing}
                                checked={currentFile.isVisibleForUser}
                                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                onChange={(e) => { handleCheckedChange(e, currentFile.index!) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6} sm={3} textAlign={'center'} display={'flex'} alignItems={'center'} >
                          { // if at least a file is linked to this document show document modal, otherwise show upload file button
                            currentFile.documentFiles && currentFile.documentFiles.length > 0 ?
                              (<Button sx={{ textAlign: 'center' }} fullWidth onClick={() => {
                                // open modal
                                setOpenDocumentFileModal(true);
                                if (currentFile.documentFiles)
                                  // set modal files with current document files
                                  setModalFiles(currentFile.documentFiles)
                                // set id with current document id
                                if (currentFile.id)
                                  setFileModalDocumentId(currentFile.id)
                                // null index check or if it's 0 (to avoid to evaluate 0 as false)
                                if (currentFile.index || currentFile.index === 0)
                                  setCurrentDocumentIndex(currentFile.index)
                                // set document name
                                setFileModalDocumentName(currentFile.documentName);
                              }}>

                                <>{t('employees.id.showFiles')}</>
                              </Button>) : (
                                <FileUploadInput
                                  resetOnChange
                                  multiple
                                  disabled={isViewing}
                                  maxDimension={100}
                                  handleOnChange={(e) => {
                                    if (e.target.files && currentFile.id !== undefined && currentFile.index !== undefined)
                                      addDocumentFile(Array.from(e.target.files), currentFile.id, currentFile.index);
                                  }} />
                              )
                          }

                        </Grid>
                        <Grid item xs={6} sm={1} sx={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignSelf: 'stretch',
                        }}>

                          {(!isProfileCompletition && !userMode &&
                            <DeleteIcon
                              sx={{ alignSelf: 'center', display: 'flex' }}
                              color={isViewing ? "disabled" : "error"}
                              onClick={() => {
                                if (!isViewing)
                                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                  removeDocument(currentFile.index!)
                              }}
                              style={{
                                cursor: 'pointer',

                              }}
                            />
                          )}

                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <CalendarInput
                            disabled={isViewing}
                            format='dd.MM.yyyy'
                            label={t('employees.id.documents.expirationDate')}
                            disableFuture={false}
                            localeText={{
                              fieldDayPlaceholder: () => t("DD"),
                              fieldMonthPlaceholder: () => t("MM"),
                              fieldYearPlaceholder: () => t("YYYY"),
                            }}
                            value={currentFile.expiringDate ? new Date(currentFile.expiringDate) : null
                            }

                            onChange={(newValue) => {
                              if (newValue && !isNaN(new Date(newValue).getTime())) {
                                const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                formik.setFieldValue(`[${currentFile.index}].expiringDate`, (utcDate.toISOString()), true)
                              }
                              else
                                formik.setFieldValue(`[${currentFile.index}].expiringDate`, null, true)
                            }}
                            slotProps={{
                              textField: {
                                helperText:
                                  currentFile.index !== undefined &&
                                    formik.touched[currentFile.index]?.expiringDate &&
                                    formik.errors[currentFile.index]?.expiringDate
                                    ? formik.errors[currentFile.index]?.expiringDate
                                    : t("DD") + "." + t("MM") + "." + t("YYYY"),
                                error: Boolean(
                                  currentFile.index !== undefined &&
                                  formik.touched[currentFile.index]?.expiringDate &&
                                  formik.errors[currentFile.index]?.expiringDate
                                )
                              },
                              actionBar: {
                                actions: ['clear']
                              }

                            }}
                          />
                        </Grid>
                      </Grid>

                    }
                  </FlexBox>
                )
              })
            }


          </Accordion >
        )
      }
      else {
        return (<></>)
      }
    })

    return (
      <Box maxHeight={'50vh'} overflow={'auto'}>
        {Accordions}
      </Box>);
  }



  return (
    <TabBase
      title={t('employees.id.title')}
      setIsViewing={setIsViewing}
      isViewing={isViewing}
      handleSubmit={handleSubmit}
      isProfileCompletition={isProfileCompletition}
    >


      {showToast.show &&
        <CustomToast
          duration={3000}
          onClose={handleCloseToast}
          message={showToast.message}
          icon={showToast.type === 'Created' ? <CheckCircleOutlineRoundedIcon /> : ''}
        />
      }

      {isLoading &&
        <UploadingOverlay />
      }

      {fileModalDocumentId && <DocumentFilesModal
        onClose={closeFileModal}
        onDocumentRemove={(id, index) => {
          // remove file from database
          isProfileCompletition ? (tenant && token) && idDocumentsApi.removeFileWithToken(String(id), tenant, { params: { token } }) : idDocumentsApi.removeFile(String(id))
          // remove file from local datas
          if (currentDocumentIndex !== undefined && formik?.values?.at(currentDocumentIndex)) {
            setModalFiles(
              [
                ...modalFiles.slice(0, index),
                ...modalFiles.slice(index + 1)
              ]
            )
          }
        }}
        isCompiledByEmployee={!!(token && tenant)}
        open={openDocumentFileModal}
        data={modalFiles}
        setData={setModalFiles}
        documentId={fileModalDocumentId}
        documentName={fileModalDocumentName ?? ''}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setShowToastCallback={setShowToast}
        userMode = {userMode}
      />
      }
      {!userMode && <AddDocumentModal
        addNewDocument={addNewDocumentFromModal}
        updateDocument={updateDocumentFromModal}
        open={openDocumentModal}
        documentTypes={documentTypes}
        setIsLoading={setIsLoading}
        onClose={() => {
          setOpenDocumentModal(false);
        }}
        setCurrentDocumentIndex={setCurrentDocumentIndex}
        setFileModalDocumentId={setFileModalDocumentId}
        setModalFiles={setModalFiles}
        setOpenDocumentFileModal={setOpenDocumentFileModal}
      />}

      <AddDocumentTypeModal
        open={openDocumentTypeModal}
        onClose={() => {
          setOpenDocumentTypeModal(false);
          fetchDocumentTypes();
        }}
        setShowToastCallback={setShowToast}
      />

      {!userMode && <SearchInput
        bordered={false}
        placeholder={t('employees.id.search')}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      }


      {CategoryAccordion(documentTypes)}

      {!isProfileCompletition && !userMode && (
        <Grid container spacing={2}>

          <Grid item xs={5} sm={5}>
            <Button
              sx={{ textAlign: 'left', fontSize: buttonFontSize }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenDocumentModal(true)
              }}
            >

              {t('employees.contract.contractFile')}

            </Button>
          </Grid>

          <Grid item xs={1} sm={0}>

          </Grid>

          <Grid item xs={5} sm={5} >
            <Button
              sx={{ textAlign: 'left', fontSize: buttonFontSize }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenDocumentTypeModal(true)
              }}
            >
              {t('employees.id.documentTypes.add')}

            </Button>
          </Grid>


        </Grid>
      )}





    </TabBase>
  );
};




export default IDDocument;
