import { Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import { H2, H6 } from 'components/Typography';
import { useFormik } from 'formik';
import { type FC } from 'react';
import * as Yup from 'yup';
import { licenceApi } from '../../../api';
import { useTranslation } from 'react-i18next';

// component props interface
interface ModalProps {
  data?: any;
  open: boolean;
  edit?: boolean;
  fetchData: () => void;
  onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 450,
  minWidth: 200,

  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddLicenceModal: FC<ModalProps> = ({
  open,
  fetchData,
  onClose,
  edit,
  data,
}) => {
  const initialValues = {
    name: data?.name || '',
  };
  const { t } = useTranslation();

  const fieldValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required(
      t('common.forms.field.required', {
        field: t('employees.additionalInformation.licence'),
      }),
    ),
  });

  const { values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik({
    initialValues,
    validationSchema: fieldValidationSchema,
    onSubmit: (values) => {
      licenceApi
        .create({ name: values.name.trim() })
        .then(() => {
          onClose();
          fetchData();
          resetForm();
        })
    },
  });

  return (
    <StyledAppModal open={open} handleClose={() => {
      onClose();
      resetForm();
    }}>
      <H2 mb={2}>
        {edit ? t('common.forms.button.edit')+' '+t('expLevels.name') : t('common.forms.addItemLabel', {
          item: t('employees.additionalInformation.licence'),
        })}
      </H2>

      <form onSubmit={handleSubmit}>
        <Grid item xs={6}>
          <H6 mb={1}>{t('employees.additionalInformation.licence')}</H6>
          <AppTextField
            fullWidth
            size='small'
            name='name'
            placeholder={t('employees.additionalInformation.licence')}
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name && touched.name)}
            helperText={(touched.name && errors.name) as string}
          />
        </Grid>

        <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
          <Button fullWidth size='small' variant='outlined' onClick={() => {
            onClose();
            resetForm();
          }}>
            {t('common.forms.button.cancel')}
          </Button>
          <Button fullWidth size='small' type='submit' variant='contained'>
            {t('common.forms.button.save')}
          </Button>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AddLicenceModal;
