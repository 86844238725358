import { Box, Button, Divider, Grid, styled } from '@mui/material';
import { calendarEntriesApi } from 'api';
import { CalendarEntryEntity } from 'api/generated';
import AppModal from 'components/AppModal';
import { H2, H4 } from 'components/Typography';
import { t } from 'i18next';
import { type FC } from 'react';

// component props interface
interface ModalProps {
  open: boolean;
  onClose: () => void;
  fetchData: () => void;
  requests: CalendarEntryEntity[]
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 600,
  minWidth: 300,
  boxShadow: theme.shadows[2],
  [theme.breakpoints.down('sm')]: {
    '& .main-form': { height: 200, overflow: 'auto' },
  },
}));

const PendingRequestsModal: FC<ModalProps> = ({ open, onClose, fetchData, requests }) => {

  function handleDelete(id: number, seasonId: number, employeeId: number) {
    if (requests.length <= 1) {
      onClose();
    }
    calendarEntriesApi.refuse(String(id), seasonId, employeeId).then(() => {
      fetchData();
    })
  }

  function handleAccept(id: number, seasonId: number, employeeId: number) {
    if (requests.length <= 1) {
      onClose();
    }
    calendarEntriesApi.accept(String(id), seasonId, employeeId).then(() => {
      fetchData();
    })
  }

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2}>{t('employees.calendar.request')}</H2>
      <Box maxHeight={'80vh'} overflow={'scroll'}>
        {
          requests.map((req, index, requests) => {
            return <Box style={{ margin: 2 }}>
              <Grid container>
                <Grid item md={7} xs={12}>
                  <H4>{req.employeeToSeason?.employee?.firstName + " " + req.employeeToSeason?.employee?.lastName + " - " + t(`employees.calendar.types.${req.type.toLowerCase()}`)}</H4>
                  <p>
                    {req.startedOn !== req.endedOn ? new Date(req.startedOn).toLocaleDateString() + " - " + new Date(req.endedOn).toLocaleDateString() :
                      new Date(req.startedOn).toLocaleDateString()}
                  </p>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Button color='success' variant='contained' sx={{ margin: 1 }} onClick={() => { handleAccept(req.id, req.seasonId, req.employeeId) }}>{t('employees.calendar.entry.accept')}</Button>
                  <Button color='error' variant='contained' sx={{ margin: 1 }} onClick={() => { handleDelete(req.id, req.seasonId, req.employeeId) }}>{t('employees.calendar.entry.refuse')}</Button>
                </Grid>

              </Grid>
              {index !== (requests.length - 1) ? <Divider /> : <></>}
            </Box>
          })
        }
      </Box>
    </StyledAppModal>)
};

export default PendingRequestsModal;
