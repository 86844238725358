import { Button, styled } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import { t } from "i18next";
import { FC } from "react";
import { EmployeeEntity, TurnoverItemEntity } from "api/generated";
import FlexBox from "components/flexbox/FlexBox";
import { turnoverApi } from "api";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    fetchData: () => void;
    item?: TurnoverItemEntity;
    employee?: EmployeeEntity
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: 450,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const ControllerModal: FC<ModalProps> = (
    {
        open,
        onClose,
        item,
        employee,
        fetchData
    }
) => {


    const onConfirm = async () => {
        if (!item)
            return
        await turnoverApi.setAcceptedByController(String(item.id));
        fetchData();
        onClose();
    }

    return (
        <StyledAppModal open={open} handleClose={() => { onClose() }} >
            <H2>{t('confirmMoney')}</H2>

            {
                // TODO Check translation
            }
            <FlexBox mt={3}>
                {t('confirmReceiveMoneyDialog',
                    {
                        value: item?.value,
                        employeeName: employee?.firstName + ' ' + employee?.lastName
                    }
                )}
            </FlexBox>

            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => { onClose() }}>
                    {t('cancel')}
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => { onConfirm() }}>
                    {t('confirm')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default ControllerModal;