import { Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import AppTextField from 'components/input-fields/AppTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC } from 'react';
import { H2 } from 'components/Typography';
import { CreateEmergencyContactDto } from 'api/generated';


interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<CreateEmergencyContactDto, 'additionalInformationId'>) => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 900,
  maxHeight: 800,
  padding: theme.spacing(5),
  [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddEmergencyContactModal: FC<ModalProps> = ({ open, onClose, onSubmit}) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      phone: '',
      relation: '',
      language: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('common.forms.field.required')),
      lastName: Yup.string().required(t('common.forms.field.required')),
      phone: Yup.string().required(t('common.forms.field.required')),
      relation: Yup.string().required(t('common.forms.field.required')),
      language: Yup.string().required(t('common.forms.field.required')),
    }),
    onSubmit: async values => {
      onSubmit(values);
      formik.resetForm();
      onClose()
    },
  });



  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledAppModal open={open} handleClose={onClose}>
        <H2>{t('common.tables.button.addItem', { item: t('employees.additionalInformation.emergencyContact') })}</H2>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4} marginTop={1}>
          <Grid item xs={6}>
              <AppTextField
                name="name"
                label={t('employees.generalInformation.firstName')}
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="lastName"
                label={t('employees.generalInformation.lastName')}
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="phone"
                label={t('employees.generalInformation.phone')}
                fullWidth
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="relation"
                label={t('employees.additionalInformations.relation')}
                fullWidth
                value={formik.values.relation}
                onChange={formik.handleChange}
                error={formik.touched.relation && Boolean(formik.errors.relation)}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                name="language"
                label={t('employees.additionalInformations.spokenLanguages')}
                fullWidth
                value={formik.values.language}
                onChange={formik.handleChange}
                error={formik.touched.language && Boolean(formik.errors.language)}
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button variant='outlined' fullWidth onClick={() => {
                  onClose();
                  formik.resetForm();
                }}>
                  {t('common.forms.button.cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" fullWidth variant='contained'>
                  {t('common.forms.button.save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledAppModal>
    </LocalizationProvider>
  );
};

export default AddEmergencyContactModal;
