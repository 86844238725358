import { Alert, Button, Grid, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { shiftApi } from "api";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { useSeason } from "contexts/SeasonContext";
import { addDays } from "date-fns";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEndDateValid, isValidDate } from "utils/dateValidator";
import * as Yup from 'yup';


interface ModalProps {
    open: boolean;
    department: { id: number, label: string };
    onClose: () => void;
    onSubmit: (startDate: Date, endDate: Date, mode: string) => void;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 700,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const PublishShiftsDatePicker: FC<ModalProps> = ({ open, onClose, onSubmit, department }) => {




    const { t } = useTranslation();


    const [mode, setMode] = useState('department');

    const [updateStatus, setUpdateStatus] = useState(0);

    const { seasonId } = useSeason();

    const fieldValidationSchema = Yup.object().shape({
        startDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('is-valid-date', 'Invalid date', (value) => {
                return isValidDate(value);
            }),
        endDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('isValidDate', 'Invalid date', (value) => {
                return isValidDate(value);
            })
            .test('isEndDateValid', t('employees.agreements.validation.endDateTime'), function (value) {
                return isEndDateValid(value, this.parent.startDate);
            }),
    });


    const initialValues = {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
    };



    const { values, errors, handleSubmit, setFieldValue } = useFormik(
        {
            initialValues,
            validationSchema: fieldValidationSchema,
            onSubmit: ((values) => {
                onSubmit(values.startDate, values.endDate, mode);
                onClose();
            })
        }
    )

    const fetchUpdateStatus = async () => {
        // -1 = no updates, 0 = to publish, 1 = to update
        if (department.id !== 0 || mode !== 'department') {
            const status = await shiftApi.isUpdate({
                params: {
                    startDate: values.startDate,
                    endDate: values.endDate,
                    departmentId: department && mode === 'department' ? +department.id : undefined,
                    seasonId
                }
            });

            setUpdateStatus(status.data as any);
        }
    }

    useEffect(() => {
        fetchUpdateStatus();
    }, [values.startDate, values.endDate, department, open, mode])

    useEffect(() => {
        setFieldValue('endDate', addDays(new Date(), 7));
        setFieldValue('startDate', new Date());
        setMode('department')
    }, [open])

    return (
        <StyledAppModal
            open={open}
            handleClose={() => { onClose() }}
        >
            <form onSubmit={handleSubmit}>
                <Grid container justifyContent='space-between' spacing={1}>
                    <Grid item xs={12}>
                        <H2> {t('shift.selectDate')} </H2>
                    </Grid>
                    <Grid item xs={12} marginBottom={2} marginTop={1}>
                        {t('shift.publishMode') + ': '}
                        <ToggleButtonGroup
                            color="primary"
                            sx={{ marginLeft: 2 }}
                            value={mode}
                            exclusive
                            onChange={(e, value) => {
                                setMode(value)
                            }}
                        >
                            <ToggleButton value={'department'}>
                                {t('department.itemName') + ': ' + department.label}
                            </ToggleButton>
                            <ToggleButton value={'all'}>
                                {t('common.all')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={6} marginBottom={2}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            label={t('season.startDate')}
                            value={values.startDate}
                            onChange={(value) => {
                                if (value && !isNaN(+value))
                                    setFieldValue('startDate', value);
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.startDate,
                                    helperText: errors.startDate as any ?? ''
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} marginBottom={2}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            label={t('season.endDate')}
                            value={values.endDate}
                            onChange={(value) => {
                                if (value && !isNaN(+value))
                                    setFieldValue('endDate', value);
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.endDate,
                                    helperText: errors.endDate as any ?? ''
                                }
                            }}
                        />
                    </Grid>
                    {
                        updateStatus === -1 && (
                            <Grid xs={12}>
                                <Alert severity="warning">
                                    {t('shift.noUpdates')}
                                </Alert>
                            </Grid>
                        )
                    }


                    <Grid item xs={5}>
                        <Button fullWidth size='small' variant='outlined' onClick={() => { onClose() }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button fullWidth size='small' type="submit" variant='contained' disabled={updateStatus === -1}>
                            {updateStatus === 1 ? t('shift.update') : t('shift.publish')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </StyledAppModal>
    )

}

export default PublishShiftsDatePicker;