import { createTheme, responsiveFontSizes } from '@mui/material';
import { THEMES } from '../constants';
import components from './components';
import { shadows } from './shadows';
import themesOptions from './themeOptions';

const baseOptions = {
  direction: 'ltr',
  typography: { fontFamily: "'Montserrat', sans-serif" },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};

export interface themeSettingsTypes {
  activeLayout?: string;
  theme: string;
  direction: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
}

export const createCustomTheme = (settings: themeSettingsTypes) => {
  /**
   * settings.theme value is 'light' or 'dark'
   * update settings in contexts/settingsContext.tsx
   */
  let themeOptions: any = themesOptions[settings.theme];

  if (!themeOptions) {
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const mergedThemeOptions = {
    ...baseOptions,
    ...themeOptions,
    direction: settings.direction,
    palette: {
      ...baseOptions,
      ...themeOptions.palette,
      orange: {
        light: '#fddfae',
        main: '#fddfae',
        dark: '#F28F3B',
      },
      green: {
        light: '#ccfdbb',
        main: '#ccfdbb',
        dark: '#1A8E2A',
      },
      red: {
        light: '#fecfd0',
        main: '#fecfd0',
        dark: '#ff0000',
      }
    }
  };

  let theme = createTheme(mergedThemeOptions);

  // set shadows
  theme.shadows = shadows(theme);
  // set components
  theme.components = components(theme);
  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
