import { Button, Grid, InputAdornment, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2 } from 'components/Typography';
import { useFormik } from 'formik';
import { useState, type FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CalendarInput from 'components/input-fields/CalendarInput';
import { employeePaymentsApi } from '../../../api';
import { checkToken } from 'utils/checkToken';
import AppTextField from 'components/input-fields/AppTextField';
import { toast } from 'react-hot-toast';
import { useSeason } from 'contexts/SeasonContext';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import UploadingOverlay from 'components/UploadOverlay';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import { encryptData } from 'utils/encryptionDecryptionAgreements';
import { EmployeeEntity } from 'api/generated';

export interface EmployeeEntityWithAmount extends EmployeeEntity {
    amount?: number;
}

// component props interface
interface ModalProps {
    data?: any;
    open: boolean;
    onClose: () => void;
    setTableData: any;
    tableData: any;
    employees?: EmployeeEntityWithAmount[];
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 600,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddPaymentModal: FC<ModalProps> = ({
    open,
    onClose,
    data,
    setTableData,
    tableData,
    employees,
}) => {
    const { t } = useTranslation();
    const [date, setDate] = useState<Date | null>(new Date());
    const authoriser = checkToken();
    const { seasonId } = useSeason();
    const [isDateValid, setIsDateValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { encryptionKey } = useEncryption();

    const initialValues: Array<{ amount: number | null, file: File | null }> = employees?.map((employee) => {
        return {
            amount: employee.amount ?? null,
            file: null
        } 
    }) ?? [];

    const fieldValidationSchema = Yup.array().of(
        Yup.object({
            amount: Yup.number().required(t('payment.invalidAmmount'))
        })
    ).required();

    const handleClose = () => {
        resetForm();
        onClose();
        setDate(new Date());
    }

    const handleFileChange = (file: File, index: number) => {
        setFieldValue(`[${index}].file`, file);
    }


    const {values, handleSubmit, resetForm, setFieldValue, errors, touched } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            if (date && employees) {
                const employeesWithPayment: Array<{ id: number; amount: number; hasFile: boolean }> = values.map((value, index) => {
                    let hasFile = false;
                    if (value.file) {
                        hasFile = true;
                        formData.append('files', value.file);
                    }
                    return {
                        id: +employees[index].id,
                        amount: encryptionKey ? 0 : value.amount ?? 0,
                        amount_encrypted: encryptionKey ? encryptData(value.amount, encryptionKey) : '',
                        hasFile
                    }
                });


                formData.append('employeesWithPayment', JSON.stringify(employeesWithPayment));
                formData.append('authoriserId', authoriser.toString());  // Assuming 'authoriser' is a simple string or number
                formData.append('paymentDate', date.toISOString());
                formData.append('seasonId', seasonId.toString());  // Assuming 'seasonId' is a simple string or number

                setIsLoading(true);

                employeePaymentsApi
                    .create({
                        data: formData
                    })
                    .then(() => {
                        const updatedData = tableData?.map((employee: any) => {
                            const employeeInPaymentList = employeesWithPayment.find(paymentInfo => paymentInfo.id === employee.id);

                            if (employeeInPaymentList) {
                                const newPayment = {
                                    id: Date.now(),
                                    employeeId: employee.id,
                                    authoriserId: authoriser,
                                    authorisationDate: new Date().toISOString(),
                                    paymentDate: date.toISOString(),
                                    paid: employeeInPaymentList.amount,
                                    seasonId,
                                };

                                return {
                                    ...employee,
                                    payments: [...employee.employeesToSeasons[0].payments, newPayment],
                                };
                            }
                            return employee;
                        });
                        setTableData(updatedData);
                        handleClose();
                        toast.success(t('alerts.success'))
                    }).catch((e) => {
                        console.log(e);

                        handleClose();
                        toast.error(t('alerts.error'))
                    }).finally(() => {
                        setIsLoading(false);
                    })
            }
        },
    });


    return (
        <StyledAppModal open={open} handleClose={() => {
            handleClose();
        }}>
            <H2 mb={2}>
                {t('payment.collectionName')}
            </H2>

            <form onSubmit={handleSubmit}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    {isLoading &&
                        <UploadingOverlay />
                    }
                    <Grid item xs={6}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={date}
                            onChange={(newValue) => {

                                if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                    setDate(newValue);
                                    setIsDateValid(true);
                                }
                                else
                                    setIsDateValid(false);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: !isDateValid && t('date.error'),
                                    error: !isDateValid
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                {employees?.length && (
                    <FlexBox flexDirection={'column'} marginTop={'2rem'} gap={2}>
                        {
                        employees?.map((employee, index: number) => {
                            // const selectName = `employee_${employee.original.id}`;
                            return (
                                <Grid container key={employee.id} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item xs={6}>
                                        <span>{employee.firstName} {employee.lastName}</span>
                                    </Grid>
                                    <Grid item xs={3} paddingLeft={1}>
                                        <AppTextField
                                            type='number'
                                            size='small'
                                            inputProps={{
                                                min: 0,
                                                step: 0.01,
                                                style: { textAlign: 'right' }, 
                                            }}
                                            helperText={touched[index]?.amount && errors[index]?.amount}
                                            error={Boolean(touched[index]?.amount && errors[index]?.amount)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                                            }}
                                            value={values[index]?.amount}
                                            onChange={(e) => {
                                                const newValue = parseFloat(e.target.value);
                                                if (!isNaN(newValue)) {
                                                    setFieldValue(`[${index}].amount`, newValue);
                                                } else {
                                                    setFieldValue(`[${index}].amount`, null);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={3} paddingLeft={1}>
                                        <FileUploadInput
                                            maxDimension={30}
                                            handleOnChange={(e) => {
                                                if (e.target.files)
                                                    handleFileChange(e.target.files[0], index);
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            );
                        })}
                    </FlexBox>
                )}

                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        handleClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal >
    );
};

export default AddPaymentModal;
